import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: '680px !important',
      minHeight: '330px !important',
      maxWidth: '680px !important'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    gap: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      width: '95%',
      margin: '0 auto',
      gap: theme.spacing(5)
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      gap: 0,
      marginBottom: theme.spacing(),
      marginRight: theme.spacing(3)
    }
  },
  button: {
    width: 200,
    borderRadius: 10
  }
}))
