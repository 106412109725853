import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import { PaymentGatewayProxy } from 'services'
import {
  Authenticated,
  ConfirmInscriptionDialog,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  SuccessInscriptionDialog
} from 'shared'
import {
  eventInscription,
  load,
  loadActiveInscriptionForm,
  loadCancelledEventInscription,
  loadEventInscription,
  loadReset,
  resetInscriptionForms,
  resetValue
} from 'state/modules/events'
import {
  calculatePercentage,
  getDateEventFormat,
  getFeeAmount,
  validateServiceLetter
} from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { InscriptionForm } from '../InscriptionPage/components'

import {
  InfoMessageDialog,
  PlaceholderLoader,
  RequiredSportsRoleDialog,
  SuccessInscriptionPendingDialog
} from './components'
import { useStyles } from './InscriptionFormPage.style'

const OTHER_OPTION = { label: 'Otra', value: 'other' }

const EVENT_INSCRIPTION_STATES = {
  Pending: 'Pending',
  Accepted: 'Accepted',
  Cancelled: 'Cancelled'
}

const CUSTOM_FIELD_TYPES = {
  List: 'List',
  Text: 'Text',
  Number: 'Number',
  File: 'File',
  Boolean: 'Boolean'
}

const PAYMENT_TYPES = {
  MercadoPago: 'MercadoPago',
  BankAccount: 'BankAccount',
  PaymentLocation: 'PaymentLocation',
  PaymentUrl: 'PaymentUrl'
}

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const TICKET_FREE_PRICE = 0

const InscriptionFormPage = () => {
  const classes = useStyles()
  const { slugUrlOrId, eventInscriptionId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    event,
    pending,
    error: asyncError,
    activeInscriptionForm,
    activeInscriptionFormPending,
    eventInscriptionError,
    ticketTypeFees,
    cancelledEventInscription,
    cancelledEventInscriptionPending
  } = useSelector((state) => state.events)
  const { user, userData } = useSelector((state) => state.auth)

  const [inscription, setInscription] = useState({})
  const [openConfirmInscriptionDialog, setOpenConfirmInscriptionDialog] = useState(false)
  const [openSuccessInscriptionPendingDialog, setOpenSuccessInscriptionPendingDialog] =
    useState(false)
  const [openSuccessInscriptionDialog, setOpenSuccessInscriptionDialog] = useState(false)
  const [infoOpenDialog, setInfoOpenDialog] = useState(false)
  const [openRequiredSportsRoleDialog, setOpenRequiredSportsRoleDialog] = useState(false)

  const activeLoading =
    (!pending && !event && !asyncError) ||
    pending ||
    activeInscriptionFormPending ||
    (eventInscriptionId && cancelledEventInscriptionPending)

  useEffect(() => {
    dispatch(load(slugUrlOrId))
    return () => {
      dispatch(loadReset())
    }
  }, [dispatch, slugUrlOrId, user])

  useEffect(() => {
    if (userData && !userData.isAthlete) {
      setOpenRequiredSportsRoleDialog(true)
    }
  }, [userData])

  useEffect(() => {
    if (eventInscriptionError) showSnackbarError(eventInscriptionError)
    return () => dispatch(resetValue('eventInscriptionError'))
  }, [eventInscriptionError])

  useEffect(() => {
    if (event && !!event.activeInscriptionFormId) dispatch(loadActiveInscriptionForm(event))
    return () => {
      dispatch(resetInscriptionForms())
    }
  }, [dispatch, event])

  useEffect(() => {
    if (
      activeInscriptionForm &&
      ((activeInscriptionForm.allowUnregisteredUsers && !userData) || userData?.isAthlete)
    )
      setInfoOpenDialog(true)
  }, [activeInscriptionForm])

  const formData = useMemo(
    () => ({
      ...activeInscriptionForm,
      ticketTypes: activeInscriptionForm?.ticketTypes.filter((x) => x.isActive),
      isFree: event?.isFree,
      customFields: activeInscriptionForm
        ? activeInscriptionForm.customFields
            .map((e) => ({
              ...e.eventCustomField,
              value: '',
              itemNumber: e.itemNumber
            }))
            .sort((a, b) => a.itemNumber - b.itemNumber)
        : []
    }),
    [activeInscriptionForm, event]
  )

  useEffect(() => {
    if (eventInscriptionId) dispatch(loadCancelledEventInscription(eventInscriptionId))

    return () => dispatch(resetValue('cancelledEventInscription'))
  }, [dispatch, eventInscriptionId])

  const renderLoading = () => <PlaceholderLoader />

  const getFinalPriceMercadoPago = (
    priceWithDiscount,
    mercadoPagoFeePercentage,
    sportmetricPercentage,
    sportmetricFeeAmount,
    hasDiscount
  ) => {
    const price = hasDiscount ? priceWithDiscount + sportmetricFeeAmount : priceWithDiscount

    return Number(
      (
        -Number(price) /
        (calculatePercentage(!hasDiscount ? sportmetricPercentage : 0) / 100 +
          mercadoPagoFeePercentage / 100 -
          1)
      ).toFixed(2)
    )
  }

  const getPriceWithDiscount = (price, discountCode) => {
    if (!discountCode) return Number(price)

    if (discountCode.discountType === DISCOUNT_TYPE.PERCENTAGE)
      return Number(price) - (Number(price) * discountCode.percentage) / 100

    if (discountCode.discountType === DISCOUNT_TYPE.FIXED_AMOUNT) {
      const finalPrice = Number(price) - Number(discountCode.fixedAmount)
      return finalPrice > 0 ? finalPrice : TICKET_FREE_PRICE
    }
  }

  const getPriceForOrganizer = (ticketType) =>
    ticketType.price === ticketType.mercadoPagoPrice
      ? ticketType.price -
        getFeeAmount(ticketType.price, Number(ticketType.mercadoPagoFee)) -
        ticketType.sportmetricFeeAmount
      : ticketType.price

  const onSubmit = async ({
    customFields,
    distance,
    category,
    country,
    birthdate,
    areaLevel1,
    areaLevel2,
    areaLevel2Other,
    gender,
    paymentType,
    ticketType,
    activeTicketTypes,
    changeCategory,
    discountCode: eventDiscountCode,
    discountCodeName: eventDiscountCodeName,
    changeTicketType,
    ...restProps
  }) => {
    const inscriptionPrice =
      paymentType.value === PAYMENT_TYPES.MercadoPago
        ? ticketType.mercadoPagoPrice
        : ticketType.price

    const newInscription = {
      ...restProps,
      ticketType,
      eventId: event.id,
      ticketTypeName: ticketType.name,
      ticketTypePrice: inscriptionPrice,
      ticketTypeId: ticketType.id,
      distanceId: distance.value,
      distanceName: distance.label,
      categoryId: category.value,
      categoryName: category.label,
      countryId: country.value,
      eventDiscountCode,
      eventDiscountCodeName,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      countryName: country.label,
      areaLevel1Id: areaLevel1 ? areaLevel1.value : null,
      areaLevel1Name: areaLevel1 ? areaLevel1.label : null,
      areaLevel2Id: areaLevel2?.value !== OTHER_OPTION.value ? areaLevel2.value : null,
      areaLevel2Name: areaLevel2?.value !== OTHER_OPTION.value ? areaLevel2.label : null,
      gender: gender.value,
      areaLevel2Other,
      inscriptionFormId: activeInscriptionForm.id,
      paymentType: paymentType.value,
      paymentTypeName: paymentType.label,
      customFieldValues: customFields.map((e) => ({
        eventCustomFieldId: e.id,
        fieldValue:
          (e.type === 'List' && e.value.label) ||
          (e.type === 'Number' && Number(e.value)) ||
          e.value
      }))
    }

    const saleOrder = {
      totalAmount: inscriptionPrice,
      items: [
        {
          ticketTypeId: ticketType.id,
          itemNumber: 1,
          quantity: 1,
          unitPrice: inscriptionPrice
        }
      ]
    }

    if (eventDiscountCode) {
      const finalPrice =
        paymentType.value === PAYMENT_TYPES.MercadoPago
          ? getFinalPriceMercadoPago(
              getPriceWithDiscount(getPriceForOrganizer(ticketType), eventDiscountCode),
              calculatePercentage(ticketType.mercadoPagoFee),
              ticketType.sportmetricFeePercentage,
              ticketType.sportmetricFeeAmount,
              !!eventDiscountCode
            )
          : getPriceWithDiscount(inscriptionPrice, eventDiscountCode)

      const newEventDiscountCode = {
        ...eventDiscountCode,
        eventDiscountCodeName,
        quantity: 1,
        itemNumber: 2
      }

      saleOrder.items.push(newEventDiscountCode)
      saleOrder.totalAmount = finalPrice

      if (finalPrice === TICKET_FREE_PRICE) {
        newInscription.paymentType = null
        newInscription.paymentTypeName = null
      }
    }

    setInscription({ ...newInscription, saleOrder })
    setOpenConfirmInscriptionDialog(true)
  }

  const getPaymentTypeLabel = (paymentType) => {
    if (!paymentType) return 'Sin medio de pago asignado'
    else if (paymentType === PAYMENT_TYPES.MercadoPago) return 'Mercado Pago'
    else if (paymentType === PAYMENT_TYPES.BankAccount) return 'Transferencia'
    else if (paymentType === PAYMENT_TYPES.PaymentLocation) return 'Punto Fisico'
    else if (paymentType === PAYMENT_TYPES.PaymentUrl) return 'Link de pago'

    return 'Otro medio de pago'
  }

  const handleConfirmInscription = async () => {
    if (!openConfirmInscriptionDialog) return

    setOpenConfirmInscriptionDialog(false)

    const eventInscriptionData = await dispatch(eventInscription(inscription))

    if (!eventInscriptionData) return

    await dispatch(loadEventInscription(eventInscriptionData.id))

    window.fbq('track', 'preinscription completed', {
      fullName: `${inscription.firstName} ${inscription.lastName}`,
      idNumber: inscription.idNumber,
      paymentType: getPaymentTypeLabel(inscription.paymentType),
      createdAt: inscription.createdAt,
      eMail: inscription.eMail
    })

    if (eventInscriptionData.paymentType === PAYMENT_TYPES.MercadoPago)
      handleMercadoPagoPayment(eventInscriptionData.saleOrder)
    else if (eventInscriptionData.state === EVENT_INSCRIPTION_STATES.Pending)
      setOpenSuccessInscriptionPendingDialog(true)
    else if (eventInscriptionData.state === EVENT_INSCRIPTION_STATES.Accepted)
      setOpenSuccessInscriptionDialog(true)
  }

  const handleMercadoPagoPayment = async (values) => {
    const proxy = new PaymentGatewayProxy()
    const data = await proxy.postMercadoPagoPayment(values)
    return (window.location.href = data)
  }

  const userForm = useMemo(
    () =>
      userData
        ? {
            firstName: userData.firstName,
            lastName: userData.lastName,
            gender: userData.gender,
            idNumber: userData.idNumber,
            eMail: userData.eMail,
            areaLevel2Id: userData.areaLevel2Id,
            areaLevel2Name: userData.areaLevel2Name,
            areaLevel2Other: userData.areaLevel2Other,
            areaLevel1Id: userData.areaLevel1Id,
            areaLevel1Name: userData.areaLevel1Name,
            countryId: userData.countryId,
            countryName: userData.countryName,
            phone: userData.phone,
            birthdate: userData.birthdate
          }
        : null,
    [userData]
  )

  const getFormatValue = (value, customField) => {
    if (!value) return ''

    if (customField.type === CUSTOM_FIELD_TYPES.List) {
      const option = customField.options.find((x) => x.name === value.fieldValue)

      if (!option) return ''

      return { value: option.id, label: option.name }
    }

    return value.fieldValue
  }

  const settingCustomField = (values) => (e) => {
    const fieldValue = values.find((v) => v.eventCustomFieldId === e.eventCustomField.id)
    const customFieldValue = getFormatValue(fieldValue, e.eventCustomField)
    return { ...e, fieldValue: customFieldValue }
  }

  const cancelledEventInscriptionForm = useMemo(
    () =>
      cancelledEventInscription
        ? {
            firstName: cancelledEventInscription.firstName,
            lastName: cancelledEventInscription.lastName,
            gender: cancelledEventInscription.gender,
            idNumber: cancelledEventInscription.idNumber,
            eMail: cancelledEventInscription.eMail,
            areaLevel2Id: cancelledEventInscription.areaLevel2Id,
            areaLevel2Name: cancelledEventInscription.areaLevel2?.name,
            areaLevel2Other: cancelledEventInscription.areaLevel2Other,
            areaLevel1Id: cancelledEventInscription.areaLevel1Id,
            areaLevel1Name: cancelledEventInscription.areaLevel1?.name,
            countryId: cancelledEventInscription.country.id,
            countryName: cancelledEventInscription.country.name,
            phone: cancelledEventInscription.phone,
            birthdate: cancelledEventInscription.birthdate,
            isThirdParty: cancelledEventInscription.isThirdParty,
            hasWhatsApp: cancelledEventInscription.hasWhatsApp,
            customFields: activeInscriptionForm?.customFields?.map(
              settingCustomField(cancelledEventInscription?.customFieldValues)
            )
          }
        : null,
    [cancelledEventInscription, activeInscriptionForm]
  )

  const handleOnCloseDialog = () => history.push(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)

  const renderInscriptionForm = () => {
    const { from, to } = event
    const fromLocal = moment(from)
    const toLocal = moment(to)
    const eventDateFormat = getDateEventFormat(fromLocal, toLocal)

    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.dateContainer}>
            <Typography variant='h6'>{eventDateFormat}</Typography>
          </div>
          <Typography color='primary' variant='h6' className={classes.eventName}>
            {event.name}
          </Typography>
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.mainContainer}>
            <div className={classes.titleContainer}>
              <Typography color='primary' variant='h5'>
                INSCRIPCIONES ONLINE
              </Typography>
            </div>
            <InscriptionForm
              mode='create'
              userData={userForm}
              eventInscription={cancelledEventInscriptionForm}
              {...{ formData, user, onSubmit }}
              event={event.activeInscriptionFormId && event}
            />
            <ConfirmInscriptionDialog
              data={inscription}
              open={openConfirmInscriptionDialog}
              formData={formData}
              title={
                !!inscription.paymentType && inscription.paymentType !== PAYMENT_TYPES.MercadoPago
                  ? 'Confirmá tu preinscripción'
                  : 'Confirmá tu inscripción'
              }
              onConfirm={handleConfirmInscription}
              handleOnClose={() => setOpenConfirmInscriptionDialog(false)}
            />
            <SuccessInscriptionDialog
              open={openSuccessInscriptionDialog}
              onClose={handleOnCloseDialog}
              inscription={inscription}
            />
            <SuccessInscriptionPendingDialog
              open={openSuccessInscriptionPendingDialog}
              onClose={handleOnCloseDialog}
              inscription={inscription}
            />
            <InfoMessageDialog
              open={infoOpenDialog}
              onClose={() => setInfoOpenDialog(false)}
              formData={formData}
              hiddenHeader
            />
            <RequiredSportsRoleDialog
              open={openRequiredSportsRoleDialog}
              onAction={() => setInfoOpenDialog(true)}
              onClose={() => setOpenRequiredSportsRoleDialog(false)}
            />
          </div>
        </div>
      </>
    )
  }

  const renderErrorForm = () => (
    <div className={classes.messageContainer}>
      <Typography variant='h5' color='primary'>
        Las inscripciones para este evento no se encuentran disponibles.
        <br />
        Ponte en contacto con el organizador para más información.
      </Typography>
    </div>
  )

  const renderNotFoundPage = () => (
    <div className={classes.notFoundContainer}>
      <Typography color='primary' variant='h5'>
        Ocurrió un error al cargar el formulario
      </Typography>
      <Typography color='primary'>Verifique el enlace.</Typography>
    </div>
  )

  const renderPage = () => (!event ? renderNotFoundPage() : renderInscriptionFormPage())

  const renderInscriptionFormPage = () => {
    const updateServiceLetterRequired =
      !!ticketTypeFees.length && validateServiceLetter(event.organization, ticketTypeFees)

    const availableInscription =
      !!activeInscriptionForm &&
      !activeInscriptionForm.isDraft &&
      !event.isSuspended &&
      event.isPublished &&
      moment().isBefore(moment(event.to)) &&
      moment().isBetween(
        moment(activeInscriptionForm.openFrom),
        moment(activeInscriptionForm.openTo),
        'YYYY-MM-DD'
      ) &&
      !updateServiceLetterRequired &&
      (activeInscriptionForm.quota === 0 ||
        activeInscriptionForm.inscriptionsCount < activeInscriptionForm.quota) &&
      (event.isFree ||
        !event.mercadoPagoCode ||
        event.mercadoPagoEnabled ||
        !activeInscriptionForm.ticketTypes.every(
          (x) =>
            x.mercadoPagoEnabled &&
            !x.paymentLocationsEnabled &&
            !x.bankAccountsEnabled &&
            !x.paymentUrl
        ))

    const Container = !event?.activeInscriptionForm?.allowUnregisteredUsers
      ? Authenticated
      : Fragment

    return (
      <Container
        {...(!event?.activeInscriptionForm?.allowUnregisteredUsers && { verifySession: true })}>
        {availableInscription ? renderInscriptionForm() : renderErrorForm()}
      </Container>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode={activeLoading}>
        {activeLoading ? renderLoading() : renderPage()}
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default InscriptionFormPage
