import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import { MailOutline as MailOutlineIcon, WhatsApp as WhatsAppIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { EllipseStatus } from 'shared'
import { EVENT_INSCRIPTION_STATES, SALE_ORDER_STATES } from 'utils/constants'

import { EditInscriptionFormDialog } from '../EditInscriptionFomDialog'
import { InfoInscriptionDialog } from '../InfoInscriptionDialog'

import { useStyles } from './InscriptionCard.style'

const EVENT_INSCRIPTION_STATE_OPTIONS = {
  [EVENT_INSCRIPTION_STATES.PENDING]: {
    label: 'Preinscripto',
    icon: <EllipseStatus status='yellow' title='Preinscripto' />
  },

  [EVENT_INSCRIPTION_STATES.ACCEPTED]: {
    label: 'Inscripto',
    icon: <EllipseStatus status='green' title='Inscripto' />
  },

  [EVENT_INSCRIPTION_STATES.CANCELLED]: {
    label: 'Anulado',
    icon: <EllipseStatus status='red' title='Anulado' />
  },

  [SALE_ORDER_STATES.EXPIRED]: {
    label: 'Vencido',
    icon: <EllipseStatus status='grey' title='Vencido' />
  }
}

const InscriptionCard = ({ inscription = {}, editProps, paymentComponent }) => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)

  const { firstName, lastName, idNumber, distance, category, state, hasWhatsApp, phone, eMail } =
    inscription

  const inscriptionState = EVENT_INSCRIPTION_STATE_OPTIONS[state]

  return (
    <div className={classes.card}>
      <Typography className={classes.infoText}>
        {firstName} {lastName}
      </Typography>

      <Typography className={classes.infoText}>
        <span>D.N.I:</span> {idNumber}
      </Typography>

      <Typography className={classes.infoText}>
        <span>Distancia:</span> {distance.name}
      </Typography>
      <Typography className={classes.infoText}>
        <span>Categoría:</span> {category.name}
      </Typography>
      <Typography className={clsx(classes.infoText, classes.stateInfo)}>
        <span>Estado:</span>
        <div className={classes.state}>
          {inscriptionState.icon} {inscriptionState.label}
        </div>
      </Typography>

      <div className={classes.actionButtons}>
        {paymentComponent}

        {!hasWhatsApp ? (
          <InfoInscriptionDialog field={inscription} />
        ) : (
          <Button
            color='primary'
            variant='contained'
            href={`https://wa.me/${phone}?text=Desde%20la%20organización%20${event.organization.name}%20nos%20comunicamos%20por%20el%20evento%20${event.name}%20`}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.actionButton}>
            <WhatsAppIcon />
          </Button>
        )}
        <Button
          color='primary'
          variant='contained'
          href={`mailto:${eMail}`}
          target='_blank'
          rel='noopener noreferrer'
          className={classes.actionButton}>
          <MailOutlineIcon />
        </Button>

        {editProps.canEditInscription && (
          <EditInscriptionFormDialog
            title={event.name}
            load={editProps.load}
            inscription={inscription}
            handleOpen={editProps.handleOpenWarning}
          />
        )}
      </div>
    </div>
  )
}

export default InscriptionCard
