import axios from 'axios'
import { CONTENT_TYPES } from 'utils/constants'

import BaseProxy from './BaseProxy'

export class EventsProxy extends BaseProxy {
  async get(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}`)

    return response.data
  }

  async getSearch(searchObj, page, pageSize, orderBy, order) {
    const urlParams = this._makeUrlParams({
      ...searchObj,
      page,
      pageSize,
      orderBy,
      order
    })
    const response = await axios.get(`${this._baseUrl}/events/${urlParams}`)
    return response.data
  }

  async getSearchMetadata(searchObj) {
    const urlParams = this._makeUrlParams({ ...searchObj })
    const response = await axios.get(`${this._baseUrl}/events/search-metadata/${urlParams}`)
    return response.data
  }

  async getUpcomingEvents(searchObj, page, pageSize) {
    const urlParams = this._makeUrlParams({ ...searchObj, page, pageSize })
    const response = await axios.get(`${this._baseUrl}/events/upcoming/${urlParams}`)
    return response.data
  }

  async getPastEvents(searchObj, page, pageSize) {
    const urlParams = this._makeUrlParams({ ...searchObj, page, pageSize })
    const response = await axios.get(`${this._baseUrl}/events/past/${urlParams}`)
    return response.data
  }

  async post(event) {
    const response = await axios.post(`${this._baseUrl}/events/`, event)

    return response.data
  }

  async put(id, event) {
    const response = await axios.put(`${this._baseUrl}/events/${id}`, event)

    return response.data
  }

  async putLike(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/like`)
    return response.data
  }

  async putDisLike(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/un-like`)
    return response.data
  }

  async addToCalendar(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/add-to-calendar`)
    return response.data
  }

  async removeFromCalendar(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/remove-from-calendar`)
    return response.data
  }

  async putImage(id, file) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.put(`${this._baseUrl}/events/${id}/image`, formData, {
      headers: {
        'Content-Type': CONTENT_TYPES.MULTIPART_FORM_DATA
      }
    })

    return response.data
  }

  async delete(id) {
    const response = await axios.delete(`${this._baseUrl}/events/${id}`)
    return response.data
  }

  async putImportResults(id, file, isPartialResults) {
    const formData = new FormData()
    const urlParams = this._makeUrlParams({ isPartialResults })

    formData.append('file', file)

    const response = await axios.put(
      `${this._baseUrl}/events/${id}/import-results${urlParams}`,
      formData,
      {
        headers: {
          'Content-Type': CONTENT_TYPES.MULTIPART_FORM_DATA
        }
      }
    )

    return response.data
  }

  async getResults(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/results`)
    return response.data
  }

  async deleteResults(id) {
    const response = await axios.delete(`${this._baseUrl}/events/${id}/results`)
    return response.data
  }

  async putSuspend(id, reason) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/suspend`, reason)
    return response.data
  }

  async putUnSuspend(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/un-suspend`)
    return response.data
  }

  async getDownloadResultsTemplate() {
    const response = await axios.get(`${this._baseUrl}/events/download-results-template`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
      }
    })

    return response.data
  }

  async putPublish(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/publish`)
    return response.data
  }

  async putUnPublish(id) {
    const response = await axios.put(`${this._baseUrl}/events/${id}/un-publish`)
    return response.data
  }

  async deleteImage(id) {
    const response = await axios.delete(`${this._baseUrl}/events/${id}/image`)
    return response.data
  }

  async getDownloadResultsSheet(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/download-results-sheet`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
      }
    })
    return response.data
  }

  async getDownloadResultsReport(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/download-results-report`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': CONTENT_TYPES.APPLICATION_PDF
      }
    })
    return response.data
  }

  async getDownloadInscriptionsInsuranceSheet(id) {
    const response = await axios.get(
      `${this._baseUrl}/events/${id}/download-inscriptions-insurance-sheet`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
        }
      }
    )
    return response.data
  }

  async getDownloadInscriptionsRunnersSheet(id) {
    const response = await axios.get(
      `${this._baseUrl}/events/${id}/download-inscriptions-runners-sheet`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
        }
      }
    )
    return response.data
  }

  async getDownloadInscriptionsAcceptedSheet(id) {
    const response = await axios.get(
      `${this._baseUrl}/events/${id}/download-inscriptions-accepted-sheet`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
        }
      }
    )
    return response.data
  }

  async getUpcomingForMe(page, pageSize) {
    const urlParams = this._makeUrlParams({ page, pageSize })
    const response = await axios.get(`${this._baseUrl}/events/upcoming-for-me/${urlParams}`)
    return response.data
  }

  async getCreatedByMe(page, pageSize) {
    const urlParams = this._makeUrlParams({ page, pageSize })
    const response = await axios.get(`${this._baseUrl}/events/created-by-me/${urlParams}`)
    return response.data
  }

  async getLastResultForMe(page, pageSize) {
    const urlParams = this._makeUrlParams({ page, pageSize })
    const response = await axios.get(`${this._baseUrl}/events/last-results-for-me/${urlParams}`)
    return response.data
  }

  async getActiveInscriptionForm(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/active-inscription-form`)
    return response.data
  }

  async postInscriptionForms(id, form) {
    const response = await axios.post(`${this._baseUrl}/events/${id}/inscription-forms`, form)
    return response.data
  }

  async getInscriptionFormsClonesuggestions(organizationId, page, pageSize) {
    const urlParams = this._makeUrlParams({ organizationId, page, pageSize })
    const reponse = await axios.get(
      `${this._baseUrl}/events/event-inscription-forms/clone-suggestions${urlParams}`
    )
    return reponse.data
  }

  async putInscriptionForms(id, form) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/inscription-forms/${form.id}`,
      form
    )
    return response.data
  }

  async deleteInscriptionForms(id, formId) {
    const response = await axios.delete(`${this._baseUrl}/events/${id}/inscription-forms/${formId}`)
    return response.data
  }

  async eventInscription(form) {
    const response = await axios.post(`${this._baseUrl}/event-inscriptions`, form)
    return response.data
  }

  async getEventInscription(id) {
    const response = await axios.get(`${this._baseUrl}/event-inscriptions/${id}`)
    return response.data
  }

  async getListInscription(
    eventId,
    page,
    state,
    orderState,
    pageSize,
    searchText,
    orderBy,
    orderDirection
  ) {
    const urlParams = this._makeUrlParams({
      eventId,
      page,
      pageSize,
      searchText,
      state,
      orderState,
      orderBy,
      orderDirection
    })

    const response = await axios.get(`${this._baseUrl}/event-inscriptions${urlParams}`)
    return response.data
  }

  async deleteListInscription(id) {
    const response = await axios.delete(`${this._baseUrl}/event-inscriptions/${id}`)
    return response.data
  }

  async getListInscriptionSearchMetadata(eventId, searchText, state) {
    const urlParams = this._makeUrlParams({ eventId, searchText, state })
    const response = await axios.get(
      `${this._baseUrl}/event-inscriptions/search-metadata${urlParams}`
    )
    return response.data
  }

  async putRulesDocument(eventId, rulesDocument) {
    const formData = new FormData()
    formData.append('file', rulesDocument)
    const response = await axios.put(
      `${this._baseUrl}/events/${eventId}/rules-document`,
      formData,
      {
        headers: {
          'Content-Type': CONTENT_TYPES.MULTIPART_FORM_DATA
        }
      }
    )
    return response.data
  }

  async getDownloadRulesDocument(eventId) {
    const response = await axios.get(`${this._baseUrl}/events/${eventId}/download-rules-document`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': CONTENT_TYPES.APPLICATION_PDF
      }
    })
    return response.data
  }

  async deleteRulesDocument(eventId) {
    const response = await axios.delete(`${this._baseUrl}/events/${eventId}/rules-document`)
    return response.data
  }

  async putEventInscription(form) {
    const response = await axios.put(`${this._baseUrl}/event-inscriptions/${form.id}`, form)
    return response.data
  }

  async getEventInscriptionForCurrentUser(id, page, pageSize = 20) {
    const urlParams = this._makeUrlParams({ page, pageSize })
    const response = await axios.get(
      `${this._baseUrl}/event-inscriptions/for-event/${id}/for-current-user${urlParams}`
    )
    return response.data
  }

  async getDownloadInscriptionsSheet(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/download-inscriptions-sheet`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
      }
    })
    return response.data
  }

  async acceptOrganization(id, organizationId) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/accept-organization/${organizationId}`
    )
    return response.data
  }

  async rejectOrganization(id, organizationId) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/reject-organization/${organizationId}`
    )
    return response.data
  }

  async uploadEventImage(id, file) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.post(`${this._baseUrl}/events/${id}/event-image`, formData, {
      headers: {
        'Content-Type': CONTENT_TYPES.MULTIPART_FORM_DATA
      }
    })

    return response.data
  }

  async getCategories(id) {
    const pageSizeMax = 2147483647 // max value of int32
    const response = await axios.get(
      `${this._baseUrl}/events/${id}/event-categories?pageSize=${pageSizeMax}`
    )
    return response.data
  }

  async postCategory(id, category) {
    const response = await axios.post(`${this._baseUrl}/events/${id}/event-categories`, category)
    return response.data
  }

  async putCategory(id, category) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/event-categories/${category.id}`,
      category
    )
    return response.data
  }

  async deleteCategory(id, categoryId) {
    const response = await axios.delete(
      `${this._baseUrl}/events/${id}/event-categories/${categoryId}`
    )
    return response.data
  }

  async getCategoriesCloneSuggestions(eventId, organizationId) {
    const urlParams = this._makeUrlParams({ organizationId })
    const response = await axios.get(
      `${this._baseUrl}/events/${eventId}/event-categories/clone-suggestions${urlParams}`
    )

    return response.data
  }

  async getDistances(id) {
    const pageSizeMax = 2147483647 // max value of int32
    const response = await axios.get(
      `${this._baseUrl}/events/${id}/event-distances?pageSize=${pageSizeMax}`
    )
    return response.data
  }

  async postDistance(id, distance) {
    const response = await axios.post(`${this._baseUrl}/events/${id}/event-distances`, distance)
    return response.data
  }

  async putDistance(id, distance) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/event-distances/${distance.id}`,
      distance
    )
    return response.data
  }

  async deleteDistance(id, distanceId) {
    const response = await axios.delete(
      `${this._baseUrl}/events/${id}/event-distances/${distanceId}`
    )
    return response.data
  }

  async getMercadoPagoAuthorization(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/mercado-pago-authorization`)
    return response.data
  }

  async sendMercadoPagoAuthorization(id, eMail) {
    const response = await axios.post(
      `${this._baseUrl}/events/${id}/send-mercado-pago-authorization`,
      { eMail }
    )
    return response.data
  }

  async getMercadoPagoAuthorizationUrl(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/mercado-pago-authorzation-url`)
    return response.data
  }

  async deleteMercadoPagoAuthorization(id) {
    const response = await axios.delete(`${this._baseUrl}/events/${id}/mercado-pago-authorization`)
    return response.data
  }

  async getEventBankAccounts(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/bank-accounts`)
    return response.data
  }

  async getActiveBankAccounts(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/active-bank-accounts`)
    return response.data
  }

  async postEventBankAccount(id, bankAccount) {
    const response = await axios.post(`${this._baseUrl}/events/${id}/bank-accounts`, bankAccount)

    return response.data
  }

  async putEventBankAccount(id, bankAccount) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/bank-accounts/${bankAccount.id}`,
      bankAccount
    )

    return response.data
  }

  async deleteEventBankAccount(id, bankAccountId) {
    const response = await axios.delete(
      `${this._baseUrl}/events/${id}/bank-accounts/${bankAccountId}`
    )

    return response.data
  }

  async getPaymentLocations(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/payment-locations`)
    return response.data
  }

  async getActivePaymentLocations(id) {
    const response = await axios.get(`${this._baseUrl}/events/${id}/active-payment-locations`)
    return response.data
  }

  async postPaymentLocation(id, paymentLocation) {
    const response = await axios.post(
      `${this._baseUrl}/events/${id}/payment-locations`,
      paymentLocation
    )

    return response.data
  }

  async putPaymentLocation(id, paymentLocation) {
    const response = await axios.put(
      `${this._baseUrl}/events/${id}/payment-locations/${paymentLocation.id}`,
      paymentLocation
    )

    return response.data
  }

  async deletePaymentLocation(id, paymentLocationId) {
    const response = await axios.delete(
      `${this._baseUrl}/events/${id}/payment-locations/${paymentLocationId}`
    )

    return response.data
  }

  async getBanks() {
    const response = await axios.get(`${this._baseUrl}/banks`)
    return response.data
  }

  async getTicketTypeFees() {
    const response = await axios.get(`${this._baseUrl}/events/ticket-type-fees`)
    return response.data
  }

  async getEventInscriptionByIdNumberAndCountry(eventInscription) {
    const params = this._makeUrlParams(eventInscription)
    const response = await axios.get(
      `${this._baseUrl}/event-inscriptions/by-id-number-and-country${params}`
    )

    return response.data
  }

  async putEventInscriptionPublicUpdate(inscription) {
    const response = await axios.put(
      `${this._baseUrl}/event-inscriptions/${inscription.id}/public-update`,
      inscription
    )

    return response.data
  }

  async getCancelledEventInscription(eventInscriptionId) {
    const response = await axios.get(
      `${this._baseUrl}/event-inscriptions/${eventInscriptionId}/cancelled`
    )

    return response.data
  }

  async getEventDiscountCodes(eventId) {
    const response = await axios.get(`${this._baseUrl}/events/${eventId}/event-discounts`)

    return response.data
  }

  async postEventDiscountCode(discountCode, eventId) {
    const response = await axios.post(
      `${this._baseUrl}/events/${eventId}/event-discounts`,
      discountCode
    )

    return response.data
  }

  async putEventDiscountCode(discountCode, eventId) {
    const response = await axios.put(
      `${this._baseUrl}/events/${eventId}/event-discounts/${discountCode.id}`,
      discountCode
    )

    return response.data
  }

  async deleteEventDiscountCode(discountCodeId, eventId) {
    const response = await axios.delete(
      `${this._baseUrl}/events/${eventId}/event-discounts/${discountCodeId}`
    )

    return response.data
  }

  async postAvailableEventDiscountCode(eventId, name) {
    const response = await axios.post(
      `${this._baseUrl}/events/${eventId}/event-discounts/available-event-discount-code`,
      { name }
    )
    return response.data
  }

  async getDownloadEventCategories(eventId) {
    const response = await axios.get(
      `${this._baseUrl}/events/${eventId}/event-categories/download-event-categories`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': CONTENT_TYPES.APPLICATION_EXCEL
        }
      }
    )
    return response.data
  }

  async importEventCategories(eventId, file) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.put(
      `${this._baseUrl}/events/${eventId}/event-categories/import-event-categories`,
      formData,
      {
        headers: {
          'Content-Type': CONTENT_TYPES.MULTIPART_FORM_DATA
        }
      }
    )

    return response.data
  }

  async SendEventProblem(eventId, eventProblem) {
    const response = await axios.post(
      `${this._baseUrl}/events/${eventId}/send-event-problem`,
      eventProblem
    )

    return response.data
  }
}

export default EventsProxy
