import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { INFORMATION_NAME_FIELDS, OTHER_OPTION } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './DisciplineSection.style'

const MAX_ALLOWED_DISCIPLINES = 3

const DisciplineSection = ({ event, canEdit }) => {
  const classes = useStyles()

  const { disciplines: eventDisciplines, isSuspended, slugUrl } = event

  const values = {
    ...event,
    disciplines: event.disciplines.map((x) => ({ value: x.id, label: x.name }))
  }

  return (
    <SectionBlock title='Disciplinas'>
      <div className={classes.sportsContainer}>
        {eventDisciplines
          .filter((x) => x.label !== OTHER_OPTION.label)
          .map((d) => (
            <Typography className={classes.sport} key={d.id}>
              {d?.name}
            </Typography>
          ))}
      </div>

      {canEdit && values.disciplines.length < MAX_ALLOWED_DISCIPLINES && (
        <Link
          color='primary'
          variant='h6'
          to={`${ROUTES.EVENTS.EDIT}/${slugUrl}?focus=${INFORMATION_NAME_FIELDS.DISCIPLINES}`}
          className={clsx(classes.link, isSuspended && 'disabled')}
          onClick={(e) => {
            if (isSuspended) e.preventDefault()
          }}>
          + Añadir disciplina
        </Link>
      )}
    </SectionBlock>
  )
}

export default DisciplineSection
