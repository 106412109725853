import React, { useState } from 'react'
import { useOverflowDetector } from 'react-detectable-overflow'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { OPTION_OTHER, SUSPENDED_REASON_STATE } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SuspendedSection.style'

const SuspendedSection = () => {
  const classes = useStyles()
  const { ref, overflow } = useOverflowDetector()

  const { event } = useSelector((state) => state.events)

  const [textExpanded, setTextExpanded] = useState(false)

  const { suspensionReasonOther, suspensionReason, name } = event

  const getSuspensionReasonLabel = (suspensionReason) => {
    const reasonLabel = SUSPENDED_REASON_STATE[suspensionReason]

    if (suspensionReason !== OPTION_OTHER.value) return reasonLabel

    return suspensionReasonOther
  }

  const isExpanded = overflow || textExpanded

  return (
    <SectionBlock title='Motivo de suspensión'>
      <div
        className={clsx(isExpanded && classes.suspendContainer)}
        onClick={() => {
          if (isExpanded) setTextExpanded(!textExpanded)
        }}>
        <Typography variant='h6' className={classes.title}>
          {name}
        </Typography>

        <Typography className={classes.suspendReason}>
          Motivo de Suspensión: <strong>{getSuspensionReasonLabel(suspensionReason)}</strong>
        </Typography>

        <div
          ref={ref}
          dangerouslySetInnerHTML={{ __html: event.suspensionDescription }}
          className={clsx(classes.suspendDescriptionContainer, textExpanded && 'expanded')}
        />
      </div>
    </SectionBlock>
  )
}

export default SuspendedSection
