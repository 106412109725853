import React, { useState } from 'react'
import { useOverflowDetector } from 'react-detectable-overflow'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core'
import {
  Check as CheckIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon
} from '@material-ui/icons'
import { ReactComponent as AddCalendarIcon } from 'assets/icons/add-calendar-icon.svg'
import { ReactComponent as RemoveCalendarIcon } from 'assets/icons/remove-calendar-icon.svg'
import automovilismo from 'assets/img/automovilismo.jpg'
import biking from 'assets/img/biking.jpg'
import fbi from 'assets/img/fbi.jpg'
import kayak from 'assets/img/kayak.jpg'
import motocross from 'assets/img/motocross.jpg'
import mountainBike from 'assets/img/mountain-bike.jpg'
import natacion from 'assets/img/natacion.jpg'
import running from 'assets/img/running.jpg'
import speedway from 'assets/img/speedway.jpg'
import clsx from 'clsx'
import moment from 'moment'
import { ROUTES } from 'routes'
import { ConfirmDialog, EditableImage, ShareUrlMenuDialog } from 'shared'
import { verifyUserNotLogged } from 'state/modules/app'
import {
  deleteImage as deleteImageEvent,
  deleteResults,
  downloadResultsReport,
  downloadResultsSheet,
  load,
  reactEvent,
  setImage as updateImageEvent,
  updateCalendar,
  updateEvent,
  uploadResults
} from 'state/modules/events'
import { EVENT_STATES, STRING_EMPTY, SUSPENDED_REASON_STATE } from 'utils/constants'
import { getDateEventFormat, validateServiceLetter } from 'utils/functions'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import { CheckStateInscriptionDialog } from '../CheckStateInscriptionDialog'
import { CreateInscriptionDialog } from '../CreateInscriptionDialog'
import { EventResultsDialog } from '../EventResultsDialog'
import { EventModalityDialog } from '../ModalityEventDialog'
import { InscriptionOptionsDialog } from '../OptionsInscriptionDialog'
import { SignServiceLetterDialog } from '../SignServiceLetterDialog'
import { StateInscriptionDialog } from '../StateInscriptionDialog'
import { SuspendDescriptionDialog } from '../SuspendDescriptionDialog'
import { UpdateConfirmDialog } from '../UpdateConfirmDialog'
import { UpdateServiceLetterInfoDialog } from '../UpdateServiceLetterInfoDialog'
import { UploadResultsDialog } from '../UploadResultsDialog'

import { useStyles } from './HeaderProfile.style'

const IMAGE_TYPE = {
  PROFILE_IMAGE: 'profileImage',
  DEFAULT_IMAGE: 'defaultImage'
}

const DIALOG_TITLE = (
  <Typography variant='h6'>
    Tenés que tener una cuenta de <strong>Sportmetric</strong>&nbsp; para poder inscribirte en este
    evento.
    <br />
    <br />
    Para este y más beneficios:
  </Typography>
)

const DISCIPLINE_IMAGES = {
  automovilismo,
  biking,
  fbi,
  kayak,
  motocross,
  mountainBike,
  natacion,
  running,
  speedway
}

const OPTION_OTHER = {
  value: 'Other',
  label: 'Otra '
}

const HeaderProfile = ({ handleSave, canEdit, inProgressEvent }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { ref, overflow } = useOverflowDetector()

  const { slugUrlOrId } = useParams()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const {
    event,
    deleteResultsPending,
    downloadResultsSheetPending,
    downloadResultsReportPending,
    eventResultsReportUrlPending,
    ticketTypeFees,
    updateCalendarPending,
    pendingReact
  } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)

  const {
    loggedUserMetadata,
    id,
    from,
    to,
    name,
    isFree,
    profileImages,
    externalInscriptionUrl,
    activeInscriptionFormId,
    activeInscriptionForm,
    disciplines,
    slugUrl,
    isSuspended,
    updatedAt,
    createdAt,
    organization: eventOwner,
    hasResults,
    hasPartialResults,
    isPublished,
    title,
    suspensionReason,
    suspensionReasonOther,
    hasResultsSheetFile,
    externalResultsUrl,
    likesCount,
    hasQuota
  } = event

  const hasProfileImage = Array.isArray(profileImages) && profileImages.length > 0

  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false)
  const [openCreateInscriptionDialog, setOpenCreateInscriptionDialog] = useState(false)
  const [openEventModalityDialog, setOpenEventModalityDialog] = useState(false)
  const [openSignServiceLetterDialog, setOpenSignServiceLetterDialog] = useState(false)
  const [openUploadResultsDialog, setOpenUploadResultsDialog] = useState(false)
  const [openInscriptionOptionsDialog, setOpenInscriptionOptionsDialog] = useState(false)
  const [openCheckStateInscriptionDialog, setOpenCheckStateInscriptionDialog] = useState(false)
  const [openDialogResults, setOpenDialogResults] = useState(false)
  const [openStateDialog, setOpenStateDialog] = useState(false)
  const [openEventResultsDialog, setOpenEventResultsDialog] = useState(false)
  const [openSuspendDescriptionDialog, setOpenSuspendDescriptionDialog] = useState(false)
  const [textExpanded, setTextExpanded] = useState(false)
  const [openUpdateServiceLetterInfoDialog, setOpenUpdateServiceLetterInfoDialog] = useState(false)

  const getImageUrl = () => (hasProfileImage ? profileImages[0] : null)

  const handleSaveImage = ({ value }) => dispatch(updateImageEvent(id, value))

  const handleDeleteImage = () => dispatch(deleteImageEvent(event))

  const handleSaveSlugUrl = async (values) => {
    const eventUpdate = { ...event, ...values }

    const eventData = await dispatch(updateEvent(eventUpdate))

    history.replace(`${ROUTES.EVENTS.PROFILE}/${eventData.slugUrl}`)
  }

  const handleAuthetication = (action, redirectUrl) => (e) => {
    if (user) {
      action(e)
      return
    }

    if (!activeInscriptionForm?.allowUnregisteredUsers) {
      const verifyUserNotLoggedParams = {
        state: true,
        redirectUrl,
        dialogSubTitle: DIALOG_TITLE
      }

      dispatch(verifyUserNotLogged(verifyUserNotLoggedParams))
    } else {
      setOpenInscriptionOptionsDialog(true)
    }
  }

  const redirectExternalResultsUrl = () => window.open(event.externalResultsUrl, '_blank')

  const handleEventResults = async () => {
    if (externalResultsUrl) {
      redirectExternalResultsUrl()
    } else if (!hasResultsSheetFile) {
      setOpenEventResultsDialog(true)
    } else {
      dispatch(downloadResultsReport(event))
    }
  }

  const handleUploadResults = (isPartialResults) => async (e) => {
    if (e.target.files.length === 0) return

    const file = e.target.files[0]
    const validResultFormats = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]

    e.target.value = STRING_EMPTY

    if (!validResultFormats.includes(file.type)) {
      showSnackbarError({
        message: 'Los resultados del evento deben ser en formato excel/pdf'
      })
      return
    }

    await dispatch(uploadResults(id, file, isPartialResults))
    await dispatch(load(slugUrlOrId))
    setOpenUploadResultsDialog(false)
  }

  const fromLocal = moment(from)
  const toLocal = moment(to)
  const afterEvent = toLocal < moment()
  const eventDateFormat = getDateEventFormat(fromLocal, toLocal)
  const inscriptionFormUrl = `/events/${slugUrl}/inscription`
  const listInscriptionUrl = `/events/${slugUrl}/list-inscription`

  const handleRedirectEventForm = () => history.push(inscriptionFormUrl)
  const handleRedirectExternalInscription = () => window.open(externalInscriptionUrl, '_blank')
  const handleRedirectListInscription = () => history.push(listInscriptionUrl)

  const tryDeleteResults = async () => {
    if (event.externalResultsUrl) {
      handleSave({ value: { externalResultsUrl: STRING_EMPTY, hasPartialResults: false } })
      return
    }

    await dispatch(deleteResults(event.id))
    await dispatch(load(event.id))
  }

  const handleRedirectInscriptions = () => {
    if (externalInscriptionUrl) {
      handleRedirectExternalInscription()
      return
    }

    if (activeInscriptionFormId) handleRedirectListInscription()
  }

  const handleDownloadResults = (action) => {
    if (action && user) {
      action(event)
    } else {
      dispatch(
        verifyUserNotLogged({
          state: true
        })
      )
    }
  }

  const handleActionEvent = (action) => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(action(event))
    }
  }

  const handleOpenInscriptionStateDialog = () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      setOpenStateDialog(true)
    }
  }

  const options = disciplines.map((x) => ({
    ...x,
    imgUrl: DISCIPLINE_IMAGES[x.imageName],
    className: clsx(x.imageName && classes[x.imageName])
  }))

  const handleClickContent = () => {
    if (!textExpanded && !openSuspendDescriptionDialog && !overflow) return

    if (isMobile) setTextExpanded(!textExpanded)
    else setOpenSuspendDescriptionDialog(true)
  }

  const renderEventButtons = () => {
    const isEventOwner = eventOwner.loggedUserMetadata?.canEdit
    const isAdminOwnerEvent = eventOwner.createdById === user?.id

    const updateServiceLetterRequired =
      !isFree && !!ticketTypeFees.length && validateServiceLetter(eventOwner, ticketTypeFees)

    const enableInscription =
      !afterEvent &&
      !hasResults &&
      isPublished &&
      (!!externalInscriptionUrl ||
        (!!activeInscriptionForm &&
          !activeInscriptionForm.isDraft &&
          moment().isBetween(
            moment(activeInscriptionForm.openFrom),
            moment(activeInscriptionForm.openTo),
            'YYYY-MM-DD'
          ))) &&
      !updateServiceLetterRequired &&
      (!event.mercadoPagoCode ||
        event.mercadoPagoEnabled ||
        !event.activeInscriptionForm.ticketTypes.every(
          (x) =>
            x.mercadoPagoEnabled &&
            !x.paymentLocationsEnabled &&
            !x.bankAccountsEnabled &&
            !x.paymentUrl
        ) ||
        isFree)

    const renderEnableInscriptionButton =
      !externalInscriptionUrl &&
      !activeInscriptionFormId &&
      !afterEvent &&
      isEventOwner &&
      canEdit &&
      !hasResults

    const renderUploadEventResultsButton =
      canEdit && fromLocal.isBefore(moment()) && !hasResults && !isSuspended

    const renderInscriptionButton = !canEdit && enableInscription

    const renderInscriptionStateButton =
      !canEdit &&
      !afterEvent &&
      !!activeInscriptionFormId &&
      !activeInscriptionForm?.isDraft &&
      activeInscriptionForm?.allInscriptionsCount > 0

    const renderInscriptionsButton =
      canEdit &&
      (!!externalInscriptionUrl || (!!activeInscriptionForm && !activeInscriptionForm.isDraft))

    const renderEditInscriptionFormButton =
      canEdit &&
      isEventOwner &&
      fromLocal.isAfter(moment()) &&
      !!activeInscriptionForm &&
      !activeInscriptionForm.isDraft

    const renderLabelInscriptions =
      !canEdit &&
      !hasResults &&
      !afterEvent &&
      !!activeInscriptionForm &&
      moment().isSameOrAfter(moment(activeInscriptionForm.openFrom).format('YYYY-MM-DD')) &&
      !!activeInscriptionForm.showInscriptionsFrom &&
      !activeInscriptionForm.isDraft &&
      activeInscriptionForm.inscriptionsCount >= activeInscriptionForm.showInscriptionsFrom

    const renderDownloadEventResults = hasResults && !canEdit

    const renderDeleteEventResultsButton = hasResults && canEdit

    const renderDownloadEventResultsLink = hasResults && hasResultsSheetFile && !isMobile

    const renderInscriptionFormDraftButton =
      canEdit && !!activeInscriptionForm && activeInscriptionForm.isDraft && !afterEvent

    const getResultsTitleButton = () => {
      if (hasResultsSheetFile) {
        if (hasPartialResults) return 'Descarga result. parciales'
        else return 'Descargar resultados'
      } else {
        if (hasPartialResults) return 'Ver resultados parciales'
        else return 'Ver resultados'
      }
    }

    const handleEditButton = (e) => {
      if (event.isFree) return

      const updateServiceLetterRequired = validateServiceLetter(eventOwner, ticketTypeFees)

      if (eventOwner.hasActiveContract && updateServiceLetterRequired) {
        e.preventDefault()
        setOpenUpdateServiceLetterInfoDialog(true)
      }
    }

    return (
      <div
        className={clsx(
          classes.eventButtonBlock,
          !hasQuota && !canEdit && !afterEvent && 'hasBanner'
        )}>
        <div className={classes.buttonsContainer}>
          {renderEnableInscriptionButton && (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setOpenCreateInscriptionDialog(true)}
              className={classes.enableInscriptionButton}>
              Habilitar inscripción
            </Button>
          )}

          {renderInscriptionButton && (
            <Button
              variant='contained'
              color='secondary'
              onClick={
                activeInscriptionFormId
                  ? handleAuthetication(handleRedirectEventForm, inscriptionFormUrl)
                  : handleRedirectExternalInscription
              }
              disabled={!hasQuota || updateServiceLetterRequired}
              className={classes.enableInscriptionButton}>
              {!loggedUserMetadata?.hasActiveInscription ? 'Inscribirme' : 'Inscripción'}
            </Button>
          )}

          {renderDownloadEventResults && (
            <Button
              variant='contained'
              color='secondary'
              onClick={handleEventResults}
              disabled={downloadResultsReportPending || eventResultsReportUrlPending}
              endIcon={
                (downloadResultsReportPending || eventResultsReportUrlPending) && (
                  <CircularProgress size={16} color='primary' />
                )
              }
              className={classes.downloadEventResultsButton}>
              {getResultsTitleButton()}
            </Button>
          )}

          {renderInscriptionStateButton && (
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setOpenCheckStateInscriptionDialog(true)}
              className={classes.inscriptionStateButton}>
              Estado de mi inscripción
            </Button>
          )}

          {renderLabelInscriptions && (
            <Typography
              className={classes.inscriptionLink}
              variant='h6'
              onClick={handleOpenInscriptionStateDialog}
              align='center'>
              Ver inscriptos
            </Typography>
          )}

          {renderInscriptionsButton && (
            <Button
              variant='contained'
              color='secondary'
              onClick={handleRedirectInscriptions}
              className={classes.enableInscriptionButton}>
              Inscripciones
            </Button>
          )}

          {renderEditInscriptionFormButton && (
            <Button
              variant='outlined'
              color='secondary'
              component={Link}
              onClick={handleEditButton}
              disabled={updateServiceLetterRequired && !isAdminOwnerEvent}
              to={`${ROUTES.EVENTS.INSCRIPTION}/${slugUrl}`}
              className={clsx(
                classes.inscriptionStateButton,
                updateServiceLetterRequired && !isAdminOwnerEvent && 'disabled'
              )}>
              Editar Formulario
            </Button>
          )}

          {renderUploadEventResultsButton && (
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setOpenUploadResultsDialog(true)}
              className={classes.inscriptionStateButton}>
              Cargar Resultados
            </Button>
          )}

          {renderDeleteEventResultsButton && (
            <div className={classes.resultButtonContainer}>
              <Button
                disabled={deleteResultsPending}
                className={classes.inscriptionStateButton}
                endIcon={deleteResultsPending && <CircularProgress size={16} color='secondary' />}
                variant='outlined'
                color='secondary'
                onClick={() => setOpenDialogResults(true)}>
                Quitar resultados
              </Button>

              {renderDownloadEventResultsLink && (
                <Typography
                  color='primary'
                  align='center'
                  disabled={downloadResultsSheetPending}
                  onClick={() => handleDownloadResults(() => dispatch(downloadResultsSheet(event)))}
                  className={classes.downloadResultsLink}>
                  Descargar resultados
                </Typography>
              )}
            </div>
          )}

          {renderInscriptionFormDraftButton && (
            <Button
              variant='contained'
              color='secondary'
              to={`${ROUTES.EVENTS.INSCRIPTION}/${slugUrl}`}
              component={Link}
              className={classes.enableInscriptionButton}>
              Borrador Formulario
            </Button>
          )}
        </div>
      </div>
    )
  }

  const renderActionBarButtons = () => (
    <div className={clsx(classes.actionBarButtonContainer, (canEdit || isSuspended) && 'hidden')}>
      {!afterEvent && (
        <Button
          variant='outlined'
          color='primary'
          disabled={updateCalendarPending}
          className={classes.actionButton}
          classes={{ label: classes.actionButtonLabel }}
          onClick={() => handleActionEvent(updateCalendar)}
          title={
            !loggedUserMetadata.inCalendar ? 'Agregar a mi calendario' : ' Quitar de mi calendario'
          }>
          {!loggedUserMetadata.inCalendar ? <AddCalendarIcon /> : <RemoveCalendarIcon />}
        </Button>
      )}
      <Button
        variant='outlined'
        color='primary'
        disabled={pendingReact}
        className={classes.actionButton}
        classes={{ label: classes.actionButtonLabel }}
        title={!loggedUserMetadata.like ? 'Me gusta' : 'Ya no me gusta'}
        onClick={() => handleActionEvent(reactEvent)}>
        {loggedUserMetadata.like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        <em>{likesCount}</em>
      </Button>
      <ShareUrlMenuDialog
        id={id}
        type='event'
        isHiddenLabel
        isOutlineIcon
        updatedAt={updatedAt || createdAt}
        copyMessage='El link del evento fue copiado al portapapeles'
        suspended={isSuspended}
        slugUrl={slugUrl}
        title={title}
        buttonClassName={classes.shareButton}
      />
    </div>
  )

  const renderStateBanner = () => (
    <>
      {!!activeInscriptionForm &&
        !activeInscriptionForm.isDraft &&
        !hasQuota &&
        !afterEvent &&
        !isSuspended && (
          <Typography className={classes.bannerTitle} variant='h6'>
            AGOTADO
          </Typography>
        )}

      {isSuspended && (
        <Typography className={clsx(classes.bannerTitle, classes.suspendTitle)} variant='h6'>
          SUSPENDIDO
        </Typography>
      )}
    </>
  )

  const getSuspensionReasonLabel = (suspensionReason) => {
    const reasonLabel = SUSPENDED_REASON_STATE[suspensionReason]

    if (suspensionReason !== OPTION_OTHER.value) return reasonLabel

    return suspensionReasonOther
  }

  const renderDescriptionSuspend = () => (
    <div
      className={clsx(classes.suspendContainer, overflow && 'textClick')}
      onClick={handleClickContent}>
      <Typography variant='h6' className={clsx(classes.title, EVENT_STATES.SUSPENDED)}>
        {name}
      </Typography>
      <Typography className={classes.supendReason}>
        Motivo de Suspensión: <strong>{getSuspensionReasonLabel(suspensionReason)}</strong>
      </Typography>
      <div
        className={clsx(classes.suspendDescriptionContainer, textExpanded && 'expanded')}
        ref={ref}
        dangerouslySetInnerHTML={{ __html: event.suspensionDescription }}
      />
    </div>
  )

  return (
    <div className={clsx(classes.header, afterEvent && EVENT_STATES.FINALIZED)}>
      <EditableImage
        entity={event}
        mode='button'
        field='profileImages'
        onSave={handleSaveImage}
        defaultImage={getImageUrl(IMAGE_TYPE.DEFAULT_IMAGE)}
        canEdit={canEdit && !inProgressEvent}
        isSuspended={event.isSuspended}
        defaultImageComponent={
          <>
            <div className={classes.imageInfoContainer}>
              <div className={classes.infoContainer}>
                <Typography color='primary' className={classes.infoTitle}>
                  Elegí una buena imagen de portada para que tu evento se destaque.
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  Recordá:
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  <CheckIcon className={classes.checkIcon} /> Las dimensiones correctas son 827 x
                  403px.
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  <CheckIcon className={classes.checkIcon} /> El formato es horizontal.
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  <CheckIcon className={classes.checkIcon} /> Ubicá la información más relevante en
                  la mitad inferior de la imagen.
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  <CheckIcon className={classes.checkIcon} /> Tipo de archivos permitidos .jpg o
                  .jpeg.
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  <CheckIcon className={classes.checkIcon} /> Peso máximo 10MB.
                </Typography>
                <Typography color='primary' className={classes.infoText}>
                  *En caso de no seleccionar una imagen para el evento se mostrará esta imagen.
                </Typography>
              </div>
            </div>
            {options
              .filter((x) => !!x.imageName)
              .map((x) => (
                <div
                  key={x.id}
                  className={clsx(
                    classes.featureImage,
                    x.className,
                    `size-${options.filter((x) => !!x.imageName).length}`
                  )}
                  style={{ backgroundImage: `url(${x.imgUrl})` }}
                />
              ))}
          </>
        }
        className={classes.featureImageContainer}
        inputClassName={classes.featureImage}
        onDelete={handleDeleteImage}>
        <div className={classes.featureImageContainer}>
          {hasProfileImage ? (
            <div
              className={classes.featureImage}
              style={{ backgroundImage: `url(${getImageUrl()})` }}
            />
          ) : (
            options
              .filter((x) => !!x.imageName)
              .map((x) => (
                <div
                  key={x.id}
                  className={clsx(
                    classes.featureImage,
                    x.className,
                    `size-${options.filter((x) => !!x.imageName).length}`
                  )}
                  style={{ backgroundImage: `url(${x.imgUrl})` }}
                />
              ))
          )}
        </div>
      </EditableImage>
      <div className={classes.panel}>
        <div
          className={clsx(
            classes.eventInfoContainer,
            afterEvent && EVENT_STATES.FINALIZED,
            !afterEvent && !hasQuota && EVENT_STATES.SOLD_OUT,
            isSuspended && EVENT_STATES.SUSPENDED
          )}>
          <div className={classes.date}>{eventDateFormat}</div>

          <EditableImage
            className={classes.featureImageContainerMobile}
            canEdit={canEdit && !inProgressEvent}
            inputClassName={classes.featureImage}
            defaultImage={getImageUrl(IMAGE_TYPE.DEFAULT_IMAGE)}
            entity={event}
            defaultImageComponent={
              <>
                <div className={classes.imageInfoContainer}>
                  <div className={classes.infoContainer}>
                    <Typography color='primary' className={classes.infoTitle}>
                      Una buena imagen de portada destaca tu evento.
                    </Typography>

                    <Typography color='primary' className={classes.infoText}>
                      Dimensiones: 827 x 403px.
                    </Typography>
                    <Typography color='primary' className={classes.infoText}>
                      Formato es horizontal.
                    </Typography>

                    <Typography color='primary' className={classes.infoText}>
                      Tipo de archivos permitidos .jpg o .jpeg.
                    </Typography>
                    <Typography color='primary' className={classes.infoText}>
                      Peso máximo 10MB.
                    </Typography>
                    <Typography color='primary' className={clsx(classes.infoText, classes.endText)}>
                      *En caso de no seleccionar una imagen para el evento se mostrará esta imagen.
                    </Typography>
                  </div>
                </div>
                {options
                  .filter((x) => !!x.imageName)
                  .map((x) => (
                    <div
                      key={x.id}
                      className={clsx(
                        classes.featureImage,
                        x.className,
                        `size-${options.filter((x) => !!x.imageName).length}`
                      )}
                      style={{ backgroundImage: `url(${x.imgUrl})` }}
                    />
                  ))}
              </>
            }
            field='profileImages'
            isSuspended={event.isSuspended}
            mode='button'
            onSave={handleSaveImage}
            onDelete={handleDeleteImage}>
            <div className={classes.featureImageContainerMobile}>
              {hasProfileImage ? (
                <div
                  className={classes.featureImage}
                  style={{ backgroundImage: `url(${getImageUrl()})` }}
                />
              ) : (
                options.map((x) => (
                  <div
                    key={x.id}
                    className={clsx(classes.featureImage, x.className, `size-${options.length}`)}
                    style={{ backgroundImage: `url(${x.imgUrl})` }}
                  />
                ))
              )}
            </div>
          </EditableImage>

          {!isMobile && renderStateBanner()}

          {(isMobile || hasQuota || afterEvent) && renderActionBarButtons()}
        </div>

        <UpdateConfirmDialog
          title='Estás modificando el nombre del Evento'
          open={openUpdateConfirmDialog}
          message={` Al confirmar el cambio de nombre de tu evento 
                    se modificará el link del evento, si ya compartiste el link anterior tené en cuenta que el
                    mismo ya no estará vigente.`}
          onSave={handleSaveSlugUrl}
          onClose={() => setOpenUpdateConfirmDialog(false)}
        />

        {isMobile &&
          !!activeInscriptionForm &&
          !activeInscriptionForm.isDraft &&
          !hasQuota &&
          !hasResults &&
          !isSuspended && (
            <div className={clsx(classes.banner, classes.noQuotaBanner)}>{renderStateBanner()}</div>
          )}

        {isMobile && isSuspended && (
          <div className={clsx(classes.banner, classes.suspendBanner)}>{renderStateBanner()}</div>
        )}

        {(!isMobile || !hasResults || canEdit) && (!isSuspended || (isMobile && canEdit)) && (
          <>
            <div className={classes.titleContainer}>
              <Typography variant='h2' className={classes.title} color='primary'>
                {name}
              </Typography>

              {!isMobile && !hasQuota && !afterEvent && renderActionBarButtons()}
            </div>

            {!isSuspended && renderEventButtons()}
          </>
        )}

        {isSuspended && (!canEdit || !isMobile) && renderDescriptionSuspend()}

        <UploadResultsDialog
          title='Carga de resultados'
          open={openUploadResultsDialog}
          onUploadResults={handleUploadResults}
          onSave={handleSave}
          eventId={id}
          afterEvent={afterEvent}
          load={() => dispatch(load(slugUrlOrId))}
          onClose={() => setOpenUploadResultsDialog(false)}
        />

        <CreateInscriptionDialog
          open={openCreateInscriptionDialog}
          eventSlugUrl={slugUrl}
          title='Crear formulario'
          onSave={handleSave}
          onOpenModalityEventDialog={() => setOpenEventModalityDialog(true)}
          onClose={() => setOpenCreateInscriptionDialog(false)}
        />

        <EventModalityDialog
          open={openEventModalityDialog}
          onClose={() => setOpenEventModalityDialog(false)}
          onAction={
            eventOwner.hasActiveContract
              ? setOpenUpdateServiceLetterInfoDialog
              : setOpenSignServiceLetterDialog
          }
        />

        <SignServiceLetterDialog
          open={openSignServiceLetterDialog}
          action={eventOwner.hasActiveContract ? 'update' : 'create'}
          onUpdateSuccess={() => {
            showSnackbarSuccess('¡Carta de servicio actualizada correctamente!')
            setOpenSignServiceLetterDialog(false)
            history.push(`${ROUTES.EVENTS.INSCRIPTION}/${event.slugUrl}`)
          }}
          onClose={() => setOpenSignServiceLetterDialog(false)}
        />

        <UpdateServiceLetterInfoDialog
          open={openUpdateServiceLetterInfoDialog}
          onAccept={() => {
            setOpenUpdateServiceLetterInfoDialog(false)
            setOpenSignServiceLetterDialog(true)
          }}
          onClose={() => setOpenUpdateServiceLetterInfoDialog(false)}
        />

        <InscriptionOptionsDialog
          open={openInscriptionOptionsDialog}
          onClose={() => setOpenInscriptionOptionsDialog(false)}
          inscriptionFormUrl={inscriptionFormUrl}
        />

        <CheckStateInscriptionDialog
          open={openCheckStateInscriptionDialog}
          onClose={() => setOpenCheckStateInscriptionDialog(false)}
        />

        <ConfirmDialog
          openDialog={openDialogResults}
          message='Esta acción eliminará los resultados del evento.
                                    ¿Esta seguro que desea continuar?'
          setOpen={setOpenDialogResults}
          onAction={tryDeleteResults}
          type='delete'
        />

        <EventResultsDialog
          open={openEventResultsDialog}
          event={event}
          onClose={() => setOpenEventResultsDialog(false)}
        />

        {!!event.activeInscriptionForm && (
          <StateInscriptionDialog
            open={openStateDialog}
            title={name}
            inscriptionsCount={activeInscriptionForm?.inscriptionsCount || 0}
            onClose={() => setOpenStateDialog(false)}
          />
        )}

        <SuspendDescriptionDialog
          open={openSuspendDescriptionDialog}
          eventDate={eventDateFormat}
          event={event}
          onClose={() => setOpenSuspendDescriptionDialog(false)}
        />
      </div>
    </div>
  )
}

export default HeaderProfile
