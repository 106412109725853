import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  readMoreBox: {
    maxHeight: 170,
    overflow: 'hidden',
    position: 'relative',
    '& > p': {
      textAlign: 'center',
      fontSize: '.9rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem',
        textAlign: 'left'
      }
    }
  },
  readMoreBoxRevealed: {
    maxHeight: 'none',
    overflow: 'visible'
  },
  tooltipArrow: {
    color: theme.palette.tooltip.light
  },
  readMoreButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
    '& button': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      fontSize: '1.125em',
      width: '100%',
      border: 'none',
      background: 'none',
      color: theme.palette.primary.main,
      textAlign: 'left',
      textDecoration: 'underline'
    }
  },
  sectionContainer: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(),
    '& h3': {
      fontWeight: 600,
      fontSize: '1.25em',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      color: theme.palette.primary.main
    },
    '& > div > p': {
      padding: theme.spacing(2, 0)
    }
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'flex-end',
    [theme.breakpoints.up('lg')]: {
      width: 320
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    flexGrow: 1,
    marginTop: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main
  },
  alertButton: {
    '& svg': {
      color: theme.palette.tooltip.light
    }
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.light,
    fontSize: '.75rem',
    padding: `${theme.spacing(1, 2)} !important`,
    width: '180px !important'
  },
  closeButton: {
    top: '0px !important',
    right: '5px !important'
  }
}))

export default useStyles
