import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, ClickAwayListener, Tooltip, Typography } from '@material-ui/core'
import noImage from 'assets/img/no-image.jpg'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { verifyUserNotLogged } from 'state/modules/app'
import { follow } from 'state/modules/organizations'

import useStyles from './OrganizationCard.style'

const OrganizationCard = ({ organization, disabledAction }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const [openTooltip, setOpenTooltip] = useState(false)
  const [followPending, setFollowPending] = useState(false)

  const {
    disciplines,
    name,
    followersCount,
    countryName,
    areaLevel1Name,
    loggedUserMetadata,
    id,
    handle,
    profileImages
  } = organization

  const imageUrl =
    Array.isArray(profileImages) && profileImages.length > 0 ? profileImages[0] : noImage
  const location = [areaLevel1Name, countryName].filter(Boolean).join(', ')
  const myOrganization =
    loggedUserMetadata && loggedUserMetadata.canEdit && loggedUserMetadata.isFollowing
  const countDisciplines = disciplines.length - 1
  const disciplinesToShow =
    countDisciplines <= 0
      ? disciplines
      : [
          ...disciplines.slice(0, 1),
          {
            id: 'add',
            name: `Y ${countDisciplines} más`,
            title: disciplines
              .map((e) => e.name)
              .slice(1, disciplines.length)
              .join(', ')
          }
        ]

  const handleFollow = async () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      await dispatch(follow(organization))
      setFollowPending(false)
    }
  }

  return (
    <div className={classes.container}>
      <Link
        className={classes.mainContainer}
        to={`${ROUTES.ORGANIZATIONS.PROFILE}/${handle || id}`}>
        <div className={classes.headerContainer}>
          <div className={classes.imageContainer} style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className={classes.titleContainer}>
            <Typography variant='h6' className={classes.title} title={name}>
              {name}
            </Typography>
            <Typography className={classes.handle} variant='h6' title={`@${handle || id}`}>
              @{handle || id}
            </Typography>
          </div>
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.sports}>
            {disciplinesToShow?.length
              ? disciplinesToShow.map((d) => (
                  <div key={d.id} className={classes.sport} title={d.title}>
                    {d.name}
                  </div>
                ))
              : 'Sin disciplinas'}
          </div>
          <div className={classes.informationContainer}>
            <div className={clsx(classes.address, disabledAction && 'disabled')} title={location}>
              <Typography>{location || 'No proporcionado'}.</Typography>
            </div>
          </div>
        </div>
      </Link>
      {!disabledAction && (
        <div className={classes.actions}>
          <Link
            to={`${ROUTES.ORGANIZATIONS.PROFILE}/${handle || id}`}
            className={classes.profileLink}>
            Ver perfil
          </Link>
          <div className={classes.actionButtonsContainer}>
            {!user || !organization?.loggedUserMetadata?.isFollowing ? (
              <Button
                color='primary'
                variant='contained'
                className={classes.actionButton}
                onClick={handleFollow}>
                Seguir
              </Button>
            ) : (
              <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={() => setOpenTooltip(false)}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title='No puedes dejar de seguir a tu propia organización'>
                  <Button
                    color='primary'
                    variant='outlined'
                    disabled={followPending}
                    className={classes.actionButton}
                    onClick={() => {
                      if (myOrganization) {
                        setOpenTooltip(true)
                      } else {
                        handleFollow()
                      }
                    }}>
                    Siguiendo
                  </Button>
                </Tooltip>
              </ClickAwayListener>
            )}

            <Typography className={classes.followers}>({followersCount}) Seguidores</Typography>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrganizationCard
