import React from 'react'
import { useFormikContext } from 'formik'
import { EditableRichText } from 'shared'
import { STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SuspensionReasonSection.style'

const SuspensionReasonSection = () => {
  const classes = useStyles()

  const { values, setFieldValue, errors, setValues } = useFormikContext()

  const handleChangeRichText = (e) =>
    setValues((values) => ({
      ...values,
      suspensionDescription: e?.getHTML() || STRING_EMPTY,
      suspensionDescriptionText: e?.getText().trim() || STRING_EMPTY
    }))

  return (
    <SectionBlock title='Motivo de suspensión'>
      <EditableRichText
        entity={values}
        altMode
        setStatusSection={(state) => setFieldValue('sectionState', state)}
        field='suspensionDescription'
        repositoryId={values.id}
        className={classes.editor}
        activeModeEdit={true}
        disabledActionImageButton
        error={errors.suspensionDescription || errors.suspensionDescriptionText}
        onChange={handleChangeRichText}
      />
    </SectionBlock>
  )
}

export default SuspensionReasonSection
