import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '&.disabled': {
      '& h3': {
        color: '#696969',
        borderColor: '#696969'
      }
    }
  },
  disabledSection: {
    color: '#969696 !important'
  },
  selectContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },

    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    },
    '&.disabled': {
      color: '#969696',
      '& svg': {
        color: theme.palette.common.white
      },
      '& > div': {
        borderColor: '#969696',
        borderRadius: 0,
        boxShadow: 'none'
      }
    },
    [theme.breakpoints.up('md')]: {
      width: '48%'
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  label: {
    fontWeight: 600,
    fontSize: '.875rem',
    paddingTop: '0px !important',
    marginTop: theme.spacing(2),
    position: 'relative'
  },
  paymentLocationInfoLink: {
    fontWeight: 600,
    fontSize: '.875rem',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(2)
  },
  orderPayment: {
    paddingTop: theme.spacing(2)
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    fontSize: '.875rem',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 400
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 700
    }
  },
  arrowTooltip: {
    color: theme.palette.tooltip.main
  },
  tooltipButton: {
    right: '6px !important'
  },
  messageContainer: {
    top: 'calc(50% - 5px) !important',
    width: '100% !important',
    '& > div': {
      maxWidth: 800
    }
  },
  message: {
    fontSize: '.875rem',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },
  paymentUrlInfo: {
    fontWeight: 700,
    fontSize: '.875rem',
    marginTop: theme.spacing(3)
  },
  discountCodeContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(5)
    }
  },
  content: {
    height: 40,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    width: '100%',
    '& > span > h6': {
      flexGrow: 1,
      fontSize: '0.875rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '48%'
    }
  },
  discountCodeFormGroup: {
    display: 'flex',
    gap: theme.spacing(5),
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      width: '48%',
      alignItems: 'flex-start',
      '&.admin': {
        minWidth: 450
      }
    }
  },
  apllyCodeButton: {
    borderRadius: 20,
    width: 210,
    '& span': {
      fontSize: '.875rem',
      fontWeight: 600
    }
  },
  discountCodeBlock: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0)
  },
  discountText: {
    fontWeight: 700,
    fontSize: '.875rem'
  },
  textField: {
    width: '70%',
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '& input': {
      height: 3,
      paddingLeft: theme.spacing(),
      fontSize: '.825rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  tasHelperText: {
    marginTop: theme.spacing(),
    fontSize: '.875rem'
  },
  helperText: {
    fontWeight: 500
  }
}))
