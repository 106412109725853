// Action Types
export const LOAD_ATTEMPT = 'organizations/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'organizations/LOAD_SUCCESS'
export const LOAD_FAILURE = 'organizations/LOAD_FAILURE'
export const LOAD_RESET = 'organizations/LOAD_RESET'
export const CREATE_ATTEMPT = 'organizations/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'organizations/CREATE_SUCCESS'
export const CREATE_FAILURE = 'organizations/CREATE_FAILURE'

export const SET_IMAGE_ATTEMPT = 'organizations/SET_IMAGE_ATTEMPT'
export const SET_IMAGE_SUCCESS = 'organizations/SET_IMAGE_SUCCESS'
export const SET_IMAGE_FAILURE = 'organizations/SET_IMAGE_FAILURE'

export const SET_SEARCH_PARAMS = 'organizations/SET_SEARCH_PARAMS'

export const SEARCH_ATTEMPT = 'organizations/SEARCH_ATTEMPT'
export const SEARCH_SUCCESS = 'organizations/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'organizations/SEARCH_FAILURE'
export const RESET_SEARCH = 'organizations/RESET_SEARCH'

export const SEARCH_METADATA_ATTEMPT = 'organizations/SEARCH_METADATA_ATTEMPT'
export const SEARCH_METADATA_SUCCESS = 'organizations/SEARCH_METADATA_SUCCESS'
export const SEARCH_METADATA_FAILURE = 'organizations/SEARCH_METADATA_FAILURE'

export const LOAD_UPCOMING_EVENTS_ATTEMPT = 'organizations/LOAD_UPCOMING_EVENTS_ATTEMPT'
export const LOAD_UPCOMING_EVENTS_SUCCESS = 'organizations/LOAD_UPCOMING_EVENTS_SUCCESS'
export const LOAD_UPCOMING_EVENTS_FAILURE = 'organizations/LOAD_UPCOMING_EVENTS_FAILURE'

export const LOAD_PAST_EVENTS_ATTEMPT = 'organizations/LOAD_PAST_EVENTS_ATTEMPT'
export const LOAD_PAST_EVENTS_SUCCESS = 'organizations/LOAD_PAST_EVENTS_SUCCESS'
export const LOAD_PAST_EVENTS_FAILURE = 'organizations/LOAD_PAST_EVENTS_FAILURE'

export const UPDATE_ATTEMPT = 'organizations/UPDATE_ATTEMPT'
export const UPDATE_FAILURE = 'organizations/UPDATE_FAILURE'
export const UPDATE_SUCCESS = 'organizations/UPDATE_SUCCESS'

export const FOLLOW_ATTEMPT = 'organizations/FOLLOW_ATTEMPT'
export const FOLLOW_SUCCESS = 'organizations/FOLLOW_SUCCESS'
export const FOLLOW_FAILURE = 'organizations/FOLLOW_FAILURE'

export const LOAD_UN_PUBLISHED_EVENTS_ATTEMPT = 'organizations/LOAD_UN_PUBLISHED_EVENTS_ATTEMPT'
export const LOAD_UN_PUBLISHED_EVENTS_SUCCESS = 'organizations/LOAD_UN_PUBLISHED_EVENTS_SUCCESS'
export const LOAD_UN_PUBLISHED_EVENTS_FAILURE = 'organizations/LOAD_UN_PUBLISHED_EVENTS_FAILURE'

export const DELETE_IMAGE_ATTEMPT = 'organizations/DELETE_IMAGE_ATTEMPT'
export const DELETE_IMAGE_SUCCESS = 'organizations/DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAILURE = 'organizations/DELETE_IMAGE_FAILURE'

export const RESET_SEARCH_PARAMS = 'organizations/RESET_SEARCH_PARAMS'

export const LOAD_CUSTOM_FIELDS_ATTEMPT = 'organizations/LOAD_CUSTOM_FIELDS_ATTEMPT'
export const LOAD_CUSTOM_FIELDS_SUCCESS = 'organizations/LOAD_CUSTOM_FIELDS_SUCCESS'
export const LOAD_CUSTOM_FIELDS_FAILURE = 'organizations/LOAD_CUSTOM_FIELDS_FAILURE'

export const CREATE_CUSTOM_FIELD_ATTEMPT = 'organizations/CREATE_CUSTOM_FIELD_ATTEMPT'
export const CREATE_CUSTOM_FIELD_SUCCESS = 'organizations/CREATE_CUSTOM_FIELD_SUCCESS'
export const CREATE_CUSTOM_FIELD_FAILURE = 'organizations/CREATE_CUSTOM_FIELD_FAILURE'

export const UPDATE_CUSTOM_FIELD_ATTEMPT = 'organizations/UPDATE_CUSTOM_FIELD_ATTEMPT'
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'organizations/UPDATE_CUSTOM_FIELD_SUCCESS'
export const UPDATE_CUSTOM_FIELD_FAILURE = 'organizations/UPDATE_CUSTOM_FIELD_FAILURE'

export const DELETE_CUSTOM_FIELD_ATTEMPT = 'organizations/DELETE_CUSTOM_FIELD_ATTEMPT'
export const DELETE_CUSTOM_FIELD_SUCCESS = 'organizations/DELETE_CUSTOM_FIELD_SUCCESS'
export const DELETE_CUSTOM_FIELD_FAILURE = 'organizations/DELETE_CUSTOM_FIELD_FAILURE'

export const LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_ATTEMPT =
  'organizations/LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_ATTEMPT'
export const LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_SUCCESS =
  'organizations/LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_FAILURE =
  'organizations/LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_FAILURE'

export const ACCEPT_ADMIN_USER_ATTEMPT = 'organizations/ACCEPT_ADMIN_USER_ATTEMPT'
export const ACCEPT_ADMIN_USER_SUCCESS = 'organizations/ACCEPT_ADMIN_USER_SUCCESS'
export const ACCEPT_ADMIN_USER_FAILURE = 'organizations/ACCEPT_ADMIN_USER_FAILURE'

export const REJECT_ADMIN_USER_ATTEMPT = 'organizations/REJECT_ADMIN_USER_ATTEMPT'
export const REJECT_ADMIN_USER_SUCCESS = 'organizations/REJECT_ADMIN_USER_SUCCESS'
export const REJECT_ADMIN_USER_FAILURE = 'organizations/REJECT_ADMIN_USER_FAILURE'

export const SEND_ONBOARDING_INFORMATION_ATTEMPT =
  'organizations/SEND_ONBOARDING_INFORMATION_ATTEMPT'
export const SEND_ONBOARDING_INFORMATION_SUCCESS =
  'organizations/SEND_ONBOARDING_INFORMATION_SUCCESS'
export const SEND_ONBOARDING_INFORMATION_FAILURE =
  'organizations/SEND_ONBOARDING_INFORMATION_FAILURE'

export const LOAD_SERVICE_LETTER_REPORT_ATTEMPT = 'organizations/LOAD_SERVICE_LETTER_REPORT_ATTEMPT'
export const LOAD_SERVICE_LETTER_REPORT_SUCCESS = 'organizations/LOAD_SERVICE_LETTER_REPORT_SUCCESS'
export const LOAD_SERVICE_LETTER_REPORT_FAILURE = 'organizations/LOAD_SERVICE_LETTER_REPORT_FAILURE'

export const CREATE_SERVICE_LETTER_REPORT_ATTEMPT =
  'organizations/CREATE_SERVICE_LETTER_REPORT_ATTEMPT'
export const CREATE_SERVICE_LETTER_REPORT_SUCCESS =
  'organizations/CREATE_SERVICE_LETTER_REPORT_SUCCESS'
export const CREATE_SERVICE_LETTER_REPORT_FAILURE =
  'organizations/CREATE_SERVICE_LETTER_REPORT_FAILURE'

export const UPDATE_SERVICE_LETTER_REPORT_ATTEMPT =
  'organizations/UPDATE_SERVICE_LETTER_REPORT_ATTEMPT'
export const UPDATE_SERVICE_LETTER_REPORT_SUCCESS =
  'organizations/UPDATE_SERVICE_LETTER_REPORT_SUCCESS'
export const UPDATE_SERVICE_LETTER_REPORT_FAILURE =
  'organizations/UPDATE_SERVICE_LETTER_REPORT_FAILURE'

export const DOWNLOAD_SERVICE_LETTER_REPORT_ATTEMPT =
  'organizations/DOWNLOAD_SERVICE_LETTER_REPORT_ATTEMPT'
export const DOWNLOAD_SERVICE_LETTER_REPORT_SUCCESS =
  'organizations/DOWNLOAD_SERVICE_LETTER_REPORT_SUCCESS'
export const DOWNLOAD_SERVICE_LETTER_REPORT_FAILURE =
  'organizations/DOWNLOAD_SERVICE_LETTER_REPORT_FAILURE'

export const LOAD_ACTIVE_SERVICE_LETTER_REPORT_ATTEMPT =
  'organizations/LOAD_ACTIVE_SERVICE_LETTER_REPORT_ATTEMPT'
export const LOAD_ACTIVE_SERVICE_LETTER_REPORT_SUCCESS =
  'organizations/LOAD_ACTIVE_SERVICE_LETTER_REPORT_SUCCESS'
export const LOAD_ACTIVE_SERVICE_LETTER_REPORT_FAILURE =
  'organizations/LOAD_ACTIVE_SERVICE_LETTER_REPORT_FAILURE'

export const RESET_VALUE = 'organizations/RESET_VALUE'
