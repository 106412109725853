import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    backgroundColor: '#ffff'
  },
  mainContainer: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      padding: theme.spacing(0, 4),
      margin: '0 auto'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    width: 320,
    maxWidth: 320
  },
  tooltipArrow: {
    color: theme.palette.tooltip.main
  },
  tooltipButton: {
    right: '5px !important'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(5),
    '& h5': {
      fontWeight: 600,
      textAlign: 'center',
      flexGrow: 1,
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center'
      }
    },
    '&.submitted': {
      '& h5': {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(5)
        }
      },
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center'
      },
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 7)
      }
    },

    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(7)
    }
  },
  messageContainer: {
    padding: theme.spacing(5, 0),
    '& h6': {
      fontWeight: 600,
      marginBottom: theme.spacing(2)
    },
    '& p': {
      fontSize: '.9rem'
    }
  },

  datePickersSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& h6': {
      fontWeight: 600,
      marginBottom: theme.spacing()
    },

    [theme.breakpoints.up('md')]: {
      width: 650,
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  },
  pickerContainer: {
    display: 'flex'
  },
  fromContainer: {
    width: 200
  },
  inputDateField: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3),

    '& input': {
      color: theme.palette.primary.main,
      fontSize: '0.85rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40,
      width: 170
    },
    '&.timePicker': {
      '& > div': {
        width: 160
      },
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      }
    },

    '& div > button': {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: '#ffff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffff'
      },
      width: 40,
      height: 40
    }
  },
  dateField: {
    '& input': {
      padding: theme.spacing(1.1, 1.8)
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: 'solid'
    }
  },
  dateValidationField: {
    marginBottom: 0
  },

  textAreaEdit: {
    width: '100%',
    padding: theme.spacing(3, 0),
    '& > div > textArea': {
      minHeight: 150,
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      }
    },
    '&.mini': {
      padding: 0,
      paddingTop: theme.spacing(3),
      '& > div': {
        padding: theme.spacing(1.5)
      },
      '& > div > textArea': {
        minHeight: 5
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    minHeight: 200,
    width: 400,
    margin: '30px auto',
    [theme.breakpoints.up('md')]: {
      width: 750,
      minHeight: 'auto',
      flexDirection: 'row',
      '&.medium': {
        width: 1000
      },
      '& button': {
        width: '90%'
      }
    }
  },
  button: {
    width: '50%',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(9)
    }
  },
  backButton: {
    padding: 15,
    alignSelf: 'center',
    '& svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },

  createButton: {
    '& span': {
      fontWeight: 500
    }
  },
  addButton: {
    width: '50%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginLeft: 300,
      marginTop: 0,
      width: 300
    }
  },
  sectionBottomContainer: {
    margin: '30px auto',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up('lg')]: {
      width: 1280
    }
  },
  fieldCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    '& > div': {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        width: '33%'
      }
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },

  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem'
    },
    '&.radio': {
      margin: theme.spacing(3, 0)
    },
    '& input': {
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  radioButtonContainer: {
    flexDirection: 'row'
  },
  ageSection: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  listContainer: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '80%'
    }
  },
  list: {
    paddingInlineStart: 0,
    width: '100%',
    '& li': {
      listStyle: 'none',
      color: theme.palette.primary.main,
      fontWeight: 700,
      marginBottom: theme.spacing(2)
    }
  },
  importantText: {
    color: theme.palette.primary.main,
    fontWeight: 400
  },

  eventRulesinputLink: {
    margin: theme.spacing(2, 0),
    '& input': {
      height: 4,
      color: 'rgba(66, 133, 244, 1)',
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  eventRulesButton: {
    padding: theme.spacing(1, 4)
  },
  rulesFileName: {
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'Ellipsis',
    overflow: 'hidden',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      textAlign: 'left'
    }
  },
  rulesFileNameContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: 'rgba(66, 133, 244, 1)',
    [theme.breakpoints.up('lg')]: {
      width: 300,
      marginLeft: theme.spacing(),
      '& > div': {
        flexShrink: 0
      }
    }
  },
  fieldContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start'
  },
  rulesDocumentHelperText: {
    fontSize: '.875rem'
  },
  deleteEventRulesButton: {
    height: 41,
    marginTop: 15
  },
  showInscriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(400)]: {
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  numberField: {
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  checkboxContainer: {
    flexShrink: 0,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up(400)]: {
      margin: theme.spacing(0, 2)
    }
  },

  inputField: {
    marginTop: theme.spacing(2),
    '& input': {
      height: 4,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  validationContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& h6': {
      fontWeight: 600
    }
  },

  validateAgeAndGenderContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  validateDateFromContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4)
    }
  },
  allowUnregisteredUsersLabel: {
    fontWeight: 600
  },
  radio: {
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  letterCounter: {
    fontSize: '0.675rem',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.875rem'
    }
  }
}))
