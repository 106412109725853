import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 360,
    height: '100%',
    overflow: 'hidden',
    transition: 'width 0.5s linear',
    position: 'relative',

    '&.top': {
      width: '100%',
      height: 'auto',
      transition: 'height 1s linear',
      paddingTop: theme.spacing(1.25)
    }
  },
  containerClosed: {
    width: 26,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 40,

    '&.top': {
      height: 30,
      width: '100%',
      marginBottom: 0
    }
  },
  drawer: {
    width: 350,
    flexShrink: 0,
    height: '100%',

    '& > div': {
      position: 'relative',
      zIndex: 0
    },

    '&.right': {
      marginLeft: 10
    },
    '&.top': {
      width: '100%',
      height: '100%'
    }
  },
  drawerOpen: {
    width: 350,
    visibility: 'visible !important',
    '&.top': {
      width: '100%'
    }
  },

  drawerClose: {
    overflowX: 'hidden',
    transform: 'translateX(-100%) !important',
    visibility: 'visible !important',
    transition: 'transform  1s linear, width 7s ease-in !important',

    '&.top': {
      overflowY: 'hidden',
      overflowX: 'auto',
      height: 26,
      width: '100%',
      transform: 'translateY(-100%) !important',
      visibility: 'visible !important',
      transition: 'transform  1s linear, height 2s ease-in !important'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),

    '&.adminMode': {
      flexDirection: 'column-reverse',
      paddingTop: theme.spacing(4)
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: 0,
      width: '100%',
      gap: theme.spacing(3),
      '&.results': {
        flexDirection: 'column',
        background: theme.palette.background.section,
        borderRadius: 10,
        border: `1px solid ${theme.palette.primary.main}`,
        position: 'relative',
        '& > div': {
          width: 'auto',
          backgroundColor: 'transparent',
          marginTop: 0
        },
        '& > div > div': {
          backgroundColor: 'transparent'
        },
        overflowX: 'hidden'
      }
    },
    '&.suspended': {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex'
      }
    }
  },

  openButton: {
    backgroundColor: theme.palette.alt.main,
    color: theme.palette.primary.main,
    padding: 0,
    zIndex: 10,

    '&.right': {
      left: 0,
      top: 150
    },

    '&.top': {
      position: 'absolute',
      transform: 'rotate(270deg)',
      right: 5,
      top: 0
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  lineLeft: {
    backgroundColor: theme.palette.primary.main,

    '&.top': {
      width: '100%',
      height: 2
    },

    '&.right': {
      position: 'absolute',
      width: 2,
      marginTop: 25,
      height: '100%',
      left: 10
    }
  },
  tooltip: {
    color: theme.palette.secondary.contrastText,
    fontSize: '.75rem',
    backgroundColor: theme.palette.alt.main,
    width: 70,
    maxWidth: 70,
    borderRadius: 0,
    padding: 5,
    margin: theme.spacing(2, 0),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3, 0)
    }
  },
  tooltipArrow: {
    color: theme.palette.alt.main
  },
  tooltipPopper: {
    zIndex: 1000
  }
}))
