import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '88%',
      margin: '0 auto',
      flexDirection: 'row',
      gap: 0,
      justifyContent: 'space-between'
    }
  },
  select: {
    height: 40,
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(1.25),
      fontWeight: 500
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    },
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  labelSelect: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingRight: theme.spacing(),
    backgroundColor: theme.palette.primary.contrastText,
    transform: 'translate(14px, 13px) scale(1)',

    '&.error': {
      color: theme.palette.error.main
    },

    '&.disabled': {
      color: theme.palette.disabled.main
    }
  },
  helperText: {
    marginLeft: theme.spacing()
  },
  eventPublushedTitle: {
    fontWeight: 600
  },
  eventPublishedContainer: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'center',

    '&.hidden': {
      display: 'none'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    width: 280,
    maxWidth: 280
  },
  tooltipArrow: {
    color: theme.palette.tooltip.main
  },
  tooltipCloseButton: {
    right: '5px !important',
    top: '5px !important'
  },
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.64)
    }
  },
  itemSelected: {
    display: 'none'
  },
  menuContainer: {
    marginTop: theme.spacing(5)
  }
}))
