import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Info as InfoIcon, Visibility, VisibilityOff } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { GoogleLogin } from '@react-oauth/google'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import { CountriesProxy, UserAccountsProxy } from 'services'
import { CustomizedTooltip, PrivacyPolicyContent, TermsAndConditionsContent } from 'shared'
import { getCountries, removeDiacritics } from 'utils/functions'
import * as Yup from 'yup'

import { TermsAndConditionsDialog } from '../TermsAndConditionsDialog'

import useStyles from './SignUpForm.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }

const googleValidationSchema = Yup.object().shape({
  eMail: Yup.string(),
  password: Yup.string(),
  passwordConfirmation: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  username: Yup.string()
    .min(3, 'El nombre de usuario debe tener como mínimo 3 caracteres') // Add
    .max(30, 'El nombre de usuario tiene un máximo 30 caracteres')
    .matches(/^[a-z0-9]+$/i, 'El nombre de usuario no debe tener caracteres especiales ni espacios')
    .required('Ingresa tu nombre de usuario')
    .test(
      'username-already-exists',
      'Este nombre de usuario ya está registrado.',
      async function (username) {
        if (username) {
          const proxy = new UserAccountsProxy()
          try {
            const response = await proxy.existsUsernameEmail({
              UsernameOrEmail: username
            })
            return !response
          } catch (e) {
            return true
          }
        } else {
          return true
        }
      }
    ),
  countryId: Yup.mixed().required('Debe elegir un país'),
  areaLevel1Id: Yup.mixed().when('countryId', {
    is: (countryId) => countryId === ARGENTINA_COUNTRY.value,
    then: Yup.string().required('Debe elegir una provincia')
  }),

  hasSelectedRole: Yup.boolean().test(
    'at-least-one-role',
    'Debes seleccionar al menos un rol',
    (value, { parent }) =>
      value === true ||
      parent.isAthlete === true ||
      parent.isOrganizer === true ||
      parent.isTimekeeper === true
  ),
  idNumber: Yup.string()
    .required('El DNI es obligatorio')
    .min(6, 'El DNI debe poseer como mínimo 6 caracteres')
    .max(15, 'El DNI debe poseer como máximo 15 caracteres')
    .matches(/^[a-z0-9]+$/i, 'El DNI no debe tener caracteres especiales ni espacios')
    .trim()
    .test(
      'idNumber-already-exists',
      'Este dni ya se encuentra en uso',
      async (idNumber, { parent }) => {
        if (idNumber) {
          const { countryId } = parent
          const proxy = new UserAccountsProxy()

          try {
            const response = await proxy.existsIdNumber(idNumber, countryId)
            return !response
          } catch (e) {
            return true
          }
        } else {
          return true
        }
      }
    ),
  birthdate: Yup.string()
    .nullable()
    .required('Seleccione su fecha de nacimiento')
    .test(
      'test-age',
      'La edad permitida es entre 12 y 120 años',
      (value) =>
        !!value && moment().diff(value, 'years') >= 12 && moment().diff(value, 'years') <= 120
    ),
  acceptTerms: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones')
})

const validationSchema = Yup.object().shape({
  areaLevel1Id: Yup.mixed().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.SECOND_PAGE,
    then: (schema) =>
      schema.when('countryId', {
        is: (countryId) => countryId === ARGENTINA_COUNTRY.value,
        then: Yup.string().required('Debe elegir una provincia')
      })
  }),
  countryId: Yup.mixed().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.SECOND_PAGE,
    then: (schema) => schema.required('Debe elegir un país')
  }),
  idNumber: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.SECOND_PAGE,
    then: (schema) =>
      schema
        .required('El DNI es obligatorio')
        .min(6, 'El DNI debe poseer como mínimo 6 caracteres')
        .max(15, 'El DNI debe poseer como máximo 15 caracteres')
        .matches(/^[a-z0-9]+$/i, 'El DNI no debe tener caracteres especiales ni espacios')
        .trim()
        .test(
          'idNumber-already-exists',
          'Este dni ya se encuentra en uso',
          async (idNumber, { parent }) => {
            if (idNumber) {
              const { countryId } = parent
              const proxy = new UserAccountsProxy()

              try {
                const response = await proxy.existsIdNumber(idNumber, countryId)
                return !response
              } catch (e) {
                return true
              }
            } else {
              return true
            }
          }
        )
  }),
  birthdate: Yup.string()
    .nullable()
    .when('positionPage', {
      is: (positionPage) => positionPage === FORM_POSITION.SECOND_PAGE,
      then: (schema) =>
        schema
          .required('Seleccione su fecha de nacimiento')
          .test(
            'test-age',
            'La edad permitida es entre 12 y 120 años',
            (value) =>
              !!value && moment().diff(value, 'years') >= 12 && moment().diff(value, 'years') <= 120
          )
    }),
  acceptTerms: Yup.boolean().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.SECOND_PAGE,
    then: (schema) => schema.oneOf([true], 'Debes aceptar los términos y condiciones')
  }),
  hasSelectedRole: Yup.boolean().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.SECOND_PAGE,
    then: (schema) =>
      schema.test(
        'at-least-one-role',
        'Debes seleccionar al menos un rol',
        (value, { parent }) =>
          value === true ||
          parent.isAthlete === true ||
          parent.isOrganizer === true ||
          parent.isTimekeeper === true
      )
  }),
  password: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .min(8, 'La contraseña es muy corta') // Add
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
          'Tu contraseña debe tener al menos 1 mayúscula, 1 minúscula y un número.'
        ) // Add
        .required('Ingresa una contraseña')
  }),

  passwordConfirmation: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .required('Repite tu contraseña')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
  }),
  firstName: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .transform((value) => removeDiacritics(value))
        .max(256, 'El nombre tiene un máximo de 256 caracteres')
        .required('Ingresa tu nombre')
        .matches(/^[a-z ,.-]+$/i, 'El nombre no debe tener caracteres especiales ni números')
        .trim()
  }),
  lastName: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .transform((value) => removeDiacritics(value))
        .max(256, 'El apellido tiene un máximo de 256 caracteres')
        .required('Ingresa tu apellido')
        .matches(/^[a-z ,.-]+$/i, 'El apellido no debe tener caracteres especiales ni números')
        .trim()
  }),
  username: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .min(3, 'El nombre de usuario debe tener como mínimo 3 caracteres') // Add
        .max(30, 'El nombre de usuario tiene un máximo 30 caracteres')
        .matches(
          /^[a-z0-9]+$/i,
          'El nombre de usuario no debe tener caracteres especiales ni espacios'
        )
        .required('Ingresa tu nombre de usuario')
        .test(
          'username-already-exists',
          'Este nombre de usuario ya está registrado.',
          async function (username) {
            if (username) {
              const proxy = new UserAccountsProxy()
              try {
                const response = await proxy.existsUsernameEmail({
                  UsernameOrEmail: username
                })
                return !response
              } catch (e) {
                return true
              }
            } else {
              return true
            }
          }
        )
  }),
  eMail: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .email('El e-mail es inválido')
        .min(5, 'El e-mail es demasiado corto') // Add
        .max(254, 'El e-mail es demasiado largo')
        .required('Ingresa tu dirección de correo')
        .test('email-already-exists', 'Este E-Mail ya está registrado.', async function (email) {
          if (email) {
            const proxy = new UserAccountsProxy()
            try {
              const response = await proxy.existsUsernameEmail({
                UsernameOrEmail: email
              })
              return !response
            } catch (e) {
              return true
            }
          } else {
            return true
          }
        })
  })
})

const registrationValidationSchema = Yup.object().shape({
  eMail: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .email('El e-mail es inválido')
        .min(5, 'El e-mail es demasiado corto') // Add
        .max(254, 'El e-mail es demasiado largo')
        .required('Ingresa tu dirección de correo')
        .test('email-already-exists', 'Este E-Mail ya está registrado.', async function (email) {
          if (email) {
            const proxy = new UserAccountsProxy()
            try {
              const response = await proxy.existsUsernameEmail({
                UsernameOrEmail: email
              })
              return !response
            } catch (e) {
              return true
            }
          } else {
            return true
          }
        })
  }),
  password: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .min(8, 'La contraseña es muy corta') // Add
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
          'Tu contraseña debe tener al menos 1 mayúscula, 1 minúscula y un número.'
        ) // Add
        .required('Ingresa una contraseña')
  }),

  passwordConfirmation: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .required('Repite tu contraseña')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
  }),
  firstName: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .transform((value) => removeDiacritics(value))
        .max(256, 'El nombre tiene un máximo de 256 caracteres')
        .required('Ingresa tu nombre')
        .matches(/^[a-z ,.-]+$/i, 'El nombre no debe tener caracteres especiales ni números')
        .trim()
  }),
  lastName: Yup.string().when('positionPage', {
    is: (positionPage) => positionPage === FORM_POSITION.FIRST_PAGE,
    then: (schema) =>
      schema
        .transform((value) => removeDiacritics(value))
        .max(256, 'El apellido tiene un máximo de 256 caracteres')
        .required('Ingresa tu apellido')
        .matches(/^[a-z ,.-]+$/i, 'El apellido no debe tener caracteres especiales ni números')
        .trim()
  }),
  username: Yup.string()
    .min(3, 'El nombre de usuario debe tener como mínimo 3 caracteres') // Add
    .max(30, 'El nombre de usuario tiene un máximo 30 caracteres')
    .matches(/^[a-z0-9]+$/i, 'El nombre de usuario no debe tener caracteres especiales ni espacios')
    .required('Ingresa tu nombre de usuario')
    .test(
      'username-already-exists',
      'Este nombre de usuario ya está registrado.',
      async function (username) {
        if (username) {
          const proxy = new UserAccountsProxy()
          try {
            const response = await proxy.existsUsernameEmail({
              UsernameOrEmail: username
            })
            return !response
          } catch (e) {
            return true
          }
        } else {
          return true
        }
      }
    ),
  hasSelectedRole: Yup.boolean().test(
    'at-least-one-role',
    'Debes seleccionar al menos un rol',
    (value, { parent }) =>
      value === true ||
      parent.isAthlete === true ||
      parent.isOrganizer === true ||
      parent.isTimekeeper === true
  ),
  acceptTerms: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones')
})

const FORM_POSITION = {
  FIRST_PAGE: 0,
  SECOND_PAGE: 1
}

const SignUpForm = ({
  onSubmit,
  signUpError,
  signUpData,
  handleGoogleSignUp,
  handleOpenSignInDialog,
  state = false
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { inscriptionRegister } = useSelector((state) => state.app)

  const [showPassword, setShowPassword] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const classes = useStyles()
  const [openTerms, setOpenTerms] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)

  const empty = { value: '', label: '--Seleccione--' }
  useEffect(() => {
    onCountryChange()(ARGENTINA_COUNTRY.value)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const countriesData = await handleGetCountries()
      setCountries(countriesData)
    }
    fetchData()
  }, [])

  const handleNextPage = (setValues, position) => {
    setValues((values) => ({
      ...values,
      positionPage: position
    }))
  }

  const handleClickOpenPolicy = (e) => {
    e.preventDefault()
    setOpenPolicy(true)
  }

  const handleClosePolicy = () => {
    setOpenPolicy(false)
  }

  const handleClickOpenTerms = (e) => {
    e.preventDefault()
    setOpenTerms(true)
  }

  const handleCloseTerms = () => {
    setOpenTerms(false)
  }

  const onDateChanged = (setFieldValue) => (value) => {
    if (value && value.isValid()) {
      setFieldValue('birthdate', value.format('YYYY-MM-DD'))
    } else {
      setFieldValue('birthdate', '')
    }
  }

  const onCountryChange = (setFieldValue) => async (option) => {
    try {
      if (setFieldValue) {
        setFieldValue('countryId', option)
        setFieldValue('areaLevel1Id', '', false)
        setFieldValue('areaLevel2Id', '', false)
      }

      setProvinces([])
      setCities([])

      if (option !== empty.value) {
        const proxy = new CountriesProxy()
        const data = await proxy.getAreaLevel1(option)
        if (Array.isArray(data) && !!data.length) {
          setProvinces(data.map((d) => ({ value: d.id, label: d.name })))
        }
      }
    } catch (e) {}
  }

  const onProvinceChange = (setFieldValue) => async (option) => {
    setFieldValue('areaLevel1Id', option)
    const proxy = new CountriesProxy()
    const dataAreaLevel2 = await proxy.getAreaLevel2(option)
    setCities(dataAreaLevel2)
  }

  const values = {
    eMail:
      (signUpData && signUpData.email) || inscriptionRegister?.inscription
        ? inscriptionRegister?.inscription.eMail
        : '',
    signUpWithGoogle: !!signUpData,
    countryId: ARGENTINA_COUNTRY.value,
    areaLevel1Id: '',
    username: '',
    password:
      (signUpData &&
        signUpData.authResponse &&
        signUpData.authResponse.accessToken.substring(0, 16)) ||
      '', // todo
    passwordConfirmation:
      (signUpData &&
        signUpData.authResponse &&
        signUpData.authResponse.accessToken.substring(0, 16)) ||
      '', // todo
    firstName:
      (signUpData && signUpData.first_name) || inscriptionRegister?.inscription
        ? inscriptionRegister?.inscription.firstName
        : '',
    lastName:
      (signUpData && signUpData.last_name) || inscriptionRegister?.inscription
        ? inscriptionRegister?.inscription.lastName
        : '',
    birthdate: null,
    areaLevel2Id: null,
    areaLevel2Other: null,
    idNumber: '',
    acceptTerms: false,
    isOrganizer: false,
    isTimekeeper: false,
    isAthlete: inscriptionRegister?.inscription != null,
    positionPage: 0
  }

  const handleGetCountries = async () => {
    const getCountriesFunction = getCountries('')
    const countries = await getCountriesFunction('')
    return countries
  }

  const getValidation = () => {
    if (signUpData) return googleValidationSchema

    if (state) return registrationValidationSchema

    return validationSchema
  }

  return (
    <div className={classes.container}>
      <div className={classes.bodyContainer}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={getValidation()}
          onSubmit={onSubmit}>
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
            setValues
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.formContainer}>
                  {!signUpData && !state && (
                    <div
                      className={
                        values.positionPage !== FORM_POSITION.FIRST_PAGE
                          ? classes.initialText
                          : classes.initialTextFirstPage
                      }>
                      {isMobile && values.positionPage !== FORM_POSITION.FIRST_PAGE ? (
                        <div className={classes.linkContainer}>
                          <Link
                            to='#'
                            onClick={() => handleNextPage(setValues, values.positionPage - 1)}>
                            Paso anterior
                          </Link>
                        </div>
                      ) : null}
                      <Typography variant='caption' className={classes.labelStep}>
                        {`Paso ${values.positionPage + 1} de 2 ${isMobile ? 'Registro' : ''} `}
                      </Typography>
                    </div>
                  )}
                  {!signUpData ? (
                    <div className={clsx(classes.form, state && 'register')}>
                      {values.positionPage === FORM_POSITION.FIRST_PAGE && (
                        <div className={classes.firstForm}>
                          {!state && (
                            <div className={classes.initialText}>
                              <Typography variant='caption' className={classes.label}>
                                Te pedimos estos datos para crear tu cuenta y poder ofrecerte una
                                experiencia personalizada, así como ahorrar tiempo en la inscripción
                                a tus eventos preferidos.
                              </Typography>
                            </div>
                          )}

                          <div className={classes.row}>
                            <div className={classes.formGroup}>
                              <TextField
                                name='firstName'
                                autoFocus
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={touched.firstName && Boolean(errors.firstName)}
                                required
                                variant='outlined'
                                size='small'
                                margin='normal'
                                className={classes.input}
                                label='Nombre'
                              />
                              <div className={classes.containerInputText}>
                                {errors.firstName && (
                                  <Typography
                                    color='error'
                                    variant='caption'
                                    className={classes.errorMessage}>
                                    {errors.firstName}
                                  </Typography>
                                )}
                              </div>
                            </div>
                            <div className={classes.formGroup}>
                              <TextField
                                name='lastName'
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={touched.lastName && Boolean(errors.lastName)}
                                required
                                variant='outlined'
                                size='small'
                                margin='normal'
                                className={classes.input}
                                label='Apellido'
                              />
                              <div className={classes.containerInputText}>
                                {errors.lastName && (
                                  <Typography
                                    color='error'
                                    variant='caption'
                                    className={classes.errorMessage}>
                                    {errors.lastName}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={classes.row}>
                            <div className={classes.formGroup}>
                              <TextField
                                name='username'
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={touched.username && Boolean(errors.username)}
                                required
                                placeholder='¿Cómo querés que te llamemos?'
                                variant='outlined'
                                size='small'
                                margin='normal'
                                className={classes.input}
                                label='Nombre de usuario'
                              />
                              <div className={classes.containerInputText}>
                                <Typography
                                  color='error'
                                  variant='caption'
                                  className={
                                    !errors.username ? classes.inputText : classes.errorMessage
                                  }>
                                  {!errors.username
                                    ? 'Nombre corto que te identifique'
                                    : errors.username}
                                </Typography>
                              </div>
                            </div>
                            {!signUpData ? (
                              <div className={classes.formGroup}>
                                <TextField
                                  name='eMail'
                                  value={values.eMail}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.eMail && Boolean(errors.eMail)}
                                  fullWidth
                                  required
                                  variant='outlined'
                                  size='small'
                                  margin='normal'
                                  className={classes.input}
                                  label='E-mail'
                                />
                                <div className={classes.containerInputText}>
                                  {errors.eMail && (
                                    <Typography
                                      color='error'
                                      variant='caption'
                                      className={classes.errorMessage}>
                                      {errors.eMail}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className={classes.row}>
                            <div className={classes.formGroup}>
                              <TextField
                                name='password'
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && Boolean(errors.password)}
                                fullWidth
                                required
                                variant='outlined'
                                size='small'
                                label='Contraseña'
                                margin='normal'
                                className={classes.input}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge='end'>
                                        {showPassword ? (
                                          <Visibility color='primary' />
                                        ) : (
                                          <VisibilityOff color='primary' />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                              <div className={classes.containerInputText}>
                                <Typography
                                  color='error'
                                  variant='caption'
                                  className={
                                    !errors.password ? classes.inputText : classes.errorMessage
                                  }>
                                  {!errors.password
                                    ? '8 o más caracteres, incluir minúsculas, mayúsculas y números y sin caracteres especiales.'
                                    : errors.password}
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.formGroup}>
                              <TextField
                                name='passwordConfirmation'
                                type={showPassword ? 'text' : 'password'}
                                value={values.passwordConfirmation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.passwordConfirmation &&
                                  Boolean(errors.passwordConfirmation)
                                }
                                fullWidth
                                required
                                variant='outlined'
                                size='small'
                                label='Repetir contraseña'
                                margin='normal'
                                className={classes.input}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge='end'>
                                        {showPassword ? (
                                          <Visibility color='primary' />
                                        ) : (
                                          <VisibilityOff color='primary' />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                              <div className={classes.containerInputText}>
                                {errors.passwordConfirmation && (
                                  <Typography
                                    color='error'
                                    variant='caption'
                                    className={classes.errorMessage}>
                                    {errors.passwordConfirmation}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                          {state && (
                            <>
                              <div className={classes.rolesContainer}>
                                <Typography
                                  variant='caption'
                                  className={clsx(classes.labelStep, classes.selectRolesContainer)}>
                                  Elegí el rol que tendrá tu cuenta, podés elegir más de uno:
                                  <CustomizedTooltip
                                    title='Esto nos permite brindarte una experiencia personalizada habilitando las funcionalidades correspondientes.'
                                    position='right-end'
                                    arrowClassName={classes.arrowTooltip}
                                    className={classes.tooltip}
                                    buttonClassName={classes.closeButton}
                                    isTopMost
                                    disabledClickAway>
                                    {({ handleTooltip }) => (
                                      <InfoIcon
                                        className={classes.alertInfoIcon}
                                        color='primary'
                                        onClick={handleTooltip}
                                      />
                                    )}
                                  </CustomizedTooltip>
                                </Typography>
                                <div className={classes.formGroup}>
                                  <FormControlLabel
                                    value={true}
                                    onChange={handleChange}
                                    name='isAthlete'
                                    className={classes.checkboxRoles}
                                    control={
                                      <Checkbox
                                        checked={values.isAthlete}
                                        name='isAthlete'
                                        color='primary'
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant='body1'
                                        className={classes.rolesParagraph}>
                                        Rol deportista, elegí esta opción si practicas deportes y
                                        estás interesado en competir.
                                      </Typography>
                                    }
                                  />
                                </div>
                                <div className={classes.formGroup}>
                                  <FormControlLabel
                                    value={true}
                                    onChange={handleChange}
                                    name='isOrganizer'
                                    className={classes.checkboxRoles}
                                    control={
                                      <Checkbox
                                        checked={values.isOrganizer}
                                        name='isOrganizer'
                                        color='primary'
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant='body1'
                                        className={classes.rolesParagraph}>
                                        Rol organizador, elige esta opción si organizas o te
                                        interesa organizar eventos deportivos.
                                      </Typography>
                                    }
                                  />
                                </div>
                              </div>
                              <div className={classes.infoContainer}>
                                <div className={classes.formGroup}>
                                  <FormControlLabel
                                    value={true}
                                    onChange={handleChange}
                                    name='acceptTerms'
                                    className={classes.checkbox}
                                    control={
                                      <Checkbox
                                        required
                                        checked={values.acceptTerms}
                                        name='acceptTerms'
                                        color='primary'
                                      />
                                    }
                                    label={
                                      <Typography variant='body1' className={classes.tosParagraph}>
                                        Acepto las&nbsp;
                                        <Link to='#' onClick={handleClickOpenTerms}>
                                          Condiciones de uso
                                        </Link>
                                        &nbsp;y la&nbsp;
                                        <Link to='#' onClick={handleClickOpenPolicy}>
                                          Política de privacidad.
                                        </Link>
                                      </Typography>
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {!state ? (
                            <div className={classes.buttonContainerFirstForm}>
                              <Button
                                color='primary'
                                variant='contained'
                                onClick={() => handleNextPage(setValues, values.positionPage + 1)}
                                disabled={!isValid}>
                                Siguiente
                              </Button>
                            </div>
                          ) : (
                            <div className={classes.buttonContainerGoogleSignUp}>
                              <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                                disabled={!isValid || isSubmitting}
                                endIcon={isSubmitting && <CircularProgress size={16} />}>
                                Registrarme
                              </Button>
                            </div>
                          )}

                          {isMobile && !state && (
                            <div className={classes.signInOptionsContainer}>
                              <div className={classes.googleLoginContainer}>
                                <GoogleLogin
                                  onSuccess={handleGoogleSignUp}
                                  onError={console.error}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {values.positionPage === FORM_POSITION.SECOND_PAGE && (
                        <div>
                          <div className={classes.row}>
                            <div className={classes.formGroup}>
                              <KeyboardDatePicker
                                format='DD/MM/YYYY'
                                variant='inline'
                                autoFocus
                                autoOk
                                label='Fecha de nacimiento'
                                name='birthdate'
                                margin='normal'
                                className={classes.inputDatePicker}
                                id='date-picker-inline'
                                inputVariant='outlined'
                                style={{ width: '100%' }}
                                value={values.birthdate}
                                onBlur={handleBlur}
                                onChange={onDateChanged(setFieldValue)}
                                KeyboardButtonProps={{
                                  'aria-label': 'seleccione fecha'
                                }}
                                placeholder='ddmmaaaa'
                                error={touched.birthdate && Boolean(errors.birthdate)}
                                helperText={errors.birthdate}
                              />
                            </div>
                            <div className={classes.formGroup}>
                              <TextField
                                name='idNumber'
                                label='Número de DNI'
                                value={values.idNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.idNumber && Boolean(errors.idNumber)}
                                helperText={errors.idNumber}
                                fullWidth
                                required
                                variant='outlined'
                                size='small'
                                margin='normal'
                                className={classes.input}
                              />
                            </div>
                          </div>
                          <div className={classes.row}>
                            <div className={classes.formGroup}>
                              <FormControl variant='outlined' className={classes.formControl}>
                                <InputLabel
                                  htmlFor='outlined-age-native-simple'
                                  className={classes.labelSelect}>
                                  País
                                </InputLabel>
                                <Select
                                  className={classes.selectItem}
                                  value={values.countryId}
                                  name='countryId'
                                  onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
                                  onBlur={handleBlur}
                                  label='País'
                                  inputProps={{
                                    name: 'countryId',
                                    id: 'outlined-age-native-simple'
                                  }}>
                                  {countries.map((e, i) => (
                                    <MenuItem key={i} value={e.value}>
                                      {e.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <div className={classes.containerInputText}>
                                <Typography
                                  color='error'
                                  variant='caption'
                                  className={
                                    !errors.countryId ? classes.inputText : classes.errorMessage
                                  }>
                                  {!errors.countryId ? 'Seleccione País' : errors.countryId}
                                </Typography>
                              </div>
                            </div>
                            <div className={classes.formGroup}>
                              <FormControl variant='outlined' className={classes.formControl}>
                                <InputLabel
                                  htmlFor='outlined-age-native-simple'
                                  className={classes.labelSelect}>
                                  Seleccione su Provincia
                                </InputLabel>
                                <Select
                                  autoWidth={true}
                                  className={classes.selectItem}
                                  value={values.areaLevel1Id}
                                  name='areaLevel1Id'
                                  onChange={(e) => {
                                    const selectedValue = e.target.value
                                    onProvinceChange(setFieldValue)(selectedValue)
                                  }}
                                  onBlur={handleBlur}
                                  label='Seleccione su Provincia'
                                  MenuProps={{
                                    classes: { paper: classes.option }
                                  }}
                                  inputProps={{
                                    name: 'areaLevel1Id',
                                    id: 'outlined-age-native-simple'
                                  }}>
                                  {provinces.map((e, i) => (
                                    <MenuItem className={classes.menuItem} key={i} value={e.value}>
                                      {e.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <div className={classes.containerInputText}>
                                <Typography
                                  color='error'
                                  variant='caption'
                                  className={
                                    !errors.areaLevel1Id ? classes.inputText : classes.errorMessage
                                  }>
                                  {!errors.areaLevel1Id
                                    ? 'Seleccione su Provincia'
                                    : errors.areaLevel1Id}
                                </Typography>
                              </div>
                            </div>
                          </div>
                          <div className={classes.row}>
                            <div className={classes.formGroup}>
                              <FormControl variant='outlined' className={classes.formControl}>
                                <InputLabel
                                  htmlFor='outlined-age-native-simple'
                                  className={classes.labelSelect}>
                                  Seleccione su Ciudad
                                </InputLabel>
                                <Select
                                  autoWidth={true}
                                  className={classes.selectItem}
                                  colorOptions='secondary'
                                  value={values.areaLevel2Id}
                                  name='areaLevel2Id'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label='Seleccione su Ciudad'
                                  MenuProps={{
                                    classes: { paper: classes.option }
                                  }}
                                  inputProps={{
                                    name: 'areaLevel2Id',
                                    id: 'outlined-age-native-simple'
                                  }}>
                                  <MenuItem aria-label={empty.label} value={empty.value} label='' />
                                  {cities.map((e, i) => (
                                    <MenuItem className={classes.menuItem} key={i} value={e.id}>
                                      {e.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <div className={classes.containerInputText}>
                                {errors.areaLevel2Id && (
                                  <Typography
                                    color='error'
                                    variant='caption'
                                    className={classes.errorMessage}>
                                    {errors.areaLevel2Id}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className={classes.rolesContainer}>
                            <Typography
                              variant='caption'
                              className={clsx(classes.labelStep, classes.selectRolesContainer)}>
                              Elegí el rol que tendrá tu cuenta, podés elegir más de uno:
                              <CustomizedTooltip
                                title='Esto nos permite brindarte una experiencia personalizada habilitando las funcionalidades correspondientes.'
                                position='right-end'
                                arrowClassName={classes.arrowTooltip}
                                className={classes.tooltip}
                                buttonClassName={classes.closeButton}
                                disabledClickAway
                                isTopMost>
                                {({ handleTooltip }) => (
                                  <InfoIcon
                                    className={classes.alertInfoIcon}
                                    color='primary'
                                    onClick={handleTooltip}
                                  />
                                )}
                              </CustomizedTooltip>
                            </Typography>
                            <div className={classes.formGroup}>
                              <FormControlLabel
                                value={true}
                                onChange={handleChange}
                                name='isAthlete'
                                className={classes.checkboxRoles}
                                control={
                                  <Checkbox
                                    checked={values.isAthlete}
                                    name='isAthlete'
                                    color='primary'
                                  />
                                }
                                label={
                                  <Typography variant='body1' className={classes.rolesParagraph}>
                                    Rol deportista, elegí esta opción si practicas deportes y estás
                                    interesado en competir.
                                  </Typography>
                                }
                              />
                            </div>
                            <div className={classes.formGroup}>
                              <FormControlLabel
                                value={true}
                                onChange={handleChange}
                                name='isOrganizer'
                                className={classes.checkboxRoles}
                                control={
                                  <Checkbox
                                    checked={values.isOrganizer}
                                    name='isOrganizer'
                                    color='primary'
                                  />
                                }
                                label={
                                  <Typography variant='body1' className={classes.rolesParagraph}>
                                    Rol organizador, elige esta opción si organizas o te interesa
                                    organizar eventos deportivos.
                                  </Typography>
                                }
                              />
                            </div>
                            <div className={classes.containerInputText}>
                              <Typography
                                color='error'
                                variant='caption'
                                className={classes.errorMessage}>
                                {errors.hasSelectedRole}
                              </Typography>
                            </div>
                          </div>
                          <div className={classes.infoContainer}>
                            <div className={classes.formGroup}>
                              <FormControlLabel
                                value={true}
                                onChange={handleChange}
                                name='acceptTerms'
                                className={classes.checkbox}
                                control={
                                  <Checkbox
                                    required
                                    checked={values.acceptTerms}
                                    name='acceptTerms'
                                    color='primary'
                                  />
                                }
                                label={
                                  <Typography variant='body1' className={classes.tosParagraph}>
                                    Acepto las&nbsp;
                                    <Link to='#' onClick={handleClickOpenTerms}>
                                      Condiciones de uso
                                    </Link>
                                    &nbsp;y la&nbsp;
                                    <Link to='#' onClick={handleClickOpenPolicy}>
                                      Política de privacidad.
                                    </Link>
                                  </Typography>
                                }
                              />
                            </div>
                          </div>

                          {signUpError && (
                            <Typography variant='caption' color='error'>
                              {signUpError.message}
                            </Typography>
                          )}
                          <div className={classes.buttonContainer}>
                            {!isMobile && !signUpData ? (
                              <div className={classes.linkContainer}>
                                <Link
                                  to='#'
                                  onClick={() =>
                                    handleNextPage(setValues, values.positionPage - 1)
                                  }>
                                  Paso anterior
                                </Link>
                              </div>
                            ) : null}
                            <div>
                              <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                                disabled={!isValid || isSubmitting}
                                endIcon={isSubmitting && <CircularProgress size={16} />}>
                                {!signUpData ? 'Crear cuenta' : 'Registrate con Google'}
                              </Button>
                            </div>
                          </div>
                          {/* <Typography className={classes.bottomParagraph}>
     ¿Ya eres usuario de Sportmetric?&nbsp;
     <Link to={ROUTES.SIGN_IN}>Iniciar Sesión</Link>
     </Typography> */}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={classes.form}>
                      <div className={classes.initialTextSignUpGoogle}>
                        <Typography variant='caption' className={classes.label}>
                          Te pedimos estos datos para crear tu cuenta y poder ofrecerte una
                          experiencia personalizada, así como ahorrar tiempo en la inscripción a tus
                          eventos preferidos.
                        </Typography>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.formGroup}>
                          <TextField
                            name='username'
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={touched.username && Boolean(errors.username)}
                            required
                            placeholder='¿Cómo querés que te llamemos?'
                            variant='outlined'
                            size='small'
                            margin='normal'
                            className={classes.input}
                            label='Nombre de usuario'
                          />
                          <div className={classes.containerInputText}>
                            <Typography
                              color='error'
                              variant='caption'
                              className={
                                !errors.username ? classes.inputText : classes.errorMessage
                              }>
                              {!errors.username
                                ? 'Nombre corto que te identifique'
                                : errors.username}
                            </Typography>
                          </div>
                        </div>
                        <div className={classes.formGroup}>
                          <KeyboardDatePicker
                            format='DD/MM/YYYY'
                            variant='inline'
                            autoOk
                            label='Fecha de nacimiento'
                            name='birthdate'
                            margin='normal'
                            className={classes.inputDatePicker}
                            id='date-picker-inline'
                            inputVariant='outlined'
                            style={{ width: '100%' }}
                            value={values.birthdate}
                            onBlur={handleBlur}
                            onChange={onDateChanged(setFieldValue)}
                            KeyboardButtonProps={{
                              'aria-label': 'seleccione fecha'
                            }}
                            placeholder='ddmmaaaa'
                            error={touched.birthdate && Boolean(errors.birthdate)}
                            helperText={errors.birthdate}
                          />
                        </div>
                      </div>

                      <div className={classes.row}>
                        <div className={classes.formGroup}>
                          <TextField
                            name='idNumber'
                            label='Número de DNI'
                            value={values.idNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.idNumber && Boolean(errors.idNumber)}
                            helperText={errors.idNumber}
                            fullWidth
                            required
                            variant='outlined'
                            size='small'
                            margin='normal'
                            className={classes.input}
                          />
                        </div>
                        <div className={classes.formGroup}>
                          <FormControl variant='outlined' className={classes.formControl}>
                            <InputLabel
                              htmlFor='country-native-simple'
                              className={classes.labelSelect}>
                              País
                            </InputLabel>
                            <Select
                              className={classes.selectItem}
                              value={values.countryId}
                              name='countryId'
                              onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
                              onBlur={handleBlur}
                              label='País'>
                              {countries.map((e, i) => (
                                <MenuItem key={i} value={e.value}>
                                  {e.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div className={classes.containerInputText}>
                            <Typography
                              color='error'
                              variant='caption'
                              className={
                                !errors.countryId ? classes.inputText : classes.errorMessage
                              }>
                              {!errors.countryId ? 'Seleccione País' : errors.countryId}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.formGroup}>
                          <FormControl variant='outlined' className={classes.formControl}>
                            <InputLabel
                              htmlFor='outlined-age-native-simple'
                              className={classes.labelSelect}>
                              Seleccione su Provincia
                            </InputLabel>
                            <Select
                              autoWidth={true}
                              className={classes.selectItem}
                              value={values.areaLevel1Id}
                              name='areaLevel1Id'
                              onChange={(e) => onProvinceChange(setFieldValue)(e.target.value)}
                              onBlur={handleBlur}
                              label='Seleccione su Provincia'
                              MenuProps={{
                                classes: { paper: classes.option }
                              }}
                              inputProps={{
                                name: 'areaLevel1Id',
                                id: 'outlined-age-native-simple'
                              }}>
                              {provinces.map((e, i) => (
                                <MenuItem className={classes.menuItem} key={i} value={e.value}>
                                  {e.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div className={classes.containerInputText}>
                            <Typography
                              color='error'
                              variant='caption'
                              className={
                                !errors.areaLevel1Id ? classes.inputText : classes.errorMessage
                              }>
                              {!errors.areaLevel1Id
                                ? 'Seleccione su Provincia'
                                : errors.areaLevel1Id}
                            </Typography>
                          </div>
                        </div>

                        <div className={classes.formGroup}>
                          <FormControl variant='outlined' className={classes.formControl}>
                            <InputLabel
                              htmlFor='outlined-age-native-simple'
                              className={classes.labelSelect}>
                              Seleccione su Ciudad
                            </InputLabel>
                            <Select
                              autoWidth={true}
                              className={classes.selectItem}
                              colorOptions='secondary'
                              value={values.areaLevel2Id}
                              name='areaLevel2Id'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label='Seleccione su Ciudad'
                              MenuProps={{
                                classes: { paper: classes.option }
                              }}
                              inputProps={{
                                name: 'areaLevel2Id',
                                id: 'outlined-age-native-simple'
                              }}>
                              <MenuItem aria-label={empty.label} value={empty.value} label='' />
                              {cities.map((e, i) => (
                                <MenuItem className={classes.menuItem} key={i} value={e.id}>
                                  {e.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div className={classes.containerInputText}>
                            {errors.areaLevel2Id && (
                              <Typography
                                color='error'
                                variant='caption'
                                className={classes.errorMessage}>
                                {errors.areaLevel2Id}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.rolesContainer}>
                        <Typography
                          variant='caption'
                          className={clsx(classes.labelStep, classes.selectRolesContainer)}>
                          Elegí el rol que tendrá tu cuenta, podés elegir más de uno:
                          <CustomizedTooltip
                            title='Esto nos permite brindarte una experiencia personalizada habilitando las funcionalidades correspondientes.'
                            position='right-end'
                            arrowClassName={classes.arrowTooltip}
                            className={classes.tooltip}
                            buttonClassName={classes.closeButton}
                            disabledClickAway
                            isTopMost>
                            {({ handleTooltip }) => (
                              <InfoIcon
                                className={classes.alertInfoIcon}
                                color='primary'
                                onClick={handleTooltip}
                              />
                            )}
                          </CustomizedTooltip>
                        </Typography>
                        <div className={classes.formGroup}>
                          <FormControlLabel
                            value={true}
                            onChange={handleChange}
                            name='isAthlete'
                            className={classes.checkboxRoles}
                            control={
                              <Checkbox
                                checked={values.isAthlete}
                                name='isAthlete'
                                color='primary'
                              />
                            }
                            label={
                              <Typography variant='body1' className={classes.rolesParagraph}>
                                Rol deportista, elegí esta opción si practicas deportes y estás
                                interesado en competir.
                              </Typography>
                            }
                          />
                        </div>
                        <div className={classes.formGroup}>
                          <FormControlLabel
                            value={true}
                            onChange={handleChange}
                            name='isOrganizer'
                            className={classes.checkboxRoles}
                            control={
                              <Checkbox
                                checked={values.isOrganizer}
                                name='isOrganizer'
                                color='primary'
                              />
                            }
                            label={
                              <Typography variant='body1' className={classes.rolesParagraph}>
                                Rol organizador, elige esta opción si organizas o te interesa
                                organizar eventos deportivos.
                              </Typography>
                            }
                          />
                        </div>
                        <div className={classes.containerInputText}>
                          <Typography
                            color='error'
                            variant='caption'
                            className={classes.errorMessage}>
                            {errors.hasSelectedRole}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.infoContainer}>
                        <div className={classes.formGroup}>
                          <FormControlLabel
                            value={true}
                            onChange={handleChange}
                            name='acceptTerms'
                            className={classes.checkbox}
                            control={
                              <Checkbox
                                required
                                checked={values.acceptTerms}
                                name='acceptTerms'
                                color='primary'
                              />
                            }
                            label={
                              <Typography variant='body1' className={classes.tosParagraph}>
                                Acepto las&nbsp;
                                <Link to='#' onClick={handleClickOpenTerms}>
                                  Condiciones de uso
                                </Link>
                                &nbsp;y la&nbsp;
                                <Link to='#' onClick={handleClickOpenPolicy}>
                                  Política de privacidad.
                                </Link>
                              </Typography>
                            }
                          />
                        </div>
                      </div>
                      <div className={classes.buttonContainerGoogleSignUp}>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          disabled={!isValid || isSubmitting}
                          endIcon={isSubmitting && <CircularProgress size={16} />}>
                          Crear cuenta
                        </Button>
                      </div>
                    </div>
                  )}
                  <TermsAndConditionsDialog
                    handleClose={handleCloseTerms}
                    open={openTerms}
                    content={TermsAndConditionsContent}
                    title='Condiciones de uso'
                  />
                  <TermsAndConditionsDialog
                    handleClose={handleClosePolicy}
                    open={openPolicy}
                    content={PrivacyPolicyContent}
                    title='Política de privacidad'
                  />
                </div>
                {values.positionPage !== FORM_POSITION.SECOND_PAGE && !signUpData && !state ? (
                  <div className={classes.optionsContainer}>
                    <div className={classes.signInOptionsContainer}>
                      <Typography color='primary' className={classes.signUpTitle}>
                        ¿Ya eres usuario de Sportmetric?
                      </Typography>
                      <Button
                        color='primary'
                        className={classes.signUpButton}
                        variant='outlined'
                        onClick={() => handleOpenSignInDialog()}>
                        Iniciar Sesion
                      </Button>
                    </div>
                    {!isMobile && (
                      <div className={classes.signInOptionsContainer}>
                        <Typography color='primary' className={classes.signUpTitle}>
                          ¿Quieres registrarte con Google?
                        </Typography>
                        <div className={classes.googleLoginContainer}>
                          <GoogleLogin onSuccess={handleGoogleSignUp} onError={console.error} />
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default SignUpForm
