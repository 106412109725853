import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SuspensionUpdateEventSection.style'

const SuspensionUpdateEventSection = () => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)

  return (
    <SectionBlock title='Actualización del evento'>
      <Typography variant='h6' className={classes.description}>
        {event.updateDescription}
      </Typography>
    </SectionBlock>
  )
}

export default SuspensionUpdateEventSection
