import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircularProgress, Typography } from '@material-ui/core'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, MainLayout, NavBar, PageContainer } from 'shared'
import { load, loadReset } from 'state/modules/events'
import { EVENT_STATES } from 'utils/constants'

import { NeedHelpForm } from './components'
import { useStyles } from './NeedHelpPage.style'

const EVENT_STATES_OPTIONS = {
  [EVENT_STATES.ACTIVE]: 'Activo',
  [EVENT_STATES.SUSPENDED]: 'Suspendido'
}

const NeedHelpPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event, pending, error } = useSelector((state) => state.events)
  const { slugUrlOrId } = useParams()

  const activeLoading = (!pending && !event && !error) || pending

  useEffect(() => {
    dispatch(load(slugUrlOrId))

    return () => dispatch(loadReset())
  }, [dispatch, slugUrlOrId])

  const renderLoading = () => <CircularProgress size={16} />

  const renderNeedHelpPage = () => {
    const { name, state } = event

    return (
      <div className={classes.container}>
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <Typography color='primary' variant='h5' className={classes.title}>
              Necesito ayuda - {name} - {EVENT_STATES_OPTIONS[state]}
            </Typography>
          </div>

          <NeedHelpForm />
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />

      <PageContainer altMode>
        {activeLoading ? renderLoading() : renderNeedHelpPage()}
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default NeedHelpPage
