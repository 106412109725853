import React from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import clsx from 'clsx'
import { CustomizedTooltip } from 'shared'

import { useStyles } from './EventDrawer.style'

const EventDrawer = ({
  children,
  open,
  setOpen,
  activeDrawer,
  drawerClassName,
  className,
  position = 'right'
}) => {
  const classes = useStyles()

  const handleOpen = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  return activeDrawer ? (
    <div className={clsx(classes.container, !open && classes.containerClosed, position)}>
      <div className={clsx(classes.lineLeft, position)}></div>

      <CustomizedTooltip
        title={open ? 'Contraer' : 'Expandir'}
        position='bottom'
        className={classes.tooltip}
        isTopMost
        arrowClassName={classes.tooltipArrow}>
        {({ handleTooltipClose, handleTootipOpen }) => (
          <IconButton
            className={clsx(classes.openButton, position)}
            aria-label='open drawer'
            onClick={handleOpen}
            onMouseOut={handleTooltipClose}
            onTouchEnd={handleTooltipClose}
            onTouchMove={handleTootipOpen}
            onMouseOver={handleTootipOpen}>
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        )}
      </CustomizedTooltip>

      <Drawer
        variant='persistent'
        anchor={position}
        open={open}
        className={clsx(
          classes.drawer,
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          },
          position
        )}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            },
            position
          )
        }}>
        <div className={drawerClassName}>{children}</div>
      </Drawer>
    </div>
  ) : (
    <div className={className}>{children}</div>
  )
}

export default EventDrawer
