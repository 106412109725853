import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',

      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  },
  container: {
    minHeight: '69.2vh'
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#ffff',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      minHeight: theme.spacing(15),
      padding: theme.spacing(0, 10)
    }
  },
  eventName: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    fontSize: '1rem',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  paymentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  paymentInfo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
      padding: theme.spacing()
    }
  },
  paymentMethodContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    gap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      flexDirection: 'row',
      padding: 0,
      alignItems: 'center'
    }
  },
  paymentMethodTitle: {
    fontWeight: 600
  },
  paymentMethodEnabled: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    '& span': {
      color: '#000000',
      fontWeight: 600
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(5)
    }
  },
  bodyContainer: {
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
  },
  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      padding: theme.spacing(0, 4),
      margin: '0 auto'
    }
  },
  icon: {
    position: 'absolute',
    right: 15,
    top: 7
  },
  optionButton: {
    width: 200,
    position: 'relative',
    borderRadius: 10,

    [theme.breakpoints.up('md')]: {
      width: 160,
      borderRadius: 0
    }
  },
  buttonsSection: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  messageContainer: {
    backgroundColor: '#ffff',
    height: '69.1vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 20px'
  },
  searchContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    },
    '& form': {
      flexGrow: 1
    }
  },
  searchSection: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  field: {
    '& > div': {
      paddingRight: 0
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500
    },

    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    }
  },
  mobileInfo: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0, 2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 10,

    '& p': {
      fontSize: '.875rem'
    }
  },
  searchButton: {
    height: 40,

    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },

      '& svg': {
        color: theme.palette.secondary.main,
        width: 20,
        height: 36
      }
    }
  },
  statusSection: {
    display: 'flex',
    gap: theme.spacing(4)
  },

  listContainer: {
    minHeight: 300,
    marginBottom: theme.spacing(2),
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  table: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    borderCollapse: 'collapse',
    borderSpacing: 0,

    '& th': {
      textAlign: 'left',
      fontSize: '1rem',
      padding: theme.spacing(0.75),
      color: theme.palette.primary.main
    },
    '& td': {
      textAlign: 'left',
      padding: theme.spacing(0.75)
    },
    '& table': {
      width: '100%'
    }
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    padding: theme.spacing(2),
    '& svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },
  statusCol: {
    '& > div': {
      marginLeft: theme.spacing(2)
    }
  },
  paginationContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    height: 150,
    flexDirection: 'column',
    alignItems: 'center'
  },
  stateContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: theme.palette.link.main,
      marginLeft: theme.spacing(),
      fontWeight: 600,
      cursor: 'pointer',

      '&.filter': {
        fontWeight: 600,
        color: theme.palette.primary.main,
        textDecoration: 'underline'
      }
    },

    '&.center': {
      alignSelf: 'center'
    }
  },
  tooltipArrow: {
    color: theme.palette.tooltip.main
  },
  tooltip: {
    width: 300,
    backgroundColor: theme.palette.tooltip.main,

    [theme.breakpoints.up(750)]: {
      width: '100%',
      maxWidth: 600
    }
  },
  select: {
    width: '80px',
    '& svg': {
      color: theme.palette.secondary.main
    }
  },
  btnSave: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionButton: {
    minWidth: 40,
    padding: '6px'
  },
  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '68.5vh',
    justifyContent: 'center',
    alignItems: 'center',
    ' & h5': {
      fontWeight: 600
    }
  },
  colContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontWeight: 600
    }
  },
  headerCol: {
    cursor: 'pointer'
  },
  option: {
    textDecoration: 'underline'
  },
  filterLoading: {
    marginLeft: theme.spacing()
  },
  disabledIcon: {
    color: theme.utils.rgba(theme.palette.disabled.main, 0.7)
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    paddingTop: theme.spacing(2),
    gap: theme.spacing(2)
  },
  stateBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  inscriptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  containerLoader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  viewMoreButton: {
    width: 200
  }
}))
