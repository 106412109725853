import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import {
  BankAccountCard,
  CustomizedTooltip,
  NumericField,
  PaymentLocationCard,
  StyledSwitch
} from 'shared'
import {
  loadBanks,
  loadEventBankAccounts,
  loadPaymentLocations,
  loadTicketTypeFees,
  setPaymentParams
} from 'state/modules/events'
import { FEE_OFFLINE_NAME, FEE_TYPES } from 'utils/constants'

import { CreateBankAccountDialog } from '../CreateBankAccountDialog'
import { CreatePaymentLocationDialog } from '../CreatePaymentLocationDialog'
import { OfflinePaymentConfigDialog } from '../OfflinePaymentConfigDialog'
import { PaymentBlock } from '../PaymentBlock'
import { PaymentNotifyDialog } from '../PaymentNotifyDialog'

import { useStyles } from './OfflinePaymentBlock.style'

const DIALOG_STATES = {
  PAYMENT_INFO_DIALOG: 'paymentInfoDialog'
}

const paymentNotifyInitalState = {
  open: false,
  state: DIALOG_STATES.PAYMENT_INFO_DIALOG,
  onConfirm: () => {}
}

const OfflinePaymentBlock = ({ offlinePaymentEnabled, paymentStates }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
    errors,
    touched,
    setTouched,
    setFieldValue
  } = useFormikContext()
  const {
    eventBankAccounts,
    event,
    paymentLocations,
    paymentLocationsPending,
    paymentParams,
    ticketTypeFees
  } = useSelector((state) => state.events)

  const [openOfflinePaymentConfig, setOpenOfflinePaymentConfig] = useState(false)
  const [openOfflineEditPaymentConfig, setOpenOfflineEditPaymentConfig] = useState(false)
  const [openCreatePaymentLocation, setOpenCreatePaymentLocation] = useState(false)
  const [openCreateBankAccount, setOpenCreateBankAccount] = useState(false)
  const [paymentNotifyProps, setPaymentNotifyProps] = useState(paymentNotifyInitalState)

  const BANK_ACCOUNTS_MAX = 2
  const PAYMENT_LOCATIONS_MAX = 10

  const sportmetricOfflineFee =
    Array.isArray(ticketTypeFees) && !!ticketTypeFees.length
      ? ticketTypeFees.find(
          (x) => x.name === FEE_OFFLINE_NAME && x.feeType === FEE_TYPES.Sportmetric && !x.expiredAt
        ).feePercentage
      : 0

  useEffect(() => {
    dispatch(loadEventBankAccounts(event.id))
    dispatch(loadPaymentLocations(event.id))
    dispatch(loadBanks())
    dispatch(loadTicketTypeFees())
  }, [event])

  useEffect(() => {
    if (paymentLocations.length)
      dispatch(
        setPaymentParams({ ...paymentParams, paymentCashSelected: !!paymentLocations.length })
      )
  }, [paymentLocations])

  useEffect(() => {
    if (eventBankAccounts.length)
      dispatch(
        setPaymentParams({ ...paymentParams, paymentBankSelected: !!eventBankAccounts.length })
      )
  }, [eventBankAccounts])

  useEffect(() => {
    if (values.ticketTypes.some((x) => x.paymentUrl) && values.isFree === false) {
      dispatch(setPaymentParams({ ...paymentParams, paymentUrlSelected: true }))
    }
  }, [values.ticketTypes, values.isFree])

  const handleChangePaymentUrl = (e) => {
    const paymentUrlEnabled = e.target.checked
    const hasTicketTypesPaymentUrl = values.ticketTypes.find(
      (x) =>
        (x.mercadoPagoEnabled === false || paymentParams.mercadoPagoEnabled === false) &&
        (x.paymentLocationsEnabled === false || !paymentLocations.length) &&
        (x.bankAccountsEnabled === false || !eventBankAccounts.length) &&
        !!x.paymentUrl
    )

    if (hasTicketTypesPaymentUrl) {
      setPaymentNotifyProps({
        open: true,
        state: DIALOG_STATES.PAYMENT_INFO_DIALOG,
        pending: true
      })
      return
    }

    dispatch(setPaymentParams({ ...paymentParams, [e.target.name]: paymentUrlEnabled }))

    setValues((values) => ({
      ...values,
      paymentUrlEnabled,
      ticketTypes: !paymentUrlEnabled
        ? values.ticketTypes.map((x) => ({ ...x, paymentUrl: '' }))
        : values.ticketTypes
    }))
  }

  const handleChangeOffLinePaymentSelected = (setOpenTooltip) => (e) => {
    dispatch(setPaymentParams({ ...paymentParams, [e.target.name]: e.target.checked }))
    setOpenTooltip(e.target.checked)
  }

  const handleOpenDialog = (setValue) => () => setValue(true)

  const handleOnCloseDialog = (setValue) => () => setValue(false)

  const handleChangePaymentConfirmation = (e) => {
    const paymentConfirmationPeriodEnabled = e.target.checked

    setValues((values) => ({
      ...values,
      paymentConfirmationPeriodEnabled,
      paymentConfirmationPeriod: !paymentConfirmationPeriodEnabled
        ? ''
        : values.paymentConfirmationPeriod
    }))

    if (!paymentConfirmationPeriodEnabled)
      setTouched({ ...touched, paymentConfirmationPeriod: false })
  }

  const contentTooltip = values.paymentConfirmationPeriodEnabled
    ? 'Al encender el Switch deberás definir el tiempo que otorgarás al usuario para concretar el pago (mínimo 2hs).'
    : 'Con el Switch apagado el usuario podrá concretar el pago hasta el día definido por el organizador.'

  return (
    <PaymentBlock>
      <div className={classes.payment}>
        <Typography className={classes.paymentTitle}>
          <strong>Pago Offline</strong> deberás verificar los pagos de a uno y cambiar el estado de
          inscripción manualmente en el listado de inscriptos del evento{' '}
          <strong>
            (Recordá que el costo de la tasa de servicio por esta modalidad es un{' '}
            {sportmetricOfflineFee}% + IVA).
          </strong>
        </Typography>
        <div className={classes.periodContainer}>
          <div className={classes.switchContainer}>
            <Typography className={classes.labelSwitch}>
              Definir plazo de pago para pre-inscripción.
            </Typography>
            <StyledSwitch
              checked={values.paymentConfirmationPeriodEnabled}
              onChange={handleChangePaymentConfirmation}
              disabled={!offlinePaymentEnabled}
            />
            <CustomizedTooltip
              position='right-end'
              title={contentTooltip}
              className={classes.paymentConfirmationPeriodTooltip}>
              {({ handleTooltip }) => <InfoIcon color='primary' onClick={handleTooltip} />}
            </CustomizedTooltip>
          </div>

          {values.paymentConfirmationPeriodEnabled && (
            <div className={clsx(classes.paymentConfirmationPeriodcontainer)}>
              <Typography color='primary' variant='caption' className={classes.timeLabel}>
                *Tiempo para realizar pago
              </Typography>
              <div className={classes.timeContainer}>
                <NumericField
                  variant='outlined'
                  color='primary'
                  size='small'
                  name='paymentConfirmationPeriod'
                  onBlur={handleBlur}
                  placeholder='Cantidad de hs'
                  className={classes.textField}
                  autoFocus={
                    values.paymentConfirmationPeriodEnabled && !values.paymentConfirmationPeriod
                  }
                  disabled={!offlinePaymentEnabled}
                  error={
                    touched.paymentConfirmationPeriod && Boolean(errors.paymentConfirmationPeriod)
                  }
                  helperText={
                    touched.paymentConfirmationPeriod ? errors.paymentConfirmationPeriod : ''
                  }
                  value={values.paymentConfirmationPeriod}
                  onChange={handleChange}
                />
                <Typography color='primary' variant='caption' className={classes.timeLabel}>
                  Mín. 2hs - máx 72hs
                </Typography>
              </div>
            </div>
          )}
        </div>
        <Typography color='primary' variant='h6' className={classes.periodHelperText}>
          Al activar el switch debes definir el tiempo otorgado para que el deportista suba el
          comprobante de pago, si el deportista no realiza la acción en el tiempo indicado la
          preinscripción caerá automaticamente, liberando el cupo.
        </Typography>
        <div className={classes.optionsContainer}>
          <div className={classes.option}>
            {eventBankAccounts.length > 0 && offlinePaymentEnabled ? (
              <div className={classes.formControl}>
                <Typography className={classes.label}>Pago por transferencia.</Typography>
              </div>
            ) : (
              <CustomizedTooltip
                title='Deberás definir la cuenta bancaria.'
                arrow
                position={!isDesktop ? 'top-start' : 'right'}
                arrowClassName={classes.arrowTooltip}
                className={classes.tooltip}>
                {({ setOpenTooltip }) => (
                  <FormControlLabel
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    control={
                      <Checkbox
                        checked={paymentParams.paymentBankSelected}
                        color='primary'
                        name='paymentBankSelected'
                        onChange={handleChangeOffLinePaymentSelected(setOpenTooltip)}
                        disabled={!offlinePaymentEnabled}
                        className={clsx(
                          classes.checkbox,
                          !offlinePaymentEnabled && paymentStates.PAYMENT_DISABLED
                        )}
                      />
                    }
                    label='Transferencia Bancaria.'
                  />
                )}
              </CustomizedTooltip>
            )}
          </div>
        </div>

        {eventBankAccounts.length > 0 && offlinePaymentEnabled && (
          <div className={classes.userContainer}>
            {eventBankAccounts
              .filter((e) => e.isActive)
              .map((e, index) => (
                <BankAccountCard
                  bankAccount={e}
                  key={index}
                  title={`Cuenta Bancaria ${index + 1}`}
                  {...{ values, setFieldValue }}
                />
              ))}

            {eventBankAccounts.length < BANK_ACCOUNTS_MAX && (
              <Typography
                onClick={() => setOpenCreateBankAccount(true)}
                color='primary'
                className={classes.offLineTextLink}>
                + Añadir cuenta
              </Typography>
            )}
          </div>
        )}

        <div className={classes.optionsContainer}>
          <div className={classes.option}>
            {paymentLocations.length > 0 && offlinePaymentEnabled ? (
              <div className={classes.formControl}>
                <Typography className={classes.label}>
                  Pago en efectivo. &nbsp;
                  {paymentLocationsPending && <CircularProgress size={15} color='primary' />}
                </Typography>
              </div>
            ) : (
              <CustomizedTooltip
                title='Deberás definir el/los puntos físicos de cobranzas.'
                position={!isDesktop ? 'top-start' : 'right'}
                arrowClassName={classes.arrowTooltip}
                className={classes.tooltip}>
                {({ setOpenTooltip }) => (
                  <FormControlLabel
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    control={
                      <Checkbox
                        checked={paymentParams.paymentCashSelected}
                        color='primary'
                        name='paymentCashSelected'
                        onChange={handleChangeOffLinePaymentSelected(setOpenTooltip)}
                        disabled={!offlinePaymentEnabled}
                        className={clsx(
                          classes.checkbox,
                          !offlinePaymentEnabled && paymentStates.PAYMENT_DISABLED
                        )}
                      />
                    }
                    label='Pago en efectivo.'
                  />
                )}
              </CustomizedTooltip>
            )}
          </div>
        </div>

        {paymentLocations.length > 0 && offlinePaymentEnabled && (
          <div className={classes.userContainer}>
            {paymentLocations
              .filter((e) => e.isActive)
              .map((el, index) => (
                <PaymentLocationCard
                  title={`Lugar de pago ${index + 1}`}
                  key={index}
                  paymentLocation={el}
                  {...{ values, setFieldValue }}
                />
              ))}

            {paymentLocations.length < PAYMENT_LOCATIONS_MAX && (
              <Typography
                onClick={() => setOpenCreatePaymentLocation(true)}
                color='primary'
                className={classes.offLineTextLink}>
                + Añadir punto físico
              </Typography>
            )}
          </div>
        )}

        <div className={classes.optionsContainer}>
          <div className={clsx(classes.option, classes.urlPaymentOption)}>
            <FormControlLabel
              className={classes.formControl}
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  checked={paymentParams.paymentUrlSelected}
                  color='primary'
                  name='paymentUrlSelected'
                  onChange={handleChangePaymentUrl}
                  disabled={!offlinePaymentEnabled}
                  className={clsx(
                    classes.checkbox,
                    !offlinePaymentEnabled && paymentStates.PAYMENT_DISABLED
                  )}
                />
              }
              label='Link de pago'
            />
            <Typography variant='h6'>
              (Deberás detallar el link en la creación del ticket).
            </Typography>
          </div>
        </div>
      </div>

      <OfflinePaymentConfigDialog
        title='CONFIGURAR PAGO OFFLINE'
        open={openOfflinePaymentConfig}
        hiddenBankAccounts={!paymentParams.paymentBankSelected}
        hiddenPaymentLocations={!paymentParams.paymentCashSelected}
        onClose={handleOnCloseDialog(setOpenOfflinePaymentConfig)}
        onOpen={handleOpenDialog(setOpenOfflinePaymentConfig)}
      />

      <OfflinePaymentConfigDialog
        title='EDITAR PAGO OFFLINE'
        open={openOfflineEditPaymentConfig}
        hiddenBankAccounts={!paymentParams.paymentBankSelected}
        hiddenPaymentLocations={!paymentParams.paymentCashSelected}
        onClose={handleOnCloseDialog(setOpenOfflineEditPaymentConfig)}
        onOpen={handleOpenDialog(setOpenOfflineEditPaymentConfig)}
      />

      <CreatePaymentLocationDialog
        open={openCreatePaymentLocation}
        onClose={() => setOpenCreatePaymentLocation(false)}
        paymentLocationCount={paymentLocations.length}
      />

      <CreateBankAccountDialog
        open={openCreateBankAccount}
        onClose={() => setOpenCreateBankAccount(false)}
        bankAccountCount={eventBankAccounts.length}
      />

      {(eventBankAccounts.length > 0 || paymentLocations.length > 0) && offlinePaymentEnabled ? (
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={handleOpenDialog(setOpenOfflineEditPaymentConfig)}
          disabled={!offlinePaymentEnabled}>
          Editar pago Offline
        </Button>
      ) : (
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={handleOpenDialog(setOpenOfflinePaymentConfig)}
          disabled={
            (!paymentParams.paymentBankSelected && !paymentParams.paymentCashSelected) ||
            !offlinePaymentEnabled
          }>
          Configurar pago Offline
        </Button>
      )}
      <PaymentNotifyDialog
        {...paymentNotifyProps}
        onClose={() => setPaymentNotifyProps(paymentNotifyInitalState)}
      />
    </PaymentBlock>
  )
}

export default OfflinePaymentBlock
