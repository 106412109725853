import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 660,
      maxWidth: 660
    }
  },
  radioLabel: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    '&.disabled': {
      color: theme.palette.disabled.main
    }
  },
  label: {
    fontSize: '1rem',
    color: `${theme.palette.disabled.main} !important`,
    lineHeight: '20px',
    fontWeight: 500,
    '&.selected': {
      color: `${theme.palette.primary.main} !important`
    }
  },
  formControlLabel: {
    width: 150,
    margin: '0 auto'
  },
  radio: {
    '& svg': {
      color: theme.palette.primary.main
    },
    '&.disabled': {
      '& svg': {
        color: theme.palette.disabled.light
      }
    }
  },
  button: {
    width: 247,
    height: 58,
    alignSelf: 'center',
    marginTop: theme.spacing(7),
    borderRadius: 10,
    '& span': {
      fontWeight: 500,
      fontSize: '1.125rem'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(1, 0),
    fontSize: '.875rem',
    width: 'auto',
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 415
    }
  },
  tooltipButton: {
    top: '5px !important',
    right: '5px !important'
  },
  arrowTooltip: {
    color: theme.palette.tooltip.main
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600
  }
}))
