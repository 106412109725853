import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './FollowedCardPlaceHolderLoader.style'

const FollowedCardPlaceHolderLoader = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  const title = 'Cargando...'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader
            title={title}
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            className={classes.container}>
            <circle cx='25' cy='50' r='25' />
            <rect x='65' y='45' rx='3' ry='3' className={classes.followed} />
            <rect x='280' y='45' rx='3' ry='3' className={classes.button} />
            <circle cx='25' cy='130' r='25' />
            <rect x='65' y='125' rx='3' ry='3' className={classes.followed} />
            <rect x='280' y='125' rx='3' ry='3' className={classes.button} />
            <circle cx='25' cy='215' r='25' />
            <rect x='65' y='210' rx='3' ry='3' className={classes.followed} />
            <rect x='280' y='210' rx='3' ry='3' className={classes.button} />
            <circle cx='25' cy='300' r='25' />
            <rect x='65' y='295' rx='3' ry='3' className={classes.followed} />
            <rect x='280' y='295' rx='3' ry='3' className={classes.button} />
          </ContentLoader>
        ) : (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            title={title}
            className={classes.container}>
            <circle cx='25' cy='50' r='25' />
            <rect x='65' y='45' rx='3' ry='3' className={classes.followed} />
            <rect x='240' y='45' rx='3' ry='3' className={classes.button} />
            <circle cx='25' cy='130' r='25' />
            <rect x='65' y='125' rx='3' ry='3' className={classes.followed} />
            <rect x='240' y='125' rx='3' ry='3' className={classes.button} />
            <circle cx='25' cy='215' r='25' />
            <rect x='65' y='210' rx='3' ry='3' className={classes.followed} />
            <rect x='240' y='210' rx='3' ry='3' className={classes.button} />
            <circle cx='25' cy='300' r='25' />
            <rect x='65' y='295' rx='3' ry='3' className={classes.followed} />
            <rect x='240' y='295' rx='3' ry='3' className={classes.button} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default FollowedCardPlaceHolderLoader
