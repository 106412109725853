import React, { useState } from 'react'
import { IconButton, TextField, Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import { CustomizedTooltip } from 'shared'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SuspensionUpdateEventSection.style'

const SuspensionUpdateEventSection = () => {
  const classes = useStyles()

  const { values, handleChange, handleBlur, touched, errors } = useFormikContext()

  const [enabledAddUpdateDescription, setEnabledAddUpdateDescription] = useState(
    !!values.updateDescription
  )

  return (
    <SectionBlock
      title='Actualización de evento'
      endAdorment={
        <CustomizedTooltip
          position='right'
          title='Utilizá este espacio para mantener informados a tus inscriptos.'
          disabledClickAway
          className={classes.tooltip}
          buttonClassName={classes.tooltipButton}
          arrowClassName={classes.tooltipArrow}>
          {({ handleTooltip }) => (
            <IconButton color='primary' size='small' onClick={handleTooltip}>
              <InfoIcon />
            </IconButton>
          )}
        </CustomizedTooltip>
      }>
      {enabledAddUpdateDescription && (
        <TextField
          name='updateDescription'
          value={values.updateDescription}
          className={classes.input}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder='Escriba información importante referida al evento.'
          helperText={errors.updateDescription}
          error={touched.updateDescription && Boolean(errors.updateDescription)}
          fullWidth
          autoComplete='off'
          multiline
          rowsMax={7}
          rows={2}
          variant='outlined'
          size='small'
          margin='none'
        />
      )}

      {!enabledAddUpdateDescription && (
        <Typography
          color='primary'
          variant='h6'
          className={classes.addLink}
          onClick={() => setEnabledAddUpdateDescription(true)}>
          +Añadir actualización
        </Typography>
      )}
    </SectionBlock>
  )
}

export default SuspensionUpdateEventSection
