import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 570,
      maxWidth: 570
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  infoText: {
    fontWeight: 600
  },
  acceptButton: {
    width: 210,
    height: 40,
    borderRadius: 10,
    alignSelf: 'center'
  }
}))
