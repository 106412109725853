import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, CircularProgress, TextField, Typography, useMediaQuery } from '@material-ui/core'
import {
  DeleteOutlined as DeleteIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import { EllipseStatus, FileViewerContent, SectionLayoutDialog } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { updateEventInscription } from 'state/modules/events'
import {
  EVENT_INSCRIPTION_STATES,
  PAYMENT_TYPES,
  SALE_ORDER_STATES,
  STRING_EMPTY
} from 'utils/constants'
import { getPriceFormat } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { DeletePaymentConfirmDialog } from '../DeletePaymentConfirmDialog'

import { useStyles } from './ViewFileReceiptDialog.style'

const PAYMENT_TYPE_LABELS = {
  [PAYMENT_TYPES.MercadoPago]: 'MERCADO PAGO',
  [PAYMENT_TYPES.BankAccount]: 'TRANSFERENCIA',
  [PAYMENT_TYPES.PaymentUrl]: 'LINK DE PAGO',
  [PAYMENT_TYPES.PaymentLocation]: 'PAGO EN EFECTIVO'
}

const MIN_PAYMENT_TYPE_LABELS = {
  [PAYMENT_TYPES.MercadoPago]: 'MP',
  [PAYMENT_TYPES.BankAccount]: 'TRANSF.',
  [PAYMENT_TYPES.PaymentUrl]: 'LINK DE PAGO',
  [PAYMENT_TYPES.PaymentLocation]: 'EFECTIVO'
}

const observerOptions = [
  {
    value: EVENT_INSCRIPTION_STATES.PENDING,
    label: <EllipseStatus status='yellow' title='Preinscripto' />
  },
  {
    value: EVENT_INSCRIPTION_STATES.ACCEPTED,
    label: <EllipseStatus status='green' title='Inscripto' />
  },
  {
    value: EVENT_INSCRIPTION_STATES.CANCELLED,
    label: <EllipseStatus status='red' title='Anulado' />
  },
  {
    value: SALE_ORDER_STATES.EXPIRED,
    label: <EllipseStatus status='grey' title='Vencido' />,
    optionHidden: true
  }
]

const validationSchema = Yup.object().shape({
  paymentComment: Yup.string()
    .nullable()
    .max(150, 'El comentario tiene un máximo de 150 caracteres')
})

const ViewFileReceiptDialog = ({
  fileUrl,
  fileExtension,
  disabledViewReceipt,
  reviewed,
  load = () => {},
  inscription,
  onUpdatePayment = () => {},
  onDeletePayment
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [openViewerRecieptDialog, setOpenViewerRecieptDialog] = useState(false)
  const [deletePaymentPending, setDeletePaymentPending] = useState(false)
  const [openDeletePaymentConfirmDialog, setOpenDeletePaymentConfirmDialog] = useState(false)
  const [receiptReviewed, setReceiptReviewed] = useState(reviewed)

  const {
    firstName,
    lastName,
    idNumber,
    distance,
    category,
    saleOrder,
    ticketTypeId,
    paymentType
  } = inscription ?? {}

  useEffect(() => {
    if (openViewerRecieptDialog && !receiptReviewed) handleUpdateStatePayment()
  }, [openViewerRecieptDialog])

  const handleDeletePayment = async () => {
    try {
      setDeletePaymentPending(true)

      await onDeletePayment()

      setOpenDeletePaymentConfirmDialog(false)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setDeletePaymentPending(false)
    }
  }

  const handleUpdateStatePayment = async () => {
    const updateStateSucess = await onUpdatePayment()

    if (updateStateSucess) setReceiptReviewed(true)
  }

  const getStatusInscription = (state) => observerOptions.find((x) => x.value === state)

  const onChangeStatus = (setFieldValue) => async (options) => setFieldValue('state', options.value)

  const ticketItem = saleOrder?.items.find((x) => x.ticketTypeId === ticketTypeId)
  const ticketPrice = ticketItem ? getPriceFormat(ticketItem.unitPrice) : 0
  const discountItem = saleOrder?.items.find((x) => !!x.eventDiscountCode)

  const onSubmit = async ({ state, saleOrder, paymentComment: description, ...restProps }) => {
    const isInscriptionExpired = state === SALE_ORDER_STATES.EXPIRED

    const updateInscription = {
      ...restProps,
      state,

      saleOrder: saleOrder && {
        ...saleOrder,
        state:
          !isInscriptionExpired && saleOrder.state === SALE_ORDER_STATES.EXPIRED
            ? SALE_ORDER_STATES.CANCELLED
            : saleOrder.state,

        comments: [...saleOrder.comments, { description }].filter((x) => !!x.description.trim())
      }
    }

    const updateData = await dispatch(updateEventInscription(updateInscription))

    if (updateData) {
      load(updateData.page, updateData.inscription)
      setOpenViewerRecieptDialog(false)
    }
  }

  const values = {
    ...inscription,
    paymentComment: STRING_EMPTY,
    fileUrl,
    fileExtension
  }

  const paymentLabelSelected = isDesktop
    ? PAYMENT_TYPE_LABELS[paymentType]
    : MIN_PAYMENT_TYPE_LABELS[paymentType]
  return (
    <div title={disabledViewReceipt && 'Esta inscripción no tiene comprobante de pago.'}>
      <Button
        color='primary'
        variant='contained'
        className={clsx(
          classes.button,
          !disabledViewReceipt && receiptReviewed && classes.receiptReviewedButton
        )}
        disabled={disabledViewReceipt}
        onClick={() => setOpenViewerRecieptDialog(true)}>
        <InsertDriveFileOutlinedIcon />
      </Button>

      <SectionLayoutDialog
        open={openViewerRecieptDialog}
        onClose={() => setOpenViewerRecieptDialog(false)}
        fullScreen
        title='COMPROBANTE DE PAGO'
        className={classes.dialog}>
        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize>
          {({
            values,
            handleSubmit,
            setFieldValue,
            handleBlur,
            handleChange,
            errors,
            isValid,
            touched,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className={classes.mainContainer}>
              <div className={classes.headerContainer}>
                <div className={classes.titleContainer}>
                  <Typography color='primary' variant='h6' className={classes.receiptTitle}>
                    {isDesktop ? (
                      `${firstName} ${lastName} - DNI ${idNumber}`
                    ) : (
                      <>
                        {firstName} {lastName}
                        <br />
                        D.N.I {idNumber}
                      </>
                    )}
                  </Typography>

                  {isDesktop ? (
                    <Typography
                      title={`Distancia: ${distance.name} / Categoría: ${category.name}`}
                      className={classes.distanceAndCategory}>
                      Distancia: <strong>{distance.name} / </strong> Categoría:
                      <strong> {category.name}</strong>
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        title={`Distancia: ${distance.name}`}
                        className={classes.distanceAndCategory}>
                        Distancia: <strong>{distance.name} </strong>
                      </Typography>
                      <Typography
                        title={`Categoría: ${category.name}`}
                        className={classes.distanceAndCategory}>
                        Categoría: <strong> {category.name}</strong>
                      </Typography>
                    </>
                  )}
                  <Typography title={`Ticket: ${ticketItem?.ticketTypeName} (${ticketPrice})`}>
                    Ticket:&nbsp;
                    <strong>
                      {ticketItem?.ticketTypeName} ({ticketPrice})
                    </strong>
                  </Typography>

                  <Typography title={`Modalidad de pago: ${paymentLabelSelected}`}>
                    Modalidad de pago: <strong>{paymentLabelSelected}</strong>
                  </Typography>
                  <Typography
                    title={
                      discountItem
                        ? `Código de descuento: ${
                            discountItem.eventDiscountCode.name
                          } (${getPriceFormat(discountItem.unitPrice)})`
                        : '-'
                    }>
                    {isDesktop ? 'Código de descuento' : 'Código Dto'}:&nbsp;
                    <strong>
                      {discountItem
                        ? `${discountItem.eventDiscountCode.name} (${getPriceFormat(
                            discountItem.unitPrice
                          )})`
                        : '-'}
                    </strong>
                  </Typography>
                  <Typography title={`Importe a pagar: ${getPriceFormat(saleOrder?.totalAmount)}`}>
                    Importe a pagar:&nbsp;
                    <strong>{getPriceFormat(saleOrder?.totalAmount)}</strong>
                  </Typography>
                </div>
                <div className={classes.stateContainer}>
                  <Typography color='primary' variant='h5' className={classes.stateTitle}>
                    Estado
                  </Typography>
                  <StyledSelect
                    value={getStatusInscription(values.state)}
                    defaultOptions={observerOptions}
                    colorOptions='primary'
                    filterOption={(option) =>
                      !option.data.optionHidden && values.state !== option.value
                    }
                    isSearchable={false}
                    onChange={onChangeStatus(setFieldValue)}
                    className={classes.select}
                  />
                </div>
              </div>

              <div className={classes.fileContainer}>
                <FileViewerContent
                  fileUrl={fileUrl}
                  fileExtension={fileExtension}
                  cropperClassName={classes.cropper}
                  fileViewerClassName={classes.fileViewContainer}
                />
                {!!fileUrl && (
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.deleteButton}
                    disabled={deletePaymentPending}
                    onClick={() => setOpenDeletePaymentConfirmDialog(true)}>
                    {!deletePaymentPending ? (
                      <DeleteIcon className={classes.deleteIcon} />
                    ) : (
                      <CircularProgress color='primary' size={15} className={classes.progress} />
                    )}
                  </Button>
                )}
                <DeletePaymentConfirmDialog
                  open={openDeletePaymentConfirmDialog}
                  onDelete={handleDeletePayment}
                  pending={deletePaymentPending}
                  onClose={() => setOpenDeletePaymentConfirmDialog(false)}
                />
              </div>

              <div className={classes.formGroup}>
                <Typography variant='h6' color='primary'>
                  Observaciones de pago
                </Typography>
                {values.saleOrder.comments.map((x) => (
                  <div key={x.id} style={{ display: 'flex' }}>
                    <Typography className={classes.commentDescription}>
                      {x.description}{' '}
                      <span className={classes.commentInfo}>
                        [{moment(x.createdAt).format('DD/MM/YYYY')}, @{x.createdByUsername}]
                      </span>
                    </Typography>
                  </div>
                ))}

                <TextField
                  variant='outlined'
                  placeholder='Escriba las observaciones necesarias.'
                  className={classes.textAreaEdit}
                  color='primary'
                  name='paymentComment'
                  multiline
                  error={touched.paymentComment && Boolean(errors.paymentComment)}
                  helperText={errors.paymentComment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.paymentComment}
                />
              </div>

              <Button
                variant='contained'
                color='primary'
                className={classes.saveButton}
                endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}
                disabled={!isValid || isSubmitting}
                type='submit'>
                Guardar
              </Button>
            </form>
          )}
        </Formik>
      </SectionLayoutDialog>
    </div>
  )
}

export default ViewFileReceiptDialog
