import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './CategoriesAndDistancesSection.style'

const CategoriesAndDistancesSection = ({ canEdit }) => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)

  const getCategoriesItems = () =>
    event.activeInscriptionForm?.items
      .reduce(
        (acc, x) => {
          const category = acc.find((y) => y.eventCategoryName === x.eventCategoryName)
          if (!category) {
            acc.push({
              id: x.id,
              eventCategoryName: x.eventCategoryName,
              distances: [x.eventDistanceName]
            })
          } else {
            category.distances.push(x.eventDistanceName)
          }

          return acc
        },

        []
      )
      .sort((x, y) => x.eventCategoryName.localeCompare(y.eventCategoryName))

  const hasActiveInscriptionForm =
    !!event.activeInscriptionFormId &&
    !!event.activeInscriptionForm &&
    !event.activeInscriptionForm.isDraft

  return (
    <SectionBlock
      title={`Categorías / Distancias${hasActiveInscriptionForm ? '*' : STRING_EMPTY}`}
      titleClassName={clsx(!hasActiveInscriptionForm && classes.disabledTitle)}>
      {hasActiveInscriptionForm ? (
        <div className={classes.categoriesOptionContainer}>
          {getCategoriesItems().map((x) => (
            <Typography key={x.id} variant='h6' className={classes.option}>
              {x.eventCategoryName}: {x.distances.join(' - ')}
            </Typography>
          ))}
        </div>
      ) : (
        <Typography className={classes.emptyMessage} variant='h6'>
          Categorías / Distancias se verán reflejadas aquí, una vez que habilites el formulario de
          inscripción con Sportmetric.
        </Typography>
      )}

      {canEdit && hasActiveInscriptionForm && (
        <Typography variant='caption' color='primary' className={classes.helperText}>
          *Para editar esta información deberás hacer click en Editar Formulario.
        </Typography>
      )}
    </SectionBlock>
  )
}

export default CategoriesAndDistancesSection
