import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import {
  EditOutlined as EditOutlinedIcon,
  Info as InfoIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MailOutline as MailOutlineIcon,
  Search as SearchIcon,
  WhatsApp as WhatsAppIcon
} from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'
import clsx from 'clsx'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import {
  CustomizedTooltip,
  EllipseStatus,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  SignServiceLetterDialog,
  UpdateServiceLetterInfoDialog
} from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import {
  deleteSaleOrderPayment,
  load,
  loadActiveInscriptionForm,
  loadListInscriptions,
  loadListInscriptionSearchMetadata,
  loadReset,
  loadTicketTypeFees,
  resetInscriptionForms,
  resetListInscription,
  setListinscription,
  updateEventInscription,
  updateSaleOrderPayment
} from 'state/modules/events'
import {
  EVENT_INSCRIPTION_STATES,
  PAYMENT_TYPES,
  SALE_ORDER_STATES,
  STRING_EMPTY
} from 'utils/constants'
import { validateServiceLetter } from 'utils/functions'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import {
  ChangeStateDialog,
  DownloadListsDialog,
  EditInscriptionFormDialog,
  InfoInscriptionDialog,
  InscriptionCard,
  InscriptionOffLineDialog,
  MercadoPagoInfoDialog,
  ViewFileReceiptDialog,
  WarningStateDialog
} from './components'
import { useStyles } from './InscriptionRegisteredListPage.style'

const observerOptions = [
  {
    value: EVENT_INSCRIPTION_STATES.PENDING,
    label: <EllipseStatus status='yellow' title='Preinscripto' />
  },
  {
    value: EVENT_INSCRIPTION_STATES.ACCEPTED,
    label: <EllipseStatus status='green' title='Inscripto' />
  },
  {
    value: EVENT_INSCRIPTION_STATES.CANCELLED,
    label: <EllipseStatus status='red' title='Anulado' />
  },
  {
    value: SALE_ORDER_STATES.EXPIRED,
    label: <EllipseStatus status='grey' title='Vencido' />,
    optionHidden: true
  }
]

const PAGE_SIZE = 25
const MIN_PAGE_SIZE = 3

const ORDER_BY = {
  CREATED_AT: 'CreatedAt',
  CATEGORY_SHORT_NAME: 'Category.ShortName',
  DISTANCE_NAME: 'Distance.Name',
  ID_NUMBER: 'IdNumber',
  LAST_NAME: 'LastName'
}

const ORDER_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
}

const SELECT_FILTER_INITIAL_VALUE = {
  state: STRING_EMPTY,
  orderBy: ORDER_BY.CREATED_AT,
  orderDirection: ORDER_DIRECTIONS.DESC,
  saleOrderState: STRING_EMPTY
}

const InscriptionRegisteredListPage = () => {
  const classes = useStyles()
  const { slugUrlOrId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    event,
    pending,
    error: asyncError,
    listInscription,
    listInscriptionPageCount,
    listInscriptionPageCurrentPage,
    listInscriptionPending,
    listInscriptionPageLastPage,
    ticketTypeFees,
    listInscriptionSearchMetadata,
    downloadInscriptionsSheetPending
  } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)
  const currentPageSize = isDesktop ? PAGE_SIZE : MIN_PAGE_SIZE

  const [searchInputText, setSearchInputText] = useState(STRING_EMPTY)
  const [selectedFilter, setSelectedFilter] = useState(SELECT_FILTER_INITIAL_VALUE)
  const [nextOrderBy, setNextOrderBy] = useState(STRING_EMPTY)
  const [openInscriptionOffLineDialog, setOpenInscriptionOffLineDialog] = useState(false)
  const [openChangeStateDialog, setOpenChangeStateDialog] = useState(false)
  const [openWarningStateDialog, setOpenWarningStateDialog] = useState(false)
  const [activeEdit, setActiveEdit] = useState(false)
  const [saveChangesState, setSaveChangesState] = useState([])
  const [selectOpenDialog, setSelectOpenDialog] = useState(null)
  const [filterPending, setFilterPending] = useState({})
  const [updateInscriptionStatesPending, setUpdateInscriptionStatesPending] = useState(false)
  const [openDownloadListsDialog, setOpenDownloadListsDialog] = useState(false)
  const [openSignServiceLetterDialog, setOpenSignServiceLetterDialog] = useState(false)
  const [openUpdateServiceLetterInfoDialog, setOpenUpdateServiceLetterInfoDialog] = useState(false)

  const activeLoading = (!pending && !event && !asyncError) || pending
  const inscriptions = useMemo(
    () =>
      listInscription.map((x) => ({
        ...x,
        state:
          x.state === EVENT_INSCRIPTION_STATES.CANCELLED &&
          x.saleOrder?.state === SALE_ORDER_STATES.EXPIRED
            ? SALE_ORDER_STATES.EXPIRED
            : x.state
      })),
    [listInscription]
  )

  useEffect(() => {
    dispatch(load(slugUrlOrId))
    return () => {
      dispatch(loadReset())
    }
  }, [dispatch, slugUrlOrId, user])

  useEffect(() => {
    dispatch(loadTicketTypeFees())
  }, [])

  useEffect(() => {
    if (!searchInputText && event) {
      dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          1,
          selectedFilter.state,
          selectedFilter.saleOrderState,
          currentPageSize,
          selectedFilter.orderBy,
          selectedFilter.orderDirection
        )
      )

      dispatch(loadListInscriptionSearchMetadata(event.id))
    }
    return () => {
      dispatch(resetListInscription())
    }
  }, [dispatch, event, searchInputText, currentPageSize])

  useEffect(() => {
    if (event && event.activeInscriptionFormId) dispatch(loadActiveInscriptionForm(event))

    return () => {
      dispatch(resetInscriptionForms())
    }
  }, [event, dispatch])

  const handleChange = async (onLoad, value) => {
    if (listInscriptionPageCurrentPage === value) return

    if (activeEdit && saveChangesState.length > 0) {
      setOpenWarningStateDialog(true)
      setSelectOpenDialog(() => onLoad)
    } else {
      await onLoad()
    }
  }

  const handleAccepted = (onActionAccepted) => async () => {
    if (activeEdit && saveChangesState.length > 0) {
      await dispatch(loadListInscriptions(event.id))
      dispatch(loadListInscriptionSearchMetadata(event.id))
      setActiveEdit(false)
      setSaveChangesState([])

      if (onActionAccepted) onActionAccepted()
    }
  }

  const handleOpenWarning = (handleOpenDialog) => {
    if (activeEdit && saveChangesState.length > 0) {
      setSelectOpenDialog(() => handleOpenDialog)
      setOpenWarningStateDialog(true)
    } else {
      handleOpenDialog()
      setActiveEdit(false)
    }
  }

  const stateItems = listInscriptionSearchMetadata.stateItems.reduce(
    (acc, el) => ({
      ...acc,
      [el.name]: el.eventInscriptionsCount
    }),
    {}
  )

  const orderItems = listInscriptionSearchMetadata.orderStateItems.reduce(
    (acc, el) => ({
      ...acc,
      [el.name]: el.eventInscriptionsCount
    }),
    {}
  )

  const expiredCount = orderItems['{ State = Expired }'] || 0

  const totalState = listInscriptionSearchMetadata.stateItems.reduce(
    (acc, el) => acc + el.eventInscriptionsCount,
    0
  )

  const totalStateCount = totalState - expiredCount

  const hasFilterActiveLoading = Object.values(filterPending).some(Boolean)

  const handleStateFilter = async (state) => {
    const saleOrderState = STRING_EMPTY

    await dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        1,
        state,
        saleOrderState,
        currentPageSize,
        selectedFilter.orderBy,
        selectedFilter.orderDirection
      )
    )

    setSelectedFilter({ ...selectedFilter, state, saleOrderState })
  }

  const handleChangeOrder = async (orderBy) => {
    if (hasFilterActiveLoading) return

    try {
      setFilterPending({ [orderBy]: true })

      const orderDirection =
        selectedFilter.orderBy === orderBy &&
        selectedFilter.orderDirection === ORDER_DIRECTIONS.DESC
          ? ORDER_DIRECTIONS.ASC
          : ORDER_DIRECTIONS.DESC

      await dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          1,
          selectedFilter.state,
          selectedFilter.saleOrderState,
          currentPageSize,
          orderBy,
          orderDirection
        )
      )

      setSelectedFilter({ ...selectedFilter, orderBy, orderDirection })
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setFilterPending({})
    }
  }

  const renderLoading = () => (
    <div className={classes.loadingContainer}>
      <Typography variant='h3'>Cargando...</Typography>
    </div>
  )

  const submitInscriptionsSearch = (e) => {
    e.preventDefault()

    dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        1,
        selectedFilter.state,
        selectedFilter.saleOrderState,
        currentPageSize,
        selectedFilter.orderBy,
        selectedFilter.orderDirection
      )
    )

    dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))
  }

  const hasErrors = () => {
    const { loggedUserMetadata, isSuspended } = event

    if (!loggedUserMetadata.canEdit)
      return 'No tiene los permisos necesarios para acceder a esta pagina. Ponte en contacto con el organizador.'

    if (isSuspended)
      return 'El evento se encuentra suspendido, no puedes acceder al listado de inscriptos.'
  }

  const renderNotFoundPage = () => (
    <div className={classes.notFoundContainer}>
      <Typography color='primary' variant='h5'>
        Ocurrió un error al cargar los inscriptos
      </Typography>
      <Typography color='primary'>Verifique el enlace.</Typography>
    </div>
  )

  const renderPage = () => (!event ? renderNotFoundPage() : renderInscriptionRegisteredListPage())

  const renderInscriptionRegisteredListPage = () => {
    const {
      to,
      organization: eventOwner,
      id,
      activeInscriptionForm,
      mercadoPagoCode,
      hasResults,
      isPublished,
      isFree,
      mercadoPagoEnabled
    } = event

    const isAdminOwnerEvent = eventOwner.createdById === user?.id

    const canEditInscription = moment(to).isSameOrAfter(moment())

    const updateServiceLetterRequired =
      !isFree && !!ticketTypeFees.length && validateServiceLetter(eventOwner, ticketTypeFees)

    const editFormButtonShow =
      isDesktop && eventOwner.loggedUserMetadata?.canEdit && canEditInscription

    const enableInscription =
      isPublished &&
      canEditInscription &&
      !hasResults &&
      !!activeInscriptionForm &&
      !updateServiceLetterRequired &&
      (isFree ||
        !mercadoPagoCode ||
        mercadoPagoEnabled ||
        !activeInscriptionForm.ticketTypes.every(
          (x) =>
            x.mercadoPagoEnabled &&
            !x.paymentLocationsEnabled &&
            !x.bankAccountsEnabled &&
            !x.paymentUrl
        ))

    const errors = hasErrors()

    const getStatusInscription = (state) => observerOptions.find((x) => x.value === state)

    const handleSaveStateCancel = () => {
      inscriptions.forEach((element) => {
        if ('newState' in element) {
          delete element.newState
        }
      })
      setActiveEdit(!activeEdit)
      setSaveChangesState([])
    }

    const handleChangeCanEdit = () => setActiveEdit(!activeEdit)

    const handleChangeSaveState = (e, field) => {
      if (saveChangesState.some((element) => element.id === field.id)) {
        const response = saveChangesState.find((element) => element.id === field.id)
        response.newState = e.value
        const result = inscriptions.find((element) => element.id === field.id)
        result.newState = e.value
      } else {
        const result = inscriptions.find((element) => element.id === field.id)
        result.newState = e.value

        setSaveChangesState([
          ...saveChangesState,
          {
            ...result
          }
        ])
      }
    }

    const handleOpenDialog = () => {
      const updateListInscription = saveChangesState.filter(
        (element) => element.state !== element.newState
      )

      if (updateListInscription.length === 0) handleChangeCanEdit()
      else {
        setSaveChangesState(updateListInscription)
        setOpenChangeStateDialog(!openChangeStateDialog)
      }
    }

    const handleUpdateState = async () => {
      try {
        setUpdateInscriptionStatesPending(true)

        for (const { newState, saleOrder, ...restProp } of saveChangesState) {
          const isInscriptionExpired = newState === SALE_ORDER_STATES.EXPIRED

          await dispatch(
            updateEventInscription({
              ...restProp,
              state: isInscriptionExpired ? EVENT_INSCRIPTION_STATES.CANCELLED : newState,
              saleOrder: saleOrder && {
                ...saleOrder,
                state:
                  !isInscriptionExpired && saleOrder.state === SALE_ORDER_STATES.EXPIRED
                    ? SALE_ORDER_STATES.CANCELLED
                    : saleOrder.state
              }
            })
          )
        }

        await dispatch(loadListInscriptions(event.id, searchInputText))
        await dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))

        setActiveEdit(!activeEdit)
        setSaveChangesState([])
        setSelectedFilter({
          ...selectedFilter,
          state: STRING_EMPTY,
          orderBy: ORDER_BY.CREATED_AT,
          orderDirection: ORDER_DIRECTIONS.DESC,
          saleOrderState: STRING_EMPTY
        })
      } catch (error) {
      } finally {
        setUpdateInscriptionStatesPending(false)
      }
    }

    const loadInscriptions = async () => {
      await dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))

      await dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          listInscriptionPageCurrentPage,
          selectedFilter.state,
          selectedFilter.saleOrderState,
          PAGE_SIZE,
          selectedFilter.orderBy,
          selectedFilter.orderDirection
        )
      )
    }

    const getFileReceiptProps = (inscription) => {
      if (!inscription.saleOrder) return { disabledViewReceipt: true }

      const activePayments = inscription.saleOrder.payments.filter(
        (x) => !x.removedAt && x.hasReceiptFile
      )

      if (!activePayments.length) return { disabledViewReceipt: true }

      const lastPayment = activePayments.at(-1)

      return {
        fileUrl: lastPayment.receiptFileUrl,
        fileExtension: lastPayment.receiptFile.fileName.split('.').pop(),
        reviewed: !!lastPayment.updatedAt,

        onDeletePayment: handleDeletePayment(inscription.saleOrder, lastPayment),
        onUpdatePayment: handleUpdatePayment(lastPayment)
      }
    }

    const handleEditButton = (e) => {
      if (event.isFree) return

      const updateServiceLetterRequired = validateServiceLetter(eventOwner, ticketTypeFees)

      if (eventOwner.hasActiveContract && updateServiceLetterRequired) {
        e.preventDefault()
        setOpenUpdateServiceLetterInfoDialog(true)
      }
    }

    const handleDeletePayment = (saleOrder, payment) => async () => {
      const data = await dispatch(deleteSaleOrderPayment(saleOrder.id, payment.id))

      if (!data) return

      await dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          listInscriptionPageCurrentPage,
          selectedFilter.state,
          selectedFilter.saleOrderState,
          currentPageSize,
          selectedFilter.orderBy,
          selectedFilter.orderDirection
        )
      )

      showSnackbarSuccess('¡Se eliminó el comprobante de pago con éxito!')
    }

    const handleUpdatePayment = (payment) => async () => {
      const data = await dispatch(updateSaleOrderPayment(payment))

      return data
    }

    const miniLoadInscriptions = (index) => (page, inscription) => {
      const inscriptions = [...listInscription]

      inscriptions[index] = inscription

      dispatch(setListinscription(inscriptions))

      dispatch(loadListInscriptionSearchMetadata(id, searchInputText))
    }

    const renderFileViewerReceipt = (inscription, index) => {
      const fileReceiptProps = getFileReceiptProps(inscription)

      return (
        <ViewFileReceiptDialog
          inscription={inscription}
          load={isDesktop ? loadInscriptions : miniLoadInscriptions(index)}
          {...fileReceiptProps}
        />
      )
    }

    const clearnextOrderBy = () => setNextOrderBy(STRING_EMPTY)

    const handleChangeNextOrderBy = (state) => setNextOrderBy(state)

    const handleChangeSaleOrderState = async (saleOrderState) => {
      const state = STRING_EMPTY

      await dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          1,
          state,
          saleOrderState,
          currentPageSize,
          selectedFilter.orderBy,
          selectedFilter.orderDirection
        )
      )

      setSelectedFilter({ ...selectedFilter, saleOrderState, state })
    }

    const renderOrderDirectionArrow = (direction = ORDER_DIRECTIONS.DESC) => {
      if (direction === ORDER_DIRECTIONS.DESC) return <KeyboardArrowDownIcon color='primary' />
      else if (direction === ORDER_DIRECTIONS.ASC) return <KeyboardArrowUpIcon color='primary' />
    }

    const cancelledCount = (stateItems['{ State = Cancelled }'] || 0) - expiredCount

    const textFieldProps = isDesktop
      ? { placeholder: 'Búsqueda por nombre, apellido, DNI, categoría' }
      : { label: 'Buscar deportista*', helperText: 'Búsqueda por nombre, apellido, DNI, categoría' }

    return (
      <>
        {!errors ? (
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <Typography color='primary' variant='h6' className={classes.eventName}>
                {event.name}
              </Typography>
            </div>

            <div className={classes.bodyContainer}>
              <div className={classes.mainContainer}>
                {!isDesktop && (
                  <div className={classes.mobileInfo}>
                    <Typography>
                      Te recomendamos usar una computadora para visualizar esta sección, ya que en
                      celular contiene menos fucionalidades.
                    </Typography>
                  </div>
                )}
                <div className={classes.buttonsSection}>
                  <SignServiceLetterDialog
                    open={openSignServiceLetterDialog}
                    action={eventOwner.hasActiveContract ? 'update' : 'create'}
                    onUpdateSuccess={() => {
                      showSnackbarSuccess('¡Carta de servicio actualizada correctamente!')
                      setOpenSignServiceLetterDialog(false)
                      history.push(`${ROUTES.EVENTS.INSCRIPTION}/${event.slugUrl}`)
                    }}
                    onClose={() => setOpenSignServiceLetterDialog(false)}
                  />
                  <UpdateServiceLetterInfoDialog
                    open={openUpdateServiceLetterInfoDialog}
                    onAccept={() => {
                      setOpenUpdateServiceLetterInfoDialog(false)
                      setOpenSignServiceLetterDialog(true)
                    }}
                    onClose={() => setOpenUpdateServiceLetterInfoDialog(false)}
                  />

                  {editFormButtonShow && (
                    <Button
                      to={`${ROUTES.EVENTS.INSCRIPTION}/${slugUrlOrId}`}
                      component={Link}
                      color='primary'
                      disabled={updateServiceLetterRequired && !isAdminOwnerEvent}
                      onClick={handleEditButton}
                      variant='outlined'>
                      Editar formulario
                    </Button>
                  )}

                  {!!totalState && (
                    <Button
                      color='primary'
                      disabled={downloadInscriptionsSheetPending}
                      endIcon={downloadInscriptionsSheetPending && <CircularProgress size={16} />}
                      variant='contained'
                      className={classes.optionButton}
                      onClick={() => setOpenDownloadListsDialog(true)}>
                      Descargar listado
                    </Button>
                  )}
                  <DownloadListsDialog
                    open={openDownloadListsDialog}
                    onClose={() => setOpenDownloadListsDialog(false)}
                  />
                  {enableInscription && (
                    <>
                      <Button
                        variant='outlined'
                        color='primary'
                        disabled={!event.isPublished}
                        className={classes.optionButton}
                        endIcon={
                          <CustomizedTooltip
                            title='Sólo podrás inscribir a un deportista si hay cupo disponible.'
                            position={isDesktop ? 'right-end' : 'bottom'}
                            className={classes.tooltip}
                            arrowClassName={classes.tooltipArrow}>
                            {({ handleTooltip, handleTooltipClose }) => (
                              <InfoIcon
                                color='primary'
                                className={clsx(
                                  classes.icon,
                                  !event.isPublished && classes.disabledIcon
                                )}
                                onMouseOut={handleTooltipClose}
                                onMouseOver={handleTooltip}
                              />
                            )}
                          </CustomizedTooltip>
                        }
                        onClick={() =>
                          setOpenInscriptionOffLineDialog(!openInscriptionOffLineDialog)
                        }>
                        Inscribir
                      </Button>

                      <InscriptionOffLineDialog
                        title={event.name}
                        open={openInscriptionOffLineDialog}
                        onClose={() => setOpenInscriptionOffLineDialog(false)}
                      />
                    </>
                  )}
                </div>

                {!isDesktop && (
                  <div className={classes.statusContainer}>
                    <div className={clsx(classes.stateContainer, 'center')}>
                      <Typography
                        className={clsx(
                          !selectedFilter.state && !selectedFilter.saleOrderState && 'filter'
                        )}
                        onClick={() => {
                          handleStateFilter(STRING_EMPTY)
                          handleChangeSaleOrderState(STRING_EMPTY)
                        }}>
                        Total ({totalStateCount})
                      </Typography>
                    </div>

                    <div className={classes.statusSection}>
                      <div className={classes.stateBlock}>
                        <div className={classes.stateContainer}>
                          <EllipseStatus status='yellow' title='Preinscripto' />
                          <Typography
                            className={clsx(
                              selectedFilter.state === EVENT_INSCRIPTION_STATES.PENDING && 'filter'
                            )}
                            onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.PENDING)}>
                            Preinscripto ({stateItems['{ State = Pending }'] || 0})
                          </Typography>
                        </div>
                        <div className={classes.stateContainer}>
                          <EllipseStatus status='red' title='Anulado' />
                          <Typography
                            className={clsx(
                              selectedFilter.state === EVENT_INSCRIPTION_STATES.CANCELLED &&
                                'filter'
                            )}
                            onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.CANCELLED)}>
                            Anulado ({cancelledCount})
                          </Typography>
                        </div>
                      </div>

                      <div className={classes.stateBlock}>
                        <div className={classes.stateContainer}>
                          <EllipseStatus status='green' title='Inscripto' />
                          <Typography
                            className={clsx(
                              selectedFilter.state === EVENT_INSCRIPTION_STATES.ACCEPTED && 'filter'
                            )}
                            onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.ACCEPTED)}>
                            Inscripto ({stateItems['{ State = Accepted }'] || 0})
                          </Typography>
                        </div>

                        <div className={classes.stateContainer}>
                          <EllipseStatus status='grey' title='Vencido' />
                          <Typography
                            className={clsx(
                              selectedFilter.saleOrderState === SALE_ORDER_STATES.EXPIRED &&
                                'filter'
                            )}
                            onClick={() => handleChangeSaleOrderState(SALE_ORDER_STATES.EXPIRED)}>
                            Vencido ({orderItems['{ State = Expired }'] || 0})
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!event.activeInscriptionForm.isFree && (
                  <div className={classes.paymentInfoContainer}>
                    <Typography color='primary' className={classes.paymentInfo} variant='h6'>
                      En esta lista podrás ver el estado de los inscriptos a tu evento. Si la
                      inscripción fue abonada por transferencia o en efectivo, deberás modificar
                      cada estado manualmente. Sólo las inscripciones abonadas on line actualizan su
                      estado automáticamente.
                    </Typography>
                    <div className={classes.paymentMethodContainer}>
                      <Typography
                        variant='h6'
                        color='primary'
                        className={classes.paymentMethodTitle}>
                        Medios de pago habilitados del evento:
                      </Typography>
                      <div className={classes.paymentMethodEnabled}>
                        {event.mercadoPagoEnabled && <span>Mercado Pago </span>}
                        {event.eventBankAccountsEnabled && <span>Transferencia Bancaria </span>}
                        {event.eventPaymentLocationsEnabled && <span>Pago en Efectivo</span>}
                        {event.activeInscriptionForm.ticketTypes.some(
                          (x) => x.isActive && !!x.paymentUrl
                        ) && <span>Link de Pago</span>}
                      </div>
                    </div>
                  </div>
                )}
                <div className={classes.searchContainer}>
                  <form onSubmit={submitInscriptionsSearch}>
                    <div className={classes.searchSection}>
                      <TextField
                        color='primary'
                        variant='outlined'
                        autoComplete='off'
                        value={searchInputText}
                        size='small'
                        className={classes.field}
                        {...textFieldProps}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              type='submit'
                              color='primary'
                              className={classes.searchButton}>
                              <SearchIcon />
                            </IconButton>
                          )
                        }}
                        onChange={(e) => setSearchInputText(e.target.value)}
                      />
                    </div>
                  </form>
                  {isDesktop && (
                    <div className={classes.statusSection}>
                      <div className={classes.stateContainer}>
                        <EllipseStatus status='yellow' title='Preinscripto' />
                        <Typography
                          className={clsx(
                            selectedFilter.state === EVENT_INSCRIPTION_STATES.PENDING && 'filter'
                          )}
                          onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.PENDING)}>
                          Preinscripto ({stateItems['{ State = Pending }'] || 0})
                        </Typography>
                      </div>
                      <div className={classes.stateContainer}>
                        <EllipseStatus status='green' title='Inscripto' />
                        <Typography
                          className={clsx(
                            selectedFilter.state === EVENT_INSCRIPTION_STATES.ACCEPTED && 'filter'
                          )}
                          onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.ACCEPTED)}>
                          Inscripto ({stateItems['{ State = Accepted }'] || 0})
                        </Typography>
                      </div>
                      <div className={classes.stateContainer}>
                        <EllipseStatus status='red' title='Anulado' />
                        <Typography
                          className={clsx(
                            selectedFilter.state === EVENT_INSCRIPTION_STATES.CANCELLED && 'filter'
                          )}
                          onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.CANCELLED)}>
                          Anulado ({cancelledCount})
                        </Typography>
                      </div>
                      <div className={classes.stateContainer}>
                        <Typography
                          className={clsx(
                            !selectedFilter.state && !selectedFilter.saleOrderState && 'filter'
                          )}
                          onClick={() => {
                            handleStateFilter(STRING_EMPTY)
                            handleChangeSaleOrderState(STRING_EMPTY)
                          }}>
                          Total ({totalStateCount})
                        </Typography>
                      </div>
                      <div className={classes.stateContainer}>
                        <EllipseStatus status='grey' title='Vencido' />
                        <Typography
                          className={clsx(
                            selectedFilter.saleOrderState === SALE_ORDER_STATES.EXPIRED && 'filter'
                          )}
                          onClick={() => handleChangeSaleOrderState(SALE_ORDER_STATES.EXPIRED)}>
                          Vencido ({orderItems['{ State = Expired }'] || 0})
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className={classes.listContainer}>
                    <Typography color='primary' variant='h5'>
                      <strong>LISTA DE INSCRIPTOS ON LINE</strong>
                    </Typography>

                    <table className={classes.table}>
                      <thead>
                        <tr>
                          <th
                            onMouseOut={clearnextOrderBy}
                            onMouseOver={() => handleChangeNextOrderBy(ORDER_BY.CREATED_AT)}
                            className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                            onClick={() => handleChangeOrder(ORDER_BY.CREATED_AT)}>
                            <div className={classes.colContainer}>
                              <Typography
                                color='primary'
                                className={clsx(
                                  selectedFilter.orderBy === ORDER_BY.CREATED_AT && classes.option
                                )}>
                                N°
                              </Typography>
                              {filterPending[ORDER_BY.CREATED_AT] && (
                                <CircularProgress
                                  color='primary'
                                  size={16}
                                  className={classes.filterLoading}
                                />
                              )}

                              {((!hasFilterActiveLoading && nextOrderBy === ORDER_BY.CREATED_AT) ||
                                (!filterPending[ORDER_BY.CREATED_AT] &&
                                  selectedFilter.orderBy === ORDER_BY.CREATED_AT)) &&
                                renderOrderDirectionArrow(
                                  selectedFilter.orderBy === ORDER_BY.CREATED_AT
                                    ? selectedFilter.orderDirection
                                    : ORDER_DIRECTIONS.DESC
                                )}
                            </div>
                          </th>
                          <th
                            onMouseOut={clearnextOrderBy}
                            onMouseOver={() => handleChangeNextOrderBy(ORDER_BY.LAST_NAME)}
                            className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                            onClick={() => handleChangeOrder(ORDER_BY.LAST_NAME)}>
                            <div className={classes.colContainer}>
                              <Typography
                                color='primary'
                                className={clsx(
                                  selectedFilter.orderBy === ORDER_BY.LAST_NAME && classes.option
                                )}>
                                Apellido y nombre
                              </Typography>

                              {filterPending[ORDER_BY.LAST_NAME] && (
                                <CircularProgress
                                  color='primary'
                                  size={16}
                                  className={classes.filterLoading}
                                />
                              )}

                              {((!hasFilterActiveLoading && nextOrderBy === ORDER_BY.LAST_NAME) ||
                                (!filterPending[ORDER_BY.LAST_NAME] &&
                                  selectedFilter.orderBy === ORDER_BY.LAST_NAME)) &&
                                renderOrderDirectionArrow(
                                  selectedFilter.orderBy === ORDER_BY.LAST_NAME
                                    ? selectedFilter.orderDirection
                                    : ORDER_DIRECTIONS.DESC
                                )}
                            </div>
                          </th>
                          <th
                            onMouseOut={clearnextOrderBy}
                            onMouseOver={() => handleChangeNextOrderBy(ORDER_BY.ID_NUMBER)}
                            className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                            onClick={() => handleChangeOrder(ORDER_BY.ID_NUMBER)}>
                            <div className={classes.colContainer}>
                              <Typography
                                color='primary'
                                className={clsx(
                                  selectedFilter.orderBy === ORDER_BY.ID_NUMBER && classes.option
                                )}>
                                D.N.I&nbsp;
                              </Typography>

                              {filterPending[ORDER_BY.ID_NUMBER] && (
                                <CircularProgress
                                  color='primary'
                                  size={16}
                                  className={classes.filterLoading}
                                />
                              )}

                              {((!hasFilterActiveLoading && nextOrderBy === ORDER_BY.ID_NUMBER) ||
                                (!filterPending[ORDER_BY.ID_NUMBER] &&
                                  selectedFilter.orderBy === ORDER_BY.ID_NUMBER)) &&
                                renderOrderDirectionArrow(
                                  selectedFilter.orderBy === ORDER_BY.ID_NUMBER
                                    ? selectedFilter.orderDirection
                                    : ORDER_DIRECTIONS.DESC
                                )}
                            </div>
                          </th>
                          <th
                            onMouseOut={clearnextOrderBy}
                            onMouseOver={() => handleChangeNextOrderBy(ORDER_BY.DISTANCE_NAME)}
                            className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                            onClick={() => handleChangeOrder(ORDER_BY.DISTANCE_NAME)}>
                            <div className={classes.colContainer}>
                              <Typography
                                color='primary'
                                className={clsx(
                                  selectedFilter.orderBy === ORDER_BY.DISTANCE_NAME &&
                                    classes.option
                                )}>
                                Distancia&nbsp;
                              </Typography>

                              {filterPending[ORDER_BY.DISTANCE_NAME] && (
                                <CircularProgress
                                  color='primary'
                                  size={16}
                                  className={classes.filterLoading}
                                />
                              )}

                              {((!hasFilterActiveLoading &&
                                nextOrderBy === ORDER_BY.DISTANCE_NAME) ||
                                (!filterPending[ORDER_BY.DISTANCE_NAME] &&
                                  selectedFilter.orderBy === ORDER_BY.DISTANCE_NAME)) &&
                                renderOrderDirectionArrow(
                                  selectedFilter.orderBy === ORDER_BY.DISTANCE_NAME
                                    ? selectedFilter.orderDirection
                                    : ORDER_DIRECTIONS.DESC
                                )}
                            </div>
                          </th>
                          <th
                            onMouseOut={clearnextOrderBy}
                            onMouseOver={() =>
                              handleChangeNextOrderBy(ORDER_BY.CATEGORY_SHORT_NAME)
                            }
                            className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                            onClick={() => handleChangeOrder(ORDER_BY.CATEGORY_SHORT_NAME)}>
                            <div className={classes.colContainer}>
                              <Typography
                                color='primary'
                                className={clsx(
                                  selectedFilter.orderBy === ORDER_BY.CATEGORY_SHORT_NAME &&
                                    classes.option
                                )}>
                                Categoría&nbsp;
                              </Typography>

                              {filterPending[ORDER_BY.CATEGORY_SHORT_NAME] && (
                                <CircularProgress
                                  color='primary'
                                  size={16}
                                  className={classes.filterLoading}
                                />
                              )}

                              {((!hasFilterActiveLoading &&
                                nextOrderBy === ORDER_BY.CATEGORY_SHORT_NAME) ||
                                (!filterPending[ORDER_BY.CATEGORY_SHORT_NAME] &&
                                  selectedFilter.orderBy === ORDER_BY.CATEGORY_SHORT_NAME)) &&
                                renderOrderDirectionArrow(
                                  selectedFilter.orderBy === ORDER_BY.CATEGORY_SHORT_NAME
                                    ? selectedFilter.orderDirection
                                    : ORDER_DIRECTIONS.DESC
                                )}
                            </div>
                          </th>
                          <th>
                            Estado
                            {canEditInscription &&
                              event.isPublished &&
                              !activeEdit &&
                              !!inscriptions.length && (
                                <IconButton color='primary' onClick={handleChangeCanEdit}>
                                  <EditOutlinedIcon />
                                </IconButton>
                              )}
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {!inscriptions.length && (
                          <tr>
                            <td colSpan={6}>
                              <Typography variant='h5' color='primary' align='center'>
                                No se encontraron participantes
                              </Typography>
                            </td>
                          </tr>
                        )}
                        {inscriptions.map((field, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {field.lastName} {field.firstName}
                            </td>
                            <td>{field.idNumber}</td>
                            <td>{field.distance.name}</td>
                            <td>{field.category.shortName}</td>
                            {!activeEdit ? (
                              <td>{getStatusInscription(field.state).label}</td>
                            ) : (
                              <td>
                                <StyledSelect
                                  defaultValue={getStatusInscription(field.state)}
                                  isDisabled={updateInscriptionStatesPending}
                                  defaultOptions={observerOptions}
                                  colorOptions='primary'
                                  filterOption={(option) =>
                                    !option.data.optionHidden &&
                                    option.value !== (field.newState ?? field.state)
                                  }
                                  isSearchable={false}
                                  className={classes.select}
                                  onChange={(e) => handleChangeSaveState(e, field)}
                                />
                              </td>
                            )}
                            <td>
                              {!!field.paymentType &&
                                (field.paymentType !== PAYMENT_TYPES.MercadoPago ? (
                                  renderFileViewerReceipt(field)
                                ) : (
                                  <MercadoPagoInfoDialog inscription={field} />
                                ))}
                            </td>
                            <td>
                              {!field.hasWhatsApp ? (
                                <InfoInscriptionDialog field={field} />
                              ) : (
                                <Button
                                  color='primary'
                                  variant='contained'
                                  href={`https://wa.me/${field.phone}?text=Desde%20la%20organización%20${event.organization.name}%20nos%20comunicamos%20por%20el%20evento%20${event.name}%20`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className={classes.actionButton}>
                                  <WhatsAppIcon />
                                </Button>
                              )}
                            </td>

                            <td>
                              <Button
                                color='primary'
                                variant='contained'
                                href={`mailto:${field.eMail}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={classes.actionButton}>
                                <MailOutlineIcon />
                              </Button>
                            </td>
                            {canEditInscription && (
                              <td>
                                <EditInscriptionFormDialog
                                  title={event.name}
                                  load={(page) => {
                                    dispatch(
                                      loadListInscriptions(
                                        id,
                                        searchInputText,
                                        page || listInscriptionPageCurrentPage,
                                        selectedFilter.state,
                                        selectedFilter.saleOrderState,
                                        PAGE_SIZE,
                                        selectedFilter.orderBy,
                                        selectedFilter.orderDirection
                                      )
                                    )
                                    dispatch(loadListInscriptionSearchMetadata(id, searchInputText))
                                  }}
                                  inscription={field}
                                  handleOpen={handleOpenWarning}
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {activeEdit && (
                      <div className={classes.btnSave}>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={handleOpenDialog}
                          disabled={updateInscriptionStatesPending}
                          endIcon={
                            updateInscriptionStatesPending && (
                              <CircularProgress size={16} color='primary' />
                            )
                          }>
                          Guardar
                        </Button>
                        <ChangeStateDialog
                          open={openChangeStateDialog}
                          onClose={() => setOpenChangeStateDialog(false)}
                          data={saveChangesState}
                          onCancel={handleSaveStateCancel}
                          handleUpdateState={handleUpdateState}
                        />
                      </div>
                    )}
                    <WarningStateDialog
                      open={openWarningStateDialog}
                      onClose={() => setOpenWarningStateDialog(false)}
                      onAccepted={handleAccepted(selectOpenDialog)}
                    />
                  </div>
                  <div className={classes.root}>
                    {inscriptions.length > 0 ? (
                      <div className={classes.paginationContainer}>
                        <Pagination
                          showFirstButton
                          showLastButton
                          count={listInscriptionPageCount}
                          page={listInscriptionPageCurrentPage}
                          onChange={(e, value) =>
                            handleChange(
                              async () =>
                                await dispatch(
                                  loadListInscriptions(
                                    event.id,
                                    searchInputText,
                                    value,
                                    selectedFilter.state,
                                    selectedFilter.saleOrderState,
                                    PAGE_SIZE,
                                    selectedFilter.orderBy,
                                    selectedFilter.orderDirection
                                  )
                                ),
                              value
                            )
                          }
                          variant='outlined'
                          shape='rounded'
                          color='primary'
                        />
                      </div>
                    ) : null}
                  </div>

                  {!isDesktop && (
                    <div className={classes.inscriptionsContainer}>
                      <InfiniteScroll
                        dataLength={inscriptions.length}
                        hasMore={!listInscriptionPageLastPage}
                        style={{ overflow: 'inherit' }}
                        next={() =>
                          dispatch(
                            loadListInscriptions(
                              id,
                              searchInputText,
                              listInscriptionPageCurrentPage + 1,
                              selectedFilter.state,
                              selectedFilter.saleOrderState,
                              MIN_PAGE_SIZE,
                              selectedFilter.orderBy,
                              selectedFilter.orderDirection,
                              'accumulator'
                            )
                          )
                        }>
                        {inscriptions.map((x, index) => (
                          <InscriptionCard
                            inscription={x}
                            key={x.id}
                            paymentComponent={
                              !!x.paymentType &&
                              (x.paymentType !== PAYMENT_TYPES.MercadoPago ? (
                                renderFileViewerReceipt(x, index)
                              ) : (
                                <MercadoPagoInfoDialog inscription={x} />
                              ))
                            }
                            editProps={{
                              canEditInscription,
                              load: miniLoadInscriptions(index),

                              handleOpenWarning
                            }}
                          />
                        ))}
                      </InfiniteScroll>

                      {listInscriptionPending && (
                        <div className={classes.containerLoader}>
                          <CircularProgress size={40} color='primary' />
                        </div>
                      )}

                      <div className={classes.buttonsContainer}>
                        {!listInscriptionPending && !listInscriptionPageLastPage && (
                          <div className={classes.buttonContainer}>
                            <Button
                              color='primary'
                              variant='contained'
                              disabled={listInscriptionPending}
                              className={classes.viewMoreButton}
                              onClick={() =>
                                dispatch(
                                  loadListInscriptions(
                                    id,
                                    searchInputText,
                                    listInscriptionPageCurrentPage + 1,
                                    selectedFilter.state,
                                    selectedFilter.saleOrderState,
                                    MIN_PAGE_SIZE,
                                    selectedFilter.orderBy,
                                    selectedFilter.orderDirection,
                                    'accumulator'
                                  )
                                )
                              }>
                              Ver más
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.messageContainer}>
            <Typography variant='h5' color='primary'>
              {errors}
            </Typography>
          </div>
        )}
      </>
    )
  }
  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode={activeLoading}>
        {activeLoading ? renderLoading() : renderPage()}
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default InscriptionRegisteredListPage
