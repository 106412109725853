import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '&.disabled': {
      '& h3': {
        color: '#696969',
        borderColor: '#696969'
      }
    }
  },
  selectContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: '48%'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },

    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    },
    '&.disabled': {
      color: '#969696',
      '& svg': {
        color: theme.palette.common.white
      },
      '& > div': {
        borderColor: '#969696',
        borderRadius: 0,
        boxShadow: 'none'
      }
    }
  },
  messageContainer: {
    top: 'calc(50% - 5px) !important',
    width: '100% !important',
    '& > div': {
      maxWidth: 800
    }
  },
  message: {
    fontSize: '.875rem',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem'
    }
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    fontSize: '.875rem',
    zIndex: 1000,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 400
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 700
    }
  },
  tooltipButton: {
    top: '3px !important',
    right: '6px !important'
  },
  textField: {
    width: '70%',
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '& input': {
      height: 3,
      paddingLeft: theme.spacing(),
      fontSize: '.825rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  arrowTooltip: {
    color: theme.palette.tooltip.main
  },
  focusedTooltip: {
    zIndex: 1300
  },
  viewAdminPopper: {
    zIndex: 1300
  },
  popper: {
    zIndex: 1000
  }
}))
