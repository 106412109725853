import React from 'react'
import { Typography } from '@material-ui/core'
import { AccessTime as AccessTimeIcon } from '@material-ui/icons'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import moment from 'moment'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './EventDateSection.style'

const EventDateSection = () => {
  const classes = useStyles()

  const { values, handleBlur, errors, touched, setValues, setFieldValue } = useFormikContext()

  const onFromDateChanged = (setValues, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,

      fromDate: value ? value.format() : null,
      fromTime:
        !values.fromTime && value && moment(value).isValid() ? defaultTime : values.fromTime,

      ...(!!value &&
        moment(value).isValid() &&
        (!values.toDate || !moment(values.toDate).isValid()) && {
          toDate: moment(value),
          toTime: moment('23:59', 'HH:mm').format()
        })
    }))

  const onTimeChanged = (setFieldValue, field) => (value) =>
    setFieldValue(field, value ? value.format() : null)

  const onDateTimeChanged = (setValues, field, timeField, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,
      [field]: value ? value.format() : null,
      [timeField]:
        !values[timeField] && value && moment(value).isValid() ? defaultTime : values[timeField]
    }))

  return (
    <SectionBlock title='Nueva Fecha del evento*'>
      <div className={classes.content}>
        <div className={clsx(classes.formGroup, classes.dateEventContainer)}>
          <div className={classes.datePickerContainer}>
            <div className={classes.datetimePickerContainer}>
              <KeyboardDatePicker
                className={classes.inputDatePicker}
                format='DD/MM/YYYY'
                autoOk
                variant='inline'
                name='fromDate'
                margin='none'
                label='Desde'
                id='date-picker-inline'
                inputVariant='outlined'
                onBlur={handleBlur}
                required
                helperText=''
                placeholder='dd/mm/aaaa'
                value={values.fromDate}
                onChange={onFromDateChanged(setValues, moment('00:00', 'HH:mm').format())}
                KeyboardButtonProps={{
                  'aria-label': 'seleccione fecha'
                }}
                error={touched.fromDate && Boolean(errors.fromDate)}
              />
              <KeyboardTimePicker
                className={classes.inputTimePicker}
                autoOk
                name='fromTime'
                onBlur={handleBlur}
                variant='inline'
                ampm={false}
                autoComplete='off'
                inputVariant='outlined'
                keyboardIcon={<AccessTimeIcon />}
                value={values.fromTime}
                helperText=''
                placeholder='hh:mm'
                error={touched.fromTime && Boolean(errors.fromTime)}
                onChange={onTimeChanged(setFieldValue, 'fromTime')}
              />
            </div>
            {(errors.fromDate || errors.fromTime) && (
              <Typography
                color={
                  (touched.fromDate && Boolean(errors.fromDate)) ||
                  (touched.fromTime && Boolean(errors.fromTime))
                    ? 'error'
                    : ''
                }
                className={classes.errorMessage}
                variant='caption'>
                {errors.fromDate || errors.fromTime}
              </Typography>
            )}
          </div>
          <div className={classes.formGroup}>
            <div className={classes.datetimePickerContainer}>
              <KeyboardDatePicker
                className={classes.inputDatePicker}
                format='DD/MM/YYYY'
                autoOk
                variant='inline'
                name='toDate'
                required
                label='Hasta'
                margin='none'
                id='date-picker-inline'
                inputVariant='outlined'
                onBlur={handleBlur}
                value={values.toDate}
                onChange={onDateTimeChanged(
                  setValues,
                  'toDate',
                  'toTime',
                  moment('23:59', 'HH:mm').format()
                )}
                KeyboardButtonProps={{
                  'aria-label': 'seleccione fecha'
                }}
                placeholder='dd/mm/aaaa'
                helperText=''
                error={touched.toDate && Boolean(errors.toDate)}
              />
              <KeyboardTimePicker
                className={classes.inputTimePicker}
                autoOk
                name='toTime'
                onBlur={handleBlur}
                variant='inline'
                ampm={false}
                inputVariant='outlined'
                keyboardIcon={<AccessTimeIcon />}
                value={values.toTime}
                helperText=''
                placeholder='hh:mm'
                error={touched.toTime && Boolean(errors.toTime)}
                onChange={onTimeChanged(setFieldValue, 'toTime')}
              />
            </div>
            {(errors.toDate || errors.toTime) && (
              <Typography
                color={
                  (touched.toDate && Boolean(errors.toDate)) ||
                  (touched.toTime && Boolean(errors.toTime))
                    ? 'error'
                    : 'inherit'
                }
                className={classes.errorMessage}
                variant='caption'>
                {errors.toDate || errors.toTime}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </SectionBlock>
  )
}

export default EventDateSection
