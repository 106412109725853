import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import { SuccessInscriptionPendingDialog } from 'pages/Events/InscriptionFormPage/components'
import { InscriptionForm } from 'pages/Events/InscriptionPage/components'
import { PaymentGatewayProxy } from 'services'
import { ConfirmInscriptionDialog, LayoutDialog, SuccessInscriptionDialog } from 'shared'
import {
  eventInscription,
  loadActiveInscriptionForm,
  loadEventInscription,
  loadListInscriptions,
  loadListInscriptionSearchMetadata,
  resetValue
} from 'state/modules/events'
import { calculatePercentage, getFeeAmount } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './InscriptionOffLineDialog.style'

const OTHER_OPTION = { label: 'Otra', value: 'other' }

const PAYMENT_TYPES = {
  MercadoPago: 'MercadoPago',
  BankAccount: 'BankAccount',
  PaymentLocation: 'PaymentLocation'
}

const EVENT_INSCRIPTION_STATES = {
  Pending: 'Pending',
  Accepted: 'Accepted',
  Cancelled: 'Cancelled'
}

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const TICKET_FREE_PRICE = 0

const InscriptionOffLineDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { user, userData } = useSelector((state) => state.auth)
  const { event, activeInscriptionForm, activeInscriptionFormPending, eventInscriptionError } =
    useSelector((state) => state.events)

  const [openConfirmInscriptionDialog, setOpenConfirmInscriptionDialog] = useState(false)
  const [openSuccessInscriptionPendingDialog, setOpenSuccessInscriptionPendingDialog] =
    useState(false)
  const [openSuccessInscriptionDialog, setOpenSuccessInscriptionDialog] = useState(false)
  const [inscription, setInscription] = useState({})

  const formData = useMemo(
    () => ({
      ...activeInscriptionForm,
      ticketTypes: activeInscriptionForm?.ticketTypes.filter((x) => x.isActive),
      customFields: activeInscriptionForm?.customFields
        .map((e) => ({
          ...e.eventCustomField,
          itemNumber: e.itemNumber,
          value: ''
        }))
        .sort((a, b) => a.itemNumber - b.itemNumber)
    }),
    [activeInscriptionForm]
  )

  useEffect(() => {
    if (eventInscriptionError) showSnackbarError(eventInscriptionError)

    return () => dispatch(resetValue('eventInscriptionError'))
  }, [dispatch, eventInscriptionError])

  useEffect(() => {
    if (open) dispatch(loadActiveInscriptionForm(event))
  }, [open])

  const getFinalPriceMercadoPago = (
    priceWithDiscount,
    mercadoPagoFeePercentage,
    sportmetricPercentage,
    sportmetricFeeAmount,
    hasDiscount
  ) => {
    const price = hasDiscount ? priceWithDiscount + sportmetricFeeAmount : priceWithDiscount

    return Number(
      (
        -Number(price) /
        (calculatePercentage(!hasDiscount ? sportmetricPercentage : 0) / 100 +
          mercadoPagoFeePercentage / 100 -
          1)
      ).toFixed(2)
    )
  }

  const getPriceWithDiscount = (price, discountCode) => {
    if (!discountCode) return Number(price)

    if (discountCode.discountType === DISCOUNT_TYPE.PERCENTAGE)
      return Number(price) - (Number(price) * discountCode.percentage) / 100

    if (discountCode.discountType === DISCOUNT_TYPE.FIXED_AMOUNT) {
      const finalPrice = Number(price) - Number(discountCode.fixedAmount)
      return finalPrice > 0 ? finalPrice : TICKET_FREE_PRICE
    }
  }

  const getPriceForOrganizer = (ticketType) =>
    ticketType.price === ticketType.mercadoPagoPrice
      ? ticketType.price -
        getFeeAmount(ticketType.price, Number(ticketType.mercadoPagoFee)) -
        ticketType.sportmetricFeeAmount
      : ticketType.price

  const onSubmit = async ({
    customFields,
    distance,
    category,
    country,
    birthdate,
    areaLevel1,
    areaLevel2,
    areaLevel2Other,
    paymentType,
    gender,
    ticketType,
    discountCode: eventDiscountCode,
    discountCodeName: eventDiscountCodeName,
    activePaymentMethods,
    activeTicketTypes,
    changeCategory,
    changeTicketType,
    ...restProps
  }) => {
    const inscriptionPrice =
      paymentType.value === PAYMENT_TYPES.MercadoPago
        ? ticketType.mercadoPagoPrice
        : ticketType.price

    const newInscription = {
      ...restProps,
      eventId: event.id,
      ticketTypeId: ticketType.id,
      ticketType,
      distanceId: distance.value,
      ticketTypeName: ticketType.name,
      ticketTypePrice: inscriptionPrice,
      distanceName: distance.label,
      categoryId: category.value,
      categoryName: category.label,
      countryId: country.value,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      countryName: country.label,
      areaLevel1Id: areaLevel1 ? areaLevel1.value : null,
      areaLevel1Name: areaLevel1 ? areaLevel1.label : null,
      areaLevel2Id: areaLevel2?.value !== OTHER_OPTION.value ? areaLevel2.value : null,
      areaLevel2Name: areaLevel2?.value !== OTHER_OPTION.value ? areaLevel2.label : null,
      gender: gender.value,
      paymentType: paymentType.value,
      paymentTypeName: paymentType.label,
      areaLevel2Other,
      eventDiscountCode,
      eventDiscountCodeName,
      inscriptionFormId: activeInscriptionForm.id,
      customFieldValues: customFields.map((e) => ({
        eventCustomFieldId: e.id,
        fieldValue: e.type === 'List' ? e.value.label : e.value
      }))
    }

    const saleOrder = {
      totalAmount: inscriptionPrice,
      items: [
        {
          ticketTypeId: ticketType.id,
          itemNumber: 1,
          quantity: 1,
          unitPrice: inscriptionPrice
        }
      ]
    }

    if (eventDiscountCode) {
      const finalPrice =
        paymentType.value === PAYMENT_TYPES.MercadoPago
          ? getFinalPriceMercadoPago(
              getPriceWithDiscount(getPriceForOrganizer(ticketType), eventDiscountCode),
              calculatePercentage(ticketType.mercadoPagoFee),
              ticketType.sportmetricFeePercentage,
              ticketType.sportmetricFeeAmount,
              !!eventDiscountCode
            )
          : getPriceWithDiscount(inscriptionPrice, eventDiscountCode)
      const newEventDiscountCode = {
        ...eventDiscountCode,
        eventDiscountCodeName,
        quantity: 1,
        itemNumber: 2
      }

      saleOrder.items.push(newEventDiscountCode)
      saleOrder.totalAmount = finalPrice

      if (finalPrice === TICKET_FREE_PRICE) {
        newInscription.paymentType = null
        newInscription.paymentTypeName = null
      }
    }
    setInscription({ ...newInscription, saleOrder })
    setOpenConfirmInscriptionDialog(true)
  }

  const handleConfirmInscription = async () => {
    if (!openConfirmInscriptionDialog) return

    setOpenConfirmInscriptionDialog(false)

    const eventInscriptionData = await dispatch(eventInscription(inscription))
    if (!eventInscriptionData) return

    await dispatch(loadEventInscription(eventInscriptionData.id))

    if (eventInscriptionData.paymentType === PAYMENT_TYPES.MercadoPago)
      handleMercadoPagoPayment(eventInscriptionData.saleOrder)
    else if (eventInscriptionData.state === EVENT_INSCRIPTION_STATES.Pending)
      setOpenSuccessInscriptionPendingDialog(true)
    else if (eventInscriptionData.state === EVENT_INSCRIPTION_STATES.Accepted)
      setOpenSuccessInscriptionDialog(true)

    dispatch(loadListInscriptions(event.id))
    dispatch(loadListInscriptionSearchMetadata(event.id))

    onClose()
  }

  const handleMercadoPagoPayment = async (values) => {
    const proxy = new PaymentGatewayProxy()
    const data = await proxy.postMercadoPagoPayment(values)
    return (window.location.href = data)
  }

  useEffect(() => {
    if (event.activeInscriptionFormId) {
      dispatch(loadActiveInscriptionForm(event))
    }
  }, [event, dispatch])

  return (
    <>
      <LayoutDialog title={event.name} open={open} onClose={onClose} className={classes.dialog}>
        {(scrolling) => (
          <div className={classes.bodyContainer}>
            {activeInscriptionFormPending ? (
              <CircularProgress size={30} color='primary' />
            ) : (
              <InscriptionForm
                user={user}
                scrolling={scrolling}
                userData={userData}
                formData={formData}
                onBack={onClose}
                mode='create'
                isAdmin={true}
                onSubmit={onSubmit}
              />
            )}
          </div>
        )}
      </LayoutDialog>
      <ConfirmInscriptionDialog
        data={inscription}
        title={
          !!inscription.paymentType && inscription.paymentType !== PAYMENT_TYPES.MercadoPago
            ? 'Confirmá tu preinscripción'
            : 'Confirmá tu inscripción'
        }
        open={openConfirmInscriptionDialog}
        onConfirm={handleConfirmInscription}
        formData={formData}
        handleOnClose={() => setOpenConfirmInscriptionDialog(false)}
      />
      <SuccessInscriptionDialog
        open={openSuccessInscriptionDialog}
        onClose={() => setOpenSuccessInscriptionDialog(false)}
      />
      <SuccessInscriptionPendingDialog
        open={openSuccessInscriptionPendingDialog}
        onClose={() => setOpenSuccessInscriptionPendingDialog(false)}
      />
    </>
  )
}

export default InscriptionOffLineDialog
