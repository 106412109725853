import React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import moment from 'moment'
import { CustomizedTooltip, EditableSection } from 'shared'
import * as Yup from 'yup'

import { ScheduleDateItem } from '../ScheduleDateItem'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ScheduleSection.style'

const DATE_FORMATS = ['YYYY-MM-DD', 'DD MMM YY', 'DD MMM', 'DD']

const validationScheduleItem = Yup.object().shape({
  descriptionText: Yup.string().nullable().max(3000, 'Se puede ingresar hasta 3000 caracteres')
})

const ScheduleSection = ({ event, canEdit, onSave, adminMode }) => {
  const classes = useStyles()

  const fromLocal = moment(event.from)
  const toLocal = moment(event.to)

  const fromDateTime = fromLocal.format('DD/MM/YYYY HH:mm')
  const toDateTime = toLocal.format('DD/MM/YYYY HH:mm')

  const scheduleItemCount = toLocal.add(1, 'day').diff(fromLocal, 'day')

  const scheduleDefaultOptions = new Array(scheduleItemCount).fill({
    title: '',
    description: '',
    descriptionText: '',
    scheduleDate: null
  })

  const scheduleOptions = scheduleDefaultOptions.map((s, index) => {
    const scheduleDate = moment(fromLocal.format(DATE_FORMATS[0])).add(index, 'day')
    const scheduleState = event.schedule.find((x) => moment(x.scheduleDate).isSame(scheduleDate))

    return {
      ...s,
      ...scheduleState,
      title: `Dia ${index + 1} - ${scheduleDate.format('D [de] MMMM')}`,
      scheduleDate
    }
  })

  const onSaveSchedule = async ({ value }) => {
    if (!value.description) return true

    const scheduleItemModel = {
      ...value,
      scheduleDate: value.scheduleDate.format(DATE_FORMATS[0])
    }

    const scheduleExclude = event.schedule.filter(
      (x) => !moment(x.scheduleDate).isSame(value.scheduleDate)
    )

    await onSave({ value: { schedule: [...scheduleExclude, scheduleItemModel] } })
    return true
  }

  return (
    <SectionBlock title='Cronograma'>
      <div className={classes.scheduleSection}>
        {!adminMode && (
          <div className={classes.container}>
            <Typography className={classes.dateLabel} variant='h6'>
              El evento inicia:&nbsp;
              {fromDateTime}hs
            </Typography>

            <Typography className={classes.dateLabel} variant='h6'>
              El evento finaliza:&nbsp;
              {toDateTime}hs
            </Typography>
          </div>
        )}

        <div className={classes.scheduleContainer}>
          {scheduleOptions
            .filter((x) => adminMode || x.description)
            .map((o, index) => (
              <EditableSection
                key={index}
                entity={o}
                canEdit={canEdit}
                onSave={onSaveSchedule}
                validationSchema={validationScheduleItem}>
                {({
                  handleStatusSection,
                  activeEdit,
                  setActiveEdit,
                  saveLoading,
                  canEdit,
                  enableSection
                }) => (
                  <ScheduleDateItem
                    eventId={event.id}
                    enableSection={enableSection}
                    activeEdit={activeEdit}
                    canEdit={canEdit}
                    saveLoading={saveLoading}
                    disabled={event.isSuspended}
                    handleStatusSection={handleStatusSection}
                    setActiveSectionEdit={setActiveEdit}
                  />
                )}
              </EditableSection>
            ))}

          <div className={classes.anchorContainer}>
            <Typography color='primary' variant='h6' className={classes.link}>
              + Añadir día previo al evento.
            </Typography>

            <CustomizedTooltip
              title='Añádelo, por ejemplo, si la acreditación o la prueba del circuito 
no se realizan en los días en que tiene lugar el evento.'
              position='right'
              className={classes.tooltip}
              disabledClickAway>
              {({ handleTooltip }) => (
                <IconButton onClick={handleTooltip} size='small' className={classes.button}>
                  <InfoIcon color='primary' />
                </IconButton>
              )}
            </CustomizedTooltip>
          </div>
        </div>
      </div>
    </SectionBlock>
  )
}

export default ScheduleSection
