import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialogServerLetter: {
    width: 900,
    maxWidth: 900
  },
  dialog: {
    width: 600,
    maxWidth: 660,
    height: 10,
    padding: 0
  },
  fileMainContainer: {
    '&::-webkit-scrollbar': {
      width: 12,
      [theme.breakpoints.up('md')]: {
        width: 16
      }
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',

      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.utils.rgba(theme.palette.primary.main, 0.45)
    },
    '& > div': {
      width: '100%'
    }
  },
  label: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500,
    color: `${theme.palette.primary.main} !important`,
    marginLeft: 20,
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      width: 500
    }
  },
  labelForm: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500,
    color: `${theme.palette.primary.main} !important`,
    marginBottom: 30,
    [theme.breakpoints.up('md')]: {
      width: 550,
      fontSize: 18
    }
  },
  input: {
    margin: 0,
    marginBottom: theme.spacing(3),

    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    }
  },
  inputAddress: {
    marginBottom: theme.spacing(1),
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3)
    }
  },
  formGroup: {
    width: '100%',
    padding: theme.spacing(0, 2.25)
  },
  formControlLabel: {
    width: 150,
    margin: '0 auto'
  },
  button: {
    width: 247,
    height: 47,
    alignSelf: 'center',
    marginTop: theme.spacing(7),
    borderRadius: 10,
    '& span': {
      fontWeight: 500,
      fontSize: '1.125rem'
    }
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 350,
    margin: '0 auto'
  },
  buttonContainerSave: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& button': {
      borderRadius: 10,
      width: 247,
      height: 47,
      '& span': {
        fontWeight: 500,
        fontSize: '1.125rem'
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  createButtonFirst: {
    borderRadius: 10,
    width: 247,
    height: 47,
    margin: theme.spacing(7, 0),
    '& span': {
      marginLeft: 20
    }
  },
  createButton: {
    borderRadius: 10,
    width: 247,
    height: 47,
    margin: theme.spacing(4, 0),
    [theme.breakpoints.up('md')]: {
      marginLeft: 80
    }
  },
  tooltip: {
    width: 300,
    backgroundColor: theme.palette.tooltip.main
  },
  tooltipArrow: {
    color: theme.palette.tooltip.main
  },
  buttonInfoIcon: {
    color: '#FF9D29',
    zIndex: 30,
    marginLeft: -theme.spacing(1.5)
  },
  arrow: {
    color: '#FF9D29'
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 16,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginLeft: 20
    }
  },
  tooltipButton: {
    right: '5px !important',
    top: '3px !important'
  },
  tooltipTitle: {
    fontSize: '.75rem'
  },
  infoIcon: {
    width: 23,
    height: 23
  },
  fileViewer: {
    overflowY: 'auto',
    '& .bPJfhN ': {
      overflowY: 'hidden'
    },
    '& #pdf-download': {
      display: 'none'
    },
    height: '100%'
  }
}))
