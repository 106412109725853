import React, { useMemo, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Phone as PhoneIcon } from '@material-ui/icons'
import { ReactComponent as WhatsAppIcon } from 'assets/icons/whatsApp-icon.svg'
import clsx from 'clsx'
import { EditableSection, EditableText, StyledSwitch } from 'shared'
import * as Yup from 'yup'

import { InfoContactDialog } from '../InfoContactDialog'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ContactSection.style'

const ContactSection = ({ event, canEdit, isAdmin, ...sectionProps }) => {
  const classes = useStyles()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [openInfoDialog, setOpenInfoDialog] = useState(false)

  const { hasWhatsApp, hasResults, resultsUrl, phone, name, organization, isSuspended } = event
  const TelIcon = hasWhatsApp ? WhatsAppIcon : PhoneIcon
  const contactUrl = hasWhatsApp
    ? `https://wa.me/${phone}?text=¡Hola!%20quisiera%20mas%20información%20sobre%20el%20evento%20${name}`
    : `tel:+${phone}`

  const handleRedirectContact = (e) => {
    if (hasWhatsApp || !isDesktop) {
      window.open(contactUrl, '_blank')
    } else {
      setOpenInfoDialog(true)
    }
  }

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .nullable()
      .required('Debe ingresar un número de teléfono')
      .min(5, 'El número de teléfono debe tener al menos 5 caracteres')
      .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
      .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'El número de telefono ingresado no es válido')
  })

  const handleChangeUseOrganizationPhoneCheck = (setValues) => (e) =>
    setValues((values) => {
      const checkValue = e.target.checked

      return {
        ...values,
        useOrganizationPhone: checkValue,
        phone: checkValue ? organization?.phone || values.phone : values.phone,
        hasWhatsApp: checkValue || values.hasWhatsApp,
        hasExternalChange: { state: true }
      }
    })

  const values = useMemo(
    () => ({
      ...event,
      useOrganizationPhone: event.phone === event.organization.phone
    }),
    [event]
  )

  return (
    <EditableSection
      entity={values}
      canEdit={canEdit}
      validationSchema={validationSchema}
      {...sectionProps}>
      {({
        activeEdit,
        values,
        errors,
        handleChange,
        handleBlur,
        saveLoading,
        isValid,
        handleSubmit,
        setActiveEdit,
        setValues
      }) => (
        <SectionBlock
          title={isAdmin && 'Teléfono de contacto'}
          className={clsx(classes.eventSection, hasResults && resultsUrl && 'results')}
          hiddenSection={!canEdit && !event.phone}>
          <div className={classes.phoneForm}>
            <EditableText
              entity={{ ...values, phone: values.phone }}
              field='phone'
              fullWidth
              type='text'
              activeEditSection={activeEdit}
              handleBlur={handleBlur}
              errors={errors.phone}
              labelClassName={classes.label}
              inputMode='tel'
              disabled={values.useOrganizationPhone || isSuspended}
              externalChange={values.hasExternalChange}
              inputClassName={classes.eventinputLink}
              onChange={(e) =>
                setValues((values) => ({
                  ...values,
                  phone: e.target.value,
                  hasExternalChange: false
                }))
              }>
              {!!values.phone &&
                (isAdmin ? (
                  <div className={classes.phoneContainer}>
                    <Typography className={classes.phone}>{values.phone}</Typography>

                    <TelIcon color='primary' className={classes.icon} />
                  </div>
                ) : (
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.contactButton}
                    onClick={handleRedirectContact}>
                    Contactanos
                  </Button>
                ))}
            </EditableText>

            {activeEdit && (
              <>
                <div className={classes.phoneContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={values.useOrganizationPhone}
                        className={classes.checkbox}
                        onChange={handleChangeUseOrganizationPhoneCheck(setValues)}
                        name='useOrganizationPhone'
                        onBlur={handleBlur}
                      />
                    }
                    label={
                      <Typography variant='h6' color='primary' className={classes.checkMiniLabel}>
                        Es el mismo N° de teléfono que el de la organización.
                      </Typography>
                    }
                  />
                </div>

                <div className={classes.whatsAppContainer}>
                  <StyledSwitch
                    checked={values.hasWhatsApp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name='hasWhatsApp'
                  />
                  <Typography variant='h6' className={classes.whatsAppTitle}>
                    Tiene WhatsApp
                  </Typography>
                </div>
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={saveLoading || !isValid}
                  endIcon={saveLoading && <CircularProgress size={16} color='primary' />}
                  onClick={handleSubmit}
                  className={classes.saveButton}>
                  Guardar
                </Button>
              </>
            )}

            {!activeEdit && !event.phone && (
              <Typography
                color='primary'
                variant='h6'
                className={clsx(classes.link, isSuspended && 'disabled')}
                onClick={() => setActiveEdit(true)}>
                + Añadir teléfono
              </Typography>
            )}
          </div>

          <InfoContactDialog
            openDialog={openInfoDialog}
            event={event}
            onClose={() => setOpenInfoDialog(false)}
          />
        </SectionBlock>
      )}
    </EditableSection>
  )
}

export default ContactSection
