import React, { useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core'
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { EventsProxy } from 'services'
import { CustomizedTooltip, CustomTextField } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { DISCOUNT_TYPES, PAYMENT_TYPES, PRICE_FORMAT } from 'utils/constants'
import { calculatePercentage, getFeeAmount } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { PaymentLocationDialog } from '../PaymentLocationDialog'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './PaymentMethodSection.style'

const PAYMENT_TYPE_STATES = {
  MercadoPago: {
    value: PAYMENT_TYPES.MercadoPago,
    title:
      'Al finalizar, te redirigiremos a la plataforma de Mercado Pago para completar el pago de tu inscripción (no es necesario tener cuenta en Mercado Pago).'
  },
  BankAccount: {
    value: PAYMENT_TYPES.BankAccount,
    title:
      'Para hacer efectiva la inscripción deberás subir el comprobante de pago en el perfil del evento.'
  },
  PaymentLocation: {
    value: PAYMENT_TYPES.PaymentLocation,
    title:
      'Para hacer efectiva la inscripción deberás realizar el pago en los puntos habilitados y subir el comprobante en el perfil del evento.'
  },
  PaymentUrl: {
    value: PAYMENT_TYPES.PaymentUrl,
    title:
      'Para hacer efectiva la inscripción deberás subir el comprobante de pago en el perfil del evento.'
  }
}

const TICKET_FREE_PRICE = 0

const PaymentMethodSection = ({ autoHidden, viewAdmin, formData }) => {
  const classes = useStyles()
  const { values, setFieldValue, errors, handleBlur, handleChange, touched, setValues } =
    useFormikContext()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { event } = useSelector((state) => state.events)

  const [openPaymentLocationDialog, setOpenPaymentLocationDialog] = useState(false)
  const [availibleDiscountCodePending, setAvailibleDiscountCodePending] = useState(false)
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false)

  const hoursPattern = /^\d+$/

  const getPriceForOrganizer = (ticketType) => {
    return ticketType.price === ticketType.mercadoPagoPrice
      ? ticketType.price -
          Number(getFeeAmount(ticketType.price, Number(ticketType.mercadoPagoFee))) -
          ticketType.sportmetricFeeAmount
      : ticketType.price
  }

  const getFinalPriceMercadoPago = (
    priceWithDiscount,
    mercadoPagoFeePercentage,
    sportmetricPercentage,
    sportmetricFeeAmount,
    hasDiscount
  ) => {
    const price = hasDiscount ? priceWithDiscount + sportmetricFeeAmount : priceWithDiscount

    return Number(
      (
        -Number(price) /
        (calculatePercentage(!hasDiscount ? sportmetricPercentage : 0) / 100 +
          mercadoPagoFeePercentage / 100 -
          1)
      ).toFixed(2)
    )
  }

  const getPriceWithDiscount = (price, discountCode) => {
    if (!discountCode) return Number(price)

    if (discountCode.discountType === DISCOUNT_TYPES.PERCENTAGE)
      return Number(price) - (Number(price) * discountCode.percentage) / 100

    if (discountCode.discountType === DISCOUNT_TYPES.FIXED_AMOUNT) {
      const finalPrice = Number(price) - Number(discountCode.fixedAmount)
      return finalPrice > 0 ? finalPrice : TICKET_FREE_PRICE
    }
  }

  const timeFormat = (time) => {
    if (!time) return null

    const { 0: hours, 1: minutes } = time.split(':')

    if (hoursPattern.test(hours)) return `${hours}:${minutes}`

    const numberHours = Number(hours)
    const days = Math.floor(numberHours)
    const restHours = numberHours % 1
    const totalHours = days * 24 + Math.round(restHours * 100)

    return `${totalHours}:${minutes}`
  }

  const postAvailableEventDiscountCode = async (code) => {
    try {
      setAvailibleDiscountCodePending(true)

      const proxy = new EventsProxy()
      const discountCode = await proxy.postAvailableEventDiscountCode(event.id, code)

      return discountCode
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setAvailibleDiscountCodePending(false)
    }
  }

  const handleAplyDiscountCode = async () => {
    const discountCode = await postAvailableEventDiscountCode(values.discountCodeName)
    if (discountCode) {
      setFieldValue('discountCode', discountCode)
    }
  }

  const handleDeleteDiscountCode = () => {
    setShowDiscountCodeInput(false)
    setValues((values) => ({
      ...values,
      discountCode: null,
      discountCodeName: ''
    }))
  }

  const searchPaymentType = async (searchText) => {
    if (Array.isArray(values.activePaymentMethods)) {
      return values.activePaymentMethods.filter((x) =>
        x.label.toLowerCase().includes(searchText.toLowerCase())
      )
    }
  }

  const ticketPrice =
    values.paymentType.value === PAYMENT_TYPE_STATES.MercadoPago.value
      ? values.ticketType.mercadoPagoPrice
      : values.ticketType.price

  const finalPrice =
    values.paymentType.value === PAYMENT_TYPE_STATES.MercadoPago.value
      ? getFinalPriceMercadoPago(
          getPriceWithDiscount(getPriceForOrganizer(values.ticketType), values.discountCode),
          calculatePercentage(values.ticketType.mercadoPagoFee),
          values.ticketType.sportmetricFeePercentage,
          values.ticketType.sportmetricFeeAmount,
          !!values.discountCode
        )
      : getPriceWithDiscount(ticketPrice, values.discountCode)

  const ticketIsFree = finalPrice === TICKET_FREE_PRICE

  const showPaymentType = !!values.paymentType && ticketIsFree !== TICKET_FREE_PRICE

  const showPaymentMethodsSelect =
    !formData.isFree && (ticketIsFree || values.activePaymentMethods.length > 1)

  const renderDiscountContent = () =>
    values.discountCode ? (
      <Button
        color='primary'
        variant='outlined'
        className={classes.apllyCodeButton}
        onClick={handleDeleteDiscountCode}>
        Eliminar descuento
      </Button>
    ) : (
      <div className={clsx(classes.discountCodeFormGroup, viewAdmin && 'admin')}>
        <CustomTextField
          autoFocus
          variant='outlined'
          color='primary'
          name='discountCodeName'
          value={values.discountCodeName}
          className={classes.textField}
          onBlur={handleBlur}
          error={touched.discountCodeName && Boolean(errors.discountCodeName)}
          helperText={errors.discountCodeName || 'Ingrese el código de descuento'}
          onChange={(e) => {
            const { value } = e.target
            e.target.value = value?.toUpperCase()

            handleChange(e)
          }}
        />
        <Button
          color='primary'
          variant='outlined'
          className={classes.apllyCodeButton}
          disabled={
            !!errors.discountCodeName || !values.discountCodeName || availibleDiscountCodePending
          }
          endIcon={availibleDiscountCodePending && <CircularProgress size={16} color='primary' />}
          onClick={handleAplyDiscountCode}>
          Aplicar descuento
        </Button>
      </div>
    )

  const informationPayment = (paymentType) => {
    const handleOnClose = () => setOpenPaymentLocationDialog(false)
    const handleOpen = () => setOpenPaymentLocationDialog(true)

    return (
      <div className={classes.infoContainer}>
        {formData.isFree ? (
          <Typography className={classes.label}>Tu inscripción es sin costo</Typography>
        ) : finalPrice === TICKET_FREE_PRICE ? (
          <Typography className={classes.label}>El precio de tu inscripción es de $0</Typography>
        ) : (
          <>
            {paymentType.value === PAYMENT_TYPE_STATES.MercadoPago.value && (
              <CustomizedTooltip
                position={!isDesktop ? 'top-start' : 'right'}
                activeTooltip={values.paymentType}
                title={PAYMENT_TYPE_STATES[paymentType.value].title}
                className={classes.tooltip}
                defaultState
                isTopMost={viewAdmin}
                disabledClickAway
                arrowClassName={classes.arrowTooltip}
                buttonClassName={classes.tooltipButton}
                autoHide={autoHidden}>
                <Typography className={classes.label}>
                  El precio de tu inscripción es de&nbsp;
                  {numericFormatter(Number(finalPrice).toString(), {
                    ...PRICE_FORMAT,
                    fixedDecimalScale: !Number.isInteger(finalPrice)
                  })}
                </Typography>
              </CustomizedTooltip>
            )}

            {paymentType.value === PAYMENT_TYPE_STATES.BankAccount.value && (
              <CustomizedTooltip
                position={!isDesktop ? 'top-start' : 'right'}
                activeTooltip={values.paymentType}
                title={PAYMENT_TYPE_STATES[paymentType.value].title}
                className={classes.tooltip}
                defaultState
                disabledClickAway
                isTopMost={viewAdmin}
                arrowClassName={classes.arrowTooltip}
                buttonClassName={classes.tooltipButton}
                autoHide={autoHidden}>
                <Typography className={classes.label}>
                  El precio de tu inscripción es de&nbsp;
                  {numericFormatter(Number(finalPrice).toString(), {
                    ...PRICE_FORMAT,
                    fixedDecimalScale: !Number.isInteger(finalPrice)
                  })}
                </Typography>
              </CustomizedTooltip>
            )}

            {paymentType.value === PAYMENT_TYPE_STATES.PaymentLocation.value && (
              <>
                <CustomizedTooltip
                  position={!isDesktop ? 'top-start' : 'right'}
                  activeTooltip={values.paymentType}
                  title={PAYMENT_TYPE_STATES[paymentType.value].title}
                  className={classes.tooltip}
                  defaultState
                  disabledClickAway
                  isTopMost={viewAdmin}
                  buttonClassName={classes.tooltipButton}
                  arrowClassName={classes.arrowTooltip}
                  autoHide={autoHidden}>
                  <Typography className={classes.label}>
                    El precio de tu inscripción es de&nbsp;
                    {numericFormatter(Number(finalPrice).toString(), {
                      ...PRICE_FORMAT,
                      fixedDecimalScale: !Number.isInteger(finalPrice)
                    })}
                  </Typography>
                </CustomizedTooltip>
                <Typography
                  color='primary'
                  variant='h6'
                  className={classes.paymentLocationInfoLink}
                  onClick={handleOpen}>
                  Consultar lugares de pago
                </Typography>

                <PaymentLocationDialog open={openPaymentLocationDialog} onClose={handleOnClose} />
              </>
            )}

            {paymentType.value === PAYMENT_TYPE_STATES.PaymentUrl.value && (
              <>
                <CustomizedTooltip
                  position={!isDesktop ? 'top-start' : 'right'}
                  activeTooltip={values.paymentType}
                  title={PAYMENT_TYPE_STATES[paymentType.value].title}
                  className={classes.tooltip}
                  defaultState
                  disabledClickAway
                  isTopMost={viewAdmin}
                  arrowClassName={classes.arrowTooltip}
                  buttonClassName={classes.tooltipButton}
                  autoHide={autoHidden}>
                  <Typography className={classes.label}>
                    El precio de tu inscripción es de&nbsp;
                    {numericFormatter(Number(finalPrice).toString(), {
                      ...PRICE_FORMAT,
                      fixedDecimalScale: !Number.isInteger(finalPrice)
                    })}
                  </Typography>
                </CustomizedTooltip>
                <Typography variant='h6' className={classes.paymentUrlInfo}>
                  Una vez enviado el formulario te compartiremos el link de pago&nbsp;
                  {!!formData.paymentConfirmationPeriod &&
                    `y tenés ${timeFormat(formData.paymentConfirmationPeriod)?.split(':')[0]}hs para
                      subir el comprobante de pago, \n caso contrario tu preinscripción será
                      dada de baja.`}
                </Typography>
              </>
            )}
          </>
        )}

        {!!paymentType &&
          paymentType.value !== PAYMENT_TYPE_STATES.MercadoPago.value &&
          paymentType.value !== PAYMENT_TYPE_STATES.PaymentUrl.value &&
          !!formData.paymentConfirmationPeriod && (
            <Typography className={classes.label}>
              Una vez enviado el formulario tenés&nbsp;
              {timeFormat(formData.paymentConfirmationPeriod)?.split(':')[0]}hs para subir el
              comprobante de pago, caso contrario tu preinscripción será dada de baja.
            </Typography>
          )}
      </div>
    )
  }

  return (
    <SectionBlock
      title='Modalidad de pago'
      className={clsx(classes.container)}
      contentClassName={clsx(ticketIsFree && classes.disabledSection)}
      body={
        showPaymentMethodsSelect && 'Selecciona la modalidad de pago con la cual deseas pagar:'
      }>
      {showPaymentMethodsSelect ? (
        <div className={classes.selectContainer}>
          <StyledSelect
            colorOptions='secondary'
            className={clsx(classes.select, ticketIsFree && 'disabled')}
            isDisabled={ticketIsFree}
            value={!ticketIsFree ? values.paymentType : ''}
            onChange={(option) => setFieldValue('paymentType', option)}
            placeholder='Selecciona una opción'
            defaultOptions={values.activePaymentMethods}
            loadOptions={searchPaymentType}
          />
          {values.paymentType.value === PAYMENT_TYPE_STATES.MercadoPago.value && (
            <Typography color='primary' variant='caption' className={classes.helperText}>
              *El pago puede demorar hasta una hora en impactar en el estado de tu inscripción.
            </Typography>
          )}
        </div>
      ) : (
        showPaymentType && (
          <Typography className={clsx(classes.label, classes.orderPayment)}>
            {values.paymentType.title}.&nbsp;
            {values.paymentType.value === PAYMENT_TYPE_STATES.MercadoPago.value && (
              <Typography color='primary' variant='caption' className={classes.helperText}>
                *El pago puede demorar hasta una hora en impactar en el estado de tu inscripción.
              </Typography>
            )}
          </Typography>
        )
      )}

      {errors.paymentType && (
        <Typography color='error' variant='caption'>
          {errors.paymentType}
        </Typography>
      )}

      {showDiscountCodeInput && values.discountCode && (
        <div className={classes.discountCodeBlock}>
          <Typography variant='h6' color='primary' className={classes.discountText}>
            El descuento aplicado es{' '}
            {values.discountCode.discountType === DISCOUNT_TYPES.FIXED_AMOUNT
              ? `de ${numericFormatter(
                  Number(
                    values.discountCode.fixedAmount > values.ticketType.price
                      ? values.ticketType.price
                      : values.discountCode.fixedAmount
                  ).toString(),
                  PRICE_FORMAT
                )}`
              : `del ${values.discountCode.percentage}%`}
          </Typography>
        </div>
      )}

      {informationPayment(values.paymentType)}

      {!formData.isFree && (
        <Typography className={classes.tasHelperText}>*Incluye tasa de servicio.</Typography>
      )}

      {!!values.ticketType &&
        ticketPrice !== TICKET_FREE_PRICE &&
        values.paymentType &&
        event.hasEventDiscounts && (
          <div className={classes.discountCodeContainer}>
            {showDiscountCodeInput ? (
              renderDiscountContent()
            ) : (
              <Button
                className={classes.content}
                color='primary'
                variant='contained'
                endIcon={<KeyboardArrowRightIcon color='secondary' />}
                onClick={() => setShowDiscountCodeInput(true)}>
                <Typography variant='h6'>Agregar código de descuento</Typography>
              </Button>
            )}
          </div>
        )}
    </SectionBlock>
  )
}

export default PaymentMethodSection
