import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'
import { FEE_GENERAL, FEE_OFFLINE_NAME, FEE_TYPES } from 'utils/constants'

import { useStyles } from './UpdateServiceLetterInfoDialog.style'

const UpdateServiceLetterInfoDialog = ({ open, onClose, onAccept = () => {} }) => {
  const classes = useStyles()

  const { ticketTypeFees } = useSelector((state) => state.events)

  const sportmetricFeeSorted = ticketTypeFees
    ?.filter((x) => x.feeType === FEE_TYPES.Sportmetric)
    .sort((a, b) => moment(a.createdAt).isAfter(b.createdAt))

  const sportmetricOfflineFee = sportmetricFeeSorted?.find(
    (x) => x.name === FEE_OFFLINE_NAME && !x.expiredAt
  )
  const sportmetricCurrentFee = sportmetricFeeSorted?.find(
    (x) => x.name === FEE_GENERAL && !x.expiredAt
  )

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='Actualización de carta de servicio'
      className={classes.dialog}>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <Typography color='primary' variant='h6'>
            Hemos actualizado el valor de nuestra tasa de servicio
          </Typography>
          <Typography color='primary' variant='h6'>
            A partir de ahora para inscripciones online es del{' '}
            {sportmetricCurrentFee?.feePercentage}% + IVA, y para pagos offline{' '}
            {sportmetricOfflineFee?.feePercentage}% + IVA.
          </Typography>
          <Typography color='primary' variant='h6'>
            Para poder continuar debes aceptar este cambio (esto actualizará la carta de servicio*
            que ya firmaste).
          </Typography>

          <Typography color='primary' variant='h6' className={classes.infoText}>
            *Podés acceder a ella desde el perfil de la organización.
          </Typography>
        </div>

        <Button
          color='primary'
          variant='contained'
          className={classes.acceptButton}
          onClick={onAccept}>
          Aceptar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default UpdateServiceLetterInfoDialog
