import React from 'react'
import { Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './ReportProblemDialog.style'

const ReportProblemDialog = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      title='REPORTE DE PROBLEMA'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <div className={classes.container}>
        <Typography variant='h6' color='primary' className={classes.title}>
          Tu reporte se ha enviado con éxito, analizaremos tu caso y te contactaremos a la brevedad
          por el medio elegido.
        </Typography>
      </div>
    </SectionLayoutDialog>
  )
}

export default ReportProblemDialog
