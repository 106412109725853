import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { CONTACT_TYPES } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ContactSection.style'

const CONTACT_MEANS = [CONTACT_TYPES.WHATSAPP, CONTACT_TYPES.EMAIL]

const ContactSection = () => {
  const classes = useStyles()

  const { values, handleBlur, handleChange, errors, touched } = useFormikContext()

  return (
    <SectionBlock title='Seleccioná el medio de contacto de tu preferencia'>
      <div className={classes.block}>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='select-outlined-label' className={classes.labelSelect}>
            Seleccione medio de contacto
          </InputLabel>
          <Select
            value={values.contact}
            onBlur={handleBlur}
            onChange={handleChange}
            name='contact'
            MenuProps={{
              PaperProps: {
                className: classes.menuContainer
              }
            }}
            className={classes.select}>
            {CONTACT_MEANS.map((x) => (
              <MenuItem
                value={x}
                key={x}
                className={classes.item}
                classes={{
                  selected: classes.itemSelected
                }}>
                {x}
              </MenuItem>
            ))}
          </Select>

          <Typography variant='caption' className={classes.helperText}>
            {errors.contact}
          </Typography>
        </FormControl>

        {values.contact === CONTACT_TYPES.WHATSAPP && (
          <TextField
            name='phoneContact'
            value={values.phoneContact}
            className={classes.input}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete='off'
            variant='outlined'
            autoFocus
            required
            size='small'
            error={touched.phoneContact && Boolean(errors.phoneContact)}
            helperText={errors.phoneContact}
            label='Número de teléfono'
          />
        )}

        {values.contact === CONTACT_TYPES.EMAIL && (
          <TextField
            name='eMail'
            value={values.eMail}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
            error={touched.eMail && Boolean(errors.eMail)}
            required
            helperText={errors.eMail}
            variant='outlined'
            size='small'
            className={classes.input}
            label='E-mail de contacto'
          />
        )}
      </div>
    </SectionBlock>
  )
}

export default ContactSection
