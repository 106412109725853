import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  InputAdornment
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon, Info as InfoIcon } from '@material-ui/icons'
import { ReactComponent as TriangleExclaimationIcon } from 'assets/icons/triangle-exclaimation-icon.svg'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

import { CountriesProxy } from 'services'
import {
  CustomizedTooltip,
  CustomTextField,
  LinkifyText,
  PrivacyPolicyContent,
  TermsAndConditionsContent,
  FormNotifyDialog
} from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { TermsAndConditionsDialog } from 'shared/TermsAndConditionsDialog'
import {
  filterAndSortCategories,
  filterCities,
  filterProvinces,
  getCountries
} from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import { downloadRulesDocument } from 'state/modules/events'
import { CustomField, SectionBlock, PaymentMethodSection, TicketSection } from './components'
import { useStyles } from './InscriptionForm.style'
import { STRING_EMPTY } from 'utils/constants'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }

const OTHER_OPTION = { label: 'Otra', value: 'other' }

const genderList = [
  {
    label: 'Femenino',
    value: 'Female'
  },
  { label: 'Masculino', value: 'Male' },
  { label: 'No binario', value: 'NonBinary' }
]

const FORM_STATE = {
  read: 'read',
  create: 'create'
}

const ORDER_PAYMENT = {
  MERCADO_PAGO: { value: 'MercadoPago', label: 'Mercado Pago', title: 'Mercado Pago' },
  PAYMENT_LOCATION: {
    value: 'PaymentLocation',
    label: 'Pago en efectivo',
    title: 'Pago en efectivo'
  },
  BANK_ACCOUNT: { value: 'BankAccount', label: 'Transferencia', title: 'Pago con transferencia' },
  PAYMENT_URL: { value: 'PaymentUrl', label: 'Link de pago', title: 'Link de pago' }
}

const TICKET_FREE_PRICE = 0
const TICKET_QUOTA_UNLIMITED = 0

const InscriptionForm = ({
  onSubmit,
  mode = FORM_STATE.read,
  formData,
  userData,
  onBack,
  isAdmin = false,
  eventInscription,
  scrolling
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { eventInscriptionId } = useParams()
  const { event, eventInscriptionPending, downloadRulesDocumentPending } = useSelector(
    (state) => state.events
  )

  const [activeLoadEventInscription, setActiveLoadEventInscription] = useState(!!eventInscriptionId)
  const [openNotifyErrorsDialog, setOpenNotifyErrorsDialog] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [categories, setCategories] = useState([])
  const [openTerms, setOpenTerms] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)

  const [checked, setChecked] = useState(
    (!isAdmin && !event.loggedUserMetadata.hasActiveInscription) || mode === FORM_STATE.read
  )

  useEffect(() => {
    if (eventInscription) setChecked(!eventInscription.isThirdParty)
  }, [eventInscription])

  const placeholder =
    checked && userData
      ? {
          name: 'Ingresá tu nombre como figura en el DNI',
          lastName: 'Ingresá tu apellido como figura en el DNI'
        }
      : {
          name: 'Ingresá el nombre como figura en el DNI',
          lastName: 'Ingresá el apellido como figura en el DNI'
        }

  const showNonBinaryOption = formData.items.find(
    ({ eventCategory }) => eventCategory.gender === 'Other' || eventCategory.gender === 'NonBinary'
  )

  const formErrorDictionary = {
    firstName: { label: 'Nombre', order: 1 },
    lastName: { label: 'Apellido', order: 2 },
    idNumber: { label: 'N° de DNI', order: 3 },
    gender: { label: 'Género', order: 4 },
    birthdate: { label: 'Fecha de nacimiento', order: 5 },
    country: { label: 'País', order: 6 },
    areaLevel1: { label: 'Provincia', order: 7 },
    areaLevel2Option: { label: 'Ciudad', order: 8 },
    eMail: { label: 'E-mail', order: 9 },
    phone: { label: 'Teléfono', order: 10 },
    distance: { label: 'Distancia', order: 11 },
    category: { label: 'Categoría', order: 12 },
    ticketType: { label: 'Ticket', order: 13 },
    paymentType: { label: 'Modalidad de pago', order: 14 },

    ...formData.customFields
      ?.filter((c) => !c.isPrivate)
      .reduce(
        (acc, e, index) => ({
          ...acc,
          [`customFields[${index}]`]: { label: e.name, order: 14 + index }
        }),
        {}
      ),

    termsAndConditions: {
      label: 'Términos y condiciones',
      order: 14 + formData.customFields?.length
    }
  }

  const resetValues = useCallback(() => {
    if (eventInscription?.countryId && activeLoadEventInscription) {
      onCountryChange()({ value: eventInscription.countryId, label: eventInscription.countryName })
      onProvinceChange()({
        value: eventInscription.areaLevel1Id,
        label: eventInscription.areaLevel1Name
      })
    } else if (userData?.countryId && checked) {
      onCountryChange()({ value: userData.countryId, label: userData.countryName })
      onProvinceChange()({ value: userData.areaLevel1Id, label: userData.areaLevel1Name })
    } else {
      onCountryChange()(ARGENTINA_COUNTRY)
    }
  }, [checked, userData, eventInscription])

  useEffect(() => {
    resetValues()
  }, [resetValues])

  const handleClickOpenPolicy = (e) => {
    e.preventDefault()
    setOpenPolicy(true)
  }

  const handleClosePolicy = () => setOpenPolicy(false)

  const handleClickOpenTerms = (e) => {
    e.preventDefault()
    setOpenTerms(true)
  }

  const handleCloseTerms = () => setOpenTerms(false)

  const resetDistancesValues = () =>
    formData?.items
      .map(({ eventDistance, eventDistanceId }) => ({
        quota: eventDistance.quota,
        label: eventDistance.name,
        value: eventDistanceId,
        inscriptionsCount: eventDistance.inscriptionsCount
      }))
      .reduce((acc, item) => {
        if (!acc.find((i) => i.value === item.value)) acc.push(item)
        return acc
      }, [])
      .map(({ label, value, quota, inscriptionsCount }) =>
        optionLabel(label, value, quota, inscriptionsCount)
      )

  const onCountryChange = (setValues) => async (options) => {
    try {
      setProvinces([])
      setCities([])

      if (setValues) {
        setValues((values) => {
          const resetUpdateProps =
            values.country.value !== options.value
              ? { areaLevel1: '', areaLevel2: '', areaLevel2Other: '' }
              : null

          return {
            ...values,
            ...resetUpdateProps,
            country: options
          }
        })
      }

      const proxy = new CountriesProxy()

      if (options.value) {
        const data = await proxy.getAreaLevel1(options.value)
        if (Array.isArray(data) && !!data.length) {
          setProvinces(data.map((d) => ({ value: d.id, label: d.name })))
        }
      }
    } catch (e) {
      showSnackbarError({ message: e })
    }
  }

  const onProvinceChange = (setValues) => async (options) => {
    setCities([])

    if (setValues) {
      setValues((values) => {
        const resetUpdateProps =
          values.areaLevel1.value !== options.value ? { areaLevel2: '', areaLevel2Other: '' } : null

        return { ...values, ...resetUpdateProps, areaLevel1: options }
      })
    }

    const proxy = new CountriesProxy()

    if (options.value) {
      const data = await proxy.getAreaLevel2(options.value)
      if (Array.isArray(data) && !!data.length) {
        setCities([...data.map((d) => ({ value: d.id, label: d.name })), OTHER_OPTION])
      }
    }
  }

  const filterGenderList = async (inputValue) =>
    genderList.filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))

  const onCityChange = (setFieldValue) => async (options) =>
    setFieldValue((values) => ({ ...values, areaLevel2: options, areaLevel2Other: '' }))

  const optionLabel = (name, value, quota, inscriptionsCount, state) => {
    const availableQuota = quota - inscriptionsCount
    const labelQuota = availableQuota > 0 ? `(${availableQuota} disponibles)` : 'Agotado'

    return {
      label: (
        <div className={classes.optionCategory}>
          <Typography
            className={clsx(
              classes.optionCategoryName,
              !!quota && availableQuota <= 10 && 'withQuota'
            )}
            title={name}>
            {name}
          </Typography>
          {!!quota && availableQuota <= 10 && (
            <Typography title={labelQuota}>{labelQuota}</Typography>
          )}
        </div>
      ),
      value,
      disabled: !!quota && !availableQuota,
      name,
      state
    }
  }

  const updateCategories = (distance, gender, birthdate) => {
    const years =
      moment(birthdate).isValid() && moment(formData.validateAgeFrom).diff(birthdate, 'years')

    setCategories(
      filterAndSortCategories(distance, gender, years, formData).map(
        ({ eventCategory, eventCategoryId, quota, inscriptionsCount, state }) =>
          optionLabel(eventCategory.name, eventCategoryId, quota, inscriptionsCount, state)
      )
    )
  }

  const onDistanceChange = (setValues) => (option) => {
    if (option.disabled) return

    setValues((values) => {
      updateCategories(option.value, values.gender.value, values.birthdate)

      return {
        ...values,
        category: values.distance.value != option.value ? '' : values.category,
        distance: { ...option, label: option.name },
        ticketType: values.distance.value !== option.value ? null : values.ticketType,
        paymentType: values.distance.value !== option.value ? '' : values.paymentType,
        discountCode: null,
        discountCodeName: ''
      }
    })
  }

  const handleChangeGender = (values, setValues) => (gender) => {
    const category = values.gender.value === gender.value ? values.category : ''

    setValues({ ...values, category, gender })
    updateCategories(values.distance.value, gender.value, values.birthdate)
  }

  const searchDistance = async (inputValue) => {
    const distances = resetDistancesValues()

    if (Array.isArray(distances)) {
      return distances.filter((d) => d.name.toLowerCase().includes(inputValue.toLowerCase()))
    }
  }

  const searchCategory = async (inputValue) =>
    Array.isArray(categories) &&
    categories.filter((c) => c.name.toLowerCase().includes(inputValue.toLowerCase()))

  const handleChangeBirthdate = (setValues, values) => (e) => {
    setValues({ ...values, birthdate: e, category: '', ticketType: null })
    updateCategories(values.distance.value, values.gender.value, e)
  }

  const getAreaLevel2 = (user) => {
    if (user.areaLevel2Other) return OTHER_OPTION

    if (user.areaLevel1Id) return { value: user.areaLevel2Id, label: user.areaLevel2Name }

    return ''
  }

  const handleDownloadRulesDocument = (e) => {
    if (event.rulesDocumentFile) {
      e.preventDefault()
      dispatch(downloadRulesDocument(event))
    }
  }

  const formValidate = (handleSubmit, isValid) => (e) => {
    e.preventDefault()
    if (isValid) handleSubmit(e)
    else setOpenNotifyErrorsDialog(true)
  }

  const requiredProvince = (country) => country && country.value === event?.countryId

  const getErrorsList = (errors) => {
    const termsAndConditions = errors.acceptedTermsAndConditions || errors.acceptedEventRules
    const areaLevel2Option = errors.areaLevel2 || errors.areaLevel2Other

    return Object.keys({
      ...errors,
      ...errors.customFields?.reduce(
        (acc, field, index) =>
          field
            ? {
                ...acc,
                [`customFields[${index}]`]: field.value
              }
            : acc,
        {}
      ),
      ...(areaLevel2Option ? { areaLevel2Option } : {}),
      ...(termsAndConditions ? { termsAndConditions } : {})
    })
      .map((x) => formErrorDictionary[x])
      .sort((a, b) => a?.order - b?.order)
  }

  const getActivePaymentMethods = (ticketType) =>
    [
      {
        ...ORDER_PAYMENT.MERCADO_PAGO,
        active:
          ticketType?.mercadoPagoEnabled && (mode === FORM_STATE.read || event.mercadoPagoEnabled)
      },
      {
        ...ORDER_PAYMENT.BANK_ACCOUNT,
        active:
          ticketType?.bankAccountsEnabled &&
          (mode === FORM_STATE.read || event.eventBankAccountsEnabled)
      },
      {
        ...ORDER_PAYMENT.PAYMENT_LOCATION,
        active:
          ticketType?.paymentLocationsEnabled &&
          (mode === FORM_STATE.read || event.eventPaymentLocationsEnabled)
      },
      {
        ...ORDER_PAYMENT.PAYMENT_URL,
        active: !!ticketType?.paymentUrl
      }
    ].filter((x) => Boolean(x.active))

  const getTicket = (activeTicketTypes) => {
    if (activeTicketTypes.length !== 1) return STRING_EMPTY

    return activeTicketTypes[0]
  }

  const handleChangeCategory = (setValues) => (e) => {
    if (e.disabled) return

    setValues((values) => {
      const changeCategory = values.category.value !== e.value

      const activeTicketTypes = formData.ticketTypes
        .filter((x) => x.isActive)
        .filter(
          ({ items, saleFrom, saleTo, quota = 0, inscriptionsCount = 0 }) =>
            moment().isBetween(moment(saleFrom), moment(saleTo)) &&
            items.some(
              ({ inscriptionFormItem }) =>
                inscriptionFormItem.eventCategoryId === e.value &&
                inscriptionFormItem.eventDistanceId === values.distance.value
            ) &&
            (quota === TICKET_QUOTA_UNLIMITED || inscriptionsCount < quota)
        )

      const ticketType = getTicket(activeTicketTypes)
      const activePaymentMethods = ticketType ? getActivePaymentMethods(ticketType) : []

      const changeTicketType = { state: values.ticketType?.value !== ticketType.value }

      return {
        ...values,
        category: { ...e, label: e.name },
        activeTicketTypes,
        changeCategory,
        activePaymentMethods,
        discountCode: null,
        discountCodeName: '',
        paymentType: changeTicketType
          ? (!formData.isFree &&
              ticketType?.price !== TICKET_FREE_PRICE &&
              activePaymentMethods.length === 1 &&
              activePaymentMethods[0]) ||
            ''
          : values.paymentType,
        changeTicketType,
        ticketType: changeCategory ? ticketType : values.ticketType
      }
    })
  }

  const values = {
    firstName: '',
    lastName: '',
    gender: '',
    idNumber: '',
    eMail: '',
    areaLevel1: '',
    areaLevel2: '',
    areaLevel2Other: '',
    phone: '+54',
    hasWhatsApp: false,
    country: '',
    discountCodeName: '',
    discountCode: null,
    birthdate: null,
    acceptedEventRules: !event.rulesUrl && !event.rulesDocumentFile,
    distance: '',
    acceptedTermsAndConditions: false,
    category: '',
    customFields: formData.customFields.filter((c) => !c.isPrivate),
    ticketType: null,
    activeTicketTypes: [],
    paymentType: '',
    activePaymentMethods: [],
    inscriptionFree: event.isFree,
    ticketType: null,
    ...(activeLoadEventInscription && eventInscription
      ? {
          ...eventInscription,
          phone: eventInscription.phone || '+54',
          gender: genderList.find((g) => g.value === eventInscription.gender) ?? '',
          areaLevel2: getAreaLevel2(eventInscription),
          areaLevel1: eventInscription.areaLevel1Id
            ? { value: eventInscription.areaLevel1Id, label: eventInscription.areaLevel1Name }
            : '',
          country: eventInscription.countryId
            ? { value: eventInscription.countryId, label: eventInscription.countryName }
            : ARGENTINA_COUNTRY,
          customFields: formData.customFields
            .filter((c) => !c.isPrivate)
            .map((x) => ({
              ...x,
              value: eventInscription.customFields.find((c) => c.eventCustomFieldId === x.id)
                .fieldValue
            }))
        }
      : checked && userData
      ? {
          ...userData,
          phone: userData.phone || '+54',
          gender: genderList.find((g) => g.value === userData.gender) ?? '',
          areaLevel2: getAreaLevel2(userData),
          areaLevel1: userData.areaLevel1Id
            ? { value: userData.areaLevel1Id, label: userData.areaLevel1Name }
            : '',
          country: userData.countryId
            ? { value: userData.countryId, label: userData.countryName }
            : ARGENTINA_COUNTRY
        }
      : {
          country: ARGENTINA_COUNTRY,
          areaLevel1: '',
          areaLevel2: '',
          areaLevel2Other: ''
        })
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string()
          .max(256, 'El nombre es demasiado largo')
          .required('Ingrese su nombre')
          .trim(),
        acceptedTermsAndConditions: Yup.boolean().oneOf(
          [true],
          'Debe aceptar los términos y condiciones'
        ),
        country: Yup.mixed().required('Seleccione un país'),
        areaLevel1: Yup.mixed().when('country', {
          is: (country) => country?.value === event?.countryId,
          then: Yup.mixed().required('Seleccione una provincia')
        }),
        areaLevel2: Yup.mixed().when('country', {
          is: (country) => country?.value === event?.countryId,
          then: Yup.mixed().required('Seleccione una ciudad')
        }),
        areaLevel2Other: Yup.string()
          .nullable()
          .when(['areaLevel2', 'country'], (areaLevel2, country, schema) =>
            areaLevel2?.value === OTHER_OPTION.value && country?.value === event?.countryId
              ? schema.trim().required('Debe ingresar el nombre de su ciudad')
              : schema
          ),
        acceptedEventRules: Yup.boolean().oneOf([true], 'Debe aceptar las reglas del evento'),
        lastName: Yup.string()
          .max(256, 'El apellido es demasiado largo')
          .required('Ingrese su apellido')
          .trim(),
        eMail: Yup.string()
          .email('El e-mail no es válido')
          .min(5, 'El e-mail es demasiado corto')
          .max(254, 'El e-mail es demasiado largo')
          .required('Debe ingresar el e-mail')
          .trim(),
        gender: Yup.object().nullable().required('Debe elegir su género'),
        idNumber: Yup.string()
          .nullable()
          .required('Debe ingresar su DNI')
          .min(6, 'El DNI debe poseer como mínimo 6 caracteres')
          .max(15, 'El DNI debe poseer como máximo 15 caracteres')
          .matches(/^[a-z0-9]+$/i, 'El DNI no debe tener caracteres especiales ni espacios'),
        birthdate: Yup.string()
          .nullable()
          .required('Seleccione su fecha de nacimiento')
          .test(
            'test-date',
            'No se admiten fechas futuras',
            (value) => !!value && moment(value).isBefore()
          )
          .test(
            'test-age',
            'La edad permitida es entre 3 y 120 años',
            (value) =>
              !!value && moment().diff(value, 'years') >= 3 && moment().diff(value, 'years') <= 120
          ),
        category: Yup.object().required('Debe elegir una categoría'),
        distance: Yup.object().required('Debe elegir una distancia'),
        phone: Yup.string()
          .nullable()
          .required('Debe ingresar un número de teléfono')
          .min(5, 'El número de teléfono debe tener al menos 5 caracteres')
          .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
          .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'El número de teléfono ingresado no es válido'),
        ticketType: Yup.object().required('Debe escoger un ticket'),
        paymentType: Yup.mixed().when('ticketType', {
          is: (ticketType) => Boolean(ticketType),
          then: (schema) =>
            schema.test(
              'test-required',
              'Debe escoger una modalidad de pago',
              (paymentType, { parent }) =>
                parent.inscriptionFree ||
                (Boolean(parent.ticketType) &&
                  (parent.ticketType.price !== TICKET_FREE_PRICE ? Boolean(paymentType) : true))
            )
        }),
        customFields: Yup.array().of(
          Yup.object().shape({
            value: Yup.mixed()
              .when('type', (type, schema) => {
                switch (type) {
                  case 'File':
                  case 'Text':
                  case 'Boolean':
                    return Yup.string().trim()

                  case 'Number':
                    return Yup.number()
                      .typeError('Ingrese un número válido')
                      .max(999999999999999, 'Se puede ingresar hasta 15 dígitos')

                  case 'List':
                    return Yup.object()
                  default:
                    return schema
                }
              })
              .when('isRequired', (isRequired, schema) =>
                isRequired ? schema.required('Este campo es requerido') : schema
              )
          })
        ),
        discountCodeName: Yup.string()
          .min(3, 'Debe ingresar al menos 3 caracteres')
          .max(15, 'El nombre tiene un máximo de 15 caracteres')
          .matches(/^[a-z0-9.\-_]+$/i, 'El nombre no debe tener caracteres especiales ni espacios')
          .trim()
      }),
    [event]
  )

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        setValues
      }) => {
        const validationProvince = requiredProvince(values.country)
        const showOtherOption = values.areaLevel2.value === OTHER_OPTION.value

        return (
          <form
            onSubmit={handleSubmit}
            className={clsx(mode !== FORM_STATE.create && classes.form)}>
            <div className={classes.initialMessageContainer}>
              <div className={classes.alertContainer}>
                <TriangleExclaimationIcon />
                <Typography className={classes.alertParagraph}>
                  Los datos que completes a continuación tienen carácter de Declaración Jurada y son
                  los que se brindarán a la compañía de seguro, en caso de corresponder.
                </Typography>
              </div>
              {formData.welcomeMessage && (
                <LinkifyText>
                  <div className={classes.alertContainer}>
                    <Typography color='primary' variant='h6' className={classes.welcomeMessage}>
                      {formData.welcomeMessage}
                    </Typography>
                  </div>
                </LinkifyText>
              )}
            </div>

            <SectionBlock title='¿A quién inscribes?'>
              {userData && (
                <div className={classes.separatorContainer}>
                  <RadioGroup
                    aria-label='required'
                    name='isRequired'
                    value={checked}
                    onChange={(e) => {
                      setActiveLoadEventInscription(false)
                      setChecked(e.target.value === 'true')
                    }}
                    className={classes.radioContainer}>
                    {((!event.loggedUserMetadata.hasActiveInscription && !isAdmin) ||
                      mode === FORM_STATE.read) && (
                      <FormControlLabel
                        value={true}
                        color='primary'
                        control={<Radio color='primary' />}
                        label={<Typography variant='h6'>A mi mismo</Typography>}
                      />
                    )}
                    <FormControlLabel
                      value={false}
                      control={<Radio color='primary' />}
                      label={<Typography variant='h6'>A otra persona</Typography>}
                    />
                  </RadioGroup>
                </div>
              )}
            </SectionBlock>
            <SectionBlock
              title={!checked && 'Datos de quién inscribas'}
              className={classes.section}>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6'>
                  Nombre *
                </Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  autoFocus={mode === FORM_STATE.create}
                  name='firstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.firstName && touched.firstName}
                  helperText={errors.firstName}
                  fullWidth
                  value={values.firstName}
                  placeholder={placeholder.name}
                  autoComplete='off'
                />
              </div>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6'>
                  Apellido *
                </Typography>
                <TextField
                  variant='outlined'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.lastName && touched.lastName}
                  helperText={errors.lastName}
                  placeholder={placeholder.lastName}
                  size='small'
                  name='lastName'
                  fullWidth
                  autoComplete='off'
                />
              </div>
              <div className={classes.separatorContainer}>
                <div className={classes.formGroupContainer}>
                  <div className={classes.formGroup}>
                    <Typography color='primary' variant='h6'>
                      N° de DNI *
                    </Typography>
                    <TextField
                      variant='outlined'
                      size='small'
                      onBlur={handleBlur}
                      error={!!errors.idNumber && touched.idNumber}
                      helperText={errors.idNumber}
                      onChange={handleChange}
                      placeholder='********'
                      value={values.idNumber}
                      name='idNumber'
                      fullWidth
                      autoComplete='off'
                    />
                  </div>

                  <div className={clsx(classes.fieldContainer, classes.formGroup)}>
                    <Typography color='primary' variant='h6'>
                      Género *
                    </Typography>
                    <StyledSelect
                      cacheOptions
                      defaultOptions={genderList}
                      className={classes.select}
                      value={values.gender}
                      onBlur={handleBlur}
                      loadOptions={filterGenderList}
                      name='gender'
                      placeholder='Seleccioná una opción'
                      colorOptions='secondary'
                      onChange={handleChangeGender(values, setValues)}
                      filterOption={(option) => option.value !== 'NonBinary' || showNonBinaryOption}
                    />
                    {errors.gender && (
                      <Typography variant='caption' color={touched.gender ? 'error' : 'initial'}>
                        {errors.gender}
                      </Typography>
                    )}
                  </div>
                </div>

                <div className={classes.formGroupContainer}>
                  <div className={classes.formGroup}>
                    <Typography color='primary' variant='h6'>
                      Fecha de nacimiento *
                    </Typography>
                    <KeyboardDatePicker
                      className={`${classes.input} datePicker`}
                      format='DD/MM/YYYY'
                      autoOk
                      variant='inline'
                      name='birthdate'
                      onBlur={handleBlur}
                      margin='none'
                      id='date-picker-inline'
                      inputVariant='outlined'
                      error={!!errors.birthdate && touched.birthdate}
                      helperText={errors.birthdate}
                      onChange={handleChangeBirthdate(setValues, values)}
                      style={{ width: '100%' }}
                      value={values.birthdate}
                      KeyboardButtonProps={{
                        'aria-label': 'seleccione fecha'
                      }}
                      placeholder='dd/mm/aaaa'
                    />
                  </div>
                  <div className={clsx(classes.fieldContainer, classes.formGroup)}>
                    <Typography color='primary' variant='h6'>
                      País *
                    </Typography>
                    <StyledSelect
                      cacheOptions
                      defaultOptions
                      className={classes.select}
                      value={values.country}
                      onBlur={handleBlur}
                      loadOptions={getCountries()}
                      name='country'
                      placeholder='Seleccione su país'
                      colorOptions='secondary'
                      onChange={onCountryChange(setValues)}
                    />
                    {errors.country && (
                      <Typography variant='caption' color={touched.country ? 'error' : 'initial'}>
                        {errors.country}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className={classes.formGroupContainer}>
                  {validationProvince && (
                    <>
                      <div className={clsx(classes.formGroup, classes.fieldContainer)}>
                        <Typography variant='h6' color='primary'>
                          Provincia *
                        </Typography>
                        <StyledSelect
                          cacheOptions
                          className={classes.select}
                          value={values.areaLevel1}
                          defaultOptions={provinces}
                          placeholder='Seleccione su provincia'
                          loadOptions={filterProvinces(provinces)}
                          onBlur={handleBlur}
                          colorOptions='secondary'
                          name='areaLevel1'
                          onChange={onProvinceChange(setValues)}
                        />
                        {errors.areaLevel1 && (
                          <Typography
                            variant='caption'
                            color={touched.areaLevel1 ? 'error' : 'initial'}>
                            {errors.areaLevel1}
                          </Typography>
                        )}
                      </div>
                      {values.areaLevel1 && (
                        <div className={classes.citiesContainer}>
                          <div
                            className={clsx(
                              classes.formGroup,
                              classes.fieldContainer,
                              showOtherOption && classes.optionOtherContainer
                            )}>
                            <Typography variant='h6' color='primary'>
                              Ciudad de Residencia *
                            </Typography>
                            <StyledSelect
                              cacheOptions
                              className={classes.select}
                              value={values.areaLevel2}
                              defaultOptions={cities}
                              placeholder='Seleccione su ciudad'
                              loadOptions={filterCities(cities)}
                              onBlur={handleBlur}
                              colorOptions='secondary'
                              name='areaLevel2'
                              onChange={onCityChange(setValues)}
                            />
                            {errors.areaLevel2 && (
                              <Typography
                                variant='caption'
                                color={touched.areaLevel2 ? 'error' : 'initial'}>
                                {errors.areaLevel2}
                              </Typography>
                            )}
                          </div>
                          {showOtherOption && (
                            <div className={clsx(classes.formGroup, classes.otherOptionText)}>
                              <TextField
                                variant='outlined'
                                color='primary'
                                autoComplete='off'
                                size='small'
                                fullWidth
                                placeholder='Ingrese su ciudad'
                                onChange={handleChange}
                                errors={touched.areaLevel2Other && !!errors.areaLevel2Other}
                                helperText={errors.areaLevel2Other}
                                name='areaLevel2Other'
                                onBlur={handleBlur}
                                value={values.areaLevel2Other}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={classes.formGroupContainer}>
                  <div className={classes.formGroup}>
                    <Typography color='primary' variant='h6'>
                      E-mail *
                    </Typography>
                    <TextField
                      variant='outlined'
                      size='small'
                      value={values.eMail}
                      name='eMail'
                      placeholder='Ingrese su dirección de correo electrónico'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.eMail && touched.eMail}
                      helperText={errors.eMail}
                      fullWidth
                      autoComplete='off'
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography color='primary' variant='h6'>
                      Teléfono *
                    </Typography>
                    <CustomTextField
                      variant='outlined'
                      size='small'
                      value={values.phone}
                      onBlur={handleBlur}
                      error={!!errors.phone && touched.phone}
                      helperText={errors.phone}
                      onChange={handleChange}
                      name='phone'
                      className={classes.phone}
                      fullWidth
                      autoComplete='off'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <CustomizedTooltip
                              position='left-end'
                              isTopMost={isAdmin}
                              title='El Organizador utilizará este número para enviarte información sobre el evento.'>
                              {({ handleTooltip }) => (
                                <IconButton onClick={handleTooltip}>
                                  <InfoIcon color='primary' />
                                </IconButton>
                              )}
                            </CustomizedTooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Typography className={classes.helperTextPhone}>
                      Cód. país + cód. de área sin cero + N° tel sin el 15, sin espacios ni guiones.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          checked={values.hasWhatsApp}
                          onChange={(e) => setFieldValue('hasWhatsApp', e.target.checked)}
                          name='hasWhatsApp'
                          onBlur={handleBlur}
                        />
                      }
                      label={<Typography variant='h6'>Este teléfono tiene WhatsApp</Typography>}
                    />
                  </div>
                </div>
              </div>
            </SectionBlock>

            {values.customFields.map((field, index) => (
              <SectionBlock
                key={index}
                title={`${field.name} ${field.isRequired ? '*' : ''}`}
                body={field.helperText}>
                <CustomField
                  {...field}
                  error={
                    Array.isArray(errors.customFields) ? errors?.customFields[index]?.value : ''
                  }
                  touched={
                    Array.isArray(touched.customFields) && touched?.customFields[index]?.value
                  }
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  fieldName={`customFields[${index}].value`}
                  handleBlur={handleBlur}
                />
              </SectionBlock>
            ))}

            <SectionBlock title='Distancia y categoría'>
              <div className={classes.comboxsContainer}>
                <div className={classes.fieldContainer}>
                  <StyledSelect
                    cacheOptions
                    placeholder='Seleccione una distancia'
                    name='distance'
                    defaultOptions={resetDistancesValues()}
                    onBlur={handleBlur}
                    onChange={onDistanceChange(setValues)}
                    colorOptions='secondary'
                    value={values.distance}
                    className={classes.select}
                    loadOptions={searchDistance}
                  />
                  {errors.distance && (
                    <Typography variant='caption' color={touched.distance ? 'error' : 'initial'}>
                      {errors.distance}
                    </Typography>
                  )}
                </div>
                <div className={classes.fieldContainer}>
                  <StyledSelect
                    cacheOptions
                    placeholder='Seleccione una categoría'
                    defaultOptions={categories}
                    onBlur={handleBlur}
                    onChange={handleChangeCategory(setValues)}
                    isOptionDisabled={(option) => option.disabled}
                    value={values.category}
                    colorOptions='secondary'
                    name='category'
                    className={classes.select}
                    loadOptions={searchCategory}
                  />
                  {errors.category && (
                    <Typography variant='caption' color={touched.category ? 'error' : 'initial'}>
                      {errors.category}
                    </Typography>
                  )}
                </div>
              </div>
            </SectionBlock>

            <TicketSection formData={formData} viewAdmin={isAdmin} autoHidden={scrolling} />

            {!!values.ticketType && (
              <PaymentMethodSection
                formData={formData}
                viewAdmin={isAdmin}
                autoHidden={scrolling}
              />
            )}

            <SectionBlock title='Términos y condiciones'>
              <div className={classes.checkContainer}>
                {(event.rulesUrl || event.rulesDocumentFile) && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          checked={values.acceptedEventRules}
                          onChange={(e) => setFieldValue('acceptedEventRules', e.target.checked)}
                          name='acceptedEventRules'
                          onBlur={handleBlur}
                        />
                      }
                      label={
                        <Typography className={classes.tosParagraph}>
                          Acepto el&nbsp;
                          <a
                            href={event.rulesUrl}
                            rel='noopener noreferrer'
                            target='_blank'
                            onClick={handleDownloadRulesDocument}>
                            reglamento del evento.&nbsp;
                            {downloadRulesDocumentPending && (
                              <CircularProgress size={14} color='inherit' />
                            )}
                          </a>
                        </Typography>
                      }
                    />
                    {!!errors.acceptedEventRules && (
                      <Typography variant='caption' color='error'>
                        {errors.acceptedEventRules}
                      </Typography>
                    )}
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={values.acceptedTermsAndConditions}
                      onChange={(e) =>
                        setFieldValue('acceptedTermsAndConditions', e.target.checked)
                      }
                      name='acceptedTermsAndConditions'
                      onBlur={handleBlur}
                    />
                  }
                  label={
                    <Typography variant='body1' className={classes.tosParagraph}>
                      Acepto las&nbsp;
                      <Link to='#' onClick={handleClickOpenTerms}>
                        Condiciones de uso
                      </Link>
                      &nbsp;y la&nbsp;
                      <Link to='#' onClick={handleClickOpenPolicy}>
                        Política de privacidad.
                      </Link>
                    </Typography>
                  }
                />
                <TermsAndConditionsDialog
                  handleClose={handleCloseTerms}
                  open={openTerms}
                  content={TermsAndConditionsContent}
                  title='Condiciones de uso'
                />
                <TermsAndConditionsDialog
                  handleClose={handleClosePolicy}
                  open={openPolicy}
                  content={PrivacyPolicyContent}
                  title='Política de privacidad'
                />
                {!!errors.acceptedTermsAndConditions && (
                  <Typography variant='caption' color='error'>
                    {errors.acceptedTermsAndConditions}
                  </Typography>
                )}
              </div>
            </SectionBlock>
            <Typography className={classes.message}>
              Al finalizar la preinscripción recibirá una confirmación a los correos electrónicos
              registrados, por favor verifique su bandeja de Spam.
            </Typography>
            <div className={classes.buttonsContainer}>
              {mode === FORM_STATE.create && (
                <>
                  <Button
                    color='primary'
                    onClick={formValidate(handleSubmit, isValid)}
                    disabled={isSubmitting || eventInscriptionPending}
                    variant='contained'
                    endIcon={
                      eventInscriptionPending && <CircularProgress color='primary' size={16} />
                    }
                    className={classes.button}>
                    Enviar inscripción
                  </Button>
                  <FormNotifyDialog
                    open={openNotifyErrorsDialog}
                    onClose={() => setOpenNotifyErrorsDialog(false)}
                    title='NOTIFICACIÓN FORMULARIO INCOMPLETO'
                    subtitle='Para poder finalizar tu pre-inscripción el formulario debe estar completo y no tener errores, revisa los siguientes campos:'
                    errors={getErrorsList(errors)}
                  />
                </>
              )}
              {mode !== FORM_STATE.create && (
                <IconButton onClick={onBack} className={classes.backButton}>
                  <ArrowBackIcon size='medium' color='primary' />
                </IconButton>
              )}
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default InscriptionForm
