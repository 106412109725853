import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { CustomizedTooltip, StyledSwitch } from 'shared'
import { EVENT_STATES, STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'
import { SuspendEventDialog } from '../SuspendEventDialog'

import { useStyles } from './StateSection.style'

const EVENT_STATES_OPTIONS = [
  { value: EVENT_STATES.ACTIVE, label: 'Activo' },
  { value: EVENT_STATES.SUSPENDED, label: 'Suspendido' }
]

const StateSection = () => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { values, handleBlur, handleChange, errors, setFieldValue, setValues, initialValues } =
    useFormikContext()

  const [openTooltip, setOpenTooltip] = useState({})
  const [suspendEventDialogProps, setSuspendEventDialogProps] = useState({
    open: false,
    prevState: STRING_EMPTY
  })

  const handleChangeStateOption = (e) => {
    const value = e.target.value

    if (value === EVENT_STATES.SUSPENDED && event.state !== EVENT_STATES.SUSPENDED)
      setSuspendEventDialogProps({
        open: true,
        prevState: values.state,
        onConfirm: handleConfirm
      })

    if (event.state === EVENT_STATES.SUSPENDED) {
      const eventProps =
        value !== EVENT_STATES.SUSPENDED
          ? {
              fromDate: null,
              fromTime: null,
              toDate: null,
              toTime: null
            }
          : initialValues

      setValues((values) => ({
        ...values,
        ...eventProps
      }))
    }

    handleChange(e)
  }

  const handleClose = () => {
    setFieldValue('state', suspendEventDialogProps.prevState)

    setSuspendEventDialogProps({
      open: false,
      prevState: STRING_EMPTY
    })
  }

  const handleConfirm = () =>
    setSuspendEventDialogProps({
      open: false,
      prevState: STRING_EMPTY
    })

  const hasInscriptions =
    !!event.activeInscriptionForm && event.activeInscriptionForm.inscriptionsCount > 0

  const stateSelectDisabled =
    (event.everSuspended && event.state === EVENT_STATES.ACTIVE) || !values.isPublished

  return (
    <SectionBlock title='Estado del Evento'>
      <div className={classes.content}>
        <FormControl variant='outlined'>
          <InputLabel
            id='discipline-select-outlined-label'
            className={clsx(classes.labelSelect, stateSelectDisabled && 'disabled')}>
            Seleccione el estado de su evento
          </InputLabel>
          <Select
            value={values.state}
            onBlur={handleBlur}
            onChange={handleChangeStateOption}
            name='state'
            disabled={stateSelectDisabled}
            MenuProps={{
              PaperProps: {
                className: classes.menuContainer
              }
            }}
            className={classes.select}>
            {EVENT_STATES_OPTIONS.map((x) => (
              <MenuItem
                value={x.value}
                key={x.value}
                className={classes.item}
                classes={{
                  selected: classes.itemSelected
                }}>
                {x.label}
              </MenuItem>
            ))}
          </Select>

          <Typography variant='caption' className={classes.helperText}>
            {errors.state || 'Seleccione el estado para su evento '}
          </Typography>
        </FormControl>
        <div
          className={clsx(
            classes.eventPublishedContainer,
            values.state === EVENT_STATES.SUSPENDED && 'hidden'
          )}>
          <CustomizedTooltip
            title={
              hasInscriptions
                ? 'Este evento no puede ocultarse porque ya tiene inscriptos.'
                : 'Si el switch está activado, el evento es visible para todos los usuarios.'
            }
            position={isDesktop ? 'top' : 'bottom-start'}
            autoOpenTooltip={openTooltip}
            disabledClickAway
            arrowClassName={classes.tooltipArrow}
            className={classes.tooltip}
            buttonClassName={classes.tooltipCloseButton}>
            {({ handleTooltip }) => (
              <IconButton color='primary' size='small' onClick={handleTooltip}>
                <InfoIcon />
              </IconButton>
            )}
          </CustomizedTooltip>
          <Typography variant='h6' className={classes.eventPublushedTitle}>
            Evento publicado
          </Typography>
          <StyledSwitch
            checked={values.isPublished}
            disabled={hasInscriptions}
            onChange={(e) => {
              if (!values.isPublished) setOpenTooltip({ state: true })
              handleChange(e)
            }}
            name='isPublished'
          />

          <SuspendEventDialog {...suspendEventDialogProps} onClose={handleClose} />
        </div>
      </div>
    </SectionBlock>
  )
}

export default StateSection
