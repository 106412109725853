import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { EditableText } from 'shared'
import { CONTENT_TYPES, STRING_EMPTY } from 'utils/constants'
import { showSnackbarError } from 'utils/snackbar'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './RulesSection.style'

const MAX_FILE_SIZE = 20

const RulesSection = ({ sectionDisabled }) => {
  const classes = useStyles()
  const eventRulesInputFileRef = useRef()
  const { values, handleBlur, errors, setFieldValue, setValues } = useFormikContext()

  const { uploadRulesDocumentPending, deleteRulesDocumentPending } = useSelector(
    (state) => state.events
  )

  const [activeEdit, setActiveEdit] = useState(!!values.rulesDocumentFile || !!values.rulesUrl)

  const handleChangeRulesEvent = (setFieldValue, values) => async (e, onClearField) => {
    const text = e.target.value
    const deleteRulesFile =
      values.rulesDocumentFile && values.rulesFileNameOrUrl?.length > text?.length

    if (deleteRulesFile) await onDeleteRulesFile(setFieldValue, values)(onClearField)
    else {
      setFieldValue('rulesUrl', e.target.value)
      setFieldValue('rulesFileNameOrUrl', e.target.value)
    }
  }

  const onDeleteRulesFile = (setFieldValue, values) => async (onClearField) => {
    try {
      setFieldValue('sectionState', false)

      if (values.rulesDocumentFile) {
        setFieldValue('rulesDocumentFile', null)
        onClearField()
        setFieldValue('rulesFileNameOrUrl', STRING_EMPTY)
        setFieldValue('rulesUrl', STRING_EMPTY)
      }
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setFieldValue('sectionState', true)
    }
  }

  const handleInputFile = () => eventRulesInputFileRef.current.click()

  const handleOnchangeFile = (setFieldValue, setValues) => async (e) => {
    if (e.target.files.length === 0) return

    try {
      setFieldValue('sectionState', false)
      const file = e.target.files[0]
      const { type: documentType, size } = file

      e.target.value = STRING_EMPTY

      if (documentType !== CONTENT_TYPES.APPLICATION_PDF) {
        showSnackbarError({ message: 'El formato del documento no es válido' })
      } else if (size / 1048576 > MAX_FILE_SIZE) {
        showSnackbarError({ message: 'El tamaño del archivo no debe superar los 20MB' })
      } else {
        setValues((values) => ({
          ...values,
          rulesFileNameOrUrl: file.name,
          rulesDocumentFile: file,
          rulesFileName: file.name,
          rulesUrl: STRING_EMPTY
        }))
      }
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setFieldValue('sectionState', true)
    }
  }

  return (
    <SectionBlock title='Reglamento del evento'>
      {activeEdit ? (
        <>
          <EditableText
            entity={values}
            field='rulesFileNameOrUrl'
            activeEditSection={activeEdit}
            type={values.rulesDocumentFile ? 'text' : 'url'}
            inputType='text'
            handleBlur={handleBlur}
            disabled={sectionDisabled}
            fullWidth
            autoFocus={false}
            onChange={handleChangeRulesEvent(setFieldValue, values)}
            externalChange={values.rulesDocumentFile}
            inputClassName={classes.eventinputLink}
            placeHolder='Pegue aquí el link o adjunte archivo del reglamento de su evento'
            errors={errors.rulesUrl}
          />

          <div className={classes.uploadRulesDocumentContainer}>
            <input
              type='file'
              style={{ display: 'none' }}
              accept='.pdf'
              onChange={handleOnchangeFile(setFieldValue, setValues)}
              ref={eventRulesInputFileRef}
            />
            <div className={classes.buttonContainer}>
              <Button
                color='primary'
                variant='contained'
                endIcon={
                  (uploadRulesDocumentPending || deleteRulesDocumentPending) && (
                    <CircularProgress color='primary' size={16} />
                  )
                }
                disabled={
                  uploadRulesDocumentPending ||
                  deleteRulesDocumentPending ||
                  !!values.rulesUrl ||
                  sectionDisabled
                }
                className={clsx(classes.eventRulesButton, classes.eventRulesUploadButton)}
                onClick={handleInputFile}>
                Adjuntar archivo
              </Button>
            </div>
          </div>
        </>
      ) : (
        <Typography
          color='primary'
          className={clsx(classes.addLink, sectionDisabled && 'disabled')}
          variant='h6'
          onClick={() => {
            if (sectionDisabled) return

            setActiveEdit(true)
          }}>
          + Añadir reglamento
        </Typography>
      )}
    </SectionBlock>
  )
}

export default RulesSection
