import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  eventSection: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      maxWidth: 420
    }
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    gap: theme.spacing(2)
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',

    gap: theme.spacing(2)
  },
  icon: {
    height: '24px !important',
    width: '24px !important'
  },
  iconMedium: {
    height: '40px !important',
    width: '40px !important'
  },
  iconContainer: {
    display: 'flex',
    padding: 3,
    '&:hover': {
      border: '1px solid #81B0FF',
      borderRadius: '50%'
    }
  },
  whatsAppLabel: {
    fontSize: '.825rem',
    fontWeight: 700
  },
  eventinputLink: {
    width: '100%',
    '& input': {
      height: 19,
      color: theme.palette.primary.main,
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  helperText: {
    fontSize: '.875rem !important',
    fontWeight: 600
  },
  phone: {
    fontWeight: 600,
    fontSize: '.875rem'
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem',
    cursor: 'pointer',

    '&.disabled': {
      color: theme.palette.disabled.main,
      cursor: 'auto'
    }
  },
  saveButton: {
    width: 200,
    height: 48,
    alignSelf: 'center',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  contactButton: {
    width: 300,
    height: 43,
    borderRadius: 20,
    fontSize: '1.25rem',
    '& span': {
      fontWeight: 700
    }
  },
  checkMiniLabel: {
    fontSize: '.75rem'
  },
  checkbox: {
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  whatsAppContainer: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      marginLeft: 0
    },

    [theme.breakpoints.up('lg')]: {
      marginRight: -theme.spacing(17)
    }
  },
  whatsAppTitle: {
    fontSize: '.875rem',
    flexShrink: 0,
    fontWeight: 600
  },
  label: {
    color: theme.palette.primary.main
  },
  phoneForm: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  }
}))
