import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, MainLayout, NavBar, PageContainer } from 'shared'
import { load, loadReset, loadTicketTypeFees } from 'state/modules/events'
import { getDateEventFormat, validateServiceLetter } from 'utils/functions'

import { InfoMessageDialog, PlaceholderLoading, SettingForm } from './components'
import { useStyles } from './InscriptionPage.style'

const InscriptionPage = () => {
  const classes = useStyles()
  const { slugUrlOrId } = useParams()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { event, pending, error: asyncError, ticketTypeFees } = useSelector((state) => state.events)

  const [infoOpenDialog, setInfoOpenDialog] = useState(false)

  const activeLoading = (!pending && !event && !asyncError) || pending

  useEffect(() => {
    dispatch(load(slugUrlOrId))
    return () => {
      dispatch(loadReset())
    }
  }, [dispatch, slugUrlOrId, user])

  useEffect(() => {
    if (event?.activeInscriptionForm && !event.activeInscriptionForm.isDraft)
      setInfoOpenDialog(true)
  }, [event])

  useEffect(() => {
    dispatch(loadTicketTypeFees())
  }, [])

  const renderLoading = () => <PlaceholderLoading />

  const controlError = () => {
    const {
      externalInscriptionUrl,
      to,
      organization,
      isSuspended,
      isFree,
      organization: eventOwner
    } = event
    const canEditInscription = moment(to).isSameOrAfter(moment())
    const updateServiceLetterRequired =
      !isFree &&
      (!eventOwner.hasActiveContract ||
        (!!ticketTypeFees.length && validateServiceLetter(eventOwner, ticketTypeFees)))

    if (!organization.loggedUserMetadata?.canEdit)
      return (
        <Typography variant='h5' color='primary'>
          No tiene los permisos necesarios para acceder a esta página. Ponte en contacto con el
          dueño del evento.
        </Typography>
      )
    else if (externalInscriptionUrl)
      return (
        <Typography variant='h5' color='primary'>
          Ya existe un formulario de inscripción activo.
        </Typography>
      )
    else if (!canEditInscription)
      return (
        <Typography variant='h5' color='primary'>
          El evento ha finalizado, no puedes crear o editar una configuración de inscripción.
        </Typography>
      )
    else if (isSuspended)
      return (
        <Typography variant='h5' color='primary'>
          El evento se encuentra suspendido, no puedes crear o editar una configuración de
          inscripción.
        </Typography>
      )
    else if (updateServiceLetterRequired)
      return (
        <Typography variant='h5' color='primary'>
          El referente debe firmar la carta de servicio para crear o editar una configuración de
          inscripción.
        </Typography>
      )
  }

  const renderPage = () =>
    !event ? (
      <div className={classes.notFoundContainer}>
        <Typography color='primary' variant='h5'>
          Ocurrió un error al cargar el formulario
        </Typography>
        <Typography color='primary'>Verifique el enlace.</Typography>
      </div>
    ) : (
      renderInscriptionPage()
    )

  const renderInscriptionPage = () => {
    const { from, to } = event
    const fromLocal = moment(from)
    const toLocal = moment(to)
    const eventDateFormat = getDateEventFormat(fromLocal, toLocal)
    const errors = controlError()

    return (
      <>
        {!errors ? (
          <>
            <div className={classes.headerContainer}>
              <div className={classes.dateContainer}>
                <Typography variant='h6'>{eventDateFormat}</Typography>
              </div>
              <Typography color='primary' variant='h6' className={classes.eventName}>
                {event.name}
              </Typography>
            </div>
            <InfoMessageDialog onClose={() => setInfoOpenDialog(false)} open={infoOpenDialog} />
            <SettingForm event={event} />
          </>
        ) : (
          <div className={classes.messageContainer}>{errors}</div>
        )}
      </>
    )
  }
  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} />
      <PageContainer altMode>{activeLoading ? renderLoading() : renderPage()}</PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default InscriptionPage
