import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyle } from './PlaceHolderLoader.style'

const PlaceHolderLoader = () => {
  const classes = useStyle()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  const title = 'Cargando...'

  return (
    <div>
      <Media>
        {({ breakpoints, currentBreakpoint }) =>
          breakpoints[currentBreakpoint] < breakpoints.sm ? (
            <ContentLoader
              backgroundColor={bgColor}
              foregroundColor={fgColor}
              title={title}
              className={classes.containerHorizontal}>
              <circle cx='163' cy='245' r='74' className={classes.containerImg} />
              <rect x='0' y='35' rx='3' ry='3' className={classes.backgroundImage} />
              <rect x='100' y='331' rx='3' ry='3' className={classes.fullName} />
              <rect x='118' y='355' rx='3' ry='3' className={classes.userName} />
              <rect x='40' y='445' rx='3' ry='3' className={classes.followers} />
              <rect x='200' y='445' rx='3' ry='3' className={classes.butonFollow} />
              <rect x='10' y='520' rx='3' ry='3' className={classes.title} />
              <rect x='250' y='520' rx='3' ry='3' className={classes.title} />
              <rect x='10' y='553' rx='3' ry='3' className={classes.subtitle} />
              <rect x='10' y='570' rx='3' ry='3' className={classes.text} />
              <rect x='10' y='600' rx='3' ry='3' className={classes.subtitle} />
              <rect x='10' y='617' rx='3' ry='3' className={classes.text} />
              <rect x='10' y='647' rx='3' ry='3' className={classes.subtitle} />
              <rect x='10' y='664' rx='3' ry='3' className={classes.text} />
              <rect x='10' y='694' rx='3' ry='3' className={classes.subtitle} />
              <rect x='10' y='711' rx='3' ry='3' className={classes.text} />
              <rect x='10' y='741' rx='3' ry='3' className={classes.subtitle} />
              <rect x='15' y='768' rx='3' ry='3' className={classes.social} />
              <rect x='60' y='768' rx='3' ry='3' className={classes.social} />
              <rect x='105' y='768' rx='3' ry='3' className={classes.social} />
              <rect x='150' y='768' rx='3' ry='3' className={classes.social} />
              <rect x='10' y='855' rx='3' ry='3' className={classes.title} />
              <rect x='250' y='855' rx='3' ry='3' className={classes.title} />
              <circle cx='40' cy='940' r='35' />
              <rect x='90' y='935' rx='3' ry='3' className={classes.followed} />
              <circle cx='40' cy='1030' r='35' />
              <rect x='90' y='1025' rx='3' ry='3' className={classes.followed} />
              <rect x='10' y='1110' rx='3' ry='3' className={classes.title} />
              <rect x='250' y='1110' rx='3' ry='3' className={classes.title} />
              <rect x='10' y='1140' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='1140' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='1163' rx='3' ry='3' className={classes.body} />
              <rect x='110' y='1180' rx='3' ry='3' className={classes.body} />
              <rect x='110' y='1197' rx='3' ry='3' className={classes.body} />
              <rect x='10' y='1227' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='1227' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='1250' rx='3' ry='3' className={classes.body} />
              <rect x='110' y='1267' rx='3' ry='3' className={classes.body} />
              <rect x='110' y='1284' rx='3' ry='3' className={classes.body} />
              <rect x='10' y='1314' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='1314' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='1337' rx='3' ry='3' className={classes.body} />
              <rect x='110' y='1354' rx='3' ry='3' className={classes.body} />
              <rect x='110' y='1371' rx='3' ry='3' className={classes.body} />

              <rect x='10' y='1451' rx='3' ry='3' className={classes.title} />
              <rect x='250' y='1451' rx='3' ry='3' className={classes.title} />
              <rect x='10' y='1481' rx='3' ry='3' className={classes.subtitle} />
              <rect x='10' y='1511' rx='3' ry='3' width='70%' height={8} />
              <rect x='10' y='1526' rx='3' ry='3' width='70%' height={8} />
              <rect x='10' y='1541' rx='3' ry='3' width='70%' height={8} />
              <rect x='10' y='1560' rx='3' ry='3' width='20%' height={8} />
              <rect x='200' y='1560' rx='3' ry='3' width='30%' height={8} />
              <rect x='10' y='1590' rx='3' ry='3' className={classes.subtitle} />
              <rect x='10' y='1620' rx='3' ry='3' width='70%' height={8} />
              <rect x='10' y='1635' rx='3' ry='3' width='70%' height={8} />
              <rect x='10' y='1650' rx='3' ry='3' width='70%' height={8} />
              <rect x='10' y='1669' rx='3' ry='3' width='20%' height={8} />
              <rect x='200' y='1669' rx='3' ry='3' width='30%' height={8} />
            </ContentLoader>
          ) : (
            <ContentLoader
              backgroundColor={bgColor}
              foregroundColor={fgColor}
              title={title}
              className={classes.container}>
              <rect x='0' y='40' rx='3' ry='3' className={classes.backgroundImage} />
              <circle cx='130' cy='450' r='74' rx='10' ry='10' className={classes.containerImg} />
              <rect x='230' y='480' rx='3' ry='3' className={classes.fullName} />
              <rect x='230' y='510' rx='3' ry='3' className={classes.userName} />
              <rect x='325' y='610' rx='3' ry='3' className={classes.followers} />
              <rect x='800' y='605' rx='3' ry='3' className={classes.butonFollow} />
              <rect x='10' y='690' rx='3' ry='3' className={classes.title} />
              <rect x='10' y='710' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='720' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='740' rx='3' ry='3' className={classes.body} />
              <rect x='670' y='730' rx='3' ry='3' width={100} height={10} />

              <rect x='10' y='770' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='790' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='810' rx='3' ry='3' className={classes.body} />
              <rect x='670' y='800' rx='3' ry='3' width={100} height={10} />

              <rect x='10' y='840' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='860' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='880' rx='3' ry='3' className={classes.body} />
              <rect x='670' y='870' rx='3' ry='3' width={100} height={10} />

              <rect x='10' y='910' rx='3' ry='3' className={classes.position} />
              <rect x='110' y='930' rx='3' ry='3' className={classes.date} />
              <rect x='110' y='950' rx='3' ry='3' className={classes.body} />
              <rect x='670' y='940' rx='3' ry='3' width={100} height={10} />

              <rect x='820' y='690' rx='3' ry='3' className={classes.title} />
              <rect x='820' y='710' rx='3' ry='3' className={classes.subtitle} />
              <rect x='820' y='730' rx='3' ry='3' className={classes.text} />
              <rect x='820' y='760' rx='3' ry='3' className={classes.subtitle} />
              <rect x='820' y='780' rx='3' ry='3' className={classes.text} />
              <rect x='820' y='810' rx='3' ry='3' className={classes.subtitle} />
              <rect x='820' y='830' rx='3' ry='3' className={classes.text} />
              <rect x='820' y='810' rx='3' ry='3' className={classes.subtitle} />
              <rect x='820' y='880' rx='3' ry='3' className={classes.text} />
              <rect x='820' y='910' rx='3' ry='3' className={classes.subtitle} />
              <rect x='835' y='940' rx='3' ry='3' className={classes.social} />
              <rect x='915' y='940' rx='3' ry='3' className={classes.social} />
              <rect x='995' y='940' rx='3' ry='3' className={classes.social} />
              <rect x='1075' y='940' rx='3' ry='3' className={classes.social} />

              <rect x='10' y='1000' rx='3' ry='3' className={classes.title} />
              <rect x='10' y='1040' rx='3' ry='3' className={classes.date} />
              <rect x='130' y='1040' rx='3' ry='3' className={classes.body} />
              <rect x='130' y='1060' rx='3' ry='3' className={classes.body} />
              <rect x='650' y='1050' rx='3' ry='3' width='10%' height={8} />

              <rect x='10' y='1090' rx='3' ry='3' className={classes.date} />
              <rect x='130' y='1090' rx='3' ry='3' className={classes.body} />
              <rect x='130' y='1110' rx='3' ry='3' className={classes.body} />
              <rect x='650' y='1100' rx='3' ry='3' width='10%' height={8} />

              <rect x='10' y='1150' rx='3' ry='3' className={classes.date} />
              <rect x='130' y='1150' rx='3' ry='3' className={classes.body} />
              <rect x='130' y='1170' rx='3' ry='3' className={classes.body} />
              <rect x='650' y='1160' rx='3' ry='3' width='10%' height={8} />
              <rect x='820' y='1000' rx='3' ry='3' className={classes.title} />
              <circle cx='860' cy='1080' r='35' />
              <rect x='910' y='1080' rx='3' ry='3' className={classes.followed} />
              <circle cx='860' cy='1180' r='35' />
              <rect x='910' y='1180' rx='3' ry='3' className={classes.followed} />
            </ContentLoader>
          )
        }
      </Media>
    </div>
  )
}

export default PlaceHolderLoader
