import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import {
  Close as CloseIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { EditableRichText } from 'shared/EditableRichText'
import { ShareUrlMenuDialog } from 'shared/ShareUrlMenuDialog'
import { verifyUserNotLogged } from 'state/modules/app'
import { reactEvent } from 'state/modules/events'
import { EVENT_STATES } from 'utils/constants'

import { useStyles } from './EventCardPreview.style'

const EventCardPreview = ({
  disciplinesToShow,
  imageUrl,
  eventDateFormat,
  organizationsToShow,
  locationEvent,
  open,
  like,
  eventDisciplines,
  afterEvent,
  setIsModalOpen,
  event
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { pendingReact } = useSelector((state) => state.events)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    likesCount,
    createdAt,
    updatedAt,
    slugUrl,
    id,
    name,
    isSuspended,
    profileImages,
    state,
    hasResults,
    hasQuota
  } = event

  const handleActionEvent = (action) => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(action(event))
    }
  }

  const onClose = () => setIsModalOpen(false)

  const hasProfileImage = Array.isArray(profileImages) && profileImages.length > 0

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      fullWidth
      fullScreen={isDesktop}
      open={open}
      onClose={onClose}
      className={classes.container}>
      <div className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.date}>{eventDateFormat}</Typography>
          <div className={classes.endHeader}>
            <div className={classes.sportContainer}>
              {disciplinesToShow.map((d) => (
                <div className={classes.sport} key={d.id} title={d.title}>
                  {d.name}
                </div>
              ))}
            </div>

            {!isDesktop && (
              <IconButton className={classes.closeButton} size='small' onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.featureImageContainer} id='feature-image-container'>
          {!hasQuota && !hasResults && !afterEvent && (
            <div className={clsx(classes.banner, classes.noQuotaBanner)}>
              <Typography className={classes.altBannerTitle}>AGOTADO</Typography>
            </div>
          )}

          {state === EVENT_STATES.SUSPENDED && (
            <div className={clsx(classes.banner, classes.suspendedBanner)}>
              <Typography className={classes.bannerTitle}>SUSPENDIDO</Typography>
            </div>
          )}

          {state === EVENT_STATES.CANCELLED && (
            <div className={clsx(classes.banner, classes.cancelledBanner)}>
              <Typography className={classes.bannerTitle}>CANCELADO</Typography>
            </div>
          )}
          {hasProfileImage ? (
            <div
              className={classes.featureImage}
              id='feature-image'
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          ) : (
            eventDisciplines
              .filter((x) => !!x.imageName)
              .map((x) => (
                <div
                  key={x.id}
                  className={clsx(
                    classes.featureImage,
                    x.className,
                    `size-${eventDisciplines.filter((x) => !!x.imageName).length}`
                  )}
                  style={{ backgroundImage: `url(${x.imgUrl})` }}
                />
              ))
          )}
        </div>

        <div className={classes.actionSocialBarButton}>
          <IconButton
            className={classes.actionButton}
            disabled={pendingReact}
            onClick={() => handleActionEvent(reactEvent)}>
            {like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            {!!likesCount && <em>{likesCount}</em>}
          </IconButton>
          <ShareUrlMenuDialog
            id={id}
            updatedAt={updatedAt ?? createdAt}
            type='event'
            isHiddenLabel
            isOutlineIcon
            buttonClassName={classes.shareButton}
            copyMessage='El link del evento fue copiado al portapapeles'
            suspended={isSuspended}
            slugUrl={slugUrl}
            title={name}
          />
        </div>
        <div className={classes.nameEventContainer}>
          <Typography color='primary' variant='h5' className={classes.nameEvent} title={name}>
            {name}
          </Typography>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.textContainer}>
            <div className={classes.organizationContainer}>
              <Typography
                className={classes.organization}
                title={`Organiza ${organizationsToShow}`}>
                Organiza <span>{organizationsToShow}</span>
              </Typography>
            </div>
            <div className={classes.locationContainer}>
              <Typography component='p' title={locationEvent}>
                {locationEvent}
              </Typography>
            </div>
            <div className={classes.organizationContainer}>
              <EditableRichText
                className={classes.description}
                entity={event}
                readOnly
                field='description'
              />
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default EventCardPreview
