import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { INFORMATION_NAME_FIELDS } from 'utils/constants'

import { OrganizationWidget } from '../OrganizationWidget'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './OrganizationsSection.style'

const OrganizationsSection = ({ event, eventOwner, afterEvent, isAdmin, canEdit }) => {
  const classes = useStyles()

  const { organizations, isSuspended, slugUrl } = event

  const organizationList = organizations
    .filter((e) => !e.rejectedAt && e.organization.id !== eventOwner.id)
    .filter(
      (e) =>
        isAdmin || !!e.acceptedAt || (e.organization.loggedUserMetadata?.canEdit && !afterEvent)
    )
    .sort((x, y) => (!x.acceptedAt || !y.acceptedAt ? -1 : x.acceptedAt - y.acceptedAt))

  return (
    <SectionBlock title={!isAdmin ? 'Organizado por' : 'Organiza'}>
      <OrganizationWidget organization={event.organization} />

      <div className={classes.organizationsContainer}>
        {organizationList.map(({ organization, acceptedAt }) => (
          <OrganizationWidget
            key={organization.id}
            organization={organization}
            pendingInvitation={!isSuspended && !acceptedAt}
            pendingConfirm={eventOwner.loggedUserMetadata?.canEdit && !acceptedAt}
          />
        ))}

        {canEdit && (
          <Link
            to={`${ROUTES.EVENTS.EDIT}/${slugUrl}?focus=${INFORMATION_NAME_FIELDS.ORGANIZATIONS}`}
            className={clsx(classes.link, isSuspended && 'disabled')}
            onClick={(e) => {
              if (isSuspended) e.preventDefault()
            }}>
            + Añadir organización
          </Link>
        )}
      </div>
    </SectionBlock>
  )
}

export default OrganizationsSection
