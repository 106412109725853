import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ReportProblemSection.style'

const EVENT_PROBLEM_OPTIONS = [
  'Necesito cancelar el evento',
  'Problema con inscripciones/inscriptos',
  'Problema con los pagos',
  'Problema con formulario de inscripción',
  'Necesito ampliar tiempo de suspensión',
  'Otro'
]

const ReportProblemSection = () => {
  const classes = useStyles()

  const { values, handleBlur, errors, handleChange } = useFormikContext()

  return (
    <SectionBlock title='Reportar Problema'>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='select-outlined-label' className={classes.labelSelect}>
          Seleccione problema
        </InputLabel>
        <Select
          value={values.problem}
          onBlur={handleBlur}
          onChange={handleChange}
          name='problem'
          MenuProps={{
            PaperProps: {
              className: classes.menuContainer
            }
          }}
          className={classes.select}>
          {EVENT_PROBLEM_OPTIONS.map((x) => (
            <MenuItem
              value={x}
              key={x}
              className={classes.item}
              classes={{
                selected: classes.itemSelected
              }}>
              {x}
            </MenuItem>
          ))}
        </Select>

        <Typography variant='caption' className={classes.helperText}>
          {errors.problem || 'Seleccione el problema de su evento '}
        </Typography>
      </FormControl>
    </SectionBlock>
  )
}

export default ReportProblemSection
