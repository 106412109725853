import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.utils.rgba('#D9D9D966', 0.4),
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(3, 7),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    marginTop: theme.spacing(3)
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 180
  },
  subtitle: {
    marginTop: theme.spacing(3),
    color: '#696969',
    [theme.breakpoints.down('md')]: {
      fontSize: '.9rem'
    }
  },
  buttonContainer: {
    alignSelf: 'center',
    position: 'relative'
  },
  button: {
    height: 41,
    width: 215
  },
  table: {
    borderSpacing: theme.spacing(0, 0.7),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10)
  },
  row: {
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    color: '#000',
    position: 'relative',
    fontWeight: 600
  },
  column: {
    padding: theme.spacing(3, 2),
    textAlign: 'left',
    color: '#696969',
    fontSize: '1rem',
    fontWeight: 600
  },
  bodyColumn: {
    padding: theme.spacing(1, 2),
    textAlign: 'left'
  },
  infoIcon: {
    color: '#FF9D29',
    position: 'absolute',
    right: -15,
    bottom: 5
  },
  arrow: {
    color: '#FF9D29'
  },
  tooltip: {
    backgroundColor: '#FF9D29',
    fontSize: '.75rem',
    [theme.breakpoints.up('md')]: {
      width: 320,
      maxWidth: 320
    }
  },
  alertMessage: {
    fontWeight: 700,
    fontSize: '.875rem',
    paddingBottom: theme.spacing()
  },
  formControl: {
    alignSelf: 'flex-start',
    display: 'flex',
    marginRight: 0,

    '& svg': {
      color: theme.palette.primary.main,
      width: 20,
      height: 20
    },

    '&.disabled': {
      '& svg': {
        color: '#696969'
      }
    }
  },
  formControlLabel: {
    fontWeight: 600,
    fontSize: '.875rem',
    color: '#3C3C3C'
  },
  selectAssociateContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column'
  },
  asocciateContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  associateTitle: {
    fontWeight: 600,
    '&.disabled': {
      color: '#696969'
    }
  },
  ticketExpired: {
    color: '#696969'
  },
  ticketActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.1)
  },
  errorTicket: {
    color: `${theme.palette.error.main} !important`
  },
  errorTicketTooltip: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.contrastText} !important`,
    boxShadow: 'none',
    fontSize: '.75rem',
    padding: theme.spacing(),
    margin: '0px !important',
    width: '180px !important',
    [theme.breakpoints.up('md')]: {
      marginBottom: '12px !important'
    }
  },
  errorTicketArrow: {
    color: `${theme.palette.error.main} !important`
  },
  alertInfoIcon: {},
  closeButton: {
    top: '3px !important',
    right: '2px !important',
    '& svg': {
      height: '12px !important',
      width: '12px !important'
    },
    [theme.breakpoints.up('md')]: {
      right: '8px !important'
    }
  },
  alertButton: {
    position: 'absolute',
    right: -15,
    top: '45%',
    '& svg': {
      color: theme.palette.tooltip.light
    },

    [theme.breakpoints.up('md')]: {
      top: '30%'
    }
  },
  alertTooltip: {
    backgroundColor: theme.palette.tooltip.light,
    fontSize: '.75rem',
    padding: `${theme.spacing(1, 2)} !important`,
    width: '180px !important'
  },
  tooltipCloseButton: {
    top: '0px !important',
    right: '3px !important'
  },
  infoText: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  alertTooltipArrow: {
    color: theme.palette.tooltip.light
  }
}))
