import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  disabledTitle: {
    color: theme.palette.disabled.main,
    borderColor: theme.palette.disabled.main
  },
  emptyMessage: {
    fontWeight: 600,
    fontSize: '.875rem',
    whiteSpace: 'pre-wrap'
  },
  helperText: {
    fontWeight: 600,
    marginTop: theme.spacing(),
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap'
  },
  categoriesOptionContainer: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing()
  },
  option: {
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap'
  }
}))
