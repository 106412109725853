import React from 'react'
import { makeStyles, Switch, withStyles } from '@material-ui/core'

export const StyledDistanceSwitch = withStyles((theme) => ({
  root: {
    width: 43,
    height: 19,
    padding: 0,
    margin: theme.spacing(1),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 9
  },
  switchBase: {
    padding: '3px 5px',

    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.primary.main,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '1px solid #0600AD'
    }
  },
  thumb: {
    width: 18,
    height: 13,

    color: theme.palette.primary.contrastText,
    borderRadius: 9
  },
  track: {
    borderRadius: 9,
    backgroundColor: '#969696',
    opacity: 1
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

export const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  filterTitle: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    '&.disabled': {
      color: '#969696'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    },
    '&.disabled': {
      '& svg': {
        color: theme.palette.primary.contrastText
      },
      '& > div': {
        borderColor: '#969696',
        borderRadius: 0,
        boxShadow: 'none'
      }
    }
  },
  textField: {
    width: '100%',
    '& input': {
      textAlign: 'right',
      height: 2
    },
    '& fieldset': {
      height: 43,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
      borderColor: theme.palette.primary.main
    }
  },
  inputEdit: {
    marginTop: 0,
    width: '100%',
    '& fieldset': {
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
      borderColor: theme.palette.primary.main
    }
  },
  table: {
    width: '100%',
    marginTop: 20
  },
  row: {
    '& th': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: '1rem'
    }
  },
  distanceRow: {
    '& th': {
      color: '#696969',
      fontWeight: 600,
      fontSize: '1rem'
    }
  },
  bodyRow: {
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
  },
  resumeRow: {
    fontWeight: 700,
    '& td': {
      color: '#696969'
    }
  },
  indexCol: {
    width: '3% !important'
  },
  col: {
    textAlign: 'left',
    padding: 4,
    width: '15%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  nameCol: {
    width: '25%',
    paddingLeft: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2)
    }
  },

  actionCol: {
    width: '10%',
    position: 'relative'
  },

  genderCol: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4)
    }
  },
  totalCategories: {
    paddingLeft: theme.spacing(),
    width: '23%',
    textAlign: 'center'
  },
  text: {
    fontSize: '1rem'
  },

  errorMessage: {
    alignSelf: 'flex-start'
  },

  emptyMessageContainer: {
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.utils.rgba('#D9D9D999', 0.6)
  },
  emptyMessage: {
    color: '#FF4B4B',
    fontWeight: 700,
    paddingLeft: theme.spacing(2)
  },
  createDistanceButtonContainer: {
    margin: theme.spacing(3, 0),
    width: 185
  },
  createDistanceButton: {
    padding: theme.spacing(1, 5)
  },
  tooltip: {
    width: 200,
    [theme.breakpoints.up(750)]: {
      width: '100%',
      maxWidth: 450
    }
  },
  addCategoryButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 4)
  },
  limitCategoryContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  ellipsisCol: {
    maxWidth: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  distanceNameCol: {
    width: '20%',
    paddingLeft: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2)
    }
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  panel: {
    position: 'relative'
  },
  messageContainer: {
    top: 'calc(50% - 5px)'
  },
  categoryError: {
    color: `${theme.palette.error.main} !important`
  },
  errorTicketTooltip: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.contrastText} !important`,
    boxShadow: 'none',
    fontSize: '.75rem',
    padding: theme.spacing(),
    width: '195px !important'
  },
  errorTicketArrow: {
    color: `${theme.palette.error.main} !important`
  },
  alertInfoIcon: {
    position: 'absolute',
    right: -10,
    top: '45%',

    [theme.breakpoints.up('md')]: {
      top: '30%'
    }
  },
  closeButton: {
    top: '3px !important',
    right: '2px !important',
    '& svg': {
      height: '12px !important',
      width: '12px !important'
    },
    [theme.breakpoints.up('md')]: {
      right: '8px !important'
    }
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      alignItems: 'flex-start',
      flexDirection: 'row'
    }
  },
  alertButton: {
    position: 'absolute',
    right: -15,
    top: '30%',

    '& svg': {
      color: theme.palette.tooltip.light
    }
  },
  alertTooltipArrow: {
    color: theme.palette.tooltip.light
  },
  tooltipPosition: {
    top: '20px !important',
    [theme.breakpoints.up('md')]: {
      top: '0px !important',
      left: '15px !important'
    }
  },
  alertTooltip: {
    backgroundColor: theme.palette.tooltip.light,
    fontSize: '.75rem',
    padding: `${theme.spacing(1, 2)} !important`,
    width: '180px !important'
  },

  tooltipCloseButton: {
    top: '0px !important',
    right: '5px !important'
  }
}))
