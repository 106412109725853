import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { LocationPicker } from 'shared'
import { STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './LocationSection.style'

const LocationSection = ({ event }) => {
  const classes = useStyles()

  const { latitude, longitude, countryName, countryId } = event

  const country = countryId
    ? {
        value: countryId,
        label: countryName
      }
    : STRING_EMPTY

  const hasCoord = latitude !== 0 && longitude !== 0

  useEffect(() => {
    onCountryChange()(country)
  }, [])

  const getAddress = (event) =>
    [
      event.countryName,
      event.areaLevel1Name,
      event.address,
      event.latitude === 0 &&
        event.longitude === 0 &&
        'la ubicación exacta se informará próximamente'
    ]
      .filter(Boolean)
      .join(', ')

  const onCountryChange = (setValues) => async (options) => {
    try {
      if (setValues) {
        setValues((values) => ({
          ...values,
          country: options,
          countryId: options.value,
          areaLevel1: STRING_EMPTY,
          areaLevel1Id: STRING_EMPTY
        }))
      }
    } catch (e) {}
  }

  return (
    <SectionBlock title='Ubicación'>
      <div className={classes.locationContainer}>
        <Typography variant='body1' className={classes.address}>
          {getAddress(event)}.
        </Typography>
      </div>

      {hasCoord && (
        <LocationPicker
          latitude={latitude}
          longitude={longitude}
          variant='outlined'
          buttonClassName={classes.locationButton}
        />
      )}
    </SectionBlock>
  )
}

export default LocationSection
