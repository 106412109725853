import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: '950px !important',
      maxWidth: '950px !important'
    }
  },

  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  subtitle: {
    fontWeight: 500
  },
  formControl: {
    width: 350,
    alignSelf: 'center'
  },
  labelSelect: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingRight: theme.spacing(),
    backgroundColor: theme.palette.primary.contrastText,
    transform: 'translate(14px, 13px) scale(1)'
  },
  alertText: {
    fontSize: '1rem',
    fontWeight: 500
  },
  select: {
    height: 48,
    width: 350,
    backgroundColor: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(),

    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(0.5),
      fontWeight: 500
    },

    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },

    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },

    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    }
  },
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.6)
    }
  },
  itemSelected: {
    display: 'none'
  },
  helperText: {
    fontWeight: 500
  },
  menuContainer: {
    marginTop: theme.spacing(4)
  },
  editor: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
    '&.error': {
      '& .ql-toolbar': {
        border: `1px solid ${theme.palette.error.main}`
      },
      '& .ql-container': {
        border: `1px solid  ${theme.palette.error.main}`
      },
      '& .ql-editor': {
        '&:focus': {
          border: `1px solid  ${theme.palette.error.main}`
        }
      }
    }
  },
  saveButton: {
    alignSelf: 'center',
    width: 250,
    height: 48,
    borderRadius: 10,
    marginTop: theme.spacing(2)
  },
  input: {
    marginBottom: theme.spacing(),
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    }
  }
}))
