import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  formControl: {},
  select: {
    height: 40,
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(1.25),
      fontWeight: 500
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    },
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  labelSelect: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingRight: theme.spacing(),
    backgroundColor: theme.palette.primary.contrastText,
    transform: 'translate(14px, 13px) scale(1)',
    '&.error': {
      color: theme.palette.error.main
    }
  },
  helperText: {
    marginLeft: theme.spacing()
  },
  menuContainer: {
    marginTop: theme.spacing(5)
  },
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.64)
    }
  },
  itemSelected: {
    display: 'none'
  },
  input: {
    marginBottom: theme.spacing(),
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    },
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '88%',
      margin: '0 auto'
    }
  }
}))
