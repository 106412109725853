// Action Types
export const LOAD_ATTEMPT = 'events/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'events/LOAD_SUCCESS'
export const LOAD_FAILURE = 'events/LOAD_FAILURE'
export const EVENT_RESET = 'events/EVENT_RESET'

export const CREATE_ATTEMPT = 'events/CREATE_ATTEMPT'
export const CREATE_SUCCESS = 'events/CREATE_SUCCESS'
export const CREATE_FAILURE = 'events/CREATE_FAILURE'

export const SET_IMAGE_ATTEMPT = 'events/SET_IMAGE_ATTEMPT'
export const SET_IMAGE_SUCCESS = 'events/SET_IMAGE_SUCCESS'
export const SET_IMAGE_FAILURE = 'events/SET_IMAGE_FAILURE'

export const SET_SEARCH_PARAMS = 'events/SET_SEARCH_PARAMS'

export const SEARCH_ATTEMPT = 'events/SEARCH_ATTEMPT'
export const SEARCH_SUCCESS = 'events/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'events/SEARCH_FAILURE'
export const RESET_SEARCH = 'events/RESET_SEARCH'

export const SET_ADDRESS = 'events/SET_ADDRESS'

export const SEARCH_METADATA_ATTEMPT = 'events/SEARCH_METADATA_ATTEMPT'
export const SEARCH_METADATA_SUCCESS = 'events/SEARCH_METADATA_SUCCESS'
export const SEARCH_METADATA_FAILURE = 'events/SEARCH_METADATA_FAILURE'

export const LOAD_RESULTS_ATTEMPT = 'events/LOAD_RESULTS_ATTEMPT'
export const LOAD_RESULTS_SUCCESS = 'events/LOAD_RESULTS_SUCCESS'
export const LOAD_RESULTS_FAILURE = 'events/LOAD_RESULTS_FAILURE'

export const UPLOAD_RESULTS_ATTEMPT = 'events/UPLOAD_RESULTS_ATTEMPT'
export const UPLOAD_RESULTS_SUCCESS = 'events/UPLOAD_RESULTS_SUCCESS'
export const UPLOAD_RESULTS_FAILURE = 'events/UPLOAD_RESULTS_FAILURE'

export const DELETE_RESULTS_ATTEMPT = 'events/DELETE_RESULTS_ATTEMPT'
export const DELETE_RESULTS_SUCCESS = 'events/DELETE_RESULTS_SUCCESS'
export const DELETE_RESULTS_FAILURE = 'events/DELETE_RESULTS_FAILURE'

export const SUSPEND_ATTEMPT = 'events/SUSPEND_ATTEMPT'
export const SUSPEND_SUCCESS = 'events/SUSPEND_SUCCESS'
export const SUSPEND_FAILURE = 'events/SUSPEND_FAILURE'

export const DOWNLOAD_RESULTS_TEMPLATE_ATTEMPT = 'events/DOWNLOAD_RESULTS_TEMPLATE_ATTEMPT'
export const DOWNLOAD_RESULTS_TEMPLATE_SUCCESS = 'events/DOWNLOAD_RESULTS_TEMPLATE_SUCCESS'
export const DOWNLOAD_RESULTS_TEMPLATE_FAILURE = 'events/DOWNLOAD_RESULTS_TEMPLATE_FAILURE'

export const DOWNLOAD_RESULTS_SHEET_ATTEMPT = 'events/DOWNLOAD_RESULTS_SHEET_ATTEMPT'
export const DOWNLOAD_RESULTS_SHEET_SUCCESS = 'events/DOWNLOAD_RESULTS_SHEET_SUCCESS'
export const DOWNLOAD_RESULTS_SHEET_FAILURE = 'events/DOWNLOAD_RESULTS_SHEET_FAILURE'

export const DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_ATTEMPT =
  'events/DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_ATTEMPT'
export const DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_SUCCESS =
  'events/DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_SUCCESS'
export const DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_FAILURE =
  'events/DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_FAILURE'

export const DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_ATTEMPT =
  'events/DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_ATTEMPT'
export const DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_SUCCESS =
  'events/DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_SUCCESS'
export const DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_FAILURE =
  'events/DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_FAILURE'

export const DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_ATTEMPT =
  'events/DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_ATTEMPT'
export const DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_SUCCESS =
  'events/DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_SUCCESS'
export const DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_FAILURE =
  'events/DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_FAILURE'

export const DOWNLOAD_RESULTS_REPORT_ATTEMPT = 'events/DOWNLOAD_RESULTS_REPORT_ATTEMPT'
export const DOWNLOAD_RESULTS_REPORT_SUCCESS = 'events/DOWNLOAD_RESULTS_REPORT_SUCCESS'
export const DOWNLOAD_RESULTS_REPORT_FAILURE = 'events/DOWNLOAD_RESULTS_REPORT_FAILURE'

export const UPDATE_ATTEMPT = 'events/UPDATE_ATTEMPT'
export const UPDATE_FAILURE = 'events/UPDATE_FAILURE'
export const UPDATE_SUCCESS = 'events/UPDATE_SUCCESS'

export const REACT_ATTEMPT = 'events/REACT_ATTEMPT'
export const REACT_SUCCESS = 'events/REACT_SUCCESS'
export const REACT_FAILURE = 'events/REACT_FAILURE'

export const DELETE_ATTEMPT = 'events/DELETE_ATTEMPT'
export const DELETE_FAILURE = 'events/DELETE_FAILURE'
export const DELETE_SUCCESS = 'events/DELETE_SUCCESS'

export const UPDATE_CALENDAR_ATTEMPT = 'events/UPDATE_CALENDAR_ATTEMPT'
export const UPDATE_CALENDAR_SUCCESS = 'events/UPDATE_CALENDAR_SUCCESS'
export const UPDATE_CALENDAR_FAILURE = 'events/UPDATE_CALENDAR_FAILURE'

export const TOGGLE_PUBLISHED_ATTEMPT = 'events/TOGGLE_PUBLISHED_ATTEMPT'
export const TOGGLE_PUBLISHED_SUCCESS = 'events/TOGGLE_PUBLISHED_SUCCESS'
export const TOGGLE_PUBLISHED_FAILURE = 'events/TOGGLE_PUBLISHED_FAILURE'

export const DELETE_IMAGE_ATTEMPT = 'events/DELETE_IMAGE_ATTEMPT'
export const DELETE_IMAGE_SUCCESS = 'events/DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAILURE = 'events/DELETE_IMAGE_FAILURE'

export const RESET_SEARCH_PARAMS = 'events/RESET_SEARCH_PARAMS'

export const LOAD_LAST_RESULT_FOR_ME_ATTEMPT = 'events/LOAD_LAST_RESULT_FOR_ME_ATTEMPT'
export const LOAD_LAST_RESULT_FOR_ME_SUCCESS = 'events/LOAD_LAST_RESULT_FOR_ME_SUCCESS'
export const LOAD_LAST_RESULT_FOR_ME_FAILURE = 'events/LOAD_LAST_RESULT_FOR_ME_FAILURE'
export const RESET_LAST_RESULT_FOR_ME = 'events/RESET_LAST_RESULT_FOR_ME'

export const LOAD_LAST_RESULT_FOR_ME_ALL_ATTEMPT = 'events/LOAD_LAST_RESULT_FOR_ME_ALL_ATTEMPT'
export const LOAD_LAST_RESULT_FOR_ME_ALL_SUCCESS = 'events/LOAD_LAST_RESULT_FOR_ME_ALL_SUCCESS'
export const LOAD_LAST_RESULT_FOR_ME_ALL_FAILURE = 'events/LOAD_LAST_RESULT_FOR_ME_ALL_FAILURE'
export const RESET_LAST_RESULT_FOR_ME_ALL = 'events/RESET_LAST_RESULT_FOR_ME_ALL'

export const LOAD_UPCOMING_ALL_ATTEMPT = 'events/LOAD_UPCOMING_ALL_ATTEMPT'
export const LOAD_UPCOMING_ALL_SUCCESS = 'events/LOAD_UPCOMING_ALL_SUCCESS'
export const LOAD_UPCOMING_ALL_FAILURE = 'events/LOAD_UPCOMING_ALL_FAILURE'
export const RESET_UPCOMING_ALL = 'events/RESET_UPCOMING_ALL'

export const LOAD_LAST_RESULT_ALL_ATTEMPT = 'events/LOAD_LAST_RESULT_ALL_ATTEMPT'
export const LOAD_LAST_RESULT_ALL_SUCCESS = 'events/LOAD_LAST_RESULT_ALL_SUCCESS'
export const LOAD_LAST_RESULT_ALL_FAILURE = 'events/LOAD_LAST_RESULT_ALL_FAILURE'
export const RESET_LAST_RESULT_ALL = 'events/RESET_LAST_RESULT_ALL'

export const LOAD_UPCOMING_FOR_ME_ATTEMPT = 'events/LOAD_UPCOMING_FOR_ME_ATTEMPT'
export const LOAD_UPCOMING_FOR_ME_SUCCESS = 'events/LOAD_UPCOMING_FOR_ME_SUCCESS'
export const LOAD_UPCOMING_FOR_ME_FAILURE = 'events/LOAD_UPCOMING_FOR_ME_FAILURE'
export const RESET_UPCOMING_FOR_ME = 'events/RESET_UPCOMING_FOR_ME'

export const LOAD_UPCOMING_FOR_ME_ALL_ATTEMPT = 'events/LOAD_UPCOMING_FOR_ME_ALL_ATTEMPT'
export const LOAD_UPCOMING_FOR_ME_ALL_SUCCESS = 'events/LOAD_UPCOMING_FOR_ME_ALL_SUCCESS'
export const LOAD_UPCOMING_FOR_ME_ALL_FAILURE = 'events/LOAD_UPCOMING_FOR_ME_ALL_FAILURE'
export const RESET_UPCOMING_FOR_ME_ALL = 'events/RESET_UPCOMING_FOR_ME_ALL'

export const LOAD_CREATED_BY_ME_ATTEMPT = 'events/LOAD_CREATED_BY_ME_ATTEMPT'
export const LOAD_CREATED_BY_ME_SUCCESS = 'events/LOAD_CREATED_BY_ME_SUCCESS'
export const LOAD_CREATED_BY_ME_FAILURE = 'events/LOAD_CREATED_BY_ME_FAILURE'
export const RESET_CREATED_BY_ME = 'events/RESET_CREATED_BY_ME'

export const LOAD_CREATED_BY_ME_ALL_ATTEMPT = 'events/CREATED_BY_ME_ALL_ATTEMPT'
export const LOAD_CREATED_BY_ME_ALL_SUCCESS = 'events/CREATED_BY_ME_ALL_SUCCESS'
export const LOAD_CREATED_BY_ME_ALL_FAILURE = 'events/CREATED_BY_ME_ALL_FAILURE'
export const RESET_CREATED_BY_ME_ALL = 'events/RESET_CREATED_BY_ME_ALL'

export const LOAD_ACTIVE_INSCRIPTION_FORM_ATTEMPT = 'events/LOAD_ACTIVE_INSCRIPTION_FORM_ATTEMPT'
export const LOAD_ACTIVE_INSCRIPTION_FORM_SUCCESS = 'events/LOAD_ACTIVE_INSCRIPTION_FORM_SUCCESS'
export const LOAD_ACTIVE_INSCRIPTION_FORM_FAILURE = 'events/LOAD_ACTIVE_INSCRIPTION_FORM_FAILURE'

export const CREATE_INSCRIPTION_FORMS_ATTEMPT = 'events/CREATE_INSCRIPTION_FORMS_ATTEMPT'
export const CREATE_INSCRIPTION_FORMS_SUCCESS = 'events/CREATE_INSCRIPTION_FORMS_SUCCESS'
export const CREATE_INSCRIPTION_FORMS_FAILURE = 'events/CREATE_INSCRIPTION_FORMS_FAILURE'

export const UPDATE_INSCRIPTION_FORMS_ATTEMPT = 'events/UPDATE_INSCRIPTION_FORMS_ATTEMPT'
export const UPDATE_INSCRIPTION_FORMS_SUCCESS = 'events/UPDATE_INSCRIPTION_FORMS_SUCCESS'
export const UPDATE_INSCRIPTION_FORMS_FAILURE = 'events/UPDATE_INSCRIPTION_FORMS_FAILURE'

export const DELETE_INSCRIPTION_FORMS_ATTEMPT = 'events/DELETE_INSCRIPTION_FORMS_ATTEMPT'
export const DELETE_INSCRIPTION_FORMS_SUCCESS = 'events/DELETE_INSCRIPTION_FORMS_SUCCESS'
export const DELETE_INSCRIPTION_FORMS_FAILURE = 'events/DELETE_INSCRIPTION_FORMS_FAILURE'

export const RESET_INSCRIPTION_FORMS = 'events/RESET_INSCRIPTION_FORMS'

export const EVENT_INSCRIPTION_ATTEMPT = 'events/EVENT_INSCRIPTION_ATTEMPT'
export const EVENT_INSCRIPTION_SUCCESS = 'events/EVENT_INSCRIPTION_SUCCESS'
export const EVENT_INSCRIPTION_FAILURE = 'events/EVENT_INSCRIPTION_FAILURE'

export const LOAD_LIST_INSCRIPTION_ATTEMPT = 'events/LOAD_LIST_INSCRIPTION_ATTEMPT'
export const LOAD_LIST_INSCRIPTION_SUCCESS = 'events/LOAD_LIST_INSCRIPTION_SUCCESS'
export const LOAD_LIST_INSCRIPTION_FAILURE = 'events/LOAD_LIST_INSCRIPTION_FAILURE'
export const RESET_LIST_INSCRIPTION = 'events/RESET_LIST_INSCRIPTION'

export const SET_LIST_INSCRIPTION = 'events/SET_LIST_INSCRIPTION'

export const DELETE_LIST_INSCRIPTION_ATTEMPT = 'events/DELETE_LIST_INSCRIPTION_ATTEMPT'
export const DELETE_LIST_INSCRIPTION_SUCCESS = 'events/DELETE_LIST_INSCRIPTION_SUCCESS'
export const DELETE_LIST_INSCRIPTION_FAILURE = 'events/DELETE_LIST_INSCRIPTION_FAILURE'

export const LIST_INSCRIPTION_SEARCH_METADATA_ATTEMPT =
  'events/LIST_INSCRIPTION_SEARCH_METADATA_ATTEMPT'
export const LIST_INSCRIPTION_SEARCH_METADATA_SUCCESS =
  'events/LIST_INSCRIPTION_SEARCH_METADATA_SUCCESS'
export const LIST_INSCRIPTION_SEARCH_METADATA_FAILURE =
  'events/LIST_INSCRIPTION_SEARCH_METADATA_FAILURE'

export const UPLOAD_RULES_DOCUMENT_ATTEMPT = 'events/UPLOAD_RULES_DOCUMENT_ATTEMPT'
export const UPLOAD_RULES_DOCUMENT_SUCCESS = 'events/UPLOAD_RULES_DOCUMENT_SUCCESS'
export const UPLOAD_RULES_DOCUMENT_FAILURE = 'events/UPLOAD_RULES_DOCUMENT_FAILURE'

export const DOWNLOAD_RULES_DOCUMENT_ATTEMPT = 'events/DOWNLOAD_RULES_DOCUMENT_ATTEMPT'
export const DOWNLOAD_RULES_DOCUMENT_SUCCESS = 'events/DOWNLOAD_RULES_DOCUMENT_SUCCESS'
export const DOWNLOAD_RULES_DOCUMENT_FAILURE = 'events/DOWNLOAD_RULES_DOCUMENT_FAILURE'

export const DELETE_RULES_DOCUMENT_ATTEMPT = 'events/DELETE_RULES_DOCUMENT_ATTEMPT'
export const DELETE_RULES_DOCUMENT_SUCCESS = 'events/DELETE_RULES_DOCUMENT_SUCCESS'
export const DELETE_RULES_DOCUMENT_FAILURE = 'events/DELETE_RULES_DOCUMENT_FAILURE'

export const UPDATE_EVENT_INSCRIPTION_ATTEMPT = 'events/UPDATE_EVENT_INSCRIPTION_ATTEMPT'
export const UPDATE_EVENT_INSCRIPTION_SUCCESS = 'events/UPDATE_EVENT_INSCRIPTION_SUCCESS'
export const UPDATE_EVENT_INSCRIPTION_FAILURE = 'events/UPDATE_EVENT_INSCRIPTION_FAILURE'

export const LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_ATTEMPT =
  'events/LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_ATTEMPT'
export const LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_SUCCESS =
  'events/LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_SUCCESS'
export const LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_FAILURE =
  'events/LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_FAILURE'

export const RESET_EVENT_INSCRIPTIONS_FOR_CURRENT_USER =
  'events/RESET_EVENT_INSCRIPTIONS_FOR_CURRENT_USER'

export const LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_ATTEMPT =
  'events/LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_ATTEMPT'
export const LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_SUCCESS =
  'events/LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_SUCCESS'
export const LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_FAILURE =
  'events/LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_FAILURE'

export const RESET_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER =
  'events/RESET_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER'

export const DOWNLOAD_INSCRIPTIONS_SHEET_ATTEMPT = 'events/DOWNLOAD_INSCRIPTIONS_SHEET_ATTEMPT'
export const DOWNLOAD_INSCRIPTIONS_SHEET_SUCCESS = 'events/DOWNLOAD_INSCRIPTIONS_SHEET_SUCCESS'
export const DOWNLOAD_INSCRIPTIONS_SHEET_FAILURE = 'events/DOWNLOAD_INSCRIPTIONS_SHEET_FAILURE'

export const ACCEPT_ORGANIZATION_ATTEMPT = 'events/ACCEPT_ORGANIZATION_ATTEMPT'
export const ACCEPT_ORGANIZATION_SUCCESS = 'events/ACCEPT_ORGANIZATION_SUCCESS'
export const ACCEPT_ORGANIZATION_FAILURE = 'events/ACCEPT_ORGANIZATION_FAILURE'

export const REJECT_ORGANIZATION_ATTEMPT = 'events/REJECT_ORGANIZATION_ATTEMPT'
export const REJECT_ORGANIZATION_SUCCESS = 'events/REJECT_ORGANIZATION_SUCCESS'
export const REJECT_ORGANIZATION_FAILURE = 'events/REJECT_ORGANIZATION_FAILURE'

export const LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_ATTEMPT =
  'events/LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_ATTEMPT'
export const LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_SUCCESS =
  'events/LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_SUCCESS'
export const LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_FAILURE =
  'events/LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_FAILURE'

export const RESET_INSCRIPTION_FORMS_CLONE_SUGGESTIONS =
  'events/RESET_INSCRIPTION_FORMS_CLONE_SUGGESTIONS'

export const LOAD_CATEGORIES_CLONE_SUGGESTIONS_ATTEMPT =
  'events/LOAD_CATEGORIES_CLONE_SUGGESTIONS_ATTEMPT'
export const LOAD_CATEGORIES_CLONE_SUGGESTIONS_SUCCESS =
  'events/LOAD_CATEGORIES_CLONE_SUGGESTIONS_SUCCESS'
export const LOAD_CATEGORIES_CLONE_SUGGESTIONS_FAILURE =
  'events/LOAD_CATEGORIES_CLONE_SUGGESTIONS_FAILURE'

export const RESET_CATERORIES_CLONE_SUGGESTIONS = 'events/RESET_CATERORIES_CLONE_SUGGESTIONS'

export const RESET_VALUE = 'events/RESET_VALUE'

export const LOAD_CATEGORIES_ATTEMPT = 'events/LOAD_CATEGORIES_ATTEMPT'
export const LOAD_CATEGORIES_SUCCESS = 'events/LOAD_CATEGORIES_SUCCESS'
export const LOAD_CATEGORIES_FAILURE = 'events/LOAD_CATEGORIES_FAILURE'

export const CREATE_CATEGORY_ATTEMPT = 'events/CREATE_CATEGORY_ATTEMPT'
export const CREATE_CATEGORY_SUCCESS = 'events/CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'events/CREATE_CATEGORY_FAILURE'

export const UPDATE_CATEGORY_ATTEMPT = 'events/UPDATE_CATEGORY_ATTEMPT'
export const UPDATE_CATEGORY_SUCCESS = 'events/UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAILURE = 'events/UPDATE_CATEGORY_FAILURE'

export const DELETE_CATEGORY_ATTEMPT = 'events/DELETE_CATEGORY_ATTEMPT'
export const DELETE_CATEGORY_SUCCESS = 'events/DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'events/DELETE_CATEGORY_FAILURE'

export const LOAD_DISTANCES_ATTEMPT = 'events/LOAD_DISTANCES_ATTEMPT'
export const LOAD_DISTANCES_SUCCESS = 'events/LOAD_DISTANCES_SUCCESS'
export const LOAD_DISTANCES_FAILURE = 'events/LOAD_DISTANCES_FAILURE'

export const CREATE_DISTANCE_ATTEMPT = 'events/CREATE_DISTANCE_ATTEMPT'
export const CREATE_DISTANCE_SUCCESS = 'events/CREATE_DISTANCE_SUCCESS'
export const CREATE_DISTANCE_FAILURE = 'events/CREATE_DISTANCE_FAILURE'

export const UPDATE_DISTANCE_ATTEMPT = 'events/UPDATE_DISTANCE_ATTEMPT'
export const UPDATE_DISTANCE_SUCCESS = 'events/UPDATE_DISTANCE_SUCCESS'
export const UPDATE_DISTANCE_FAILURE = 'events/UPDATE_DISTANCE_FAILURE'

export const DELETE_DISTANCE_ATTEMPT = 'events/DELETE_DISTANCE_ATTEMPT'
export const DELETE_DISTANCE_SUCCESS = 'events/DELETE_DISTANCE_SUCCESS'
export const DELETE_DISTANCE_FAILURE = 'events/DELETE_DISTANCE_FAILURE'

export const LOAD_MERCADO_PAGO_AUTHORIZATION_ATTEMPT =
  'events/LOAD_MERCADO_PAGO_AUTHORIZATION_ATTEMPT'
export const LOAD_MERCADO_PAGO_AUTHORIZATION_SUCCESS =
  'events/LOAD_MERCADO_PAGO_AUTHORIZATION_SUCCESS'
export const LOAD_MERCADO_PAGO_AUTHORIZATION_FAILURE =
  'events/LOAD_MERCADO_PAGO_AUTHORIZATION_FAILURE'

export const SEND_MERCADO_PAGO_AUTHORIZATION_ATTEMPT =
  'events/SEND_MERCADO_PAGO_AUTHORIZATION_ATTEMPT'
export const SEND_MERCADO_PAGO_AUTHORIZATION_SUCCESS =
  'events/SEND_MERCADO_PAGO_AUTHORIZATION_SUCCESS'
export const SEND_MERCADO_PAGO_AUTHORIZATION_FAILURE =
  'events/SEND_MERCADO_PAGO_AUTHORIZATION_FAILURE'

export const DELETE_MERCADO_PAGO_AUTHORIZATION_ATTEMPT =
  'events/DELETE_MERCADO_PAGO_AUTHORIZATION_ATTEMPT'
export const DELETE_MERCADO_PAGO_AUTHORIZATION_SUCCESS =
  'events/DELETE_MERCADO_PAGO_AUTHORIZATION_SUCCESS'
export const DELETE_MERCADO_PAGO_AUTHORIZATION_FAILURE =
  'events/DELETE_MERCADO_PAGO_AUTHORIZATION_FAILURE'

export const LOAD_BANKS_ATTEMPT = 'events/LOAD_BANKS_ATTEMPT'
export const LOAD_BANKS_SUCCESS = 'events/LOAD_BANKS_SUCCESS'
export const LOAD_BANKS_FAILURE = 'events/LOAD_BANKS_FAILURE'

export const LOAD_EVENT_INSCRIPTION_ATTEMPT = 'events/LOAD_EVENT_INSCRIPTION_ATTEMPT'
export const LOAD_EVENT_INSCRIPTION_SUCCESS = 'events/LOAD_EVENT_INSCRIPTION_SUCCESS'
export const LOAD_EVENT_INSCRIPTION_FAILURE = 'events/LOAD_EVENT_INSCRIPTION_FAILURE'

export const LOAD_EVENT_BANK_ACCOUNTS_ATTEMPT = 'events/LOAD_EVENT_BANK_ACCOUNTS_ATTEMPT'
export const LOAD_EVENT_BANK_ACCOUNTS_SUCCESS = 'events/LOAD_EVENT_BANK_ACCOUNTS_SUCCESS'
export const LOAD_EVENT_BANK_ACCOUNTS_FAILURE = 'events/LOAD_EVENT_BANK_ACCOUNTS_FAILURE'

export const LOAD_ACTIVE_BANK_ACCOUNTS_ATTEMPT = 'events/LOAD_ACTIVE_BANK_ACCOUNTS_ATTEMPT'
export const LOAD_ACTIVE_BANK_ACCOUNTS_SUCCESS = 'events/LOAD_ACTIVE_BANK_ACCOUNTS_SUCCESS'
export const LOAD_ACTIVE_BANK_ACCOUNTS_FAILURE = 'events/LOAD_ACTIVE_BANK_ACCOUNTS_FAILURE'

export const CREATE_EVENT_BANK_ACCOUNT_ATTEMPT = 'events/CREATE_EVENT_BANK_ACCOUNT_ATTEMPT'
export const CREATE_EVENT_BANK_ACCOUNT_SUCCESS = 'events/CREATE_EVENT_BANK_ACCOUNT_SUCCESS'
export const CREATE_EVENT_BANK_ACCOUNT_FAILURE = 'events/CREATE_EVENT_BANK_ACCOUNT_FAILURE'

export const UPDATE_EVENT_BANK_ACCOUNT_ATTEMPT = 'events/UPDATE_EVENT_BANK_ACCOUNT_ATTEMPT'
export const UPDATE_EVENT_BANK_ACCOUNT_SUCCESS = 'events/UPDATE_EVENT_BANK_ACCOUNT_SUCCESS'
export const UPDATE_EVENT_BANK_ACCOUNT_FAILURE = 'events/UPDATE_EVENT_BANK_ACCOUNT_FAILURE'

export const DELETE_EVENT_BANK_ACCOUNT_ATTEMPT = 'events/DELETE_EVENT_BANK_ACCOUNT_ATTEMPT'
export const DELETE_EVENT_BANK_ACCOUNT_SUCCESS = 'events/DELETE_EVENT_BANK_ACCOUNT_SUCCESS'
export const DELETE_EVENT_BANK_ACCOUNT_FAILURE = 'events/DELETE_EVENT_BANK_ACCOUNT_FAILURE'

export const LOAD_PAYMENT_LOCATIONS_ATTEMPT = 'events/LOAD_EVENT_PAYMENT_LOCATIONS_ATTEMPT'
export const LOAD_PAYMENT_LOCATIONS_SUCCESS = 'events/LOAD_EVENT_PAYMENT_LOCATIONS_SUCESS'
export const LOAD_PAYMENT_LOCATIONS_FAILURE = 'events/LOAD_EVENT_PAYMENT_LOCATIONS_FAILURE'

export const LOAD_ACTIVE_PAYMENT_LOCATIONS_ATTEMPT = 'events/LOAD_ACTIVE_PAYMENT_LOCATIONS_ATTEMPT'
export const LOAD_ACTIVE_PAYMENT_LOCATIONS_SUCCESS = 'events/LOAD_ACTIVE_PAYMENT_LOCATIONS_SUCCESS'
export const LOAD_ACTIVE_PAYMENT_LOCATIONS_FAILURE = 'events/LOAD_ACTIVE_PAYMENT_LOCATIONS_FAILURE'

export const CREATE_PAYMENT_LOCATION_ATTEMPT = 'events/CREATE_PAYMENT_LOCATION_ATTEMPT'
export const CREATE_PAYMENT_LOCATION_SUCCESS = 'events/CREATE_PAYMENT_LOCATION_SUCCESS'
export const CREATE_PAYMENT_LOCATION_FAILURE = 'events/CREATE_PAYMENT_LOCATION_FAILURE'

export const UPDATE_PAYMENT_LOCATION_ATTEMPT = 'events/UPDATE_PAYMENT_LOCATION_ATTEMPT'
export const UPDATE_PAYMENT_LOCATION_SUCCESS = 'events/UPDATE_PAYMENT_LOCATION_SUCCESS'
export const UPDATE_PAYMENT_LOCATION_FAILURE = 'events/UPDATE_PAYMENT_LOCATION_FAILURE'

export const DELETE_PAYMENT_LOCATION_ATTEMPT = 'events/DELETE_PAYMENT_LOCATION_ATTEMPT'
export const DELETE_PAYMENT_LOCATION_SUCCESS = 'events/DELETE_PAYMENT_LOCATION_SUCCESS'
export const DELETE_PAYMENT_LOCATION_FAILURE = 'events/DELETE_PAYMENT_LOCATION_FAILURE'

export const SET_PAYMENT_PARAMS = 'events/SET_PAYMENT_PARAMS'

export const LOAD_TICKET_TYPE_FEES_ATTEMPT = 'events/LOAD_TICKET_TYPE_FEES_ATTEMPT'
export const LOAD_TICKET_TYPE_FEES_SUCCESS = 'events/LOAD_TICKET_TYPE_FEES_SUCCESS'
export const LOAD_TICKET_TYPE_FEES_FAILURE = 'events/LOAD_TICKET_TYPE_FEES_FAILURE'

export const LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_ATTEMPT =
  'events/LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_ATTEMPT'
export const LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_SUCCESS =
  'events/LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_SUCCESS'
export const LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_FAILURE =
  'events/LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_FAILURE'

export const PUBLIC_UPDATE_EVENT_INSCRIPTION_ATTEMPT =
  'events/PUBLIC_UPDATE_EVENT_INSCRIPTION_ATTEMPT'
export const PUBLIC_UPDATE_EVENT_INSCRIPTION_SUCCESS =
  'events/PUBLIC_UPDATE_EVENT_INSCRIPTION_SUCCESS'
export const PUBLIC_UPDATE_EVENT_INSCRIPTION_FAILURE =
  'events/PUBLIC_UPDATE_EVENT_INSCRIPTION_FAILURE'

export const LOAD_CANCELLED_EVENT_INSCRIPTION_ATTEMPT =
  'events/LOAD_CANCELLED_EVENT_INSCRIPTION_ATTEMPT'
export const LOAD_CANCELLED_EVENT_INSCRIPTION_SUCCESS =
  'events/LOAD_CANCELLED_EVENT_INSCRIPTION_SUCCESS'
export const LOAD_CANCELLED_EVENT_INSCRIPTION_FAILURE =
  'events/LOAD_CANCELLED_EVENT_INSCRIPTION_FAILURE'

export const DELETE_SALE_ORDER_PAYMENT_ATTEMPT = 'events/DELETE_SALE_ORDER_PAYMENT_ATTEMPT'
export const DELETE_SALE_ORDER_PAYMENT_SUCCESS = 'events/DELETE_SALE_ORDER_PAYMENT_SUCCESS'
export const DELETE_SALE_ORDER_PAYMENT_FAILURE = 'events/DELETE_SALE_ORDER_PAYMENT_FAILURE'

export const UPDATE_SALE_ORDER_PAYMENT_ATTEMPT = 'events/UPDATE_SALE_ORDER_PAYMENT_ATTEMPT'
export const UPDATE_SALE_ORDER_PAYMENT_SUCCESS = 'events/UPDATE_SALE_ORDER_PAYMENT_SUCCESS'
export const UPDATE_SALE_ORDER_PAYMENT_FAILURE = 'events/UPDATE_SALE_ORDER_PAYMENT_FAILURE'

export const LOAD_EVENT_DISCOUNT_CODES_ATTEMPT = 'events/LOAD_EVENT_DISCOUNT_CODES_ATTEMPT'
export const LOAD_EVENT_DISCOUNT_CODES_SUCCESS = 'events/LOAD_EVENT_DISCOUNT_CODES_SUCCESS'
export const LOAD_EVENT_DISCOUNT_CODES_FAILURE = 'events/LOAD_EVENT_DISCOUNT_CODES_FAILURE'

export const CREATE_EVENT_DISCOUNT_CODE_ATTEMPT = 'events/CREATE_EVENT_DISCOUNT_CODE_ATTEMPT'
export const CREATE_EVENT_DISCOUNT_CODE_SUCCESS = 'events/CREATE_EVENT_DISCOUNT_CODE_SUCCESS'
export const CREATE_EVENT_DISCOUNT_CODE_FAILURE = 'events/CREATE_EVENT_DISCOUNT_CODE_FAILURE'

export const UPDATE_EVENT_DISCOUNT_CODE_ATTEMPT = 'events/UPDATE_EVENT_DISCOUNT_CODE_ATTEMPT'
export const UPDATE_EVENT_DISCOUNT_CODE_SUCCESS = 'events/UPDATE_EVENT_DISCOUNT_CODE_SUCCESS'
export const UPDATE_EVENT_DISCOUNT_CODE_FAILURE = 'events/UPDATE_EVENT_DISCOUNT_CODE_FAILURE'

export const DELETE_EVENT_DISCOUNT_CODE_ATTEMPT = 'events/DELETE_EVENT_DISCOUNT_CODE_ATTEMPT'
export const DELETE_EVENT_DISCOUNT_CODE_SUCCESS = 'events/DELETE_EVENT_DISCOUNT_CODE_SUCCESS'
export const DELETE_EVENT_DISCOUNT_CODE_FAILURE = 'events/DELETE_EVENT_DISCOUNT_CODE_FAILURE'

export const LOAD_EVENT_RESULTS_REPORT_URL_ATTEMPT = 'events/LOAD_EVENT_RESULTS_REPORT_URL_ATTEMPT'
export const LOAD_EVENT_RESULTS_REPORT_URL_SUCCESS = 'events/LOAD_EVENT_RESULTS_REPORT_URL_SUCCESS'
export const LOAD_EVENT_RESULTS_REPORT_URL_FAILURE = 'events/LOAD_EVENT_RESULTS_REPORT_URL_FAILURE'

export const DOWNLOAD_EVENT_CATEGORIES_ATTEMPT = 'events/DOWNLOAD_EVENT_CATEGORIES_ATTEMPT'
export const DOWNLOAD_EVENT_CATEGORIES_SUCCESS = 'events/DOWNLOAD_EVENT_CATEGORIES_SUCCESS'
export const DOWNLOAD_EVENT_CATEGORIES_FAILURE = 'events/DOWNLOAD_EVENT_CATEGORIES_FAILURE'
