import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  suspendContainer: {
    cursor: 'pointer'
  },

  suspendReason: {
    textOverflow: 'anywhere',
    whiteSpace: 'pre-wrap'
  },
  suspendDescriptionContainer: {
    transition: 'all 20s',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',

    '&.expanded': {
      display: 'block'
    },

    height: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,

    [theme.breakpoints.up(1505)]: {
      WebkitLineClamp: 6
    },
    [theme.breakpoints.up(1585)]: {
      WebkitLineClamp: 7
    },
    [theme.breakpoints.up(1665)]: {
      WebkitLineClamp: 8
    },
    [theme.breakpoints.up(1745)]: {
      WebkitLineClamp: 9
    },
    [theme.breakpoints.up('xl')]: {
      WebkitLineClamp: 10
    }
  },
  title: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 500,
    fontSize: '1rem',
    textAlign: 'left',
    marginBottom: theme.spacing(),
    textOverflow: 'anywhere',
    whiteSpace: 'pre-wrap'
  }
}))
