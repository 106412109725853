import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './PlaceHolderLoader.style'

const PlaceHolderLoader = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  const title = 'Cargando...'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            title={title}
            className={classes.containerMini}>
            <circle cx='163' cy='245' r='74' className={classes.containerImg} />
            <rect x='0' y='35' rx='3' ry='3' className={classes.backgroundImage} />
            <rect x='100' y='331' rx='3' ry='3' className={classes.fullName} />
            <rect x='118' y='355' rx='3' ry='3' className={classes.userName} />
            <rect x='100' y='410' rx='3' ry='3' className={classes.dicipline} />
            <rect x='100' y='440' rx='3' ry='3' className={classes.dicipline} />
            <rect x='20' y='490' rx='3' ry='3' className={classes.followers} />
            <rect x='200' y='490' rx='3' ry='3' className={classes.buttonFollow} />
            <rect x='10' y='550' rx='3' ry='3' className={classes.title} />
            <rect x='10' y='600' rx='3' ry='3' className={classes.text} />
            <rect x='10' y='620' rx='3' ry='3' className={classes.text} />
            <rect x='10' y='640' rx='3' ry='3' className={classes.text} />

            <rect x='10' y='700' rx='3' ry='3' className={classes.title} />

            <rect x='10' y='730' rx='3' ry='3' className={classes.date} />
            <rect x='10' y='760' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='810' rx='3' ry='3' className={classes.date} />
            <rect x='10' y='840' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='890' rx='3' ry='3' className={classes.date} />
            <rect x='10' y='920' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='980' rx='3' ry='3' className={classes.title} />
            <rect x='10' y='1010' rx='3' ry='3' className={classes.subtitle} />
            <rect x='10' y='1030' rx='3' ry='3' className={classes.text} />
            <rect x='10' y='1070' rx='3' ry='3' className={classes.subtitle} />
            <rect x='10' y='1090' rx='3' ry='3' className={classes.text} />
            <rect x='10' y='1130' rx='3' ry='3' className={classes.subtitle} />
            <rect x='10' y='1150' rx='3' ry='3' className={classes.text} />
            <rect x='10' y='1190' rx='3' ry='3' className={classes.subtitle} />
            <rect x='15' y='1230' rx='3' ry='3' className={classes.social} />
            <rect x='80' y='1230' rx='3' ry='3' className={classes.social} />
            <rect x='135' y='1230' rx='3' ry='3' className={classes.social} />
            <rect x='190' y='1230' rx='3' ry='3' className={classes.social} />
          </ContentLoader>
        ) : (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            title={title}
            className={classes.container}>
            <rect x='0' y='40' rx='3' ry='3' className={classes.backgroundImage} />
            <circle cx='130' cy='495' r='74' rx='10' ry='10' className={classes.containerImg} />
            <rect x='230' y='520' rx='3' ry='3' className={classes.organizationName} />
            <rect x='230' y='550' rx='3' ry='3' className={classes.nickName} />
            <rect x='190' y='630' rx='3' ry='3' className={classes.followers} />
            <rect x='900' y='630' rx='3' ry='3' className={classes.buttonFollow} />
            <rect x='0' y='690' rx='3' ry='3' className={classes.title} />
            <rect x='10' y='730' rx='3' ry='3' className={classes.date} />
            <rect x='150' y='760' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='800' rx='3' ry='3' className={classes.date} />
            <rect x='150' y='830' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='870' rx='3' ry='3' className={classes.date} />
            <rect x='150' y='900' rx='3' ry='3' className={classes.nameEvent} />

            <rect x='0' y='950' rx='3' ry='3' className={classes.title} />
            <rect x='10' y='990' rx='3' ry='3' className={classes.date} />
            <rect x='150' y='1020' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='1060' rx='3' ry='3' className={classes.date} />
            <rect x='150' y='1090' rx='3' ry='3' className={classes.nameEvent} />
            <rect x='10' y='1130' rx='3' ry='3' className={classes.date} />
            <rect x='150' y='1160' rx='3' ry='3' className={classes.nameEvent} />

            <rect x='820' y='690' rx='3' ry='3' className={classes.title} />
            <rect x='820' y='720' rx='3' ry='3' className={classes.subtitle} />
            <rect x='820' y='740' rx='3' ry='3' className={classes.text} />
            <rect x='820' y='785' rx='3' ry='3' className={classes.subtitle} />
            <rect x='820' y='805' rx='3' ry='3' className={classes.text} />
            <rect x='820' y='850' rx='3' ry='3' className={classes.subtitle} />
            <rect x='820' y='870' rx='3' ry='3' className={classes.text} />
            <rect x='820' y='920' rx='3' ry='3' className={classes.subtitle} />
            <rect x='835' y='950' rx='3' ry='3' className={classes.social} />
            <rect x='915' y='950' rx='3' ry='3' className={classes.social} />
            <rect x='995' y='950' rx='3' ry='3' className={classes.social} />
            <rect x='1075' y='950' rx='3' ry='3' className={classes.social} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default PlaceHolderLoader
