import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerText: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 2,
    alignItems: 'center',
    margin: '10px 0 10px 0'
  },
  button: {
    height: 41,
    width: 215
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  containerTextGrey: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    background: '#D1D1D1',
    padding: 2
  },
  containerTextGreySportmetricCommission: {
    color: theme.palette.primary.main,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 10,
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.16),
    padding: 2
  },
  textSportmetricCommissionLeft: {
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      margin: '0 10px 0 10px'
    },
    gridColumn: 1
  },
  textSportmetricCommissionRight: {
    gridColumn: 2,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textSportmetricPercentage: {
    fontSize: 15,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      margin: '0 10px 0 10px'
    },
    paddingInlineEnd: 90
  },
  textPrice: {
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      margin: '0 10px 0 10px'
    },
    color: 'gray'
  },
  textTotalForOrganizer: {
    fontSize: 15,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      margin: '0 10px 0 10px'
    }
  },
  textInformation: {
    fontSize: 13,
    color: theme.palette.primary.main,
    padding: 5,
    fontWeight: 500
  },
  text: {
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: 18
    }
  },
  Subtext: {
    fontSize: 14,
    fontWeight: 500
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0
    }
  },
  switchTooltip: {
    width: '100%',
    borderEndStartRadius: 0,
    backgroundColor: theme.palette.tooltip.light
  },
  switchTooltipArrow: {
    color: theme.palette.tooltip.light
  },

  containerTooltip: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  IconInfo: {
    color: 'orange',
    width: 30,
    height: 25
  },
  containerCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  textCheckbox: {
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  textCheckboxDisabled: {
    fontSize: 15,
    fontWeight: 500,
    color: 'grey'
  },
  containerSelect: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
    gridGap: 20,
    width: 150,
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },
  TextField: {
    width: 110,
    appearance: 'textfield',
    '& input': {
      height: 5,
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.primary.main,
      background: 'white'
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  containerSettingMP: {
    width: 400,
    display: 'grid',
    gridGap: 30,
    gridTemplateColumns: '0.5fr 1fr '
  }
}))
