import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from './SectionBlock.style'

const SectionBlock = ({ title, children, endAdorment }) => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div className={classes.titleContainer}>
        <Typography variant='h3' className={classes.title}>
          {title}
        </Typography>
        {endAdorment}
      </div>

      {children}
    </div>
  )
}

export default SectionBlock
