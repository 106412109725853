import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 827,
      maxWidth: 827,
      margin: '0 auto'
    }
  },
  suspendReason: {
    textOverflow: 'anywhere',
    whiteSpace: 'pre-wrap'
  },
  dialogTitle: {
    padding: 0
  },
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    backgroundColor: theme.palette.tooltip.light,
    padding: theme.spacing(0, 4)
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontSize: '1.25rem',
    flexGrow: 1,
    marginRight: theme.spacing(5)
  },
  eventName: {
    fontSize: '1.25rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    textOverflow: 'anywhere',
    whiteSpace: 'pre-wrap'
  },
  eventDate: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontSize: '1.2rem'
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    backgroundColor: '#8684CD',
    padding: theme.spacing(4)
  },
  supendReason: {
    margin: theme.spacing(3, 0)
  },
  mainContainer: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: 10,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3.5, 5),

    '& p ': {
      color: theme.palette.primary.main,
      fontWeight: 500
    }
  }
}))
