import React from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { EditableSection, EditableText } from 'shared'
import * as Yup from 'yup'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './InscriptionUrlSection.style'

const InscriptionUrlSection = ({ event, ...sectionProps }) => {
  const classes = useStyles()

  const { externalInscriptionUrl, isSuspended } = event

  const validationSchema = Yup.object().shape({
    externalInscriptionUrl: Yup.string()
      .nullable()
      .test(
        'test-externalInscriptionUrl',
        'El link de inscripción no tiene una url valida',
        (value) => {
          const pattern =
            /[-a-zA-Z0-9@:%._'+~#=]{1,256}\.[a-zA-Z0-9()]{1,15}\b([-a-zA-Z0-9()@:%_'+.~#?&'/'/=]*)/
          return !value || !!pattern.test(value)
        }
      )
  })
  return (
    <EditableSection entity={event} validationSchema={validationSchema} {...sectionProps}>
      {({ activeEdit, values, handleChange, errors, handleBlur }) => (
        <SectionBlock title='Link de inscripción'>
          <EditableText
            entity={values}
            field='externalInscriptionUrl'
            activeEditSection={activeEdit}
            type='url'
            handleBlur={handleBlur}
            onChange={handleChange}
            disabled={isSuspended}
            inputClassName={classes.eventinputLink}
            placeHolder='https://docs.google.com/'
            errors={errors.externalInscriptionUrl}>
            {externalInscriptionUrl ? (
              <a
                href={externalInscriptionUrl}
                className={classes.eventLink}
                rel='noreferrer'
                title={externalInscriptionUrl}
                target='_blank'>
                {externalInscriptionUrl}
              </a>
            ) : (
              <Typography color='primary' className={clsx(classes.eventLink, 'text')}>
                No proporcionado
              </Typography>
            )}
          </EditableText>
        </SectionBlock>
      )}
    </EditableSection>
  )
}

export default InscriptionUrlSection
