import { createMuiTheme } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'

const defaultTheme = createMuiTheme()

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Montserrat", sans-serif',
    fontFamilyAlt: '"Hurme Geometric Sans 3", sans-serif',
    h1: {
      fontSize: '4rem'
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.75rem'
    },
    h4: {
      fontSize: '1.5rem'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1rem'
    }
  },
  palette: {
    background: {
      default: '#f0f0f0',
      alt: '#D9D9D9CC',
      section: '#D9D9D966'
    },
    primary: {
      light: '#5c35e0',
      main: '#0600ad',
      dark: '#00007c',
      contrastText: '#fff'
    },
    secondary: {
      light: '#78ff8b',
      main: '#29ff59',
      dark: '#00ca22',
      contrastText: '#000'
    },
    success: {
      light: '#80e872',
      main: '#4bb543',
      dark: '#008410',
      contrastText: '#fff'
    },
    suspended: {
      light: '#dddddd',
      main: '#FF9D29;',
      dark: '#7d7d7d'
    },
    cancelled: {
      main: '#CB1A21'
    },
    disabled: {
      main: '#696969',
      light: '#ECEDED'
    },
    link: {
      main: '#4285F4'
    },
    tooltip: {
      main: '#9D9BDC',
      light: '#FF9D29'
    },
    error: {
      main: '#f44336'
    },
    helperText: {
      main: '#0000008A'
    },
    alt: {
      main: '#D9D9D9',
      light: '#969696'
    }
  },
  utils: {
    rgba: (color, opacity = 1) => fade(color, opacity)
  },
  comboBox: {
    palette: {
      primary: {
        primary: '#0600AD',
        primary25: fade('#0600AD', 0.31),
        primary50: '#0600AD'
      },
      secondary: {
        primary: '#A3EEB4',
        primary25: '#A3EEB4',
        primary50: '#29ff59'
      }
    }
  },
  text: {
    secondary: '#969696'
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'none'
      },
      label: {
        fontWeight: 400
      }
    },
    MuiInputBase: {
      root: {
        '& fieldset': {
          borderRadius: 0
        }
      }
    },
    MuiFormControl: {
      root: {
        padding: 0
      }
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginBottom: defaultTheme.spacing(),
        backgroundColor: '#f0f0f0'
      },
      input: {
        padding: defaultTheme.spacing(2)
      },
      inputMarginDense: {
        paddingTop: defaultTheme.spacing(2),
        paddingBottom: defaultTheme.spacing(2)
      },
      underline: {
        '&:after': {
          borderBottom: 'none'
        },
        '&:before': {
          borderBottom: 'none'
        },
        '&:hover:before': {
          borderBottom: 'none'
        }
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          // underline color when textfield is inactive
          borderBottom: '1px solid #5c35e0'
        },
        '&:hover:not($disabled):before': {
          // underline color when hovered
          borderBottom: '1px solid #0600ad'
        }
      }
    }
  }
})

export default theme
