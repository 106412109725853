import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  eventinputLink: {
    paddingBottom: theme.spacing(2),
    width: '100%',

    '& > div': {
      backgroundColor: theme.palette.primary.contrastText
    },
    '& input': {
      height: 19,
      color: '#4285F4',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  addLink: {
    fontWeight: 600,
    fontSize: '.875rem',
    cursor: 'pointer',
    textDecoration: 'underline',

    '&.disabled': {
      color: theme.palette.disabled.main,
      cursor: 'auto'
    }
  },
  eventRulesButton: {
    alignSelf: 'center',
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'flex-start'
    }
  },
  eventRulesUploadButton: {
    width: 200,
    height: 48,
    borderRadius: 10
  }
}))
