import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  addLink: {
    fontWeight: 600,
    fontSize: '.875rem',
    cursor: 'pointer',
    textDecoration: 'underline',

    '&.disabled': {
      color: theme.palette.disabled.main,
      cursor: 'auto'
    }
  },
  editor: {
    '&.error': {
      '& .ql-toolbar': {
        border: `1px solid ${theme.palette.error.main}`
      },
      '& .ql-container': {
        border: `1px solid  ${theme.palette.error.main}`
      },
      '& .ql-editor': {
        '&:focus': {
          border: `1px solid  ${theme.palette.error.main}`
        }
      }
    }
  }
}))
