import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import {
  AccessTime as AccessTimeIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { CountriesProxy, OrganizationsProxy } from 'services'
import { CustomizedTooltip, LocationPicker, StyledSwitch } from 'shared'
import {
  ARGENTINA_COUNTRY,
  EVENT_STATES,
  INFORMATION_NAME_FIELDS,
  OTHER_OPTION,
  STRING_EMPTY
} from 'utils/constants'
import { getCountries, searchDisciplines } from 'utils/functions'
import { useQuery } from 'utils/hooks'
import { showSnackbarError } from 'utils/snackbar'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './InformationSection.style'

const InformationSection = ({ sectionDisabled }) => {
  const classes = useStyles()
  const query = useQuery()

  const focus = query.get('focus')
  const disciplinesSelectFocused = focus === INFORMATION_NAME_FIELDS.DISCIPLINES

  const organizationSelectFocused = focus === INFORMATION_NAME_FIELDS.ORGANIZATIONS

  const { values, handleBlur, handleChange, errors, touched, setValues, setFieldValue } =
    useFormikContext()

  const { event } = useSelector((state) => state.events)

  const [disciplines, setDisciplines] = useState([])
  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [organizationsPending, setOrganizationsPending] = useState(false)
  const [organizationOptions, setOrganizationOptions] = useState([])
  const [openDisciplinesSelect, setOpenDisciplinesSelect] = useState(disciplinesSelectFocused)
  const [openOrganizationsSelect, setOpenOrganizationsSelect] = useState(organizationSelectFocused)

  const empty = { value: STRING_EMPTY, label: '--Seleccione--' }

  const fetchDisciplines = async (text = STRING_EMPTY) =>
    setDisciplines(await searchDisciplines(text))

  useEffect(() => {
    fetchDisciplines(STRING_EMPTY)
    fetchCountries()
    onCountryChange()(values.countryId)
  }, [])

  const fetchCountries = async () => setCountries(await getCountries()(STRING_EMPTY))

  const onFromDateChanged = (setValues, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,

      fromDate: value ? value.format() : null,
      fromTime:
        !values.fromTime && value && moment(value).isValid() ? defaultTime : values.fromTime,

      ...(!!value &&
        moment(value).isValid() &&
        (!values.toDate || !moment(values.toDate).isValid()) && {
          toDate: moment(value),
          toTime: moment('23:59', 'HH:mm').format()
        })
    }))

  const onCountryChange = (setFieldValue) => async (option) => {
    try {
      if (setFieldValue) {
        setFieldValue('countryId', option)
        setFieldValue('areaLevel1Id', STRING_EMPTY, false)
      }

      setProvinces([])

      const proxy = new CountriesProxy()
      const data = await proxy.getAreaLevel1(option)

      if (Array.isArray(data) && !!data.length) {
        const provincesData = data.map((d) => ({ value: d.id, label: d.name }))

        if (option === ARGENTINA_COUNTRY.value) {
          setProvinces(provincesData)
        } else {
          setProvinces([empty, ...provincesData])
        }
      }
    } catch (e) {}
  }

  const onTimeChanged = (setFieldValue, field) => (value) =>
    setFieldValue(field, value ? value.format() : null)

  const onOrganizationsChange = (setFieldValue) => (options) =>
    setFieldValue('organizationsSelect', options)

  const onDisciplinesChange = (setFieldValue) => (options) =>
    setFieldValue('disciplinesSelect', options)

  const fetchOrganizations = async (searchText = STRING_EMPTY, setFieldValue) => {
    setFieldValue('usernameOrEmail', searchText)

    if (!searchText || searchText.length < 3) {
      setOrganizationOptions([])
      return
    }

    try {
      setOrganizationsPending(true)

      const proxy = new OrganizationsProxy()
      const data = await proxy.getEventOrganizationSuggestions(searchText, 1, 20)

      setOrganizationOptions(data.results)

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOrganizationsPending(false)
    }
  }

  const debounceFetchOrganizations = (inputValue, setFieldValue) =>
    fetchOrganizations(inputValue, setFieldValue)

  const debounceOnChange = debounce(debounceFetchOrganizations, 700)

  const onDateTimeChanged = (setValues, field, timeField, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,
      [field]: value ? value.format() : null,
      [timeField]:
        !values[timeField] && value && moment(value).isValid() ? defaultTime : values[timeField]
    }))

  const requiredProvince = (country) => country && country === ARGENTINA_COUNTRY.value

  const onLocationChanged = (setFieldValue) => (coord) => {
    setFieldValue('latitude', coord.lat)
    setFieldValue('longitude', coord.lng)
    setFieldValue('location', `${coord.lat} | ${coord.lng}`)
  }

  const onConfirm = (setFieldValue) => async (coord) => {
    const proxy = new CountriesProxy()
    const res = await proxy.getAddress(coord.lat, coord.lng)
    setFieldValue('address', res.address || STRING_EMPTY)
  }

  const handleChangeUndefinedLocationCheck = (setValues) => (e) => {
    setValues((values) => {
      const checkboxValue = e.target.checked
      const locationValues = checkboxValue && {
        location: STRING_EMPTY,
        latitude: 0,
        longitude: 0,
        address: STRING_EMPTY
      }

      return { ...values, ...locationValues, undefinedLocation: checkboxValue }
    })
  }

  const handleChangeUseOrganizationPhoneCheck = (setValues) => (e) =>
    setValues((values) => {
      const checkValue = e.target.checked

      return {
        ...values,
        useOrganizationPhone: checkValue,
        phone: checkValue ? values.organizationPhone || values.phone : values.phone,
        hasWhatsApp: checkValue || values.hasWhatsApp
      }
    })

  const validationProvince = requiredProvince(values.countryId)

  return (
    <SectionBlock title='Información General'>
      <div className={classes.content}>
        <div className={classes.block}>
          <div className={classes.formGroup}>
            <TextField
              className={classes.input}
              name='name'
              autoComplete='off'
              fullWidth
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.name || 'Máximo 100 caracteres.'}
              error={touched.name && Boolean(errors.name)}
              label='Nombre del Evento'
              required
              variant='outlined'
              disabled={sectionDisabled}
              size='small'
              margin='none'
              InputProps={{
                endAdornment: !sectionDisabled && (
                  <InputAdornment position='end'>
                    <CustomizedTooltip
                      position='top'
                      arrow
                      title='Este cambio modificará el enlace de acceso al evento.'
                      buttonClassName={classes.altTooltipButton}
                      disabledClickAway
                      arrowClassName={classes.altTooltipArrow}
                      className={classes.alertTooltip}>
                      {({ handleTooltip }) => (
                        <IconButton
                          onClick={handleTooltip}
                          color='primary'
                          className={classes.alertButton}
                          size='small'>
                          <ErrorIcon />
                        </IconButton>
                      )}
                    </CustomizedTooltip>
                  </InputAdornment>
                )
              }}
            />
          </div>

          <div className={classes.disciplinesContainer}>
            <FormControl variant='outlined' className={classes.formControl}>
              <Autocomplete
                multiple
                id='disciplinesSelect-autocomplete'
                options={disciplines.filter((x) => x.label !== OTHER_OPTION.label)}
                getOptionLabel={(option) => option.label}
                disabled={sectionDisabled}
                value={values.disciplinesSelect}
                onChange={(event, optionSelected) =>
                  onDisciplinesChange(setFieldValue)(optionSelected)
                }
                getOptionSelected={(option) =>
                  values.disciplinesSelect.some((o) => o.value === option.value)
                }
                filterSelectedOptions
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option.label}
                      key={option.value}
                      title={option.label}
                      {...getTagProps({ index })}
                      color='primary'
                      className={clsx(classes.option, sectionDisabled && 'disabled')}
                    />
                  ))
                }
                open={openDisciplinesSelect}
                onOpen={() => setOpenDisciplinesSelect(true)}
                onClose={() => setOpenDisciplinesSelect(false)}
                className={clsx(classes.combo, sectionDisabled && 'disabled')}
                clearText='Quitar todo'
                openText='Abrir desplegable'
                closeText='Cerrar desplegable'
                loadingText={<Typography align='center'> Cargando...</Typography>}
                noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={clsx(classes.input, classes.comboInput)}
                    name='disciplines'
                    label='Seleccione Deporte/Disciplina'
                    autoFocus={disciplinesSelectFocused}
                    variant='outlined'
                    error={Boolean(errors.disciplinesSelect)}
                    helperText={errors.disciplinesSelect || 'Escriba el nombre de la disciplina'}
                  />
                )}
              />
            </FormControl>
            <CustomizedTooltip
              position='right-end'
              className={classes.tooltip}
              disabledClickAway
              disabledTooltip={sectionDisabled}
              arrowClassName={classes.tooltipArrow}
              buttonClassName={classes.tooltipButton}
              title={
                <Typography className={classes.tooltipTitle}>
                  Si tu disciplina no figura en el listado y no es una subdisciplina de las
                  existentes, por favor envíanos un correo a:&nbsp;
                  <a
                    href='mailto:soporte@sportmetric.net'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.tooltipLink}>
                    soporte@sportmetric.net
                  </a>
                  &nbsp;indicándonos cuál es para que la podamos agregar.
                </Typography>
              }>
              {({ handleTooltip }) => (
                <IconButton
                  onClick={handleTooltip}
                  color='primary'
                  className={classes.infoButton}
                  size='small'>
                  <InfoIcon color='primary' />
                </IconButton>
              )}
            </CustomizedTooltip>
          </div>

          <div className={classes.phoneForm}>
            <div className={classes.phoneContainer}>
              <TextField
                name='phone'
                value={values.phone}
                className={classes.input}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                autoComplete='off'
                disabled={
                  (values.useOrganizationPhone && !!values.organizationPhone) || sectionDisabled
                }
                variant='outlined'
                size='small'
                error={touched.phone && Boolean(errors.phone)}
                helperText={errors.phone}
                label='Teléfono de contacto*'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={values.useOrganizationPhone}
                    className={clsx(classes.checkbox, sectionDisabled && 'disabled')}
                    disabled={sectionDisabled}
                    onChange={handleChangeUseOrganizationPhoneCheck(setValues)}
                    name='useOrganizationPhone'
                    onBlur={handleBlur}
                  />
                }
                label={
                  <Typography
                    variant='h6'
                    color='primary'
                    className={clsx(classes.checkMiniLabel, sectionDisabled && 'disabled')}>
                    Es el mismo N° de teléfono que el de la organización.
                  </Typography>
                }
              />
            </div>

            <div className={classes.whatsAppContainer}>
              <StyledSwitch
                checked={values.hasWhatsApp}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={sectionDisabled}
                name='hasWhatsApp'
              />
              <Typography variant='h6' className={classes.whatsAppTitle}>
                Tiene WhatsApp
              </Typography>
            </div>
          </div>

          {event.state !== EVENT_STATES.SUSPENDED && (
            <div className={clsx(classes.formGroup, classes.dateEventContainer)}>
              <div className={classes.datePickerContainer}>
                <Typography variant='caption' color='primary' className={classes.label}>
                  Fecha del evento*
                </Typography>
                <div className={classes.datetimePickerContainer}>
                  <KeyboardDatePicker
                    className={classes.inputDatePicker}
                    format='DD/MM/YYYY'
                    autoOk
                    variant='inline'
                    name='fromDate'
                    margin='none'
                    label='Desde'
                    id='date-picker-inline'
                    inputVariant='outlined'
                    onBlur={handleBlur}
                    required
                    helperText=''
                    placeholder='dd/mm/aaaa'
                    value={values.fromDate}
                    onChange={onFromDateChanged(setValues, moment('00:00', 'HH:mm').format())}
                    KeyboardButtonProps={{
                      'aria-label': 'seleccione fecha'
                    }}
                    error={touched.fromDate && Boolean(errors.fromDate)}
                  />
                  <KeyboardTimePicker
                    className={classes.inputTimePicker}
                    autoOk
                    name='fromTime'
                    onBlur={handleBlur}
                    variant='inline'
                    ampm={false}
                    autoComplete='off'
                    inputVariant='outlined'
                    keyboardIcon={<AccessTimeIcon />}
                    value={values.fromTime}
                    helperText=''
                    placeholder='hh:mm'
                    error={touched.fromTime && Boolean(errors.fromTime)}
                    onChange={onTimeChanged(setFieldValue, 'fromTime')}
                  />
                </div>
                {(errors.fromDate || errors.fromTime) && (
                  <Typography
                    color={
                      (touched.fromDate && Boolean(errors.fromDate)) ||
                      (touched.fromTime && Boolean(errors.fromTime))
                        ? 'error'
                        : ''
                    }
                    className={classes.errorMessage}
                    variant='caption'>
                    {errors.fromDate || errors.fromTime}
                  </Typography>
                )}
              </div>
              <div className={classes.formGroup}>
                <div className={classes.datetimePickerContainer}>
                  <KeyboardDatePicker
                    className={classes.inputDatePicker}
                    format='DD/MM/YYYY'
                    autoOk
                    variant='inline'
                    name='toDate'
                    required
                    label='Hasta'
                    margin='none'
                    id='date-picker-inline'
                    inputVariant='outlined'
                    onBlur={handleBlur}
                    value={values.toDate}
                    onChange={onDateTimeChanged(
                      setValues,
                      'toDate',
                      'toTime',
                      moment('23:59', 'HH:mm').format()
                    )}
                    KeyboardButtonProps={{
                      'aria-label': 'seleccione fecha'
                    }}
                    placeholder='dd/mm/aaaa'
                    helperText=''
                    error={touched.toDate && Boolean(errors.toDate)}
                  />
                  <KeyboardTimePicker
                    className={classes.inputTimePicker}
                    autoOk
                    name='toTime'
                    onBlur={handleBlur}
                    variant='inline'
                    ampm={false}
                    inputVariant='outlined'
                    keyboardIcon={<AccessTimeIcon />}
                    value={values.toTime}
                    helperText=''
                    placeholder='hh:mm'
                    error={touched.toTime && Boolean(errors.toTime)}
                    onChange={onTimeChanged(setFieldValue, 'toTime')}
                  />
                </div>
                {(errors.toDate || errors.toTime) && (
                  <Typography
                    color={
                      (touched.toDate && Boolean(errors.toDate)) ||
                      (touched.toTime && Boolean(errors.toTime))
                        ? 'error'
                        : 'inherit'
                    }
                    className={classes.errorMessage}
                    variant='caption'>
                    {errors.toDate || errors.toTime}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={classes.block}>
          <FormControl variant='outlined'>
            <InputLabel
              id='country-native-simple'
              className={clsx(
                classes.labelSelect,
                errors.countryId && 'error',
                sectionDisabled && 'disabled'
              )}>
              País*
            </InputLabel>
            <Select
              className={classes.select}
              value={values.countryId}
              required
              disabled={sectionDisabled}
              error={Boolean(errors.countryId) && touched.countryId}
              onBlur={handleBlur}
              name='countryId'
              onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
              color='primary'
              variant='outlined'
              labelId='country-native-simple'
              label='País'>
              {countries.map((x) => (
                <MenuItem value={x.value} key={x.value}>
                  {x.label}
                </MenuItem>
              ))}
            </Select>
            <Typography
              variant='caption'
              className={classes.helperText}
              color={errors.countryId ? 'error' : 'inherit'}>
              {errors.countryId || 'Seleccione su País.'}
            </Typography>
          </FormControl>
          {!!provinces.length && (
            <div className={classes.formGroup}>
              <FormControl variant='outlined'>
                <InputLabel
                  id='area-level-1-select-outlined-label'
                  className={clsx(
                    classes.labelSelect,
                    Boolean(errors.areaLevel1Id) && touched.areaLevel1Id && 'error',
                    sectionDisabled && 'disabled'
                  )}>
                  Provincia / Región / Estado
                  {validationProvince ? '*' : STRING_EMPTY}
                </InputLabel>
                <Select
                  name='areaLevel1Id'
                  labelId='area-level-1-select-outlined-label'
                  id='area-level-1-select-outlined'
                  label={`Provincia / Región / Estado${validationProvince ? '*' : STRING_EMPTY}`}
                  value={values.areaLevel1Id}
                  disabled={sectionDisabled}
                  onChange={handleChange}
                  error={Boolean(errors.areaLevel1Id) && touched.areaLevel1Id}
                  onBlur={handleBlur}
                  className={classes.select}>
                  {provinces.map((x) => (
                    <MenuItem value={x.value} key={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>

                <Typography
                  variant='caption'
                  className={clsx(classes.errorMessage, classes.helperText)}
                  color={errors.areaLevel1Id && touched.areaLevel1Id ? 'error' : 'inherit'}>
                  {errors.areaLevel1Id || 'Seleccione una opción del desplegable.'}
                </Typography>
              </FormControl>
            </div>
          )}

          <div className={classes.formGroup}>
            <div
              className={clsx(
                classes.location,
                (values.undefinedLocation || sectionDisabled) && 'disabled'
              )}>
              <TextField
                name='location'
                type='text'
                autoComplete='off'
                value={values.location}
                disabled
                required
                className={clsx(
                  classes.locationInput,
                  (values.undefinedLocation || sectionDisabled) && 'disabled'
                )}
                variant='outlined'
                onChange={handleChange}
                onBlur={handleBlur}
                size='small'
                fullWidth
                margin='none'
                label='Ubicación'
                style={{ border: 0 }}
              />
              <LocationPicker
                mode='write'
                mini
                variant='outlined'
                disabled={values.undefinedLocation || sectionDisabled}
                latitude={values.latitude}
                longitude={values.longitude}
                onLocationChanged={onLocationChanged(setFieldValue)}
                onConfirm={onConfirm(setFieldValue)}
              />
            </div>

            {!values.undefinedLocation && (
              <Typography
                variant='caption'
                className={clsx(classes.helperText, classes.errorMessage)}
                color={errors.location && touched.location ? 'error' : 'inherit'}>
                {errors.location || 'Introduce una ubicación.'}
              </Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={values.undefinedLocation}
                  className={clsx(classes.checkbox, sectionDisabled && 'disabled')}
                  disabled={sectionDisabled}
                  onChange={handleChangeUndefinedLocationCheck(setValues)}
                  name='undefinedLocation'
                  onBlur={handleBlur}
                />
              }
              label={
                <Typography
                  variant='h6'
                  color='primary'
                  className={clsx(classes.checkLabel, sectionDisabled && 'disabled')}>
                  No lo sé aún, se definirá pronto.
                </Typography>
              }
            />
          </div>

          <div className={classes.formGroup}>
            <TextField
              className={classes.input}
              name='address'
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              label='Dirección'
              fullWidth
              disabled={values.undefinedLocation || sectionDisabled}
              required
              variant='outlined'
              size='small'
              margin='none'
              helperText={
                errors.address ||
                (!values.undefinedLocation && 'Escriba la dirección exacta del evento.')
              }
              error={touched.address && Boolean(errors.address)}
            />
          </div>
          {values.isEventOwner && (
            <>
              <div className={classes.formGroup}>
                <FormControlLabel
                  className={classes.formControl}
                  control={
                    <Checkbox
                      color='primary'
                      name='enableAddOrganization'
                      checked={values.enableAddOrganization}
                      className={clsx(classes.checkbox, sectionDisabled && 'disabled')}
                      disabled={sectionDisabled}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label={
                    <Typography
                      variant='h6'
                      color='primary'
                      className={clsx(classes.checkLabel, sectionDisabled && 'disabled')}>
                      Comparto la organización de este evento (opcional).
                    </Typography>
                  }
                />
              </div>

              {values.enableAddOrganization && (
                <div className={classes.formGroup}>
                  <FormControl variant='outlined' className={classes.formControl}>
                    <Autocomplete
                      multiple
                      id='organization-autocomplete'
                      options={organizationOptions.filter((x) => x.id !== values.organizationId)}
                      getOptionLabel={(option) => option.handle || option.name}
                      selectOnFocus
                      filterSelectedOptions
                      disabled={sectionDisabled}
                      filterOptions={(options) => options}
                      onInputChange={(e) => debounceOnChange(e?.target.value, setFieldValue)}
                      onChange={(event, optionSelected) =>
                        onOrganizationsChange(setFieldValue)(optionSelected)
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option.handle || option.name}
                            key={option.id}
                            title={option.handle || option.name}
                            {...getTagProps({ index })}
                            color='primary'
                            className={clsx(classes.option, sectionDisabled && 'disabled')}
                          />
                        ))
                      }
                      getOptionSelected={(option) =>
                        values.organizationsSelect.some((o) => o.id === option.id)
                      }
                      open={openOrganizationsSelect}
                      onOpen={() => setOpenOrganizationsSelect(true)}
                      onClose={() => setOpenOrganizationsSelect(false)}
                      value={values.organizationsSelect}
                      className={clsx(classes.combo, sectionDisabled && 'disabled')}
                      loading={organizationsPending}
                      loadingText={<Typography align='center'> Cargando...</Typography>}
                      noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                      clearText='Quitar todo'
                      openText='Abrir desplegable'
                      closeText='Cerrar desplegable'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={clsx(classes.input, classes.comboInput)}
                          name='organizationsSelect'
                          label='Nombre o alias de la organización*'
                          variant='outlined'
                          autoFocus={organizationSelectFocused}
                          error={touched.organizationsSelect && Boolean(errors.organizationsSelect)}
                          helperText={
                            errors.organizationsSelect ||
                            'Escriba nombre o alias del co-organizador.'
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: organizationsPending ? (
                              <CircularProgress color='primary' size={16} />
                            ) : (
                              params.InputProps.endAdornment
                            )
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </SectionBlock>
  )
}

export default InformationSection
