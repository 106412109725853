import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  addLink: {
    cursor: 'pointer',
    fontSize: '.875rem',
    textDecoration: 'underline',
    fontWeight: 600
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    width: 250,
    maxWidth: 250,
    fontSize: '.75rem'
  },
  tooltipButton: {
    top: '2px !important',
    right: '5px !important'
  },
  tooltipArrow: {
    color: theme.palette.tooltip.main
  },
  input: {
    flexGrow: 1,
    marginTop: theme.spacing(2),

    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    },

    '& > p': {
      color: theme.palette.secondary.contrastText
    },

    '& textarea': {
      zIndex: 1,
      fontWeight: 500,
      '&::-webkit-scrollbar': {
        width: 12,
        [theme.breakpoints.up('md')]: {
          width: 16
        }
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: 10,
        backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        background: theme.utils.rgba(theme.palette.primary.main, 0.45)
      }
    }
  }
}))
