import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, useMediaQuery } from '@material-ui/core'
import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons'
import moment from 'moment'
import { resetValue, updateEventInscription } from 'state/modules/events'
import { DATE_FORMAT, STRING_EMPTY } from 'utils/constants'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import { InscriptionFormDialog } from '../InscriptionFormDialog'

import { useStyles } from './EditInscriptionFormDialog.style'

const OPTION_OTHER = { label: 'Otra', value: 'other' }

const DIALOG_STATES = {
  MINI_DIALOG: 'miniDialog',
  FULL_DIALOG: 'fullDialog'
}

const SALE_ORDER_STATES = {
  PAID: 'Paid',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
  EXPIRED: 'Expired'
}

const INSCRIPTION_STATES = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled'
}

const EditInscriptionFormDialog = ({ title, inscription, load, handleOpen }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    event,
    activeInscriptionForm,
    updateEventInscriptionError,
    activeInscriptionFormPending
  } = useSelector((state) => state.events)

  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (updateEventInscriptionError && updateEventInscriptionError.id === inscription?.id) {
      showSnackbarError(updateEventInscriptionError)
    }
    return () => {
      dispatch(resetValue('updateEventInscriptionError'))
    }
  }, [updateEventInscriptionError])

  const settingCustomField = (values) => (e) => {
    const fieldValue =
      values.find((v) => v.eventCustomFieldId === e.eventCustomField.id)?.fieldValue ?? STRING_EMPTY

    return { ...e, fieldValue }
  }

  const onSubmit = async ({
    birthdate,
    category,
    distance,
    country,
    areaLevel1,
    areaLevel2,
    areaLevel2Other,
    customFieldValues,
    gender,
    state,
    saleOrder,
    ...restProps
  }) => {
    const isInscriptionExpired = state === SALE_ORDER_STATES.EXPIRED
    const updateInscription = {
      ...restProps,
      category: { id: category.value, name: category.label },
      distance: { id: distance.value, name: distance.label },
      categoryId: category.value,
      distanceId: distance.value,
      countryId: country ? country.value : null,
      countryName: country ? country.label : null,
      areaLevel1Id: areaLevel1 ? areaLevel1.value : null,
      areaLevel1Name: areaLevel1 ? areaLevel1.label : null,
      areaLevel2Id: areaLevel2 && areaLevel2.value !== OPTION_OTHER.value ? areaLevel2.value : null,
      areaLevel2Name:
        areaLevel2 && areaLevel2.label !== OPTION_OTHER.label ? areaLevel2.label : null,
      areaLevel2Other,
      birthdate: moment(birthdate).format(DATE_FORMAT),
      gender: gender.value,
      customFieldValues: customFieldValues.map((c) => ({
        ...c,
        fieldValue: c.eventCustomField.type === 'Number' ? Number(c.fieldValue) : c.fieldValue
      })),
      state: isInscriptionExpired ? INSCRIPTION_STATES.CANCELLED : state,
      saleOrder: saleOrder && {
        ...saleOrder,
        state:
          !isInscriptionExpired && saleOrder.state === SALE_ORDER_STATES.EXPIRED
            ? SALE_ORDER_STATES.CANCELLED
            : saleOrder.state
      }
    }

    const updateData = await dispatch(updateEventInscription(updateInscription))

    if (updateData) {
      load(updateData.page, updateData.inscription)
      setOpenDialog(false)
      showSnackbarSuccess('Inscripción actualizada')
    }
  }

  const getAreaLevel2 = (inscription) => {
    if (inscription.areaLevel2Other) return OPTION_OTHER

    if (inscription.areaLevel2?.id)
      return { value: inscription.areaLevel2.id, label: inscription.areaLevel2.name }

    return STRING_EMPTY
  }

  const inscriptionData = {
    ...inscription,
    distance: { value: inscription.distance.id, label: inscription.distance.name },
    category: { value: inscription.category.id, label: inscription.category.name },
    country: inscription.country
      ? { value: inscription.country.id, label: inscription.country.name }
      : STRING_EMPTY,
    areaLevel1: inscription.areaLevel1
      ? { value: inscription.areaLevel1.id, label: inscription.areaLevel1.name }
      : STRING_EMPTY,
    areaLevel2: getAreaLevel2(inscription),
    customFieldValues: activeInscriptionForm?.customFields
      ?.sort((a, b) => a.itemNumber - b.itemNumber)
      .map(settingCustomField(inscription?.customFieldValues))
  }

  const formData = useMemo(
    () => ({
      ...activeInscriptionForm,
      customFields: activeInscriptionForm?.customFields
        .map((e) => ({ ...e.eventCustomField, itemNumber: e.itemNumber }))
        .sort((a, b) => a.itemNumber - b.itemNumber)
    }),
    [activeInscriptionForm]
  )

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        className={classes.button}
        disabled={!event.isPublished}
        onClick={() => handleOpen(() => setOpenDialog(true))}>
        <EditOutlinedIcon />
      </Button>
      {!activeInscriptionFormPending && (
        <InscriptionFormDialog
          title={title}
          data={inscriptionData}
          onSubmit={onSubmit}
          formData={formData}
          state={isDesktop ? DIALOG_STATES.FULL_DIALOG : DIALOG_STATES.MINI_DIALOG}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  )
}

export default EditInscriptionFormDialog
