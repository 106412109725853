import React from 'react'
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { EditableRichText } from 'shared'
import { Transition } from 'utils'
import { OPTION_OTHER, SUSPENDED_REASON_STATE } from 'utils/constants'

import { useStyles } from './SuspendDescriptionDialog.style'

const SuspendDescriptionDialog = ({ open, onClose = () => {}, eventDate, event }) => {
  const classes = useStyles()

  const { id, name, suspensionReason, suspensionReasonOther } = event

  const getSuspensionReasonLabel = (suspensionReason) => {
    const reasonLabel = SUSPENDED_REASON_STATE[suspensionReason]

    if (suspensionReason !== OPTION_OTHER.value) return reasonLabel

    return suspensionReasonOther
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      TransitionProps={{ direction: 'up' }}
      TransitionComponent={Transition}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography className={classes.eventDate}>{eventDate}</Typography>

          <Typography color='primary' align='center' variant='h6' className={classes.title}>
            SUSPENDIDO
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.bodyContainer}>
          <div className={classes.mainContainer}>
            <Typography variant='h6' className={classes.eventName}>
              {name}
            </Typography>

            <Typography className={classes.suspendReason}>
              Motivo de Suspensión: <strong>{getSuspensionReasonLabel(suspensionReason)}</strong>
            </Typography>
            <EditableRichText
              entity={event}
              field='suspensionDescription'
              activeModeEdit={false}
              repositoryId={id}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SuspendDescriptionDialog
