import React, { useCallback, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { CircularProgress, Typography, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import AutoFormat from 'quill-autoformat'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import ImageResize from 'quill-image-resize-module-react'
import { EventsProxy } from 'services'
import { STRING_EMPTY } from 'utils/constants'
import { showSnackbarError } from 'utils/snackbar'

import { Resize } from './modules/Resize'
import { useStyles } from './EditableRichText.style'

import 'react-quill/dist/quill.snow.css'

Quill.register('modules/imageResize', ImageResize)

const Size = Quill.import('attributors/style/size')
Size.whitelist = ['16px', '18px']
Quill.register(Size, true)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
Quill.register('modules/autoFormat', AutoFormat, true)

const EditableRichText = ({
  entity,
  field,
  onChange = () => {},
  setStatusSection = () => {},
  repositoryId,
  error,
  activeModeEdit,
  className,
  readOnly,
  disabledActionImageButton,
  placeholder,
  onInitialValue,
  disabled,
  altMode
}) => {
  const quillRef = useRef()
  const fileInputRef = useRef()
  const theme = useTheme()
  const classes = useStyles()

  const [value, setValue] = useState(entity[field] || '')
  const [uploadEventImagePending, setUploadEventImagePending] = useState(false)

  const onChangeFile = async (event) => {
    if (!event.target.files.length) return

    const file = event.target.files[0]
    event.target.value = ''

    await handleSaveImage(file, file.type)
  }

  const imageHandler = useCallback(() => {
    if (disabled) return

    fileInputRef.current.click()
  }, [])

  const handlePasteImage = useCallback(async (imageDataUrl, type, imageData) => {
    if (disabled || disabledActionImageButton) return

    await handleSaveImage(imageData.toFile(), type)
  }, [])

  const handleSaveImage = async (imageFile, type) => {
    if (disabled || disabledActionImageButton) return

    const validImageFormats = ['image/jpg', 'image/jpeg']
    const quill = quillRef.current.getEditor()

    if (!validImageFormats.includes(type)) {
      showSnackbarError({
        message:
          'El formato de imagen no es válido, por favor seleccione una imagen con formato JPG o JPEG.'
      })
      return
    }
    try {
      setStatusSection(false)
      setUploadEventImagePending(true)
      const proxy = new EventsProxy()
      const range = quill.getSelection()
      const { eventImageUrl } = await proxy.uploadEventImage(repositoryId, imageFile)
      const url = eventImageUrl[0]
      quill.editor.insertEmbed(range.index, 'image', url)

      onChange(quillRef.current.unprivilegedEditor)
      setValue(quillRef.current.unprivilegedEditor.getHTML())
      setUploadEventImagePending(false)
      setStatusSection(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const handleOnchange = (content, __, _, state) => {
    const pattern =
      /<p><br><\/p>|class="ql-align-center"|<p class="ql-align-justify"><br><\/p>|<p class="ql-align-right"><br><\/p>|<p class="ql-align-center"><br><\/p>/

    const hasElementEmbed = content.split(pattern).find(Boolean)
    setValue(content)

    if (!state.getText().trim() && !hasElementEmbed) return onChange(null)
    onChange(state)
  }

  const modules = {
    toolbar: {
      container: [
        [
          'italic',
          'bold',
          'underline',
          'link',
          { align: STRING_EMPTY },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
          { color: [] },
          { background: [] },
          { size: Size.whitelist },
          !disabledActionImageButton ? 'image' : STRING_EMPTY,
          { list: 'ordered' },
          { list: 'bullet' }
        ].filter(Boolean),
        [{ indent: '-1' }]
      ],
      handlers: {
        image: imageHandler
      }
    },
    imageDropAndPaste: {
      handler: handlePasteImage
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: [Resize, 'DisplaySize', 'Toolbar'],
      handleStyles: {
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        color: 'blue'
      },
      toolbarButtonStyles: {
        display: 'none'
      }
    },
    autoFormat: true
  }

  return (
    <>
      <input
        type='file'
        accept='.JPG, .JPEG'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={onChangeFile}
      />
      <ReactQuill
        theme='snow'
        ref={quillRef}
        value={value}
        modules={modules}
        readOnly={readOnly || !activeModeEdit || disabled}
        placeholder={placeholder}
        className={clsx(
          classes.richField,
          altMode && classes.richFieldAlt,
          className,
          activeModeEdit && 'activeEdit',
          error && 'error',
          disabled && 'disabled',
          (readOnly || !activeModeEdit) && classes.hiddenField
        )}
        onChange={handleOnchange}
      />
      {error && (
        <Typography color='error' variant='caption' className={classes.loadingMessage}>
          {error}
        </Typography>
      )}

      {uploadEventImagePending && (
        <Typography color='primary' variant='caption' className={classes.loadingMessage}>
          Subiendo imagen&nbsp;
          <CircularProgress size={12} />
        </Typography>
      )}
    </>
  )
}

export default EditableRichText
