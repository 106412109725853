import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Typography } from '@material-ui/core'
import { DeleteOutline as DeleteOutlineIcon, Search as SearchIcon } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { ROUTES } from 'routes'

import { LoadingPlaceholder } from '../LoadingPlaceholder'

import useStyles from './FilterWidget.style'

const FilterWidget = ({
  searchParams,
  onSearchParamsChanged,
  searchMetadata,
  searchMetadataPending
}) => {
  const [searchInputText, setSearchInputText] = useState(searchParams.searchText)
  const [openSearchFromPicker, setOpenSearchFromPicker] = useState(false)
  const [openSearchToPicker, setOpenSearchToPicker] = useState(false)

  const loadTags = () => {
    const tags = [
      { id: 'disciplineId', list: 'disciplineItems' },
      { id: 'organizationId', list: 'organizationItems' },
      { id: 'areaLevel1Id', list: 'areaLevel1Items' }
    ].reduce((acc, el) => {
      const { id, list } = el
      if (searchParams[id]) {
        acc.push({
          id: searchParams[id],
          nameList: list,
          field: id
        })
      }
      return acc
    }, [])
    return tags
  }

  const [filterTags, setFilterTags] = useState(loadTags())
  const classes = useStyles()

  const { from, to, withResultsOnly, page } = searchParams

  useEffect(() => {
    const newSearchParams = {
      searchText: searchParams.searchText,
      from,
      to,
      withResultsOnly,
      page
    }

    for (let i = 0; i < filterTags.length; i++) {
      const tag = filterTags[i]
      newSearchParams[tag.field] = tag.id
    }

    const resultsOnlyTag = filterTags.find((x) => x.id === 'resultsOnly')
    if (!resultsOnlyTag && withResultsOnly) {
      filterTags.push({
        id: 'resultsOnly',
        field: 'resultsOnly',
        name: 'Solo con resultados'
      })
    } else if (resultsOnlyTag && !withResultsOnly) {
      removeFilterTag(resultsOnlyTag)
    }
    setSearchInputText(searchParams.searchText)
    onSearchParamsChanged(newSearchParams)
  }, [filterTags, searchParams])

  const addFilterTag = (item, field, nameList) => {
    if (searchParams.page !== 1) searchParams.page = 1

    setFilterTags((state) => {
      const list = state.filter((e) => e.field !== field)
      return [
        ...list,
        {
          ...item,
          field,
          nameList
        }
      ]
    })
  }

  const removeFilterTag = (item) => {
    if (item.id === 'resultsOnly') {
      searchParams.withResultsOnly = false
    }
    if (searchParams.page !== 1) searchParams.page = 1

    const remainFilters = filterTags.filter((x) => x.id !== item.id)
    setFilterTags([...remainFilters])
  }

  const load = () => {
    const tags = { ...searchMetadata }
    for (let i = 0; i < filterTags.length; i++) {
      const tag = filterTags[i]
      if (!tag.name) {
        const searchTag = searchMetadata[tag.nameList].find((x) => x.id === tag.id)
        tag.name = searchTag && searchTag.name
      }

      tags[tag.nameList] = tags[tag.nameList]?.filter((x) => x.id !== tag.id)
    }

    return tags
  }
  const searchFilter = !!searchMetadata && load()

  const onChangeDate = (date, type) => {
    if (date?.isValid()) {
      if (searchParams?.from && type === 'to') {
        if (date.isBefore(searchParams.from)) {
          type = 'from'
        }
      } else if (searchParams?.to && type === 'from') {
        if (date.isAfter(searchParams.to)) {
          type = 'to'
        }
      }
      onSearchParamsChanged({
        ...searchParams,
        [type]: date.format('YYYY-MM-DD'),
        page: 1
      })
    }
  }

  const updateDate = (date, type) => onChangeDate(date, type)
  const debounceOnChange = debounce(updateDate, 800)

  const history = useHistory()

  const submitEventsSearch = (e) => {
    e.preventDefault()

    onSearchParamsChanged({ ...searchParams, searchText: searchInputText, page: 1 })

    history.push(ROUTES.EVENTS.SEARCH)
  }

  const handleOpen = (setOpen) => {
    window.scrollTo(0, 0)

    setOpen(true)
  }

  return (
    <div
      className={clsx(classes.container, !openSearchFromPicker && !openSearchToPicker && 'resize')}>
      <Typography variant='h4' color='primary' className={classes.title}>
        Tu búsqueda
      </Typography>
      <form onSubmit={submitEventsSearch} className={classes.form}>
        <div className={classes.searchSection}>
          <input
            className={clsx(classes.inputField, classes.searchField)}
            style={{ border: 0 }}
            type='text'
            autoComplete='off'
            value={searchInputText}
            onChange={(e) => {
              setSearchInputText(e.target.value)
            }}
          />
          <IconButton type='submit' className={classes.searchButton}>
            <SearchIcon />
          </IconButton>
        </div>
      </form>
      <div className={classes.filterTagSection}>
        {filterTags?.map((x) => (
          <div className={classes.filterTag} key={x.id}>
            {x.name && <Typography color='primary'>{x.name}</Typography>}
            <IconButton onClick={() => removeFilterTag(x)}>
              <DeleteOutlineIcon className={classes.closeTag} />
            </IconButton>
          </div>
        ))}
      </div>
      <div className={classes.filterSection}>
        <Typography variant='h4' color='primary' className={classes.title}>
          Fecha
        </Typography>
        <div className={classes.dateSection}>
          <KeyboardDatePicker
            className={clsx(`${classes.inputDateField} datePicker`, classes.dateField)}
            autoOk
            format='DD/MM/YYYY'
            variant='inline'
            margin='none'
            open={openSearchFromPicker}
            onOpen={() => handleOpen(setOpenSearchFromPicker)}
            onClose={() => setOpenSearchFromPicker(false)}
            id='date-picker-inline'
            inputVariant='outlined'
            value={searchParams.from}
            onChange={(from) => {
              debounceOnChange(from, 'from')
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            emptyLabel='Desde'
            invalidDateMessage='Formato de fecha invalido'
            placeholder='dd/mm/aaaa'
          />
          <KeyboardDatePicker
            className={clsx(`${classes.inputDateField} datePicker`, classes.dateField)}
            animateYearScrolling
            autoOk
            format='DD/MM/YYYY'
            variant='inline'
            margin='none'
            id='date-picker-inline'
            inputVariant='outlined'
            value={searchParams.to}
            open={openSearchToPicker}
            onOpen={() => handleOpen(setOpenSearchToPicker)}
            onClose={() => setOpenSearchToPicker(false)}
            invalidDateMessage='Formato de fecha invalido'
            onChange={(to) => {
              debounceOnChange(to, 'to')
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            emptyLabel='Hasta'
            placeholder='dd/mm/aaaa'
          />
        </div>
      </div>
      <div className={classes.filterSection}>
        <Typography variant='h4' color='primary' className={`${classes.title} underlined`}>
          Disciplina
        </Typography>
        {searchMetadataPending ? (
          <LoadingPlaceholder />
        ) : (
          <ul className={classes.filterList}>
            {!!searchFilter &&
              searchFilter.disciplineItems?.map((x) => (
                <li key={x.id}>
                  <button
                    title={x.name}
                    onClick={() => addFilterTag(x, 'disciplineId', 'disciplineItems')}>
                    {x.name}
                  </button>
                  <span>&nbsp;({x.eventsCount})</span>
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className={classes.filterSection}>
        <Typography variant='h4' color='primary' className={`${classes.title} underlined`}>
          Ubicación
        </Typography>
        {searchMetadataPending ? (
          <LoadingPlaceholder />
        ) : (
          <ul className={classes.filterList}>
            {!!searchFilter &&
              searchFilter.areaLevel1Items?.map((x) => (
                <li key={x.id}>
                  <button
                    title={x.name}
                    onClick={() => addFilterTag(x, 'areaLevel1Id', 'areaLevel1Items')}>
                    {x.name}
                  </button>
                  <span>&nbsp;({x.eventsCount})</span>
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className={classes.filterSection}>
        <Typography variant='h4' color='primary' className={`${classes.title} underlined`}>
          Organización
        </Typography>
        {searchMetadataPending ? (
          <LoadingPlaceholder />
        ) : (
          <ul className={classes.filterList}>
            {!!searchFilter &&
              searchFilter.organizationItems?.map((x) => (
                <li key={x.id}>
                  <button
                    title={x.name}
                    onClick={() => addFilterTag(x, 'organizationId', 'organizationItems')}>
                    {x.name}
                  </button>
                  <span>&nbsp;({x.eventsCount})</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FilterWidget
