import React, { useEffect, useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import {
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditOutlinedIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { CustomizedTooltip } from 'shared'
import { loadListInscriptions } from 'state/modules/events'
import { PRICE_FORMAT } from 'utils/constants'
import { normalizeUrl } from 'utils/functions'

import { CreateTicketDialog } from '../CreateTicketDialog'
import { DeleteTicketDialog } from '../DeleteTicketDialog'
import { DuplicateButton } from '../DuplicateButton'
import { EditTicketDialog } from '../EditTicketDialog'
import { SectionBlock } from '../SectionBlock'
import { SuccessMessage } from '../SuccessMessage'
import { UpdateChangeDialog } from '../UpdateChangeDialog'
import { WarningDeleteTicketDialog } from '../WarningDeleteTicketDialog'

import { useStyles } from './InscriptionPriceSection.style'

const OPTIONS = {
  Category: { label: 'categorías', key: 'Category' },
  Distance: { label: 'distancias', key: 'Distance' }
}

const initialTicketDialog = {
  open: false,
  ticket: {
    name: '',
    quota: '',
    price: '',
    mercadoPagoEnabled: false,
    bankAccountsEnabled: false,
    paymentLocationsEnabled: false,
    bearCost: false,
    saleFrom: null,
    saleTo: null,
    distancesOrCategoriesItems: {},
    associatedBy: OPTIONS.Distance.key,
    mercadoPagoPrice: 0,
    mercadoPagoFee: 0
  }
}

const initialUpdateChangeDialogProps = {
  ticket: {},
  open: false,
  replaceTicket: false
}

const TITLE_EDIT_DIALOG = {
  TITLE_EDIT_TICKET: 'EDITAR TICKET',
  TITLE_DUPLICATE_TICKET: 'TICKET DUPLICADO'
}

const EDIT_TICKET_DIALOG_STATE = {
  DUPLICATE: 'duplicate',
  EDIT: 'edit'
}

const TICKET_STATES = {
  TICKET_EXPIRED: 'ticketExpired',
  TICKET_ACTIVE: 'ticketActive',
  TICKET_UPCOMING: 'ticketUpcoming'
}

const INSCRIPTION_STATE = {
  INSCRIPTION_CANCELLED: 'Cancelled',
  INSCRIPTION_EXPIRED: 'Expired'
}

const INSCRIPTION_FREE_PRICE = 0

const InscriptionPriceSection = ({ title }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    paymentParams,
    eventBankAccounts,
    paymentLocations,
    distances,
    categories,
    listInscription,
    event
  } = useSelector((state) => state.events)
  const { initialValues, values, errors, setFieldValue, handleBlur } = useFormikContext()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false)
  const [deleteTicketDialogProps, setDeleteTicketDialogProps] = useState(initialTicketDialog)
  const [editTicketDialogProps, setEditTicketDialogProps] = useState(initialTicketDialog)
  const [openTicketSuccessMessage, setOpenTicketSuccessMessage] = useState(false)
  const [openWarningDeleteTicketDialog, setOpenWarningDeleteTicketDialog] =
    useState(initialTicketDialog)
  const [updateChangeDialogProps, setUpdateChangeDialogProps] = useState(
    initialUpdateChangeDialogProps
  )

  useEffect(() => {
    dispatch(loadListInscriptions(event.id))
  }, [])

  const onCloseCreateTicketDialog = () => setOpenCreateTicketDialog(false)
  const onOpenCreateTicketDialog = () => setOpenCreateTicketDialog(true)

  const onCloseDeleteTicketDialog = () => setDeleteTicketDialogProps(initialTicketDialog)

  const onCloseEditTicketDialog = () => setEditTicketDialogProps(initialTicketDialog)
  const onCloseUpdateChangeDialog = () => setUpdateChangeDialogProps(initialUpdateChangeDialogProps)

  const onCloseWarningDeleteTicketDialog = () =>
    setOpenWarningDeleteTicketDialog(initialTicketDialog)

  const onOpenDeleteTicketDialog = (ticket, position) => {
    const hasInscriptions = listInscription.some(
      (item) =>
        item.ticketTypeId === ticket.id &&
        item.state !== INSCRIPTION_STATE.INSCRIPTION_CANCELLED &&
        item.state !== INSCRIPTION_STATE.INSCRIPTION_EXPIRED
    )

    if (hasInscriptions) {
      setOpenWarningDeleteTicketDialog({ ticket, open: true })
    } else {
      setDeleteTicketDialogProps({ ticket, open: true, position })
    }
  }

  const getActiveTicketTypes = () =>
    values.ticketTypes
      .filter((x) => x.isActive)
      .sort(
        (a, b) =>
          moment(a.saleFrom).diff(moment(b.saleFrom)) ||
          moment(moment(a.saleTo).format('YYYY-MM-DD')).diff(
            moment(moment(b.saleTo).format('YYYY-MM-DD'))
          ) ||
          Number(a.price) - Number(b.price) ||
          a.name.localeCompare(b.name)
      )

  const onOpenEditTicketDialog = (
    ticket,
    position,
    title,
    state = EDIT_TICKET_DIALOG_STATE.EDIT
  ) => {
    setEditTicketDialogProps({ ticket, open: true, position, title, state })
  }

  const onCreateTicket =
    (actionSuccess) =>
    async ({
      name,
      quota,
      price,
      saleFrom,
      saleToDate,
      saleToTime,
      distancesOrCategoriesItems,
      associatedBy,
      mercadoPagoEnabled,
      bearCost,
      mercadoPagoPrice,
      inscriptionsCount = 0,
      paymentUrl,
      isActive,
      items = [],
      ...restProps
    }) => {
      const prevItems = items.filter(
        (x) => distancesOrCategoriesItems[x.inscriptionFormItem[`event${associatedBy}Id`]]
      )
      const otherItems = values.items
        .filter(
          (item) =>
            !prevItems.some(
              (x) =>
                x.inscriptionFormItem[`event${associatedBy}Id`] === item[`event${associatedBy}Id`]
            ) && distancesOrCategoriesItems[item[`event${associatedBy}Id`]]
        )
        .map((item) => ({
          inscriptionFormItem: { ...item, inscriptionsCount: 0 }
        }))

      const newItems = [...prevItems, ...otherItems]

      const ticketType = {
        ...restProps,
        name: name.trim(),
        quota: Number(quota),
        price: Number(price),
        saleFrom: moment(saleFrom).isValid() ? moment(saleFrom).format() : null,
        saleTo:
          moment(saleToDate).isValid() && moment(saleToTime).isValid()
            ? moment(
                `${moment(saleToDate).format('YYYY-MM-DD')} ${moment(saleToTime).format('HH:mm')}`
              ).format()
            : null,

        items: newItems,
        mercadoPagoPrice: mercadoPagoEnabled ? (bearCost ? price : mercadoPagoPrice) : 0,
        inscriptionsCount,
        mercadoPagoEnabled,
        paymentUrl: normalizeUrl(paymentUrl),
        isActive
      }

      actionSuccess(ticketType)
    }

  const verifyChange = (ticketId, ticket) => {
    const ticketState = initialValues.ticketTypes.find((x) => x.id === ticketId)
    const { id, items, saleFrom, saleTo, ...props } = ticketState

    const changesDate =
      !moment(saleFrom).isSame(moment(ticket.saleFrom)) ||
      !moment(saleTo).isSame(moment(ticket.saleTo))

    if (changesDate) return true

    const keys = Object.keys(props)

    const changesItems =
      items.length !== ticket.items.length ||
      items.filter(
        (x) =>
          !ticket.items.some(
            (t) =>
              t.inscriptionFormItem.eventCategoryId === x.inscriptionFormItem.eventCategoryId &&
              t.inscriptionFormItem.eventDistanceId === x.inscriptionFormItem.eventDistanceId
          )
      ).length > 0

    return changesItems || keys.some((x) => props[x] !== ticket[x])
  }

  const onSubmitUpdate = (ticketType) => {
    const {
      name,
      quota,
      price,
      saleFrom,
      saleTo,
      items,
      mercadoPagoEnabled,
      bankAccountsEnabled,
      paymentLocationsEnabled,
      mercadoPagoPrice,
      mercadoPagoFee,
      sportmetricFeeAmount,
      sportmetricFeePercentage,
      inscriptionsCount,
      paymentUrl
    } = ticketType

    const newTicketTypes = getActiveTicketTypes().map((e) => ({ ...e }))
    const ticket = newTicketTypes.find((_, index) => index === editTicketDialogProps.position)

    if (!ticket) return

    const hasError = !!ticket.inscriptionsCount && ticket.price !== price

    ticket.hasChanges = !ticket.id || verifyChange(ticket.id, ticketType)
    ticket.name = name
    ticket.quota = quota
    ticket.price = price
    ticket.saleFrom = saleFrom
    ticket.saleTo = saleTo
    ticket.items = items
    ticket.mercadoPagoEnabled = mercadoPagoEnabled
    ticket.bankAccountsEnabled = bankAccountsEnabled
    ticket.paymentLocationsEnabled = paymentLocationsEnabled
    ticket.mercadoPagoFee = mercadoPagoFee
    ticket.mercadoPagoPrice = mercadoPagoPrice
    ticket.sportmetricFeeAmount = sportmetricFeeAmount
    ticket.sportmetricFeePercentage = sportmetricFeePercentage
    ticket.inscriptionsCount = inscriptionsCount
    ticket.paymentUrl = paymentUrl

    if (hasError) {
      setUpdateChangeDialogProps({ open: true, ticket })
      return
    }

    setFieldValue('ticketTypes', [
      ...newTicketTypes,
      ...values.ticketTypes.filter((x) => !x.isActive)
    ])

    onCloseEditTicketDialog()
    onCloseUpdateChangeDialog()
  }

  const disabledTicket = ({ id, ...restTicket }) => {
    const newTicketTypes = getActiveTicketTypes().map((e) => ({ ...e }))

    const ticket = newTicketTypes.find((_, index) => index === editTicketDialogProps.position)

    if (!ticket) return

    ticket.isActive = false

    const newTicket = {
      ...restTicket,
      quota: restTicket.quota && restTicket.quota - restTicket.inscriptionsCount,
      inscriptionsCount: 0
    }

    setFieldValue(
      'ticketTypes',
      [...newTicketTypes, ...values.ticketTypes.filter((x) => !x.isActive), newTicket],
      false
    )

    onCloseEditTicketDialog()
    onCloseUpdateChangeDialog()
  }

  const onSubmitCreate = (ticketType) => {
    setFieldValue('ticketTypes', [...values.ticketTypes, { ...ticketType, hasChanges: true }])
    onCloseCreateTicketDialog()
  }

  const handleDuplicateTicket = async (ticketType, index) => {
    const { id, items, ...ticket } = ticketType

    const newTicket = {
      ...ticket,
      saleFrom: null,
      saleTo: null,
      items: items.map((item) => ({
        ...item,
        inscriptionFormItem: item.inscriptionFormItem
          ? { ...item.inscriptionFormItem, inscriptionsCount: 0 }
          : null
      })),

      saleToTime: null,
      saleToDate: null,
      inscriptionsCount: 0
    }

    onOpenEditTicketDialog(
      newTicket,
      index + 1,
      TITLE_EDIT_DIALOG.TITLE_DUPLICATE_TICKET,
      EDIT_TICKET_DIALOG_STATE.DUPLICATE
    )
    setOpenTicketSuccessMessage(true)
  }

  const handleSaveTicketDuplicate = ({ ...ticket }) => {
    setFieldValue('ticketTypes', [...values.ticketTypes, { ...ticket, hasChanges: true }])

    onCloseEditTicketDialog()
  }

  const handleChangeRadio = (setFieldValue) => (e) => setFieldValue('associatedBy', e.target.value)

  const dateFormatted = (date) => moment(date).format('DD/MM/YYYY')

  const getState = (saleFrom, saleTo) => {
    if (moment().isBefore(moment(saleFrom))) return TICKET_STATES.TICKET_UPCOMING

    if (moment().isAfter(moment(saleTo))) return TICKET_STATES.TICKET_EXPIRED

    return TICKET_STATES.TICKET_ACTIVE
  }

  const validateTicket = (ticket, items) => {
    const hasItem = ticket.items.some((i) =>
      items.some(
        (x) =>
          x.eventCategoryId === i.inscriptionFormItem.eventCategoryId &&
          x.eventDistanceId === i.inscriptionFormItem.eventDistanceId
      )
    )

    if (!hasItem) return true

    if (values.isFree || ticket.price === INSCRIPTION_FREE_PRICE) return

    const hasPaymentMethod = [
      ticket.paymentLocationsEnabled,
      ticket.bankAccountsEnabled,
      ticket.mercadoPagoEnabled,
      ticket.paymentUrl
    ].some(Boolean)

    if (!hasPaymentMethod) return true
  }

  const hasTicketTypes = !!values.ticketTypes.filter((x) => x.isActive).length
  const activeItems = values.items
    .filter((x) => categories.some((c) => c.id === x.eventCategoryId))
    .filter((x) => distances.some((d) => d.id === x.eventDistanceId))

  const canCreateTicket =
    distances.some((x) => values.items.some((r) => r.eventDistanceId === x.id)) &&
    !!values.openFromDate &&
    !!values.openToDate &&
    !errors.openFromDate &&
    !errors.openToDate &&
    (values.isFree ||
      !!values.mercadoPagoCode ||
      !!paymentLocations.filter((x) => x.isActive).length ||
      !!eventBankAccounts.filter((x) => x.isActive).length ||
      paymentParams.paymentUrlSelected)

  const disabledAssociatedBy =
    !canCreateTicket || values.ticketTypes.filter((x) => x.isActive).some(Boolean)
  const showTooltip =
    !canCreateTicket &&
    (values.isFree ||
      paymentParams.mercadoPagoSelected ||
      paymentParams.paymentCashSelected ||
      paymentParams.paymentUrlSelected ||
      paymentParams.paymentBankSelected)

  const activeTicketTypes = getActiveTicketTypes()

  const hasChangesActiveTicketTypes =
    values.formPublished &&
    !initialValues.ticketTypes
      .filter((x) => x.isActive)
      .every((x) => activeTicketTypes.some((t) => t.id === x.id))

  return (
    <SectionBlock title={title} activeAlertChange={hasChangesActiveTicketTypes}>
      {!values.isFree && (
        <Typography className={classes.subtitle}>
          Creá ticket/s asignando los precios de inscripción para tu evento y habilitá los medios de
          pago de tu preferencia.
        </Typography>
      )}
      <Typography className={classes.infoText}>
        *Los ticket/s que esten vigentes se visualizarán en el perfil del evento.
      </Typography>

      <div className={classes.container}>
        <div className={classes.mainContainer}>
          <div className={classes.selectAssociateContainer}>
            <Typography
              className={clsx(classes.associateTitle, disabledAssociatedBy && 'disabled')}
              color='primary'
              variant='h6'>
              ¿Con qué desea asociar sus tickets de inscripción?
            </Typography>
            <RadioGroup
              aria-label='associatedBy'
              name='associatedBy'
              value={values.associatedBy}
              onBlur={handleBlur}
              onChange={handleChangeRadio(setFieldValue)}
              className={clsx(classes.formControl, disabledAssociatedBy && 'disabled')}>
              <FormControlLabel
                value={OPTIONS.Distance.key}
                disabled={disabledAssociatedBy}
                control={<Radio color='primary' className={classes.radio} />}
                classes={{ label: classes.formControlLabel }}
                label='Distancia.'
              />
              <FormControlLabel
                disabled={disabledAssociatedBy}
                value={OPTIONS.Category.key}
                classes={{ label: classes.formControlLabel }}
                control={<Radio color='primary' className={classes.radio} />}
                label='Categoría.'
              />
            </RadioGroup>
          </div>
          {hasTicketTypes && (
            <table className={classes.table}>
              <thead>
                <tr className={classes.row}>
                  <th className={classes.column}>Nombre</th>
                  <th className={classes.column}>Desde/Hasta</th>
                  <th className={classes.column}>Cant. de tickets</th>
                  <th className={classes.column}>Precio MP</th>
                  <th className={classes.column}>Precio</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {activeTicketTypes
                  .map((x) => ({
                    ...x,
                    state: getState(x.saleFrom, x.saleTo)
                  }))
                  .map((x) => ({
                    ...x,
                    hasErrors:
                      x.state !== TICKET_STATES.TICKET_EXPIRED && validateTicket(x, activeItems)
                  }))

                  .map((ticket, index) => (
                    <tr
                      className={clsx(
                        classes.row,
                        classes[ticket.state],
                        ticket.hasErrors && classes.errorTicket
                      )}
                      key={index}>
                      <td className={classes.bodyColumn}>{ticket.name}</td>

                      <td className={classes.bodyColumn}>{`${dateFormatted(
                        ticket.saleFrom
                      )} al ${dateFormatted(ticket.saleTo)}`}</td>

                      <td className={classes.bodyColumn}>
                        {!ticket.quota ? 'Sin límite' : ticket.quota}
                      </td>

                      <td className={classes.bodyColumn}>
                        {ticket.mercadoPagoEnabled && !values.isFree
                          ? numericFormatter(
                              Number(ticket.mercadoPagoPrice).toString(),
                              PRICE_FORMAT
                            )
                          : '--'}
                      </td>

                      <td className={classes.bodyColumn}>
                        {!values.isFree
                          ? numericFormatter(Number(ticket.price).toString(), PRICE_FORMAT)
                          : '--'}
                      </td>

                      <td className={classes.bodyColumn}>
                        <IconButton
                          color='primary'
                          onClick={() =>
                            onOpenEditTicketDialog(
                              ticket,
                              index,
                              TITLE_EDIT_DIALOG.TITLE_EDIT_TICKET
                            )
                          }>
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                          color='primary'
                          onClick={() => onOpenDeleteTicketDialog(ticket, index)}>
                          <DeleteIcon />
                        </IconButton>
                        <DuplicateButton
                          color='primary'
                          title='Duplicar ticket'
                          isDisabled={!canCreateTicket}
                          onDuplicate={() => handleDuplicateTicket(ticket, index)}
                        />
                        {ticket.hasErrors && (
                          <CustomizedTooltip
                            title='Error Ticket incompleto'
                            autoOpenTooltip={{ state: true }}
                            position={isDesktop ? 'right-end' : 'top-end'}
                            className={classes.errorTicketTooltip}
                            arrowClassName={classes.errorTicketArrow}
                            buttonClassName={classes.closeButton}
                            disabledClickAway>
                            {({ handleTooltip }) => (
                              <ErrorIcon
                                className={classes.alertInfoIcon}
                                onClick={handleTooltip}
                              />
                            )}
                          </CustomizedTooltip>
                        )}
                        {values.formPublished && ticket.hasChanges && (
                          <CustomizedTooltip
                            position={isDesktop ? 'right-end' : 'top-end'}
                            className={classes.alertTooltip}
                            disabledClickAway
                            arrowClassName={classes.alertTooltipArrow}
                            buttonClassName={classes.tooltipCloseButton}
                            title='El cambio impactará al guardar los cambios.'>
                            {({ handleTooltip }) => (
                              <IconButton
                                size='small'
                                className={classes.alertButton}
                                onClick={handleTooltip}>
                                <ErrorIcon />
                              </IconButton>
                            )}
                          </CustomizedTooltip>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          <SuccessMessage
            message='INSCRIPCIÓN DUPLICADA CORRECTAMENTE'
            open={openTicketSuccessMessage}
            onClose={() => setOpenTicketSuccessMessage(false)}
          />
          {!canCreateTicket &&
            !values.isFree &&
            !paymentParams.mercadoPagoSelected &&
            !paymentParams.paymentCashSelected &&
            !paymentParams.paymentBankSelected &&
            !paymentParams.paymentUrlSelected && (
              <Typography
                color='error'
                variant='h6'
                align='center'
                className={classes.alertMessage}>
                Deberás elegir al menos un medio de pago para poder crear un ticket
              </Typography>
            )}
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              disabled={!canCreateTicket}
              onClick={onOpenCreateTicketDialog}>
              {hasTicketTypes ? 'Agregar Ticket' : 'Crear Ticket'}
            </Button>
            {showTooltip && (
              <CustomizedTooltip
                title={`Para crear ticket/s debes tener definida la fecha de inscripción${
                  !values.isFree ? ' y configurada la modalidad/es de pago' : ''
                }.`}
                position={isDesktop ? 'right-end' : 'bottom-end'}
                className={classes.tooltip}
                arrowClassName={classes.arrow}>
                {({ handleTooltip }) => (
                  <InfoIcon className={classes.infoIcon} onClick={handleTooltip} />
                )}
              </CustomizedTooltip>
            )}
          </div>
          <DeleteTicketDialog {...deleteTicketDialogProps} onClose={onCloseDeleteTicketDialog} />
          <EditTicketDialog
            {...editTicketDialogProps}
            onClose={onCloseEditTicketDialog}
            onSubmit={onCreateTicket(
              editTicketDialogProps.state === EDIT_TICKET_DIALOG_STATE.EDIT
                ? onSubmitUpdate
                : handleSaveTicketDuplicate
            )}
            eventIsFree={values.isFree}
            items={activeItems}
            state={
              editTicketDialogProps.state === EDIT_TICKET_DIALOG_STATE.EDIT
                ? EDIT_TICKET_DIALOG_STATE.EDIT
                : EDIT_TICKET_DIALOG_STATE.DUPLICATE
            }
          />
          <UpdateChangeDialog
            {...updateChangeDialogProps}
            onUpdateChange={disabledTicket}
            onClose={onCloseUpdateChangeDialog}
          />
          <CreateTicketDialog
            onClose={onCloseCreateTicketDialog}
            open={openCreateTicketDialog}
            items={values.items}
            eventIsFree={values.isFree}
            onSubmit={onCreateTicket(onSubmitCreate)}
          />
          <WarningDeleteTicketDialog
            {...openWarningDeleteTicketDialog}
            onClose={onCloseWarningDeleteTicketDialog}
          />
        </div>
      </div>
    </SectionBlock>
  )
}

export default InscriptionPriceSection
