import React, { useEffect, useState } from 'react'
import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './CustomizedTooltip.style'

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    borderRadius: 10,
    padding: theme.spacing(2),
    width: 200
  },

  popper: {
    zIndex: 1000
  }
}))(Tooltip)

const CustomizedTooltip = ({
  title,
  position,
  children,
  className,
  arrowClassName,
  disabledTooltip,
  defaultState = false,
  disabledClickAway = false,
  autoHide,
  autoOpenTooltip,
  isTopMost,
  buttonClassName
}) => {
  const classes = useStyles()
  const [openTooltip, setOpenTooltip] = useState(defaultState)

  const handleTooltip = () => {
    if (disabledTooltip) return

    setOpenTooltip(!openTooltip)
  }

  const handleTooltipClose = () => setOpenTooltip(false)

  const handleTootipOpen = () => setOpenTooltip(true)

  const handleClickAway = () => {
    if (disabledClickAway) return

    handleTooltipClose()
  }

  useEffect(() => {
    if (autoHide) setOpenTooltip(false)
  }, [autoHide])

  useEffect(() => {
    if (autoOpenTooltip?.state) setOpenTooltip(true)

    return () => setOpenTooltip(false)
  }, [autoOpenTooltip])

  return (
    <ClickAwayListener onClickAway={handleClickAway} touchEvent='onTouchStart'>
      <LightTooltip
        onClose={handleTooltipClose}
        open={openTooltip}
        interactive
        disableFocusListener
        classes={{ tooltip: className, arrow: arrowClassName }}
        disableHoverListener
        disableTouchListener
        placement={position}
        arrow
        PopperProps={
          isTopMost && {
            style: {
              zIndex: 1400
            }
          }
        }
        title={
          <>
            {title}
            {disabledClickAway && (
              <IconButton
                onClick={handleTooltipClose}
                className={clsx(classes.button, buttonClassName, disabledClickAway && 'right-top')}
                size='small'>
                <CloseIcon />
              </IconButton>
            )}
          </>
        }>
        {typeof children === 'function'
          ? children({ handleTooltip, handleTooltipClose, setOpenTooltip, handleTootipOpen })
          : children}
      </LightTooltip>
    </ClickAwayListener>
  )
}
export default CustomizedTooltip
