import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.section,
    borderRadius: 10,

    '& > p': {
      fontSize: '.9rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem'
      }
    }
  },
  titleContainer: {
    display: 'inline-flex'
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    flexGrow: 1,
    marginTop: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      width: 300
    }
  }
}))
