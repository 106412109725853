import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '88%',
      margin: '0 auto'
    }
  },
  datePickerContainer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column'
  },
  datetimePickerContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    height: 47,

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  inputDatePicker: {
    margin: 0,
    minWidth: 120,

    '& input': {
      height: 4,
      backgroundColor: theme.palette.primary.contrastText,
      fontWeight: 500,
      fontSize: '.9rem'
    },
    '& fieldset': {
      height: 45,
      padding: theme.spacing(1.1, 1.8),

      borderColor: theme.palette.primary.main,
      borderRadius: 3
    },
    '& svg': {
      color: theme.palette.primary.main
    },
    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      transform: 'translate(14px, 11px) scale(1)',
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing(2.25)
    },
    '& > div > div': {
      marginLeft: 0
    },
    '& div > button': {
      width: 40,
      height: 40,
      borderRadius: 0,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40
    },
    [theme.breakpoints.up('sm')]: {
      width: 150
    }
  },
  inputTimePicker: {
    width: '40%',
    height: 40,
    minWidth: 120,
    '& > div > div': {
      marginLeft: 0
    },
    '& input': {
      fontSize: '0.85rem',
      height: 24,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      padding: theme.spacing(1.1, 1.8),
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40
    },
    '& label': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      fontWeight: 500,
      paddingRight: theme.spacing(),
      transform: 'translate(14px, 11px) scale(1)'
    },

    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 3
    },
    '&::placeholder': {
      color: theme.palette.disabled.main
    },
    '& div > button': {
      width: 40,
      height: 40,
      borderRadius: 0,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main
      }
    },

    [theme.breakpoints.up('sm')]: {
      width: 150
    }
  },
  dateEventContainer: {
    marginBottom: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}))
