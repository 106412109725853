export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
export const MIN_DATE = '1900-01-01'
export const MAX_DATE = '2100-01-01'
export const STRING_EMPTY = ''

export const NOT_FOUND_CODE = 404

export const PRICE_FORMAT = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '$'
}

export const DISCOUNT_TYPES = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'

export const EVENT_INSCRIPTION_STATES = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled'
}

export const SALE_ORDER_STATES = {
  PAID: 'Paid',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired'
}

export const FILE_FORMATS = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const CONTENT_TYPES = {
  APPLICATION_PDF: 'application/pdf',
  APPLICATION_EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  APLICATION_WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  IMAGE_PNG: 'image/png',
  IMAGE_JPG: 'image/jpg',
  IMAGE_JPEG: 'image/jpeg',
  MULTIPART_FORM_DATA: 'multipart/form-data'
}

export const GENDERS = {
  MALE: 'Male',
  FEMALE: 'Female',
  NONBINARY: 'NonBinary',
  OTHER: 'Other'
}

export const FILE_EXTENSIONS = ['.pdf', '.xlsx', '.docx', '.png', '.jpg', 'jpeg']

export const ANALYTICS_EVENTS = {
  ACCESS: {
    LANDING_ORGANIZATION_FROM_BANNER: 'access_landing_organization_from_banner',
    LANDING_ORGANIZATION: 'access_landing_organization',
    LANDING_ORGANIZATION_FROM_MAIN_PAGE: 'access_organization_from_main_page'
  },

  ACTION: {
    CREATE_ORGANIZATION: 'action_create_organization',
    VIEW_MORE_QUESTIONS: 'action_view_more_questions',
    EXPAND_FREQUENTLY_QUESTION_FOR_VIEW: 'action_expand_frequently_question_for_view'
  },
  DROPOUT: {
    USER_REGISTER: 'dropout_user_register'
  }
}

export const PAYMENT_TYPES = {
  MercadoPago: 'MercadoPago',
  PaymentLocation: 'PaymentLocation',
  BankAccount: 'BankAccount',
  PaymentUrl: 'PaymentUrl'
}

export const USER_ROLE = {
  ATHLETE: { value: 'Athlete', label: 'deportista' },
  ORGANIZER: { value: 'Organizer', label: 'organizador' },
  TIMEKEEPER: { value: 'Timekeeper', label: 'cronometrista' }
}

export const OTHER_OPTION = {
  label: 'Otras',
  value: 'Other'
}

export const URL_PATTERN =
  // eslint-disable-next-line
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

export const SECTION_STATES = {
  EXPANDED_SECTION: 'expandedSection'
}

export const EVENT_STATES = {
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  SUSPENDED: 'Suspended',
  FINALIZED: 'Finalized',
  SOLD_OUT: 'SoldOut',
  RESULTS: 'Results'
}

export const SUSPENDED_REASON_TYPE = {
  CLIMATOLOGICAL: 'Climatological',
  NATURAL_DISASTERS: 'NaturalDisasters',
  LEGAL: 'Legal',
  INSUFFICIENT_QUOTA: 'InsufficientQuota',
  DESCESO: 'Desceso'
}

export const OPTION_OTHER = {
  value: 'Other',
  label: 'Otra '
}

export const SUSPENDED_REASON_STATE = {
  [SUSPENDED_REASON_TYPE.CLIMATOLOGICAL]: 'Climatológico',
  [SUSPENDED_REASON_TYPE.NATURAL_DISASTERS]: 'Desastres naturales',
  [SUSPENDED_REASON_TYPE.LEGAL]: 'Legal',
  [SUSPENDED_REASON_TYPE.INSUFFICIENT_QUOTA]: 'Cupo insuficiente',
  [SUSPENDED_REASON_TYPE.DESCESO]: 'Desceso',
  [OPTION_OTHER.value]: OPTION_OTHER.label
}

export const CONTACT_TYPES = {
  WHATSAPP: 'WhatsApp',
  EMAIL: 'Email'
}

export const ARGENTINA_COUNTRY = {
  label: 'Argentina',
  value: '01fa2de7-519f-4089-a33c-d48483ea0f20'
}

export const FEE_TYPES = {
  MercadoPago: 'MercadoPago',
  Sportmetric: 'Sportmetric'
}

export const FEE_OFFLINE_NAME = 'Offline'
export const FEE_GENERAL = 'General'

export const INFORMATION_NAME_FIELDS = {
  DISCIPLINES: 'disciplines',
  ORGANIZATIONS: 'organizations'
}
