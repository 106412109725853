import React from 'react'
import { useFormikContext } from 'formik'
import { EditableRichText } from 'shared'
import { STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ProblemDescriptionSection.style'

const ProblemDescriptionSection = () => {
  const classes = useStyles()
  const { values, setFieldValue, errors, setValues } = useFormikContext()

  const handleChangeRichText = (e) =>
    setValues((values) => ({
      ...values,
      description: e?.getHTML() || STRING_EMPTY,
      descriptionText: e?.getText().trim() || STRING_EMPTY
    }))

  return (
    <SectionBlock title='Detalle del Problema'>
      <EditableRichText
        entity={values}
        altMode
        setStatusSection={(state) => setFieldValue('sectionState', state)}
        field='description'
        repositoryId={values.id}
        className={classes.editor}
        activeModeEdit={true}
        error={errors.description || errors.descriptionText}
        onChange={handleChangeRichText}
      />
    </SectionBlock>
  )
}

export default ProblemDescriptionSection
