import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  scheduleTitle: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  addDescriptionLink: {
    fontSize: '.875rem',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',

    '&.disabled': {
      color: theme.palette.disabled.main,
      cursor: 'auto'
    }
  },
  editorContainer: {
    marginTop: theme.spacing(2),
    '&.edit': {
      backgroundColor: theme.palette.primary.contrastText
    }
  },
  editor: {
    '&.activeEdit': {
      '& .ql-container, .ql-editor': {
        minHeight: 430,
        [theme.breakpoints.up('sm')]: {
          minHeight: 300
        }
      }
    },
    '&.error': {
      '& .ql-toolbar': {
        border: '1px solid #f44336'
      },
      '& .ql-container': {
        border: '1px solid #f44336'
      },
      '& .ql-editor': {
        '&:focus': {
          border: '1px solid #f44336'
        }
      }
    }
  },
  saveButton: {
    width: 200,
    height: 48,
    alignSelf: 'flex-start',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}))
