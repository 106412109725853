import React from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { Formik, useFormikContext } from 'formik'
import { EditableRichText, SectionLayoutDialog } from 'shared'
import { OTHER_OPTION, STRING_EMPTY, SUSPENDED_REASON_TYPE } from 'utils/constants'
import * as Yup from 'yup'

import { useStyles } from './SuspendEventDialog.style'

const OPTION_OTHER = {
  value: 'Other',
  label: 'Otra '
}

const SUSPENDED_REASON_OPTIONS = [
  { value: SUSPENDED_REASON_TYPE.CLIMATOLOGICAL, label: 'Climatológico' },
  {
    value: SUSPENDED_REASON_TYPE.NATURAL_DISASTERS,
    label: 'Desastres naturales'
  },
  {
    value: SUSPENDED_REASON_TYPE.LEGAL,
    label: 'Legal'
  },
  {
    value: SUSPENDED_REASON_TYPE.INSUFFICIENT_QUOTA,
    label: 'Cupo insuficiente'
  },
  {
    value: SUSPENDED_REASON_TYPE.DESCESO,
    label: 'Desceso'
  },
  {
    value: OPTION_OTHER.value,
    label: OPTION_OTHER.label
  }
]

const SuspendEventDialog = ({ open, onClose, onConfirm }) => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)

  const { setValues, values: formState } = useFormikContext()

  const values = {
    suspensionReason: formState.suspensionReason || SUSPENDED_REASON_TYPE.CLIMATOLOGICAL,
    suspensionDescription: formState.suspensionDescription || STRING_EMPTY,
    suspensionDescriptionText: STRING_EMPTY,
    suspensionReasonOther: formState.suspensionReasonOther || STRING_EMPTY,
    sectionState: true
  }

  const validationSchema = Yup.object().shape({
    suspensionDescriptionText: Yup.string()
      .nullable()
      .required('Debe ingresar una descripción sobre la suspensión')
      .max(1000, 'La descripción de la suspensión debe tener hasta 1000 caracteres')
      .trim(),
    suspensionReason: Yup.string().nullable().required('Debe escoger una opción'),
    suspensionReasonOther: Yup.string().when('suspensionReason', {
      is: (suspensionReason) => suspensionReason === OPTION_OTHER.value,
      then: Yup.string()
        .required('Debe ingresar el motivo')
        .max(25, 'El motivo tiene un máximo de 25 caracteres')
        .trim()
    })
  })

  const onSubmit = async ({ suspensionReason, suspensionDescription, suspensionReasonOther }) => {
    setValues((values) => ({
      ...values,
      suspensionReason,
      suspensionReasonOther,
      suspensionDescription
    }))

    onConfirm()
  }

  const handleChangeRichText = (setValues) => (e) =>
    setValues((values) => ({
      ...values,
      suspensionDescription: e?.getHTML() || STRING_EMPTY,
      suspensionDescriptionText: e?.getText().trim() || STRING_EMPTY
    }))

  return (
    <SectionLayoutDialog
      title='Suspensión de evento'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <Formik
        initialValues={values}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          errors,
          isValid,
          touched,
          isSubmitting,
          handleChange,
          setValues,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <form className={classes.mainContainer} onSubmit={handleSubmit}>
            <div className={classes.headerContainer}>
              <Typography className={classes.subtitle}>
                La suspensión de un evento solo puede realizarse cuando existan situaciones de
                fuerza mayor que impidan el normal desarrollo del mismo y que, en algunos casos,
                supongan un riesgo para las personas participantes.{' '}
                <Typography color='error' variant='caption' className={classes.alertText}>
                  Si en este momento tu evento no cuenta con inscriptos te recomendamos
                  despublicarlo.
                </Typography>
              </Typography>

              <Typography className={classes.subtitle}>
                ¿Cuál es el motivo de la suspensión del evento {event.name}?
                <br />
                Selecciona de las opciones a continuación la que mejor se adapte.
              </Typography>

              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='suspend-reason' className={classes.labelSelect}>
                  Motivo de suspensión
                </InputLabel>
                <Select
                  variant='outlined'
                  labelId='suspend-reason'
                  className={classes.select}
                  value={values.suspensionReason}
                  name='suspensionReason'
                  onChange={handleChange}
                  MenuProps={{
                    PaperProps: {
                      className: classes.menuContainer
                    }
                  }}
                  onBlur={handleBlur}
                  label='Motivo de suspensión'>
                  {SUSPENDED_REASON_OPTIONS.map((x) => (
                    <MenuItem
                      value={x.value}
                      key={x.value}
                      className={classes.item}
                      classes={{
                        selected: classes.itemSelected
                      }}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>

                {values.suspensionReason === OTHER_OPTION.value && (
                  <TextField
                    className={classes.input}
                    name='suspensionReasonOther'
                    autoComplete='off'
                    fullWidth
                    value={values.suspensionReasonOther}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.suspensionReasonOther || 'Máximo 25 caracteres.'}
                    error={touched.suspensionReasonOther && Boolean(errors.suspensionReasonOther)}
                    label='Otro motivo'
                    required
                    variant='outlined'
                    size='small'
                    margin='none'
                  />
                )}
              </FormControl>

              <Typography className={classes.subtitle}>
                Por favor detallá a continuación un breve texto de lo acontecido para mejor
                comprensión de los deportistas. Este texto será comunicado a todos los deportistas
                inscriptos y pre inscriptos.
              </Typography>
            </div>

            <div className={classes.editorContainer}>
              <EditableRichText
                entity={values}
                altMode
                setStatusSection={(state) => setFieldValue('sectionState', state)}
                field='suspensionDescription'
                repositoryId={values.id}
                disabledActionImageButton
                activeModeEdit={true}
                placeholder='Ingrese el motivo de la suspensión del evento, este paso es obligatorio para continuar.'
                className={classes.editor}
                error={errors.suspensionDescription || errors.suspensionDescriptionText}
                onChange={handleChangeRichText(setValues)}
              />
            </div>

            <Typography variant='caption' className={classes.helperText}>
              *Los eventos no pueden suspenderse por más de 30 días, pasado ese tiempo los eventos
              deben cancelarse. El costo de la devolución de entrada corresponde al
              organizador.&nbsp;
              <Typography variant='caption' color='error'>
                Recordá que el evento sólo puede suspenderse una vez.
              </Typography>
            </Typography>

            <Button
              variant='contained'
              color='primary'
              disabled={isSubmitting || !isValid || !values.sectionState}
              className={classes.saveButton}
              type='submit'>
              Guardar
            </Button>
          </form>
        )}
      </Formik>
    </SectionLayoutDialog>
  )
}

export default SuspendEventDialog
