import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import { Formik } from 'formik'
import moment from 'moment'
import { ROUTES } from 'routes'
import { EventsProxy } from 'services'
import { CONTACT_TYPES, STRING_EMPTY } from 'utils/constants'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { ContactSection } from '../ContactSection'
import { InformationSection } from '../InformationSection'
import { ProblemDescriptionSection } from '../ProblemDescriptionSection'
import { ReportProblemDialog } from '../ReportProblemDialog'
import { ReportProblemSection } from '../ReportProblemSection'

import { useStyles } from './NeedHelpForm.style'

const NeedHelpForm = () => {
  const classes = useStyles()
  const history = useHistory()

  const { event } = useSelector((state) => state.events)

  const [openReportProblemDialog, setOpenReportProblemDialog] = useState(false)

  const organizationOptions = event.organizations
    .filter((x) => x.organization.id !== event.organization.id)
    .map((x) => x.organization)

  const values = useMemo(
    () => ({
      problem: STRING_EMPTY,
      disciplinesSelect: event.disciplines.map((x) => ({ label: x.name, value: x.id })),
      name: event.name,
      countryId: event.countryId || STRING_EMPTY,
      areaLevel1Id: event.areaLevel1Id || STRING_EMPTY,
      fromDate: moment(event.from),
      fromTime: moment(event.from),
      toDate: moment(event.to),
      toTime: moment(event.to),
      address: event.address,
      latitude: event.latitude,
      organizationsSelect: organizationOptions,
      enableAddOrganization: !!organizationOptions.length,
      longitude: event.longitude,
      description: STRING_EMPTY,
      state: event.state,
      location:
        event.latitude !== 0 && event.longitude !== 0
          ? `${event.latitude} | ${event.longitude}`
          : STRING_EMPTY,

      sectionState: true,
      undefinedLocation: !event.address && event.latitude === 0 && event.longitude === 0,
      id: event.id,
      phone: event.phone || STRING_EMPTY,
      phoneContact: STRING_EMPTY,
      eMail: STRING_EMPTY,
      hasWhatsApp: event.hasWhatsApp || false,
      organizationPhone: event.organization.phone,
      isEventOwner: event.organization.loggedUserMetadata?.canEdit,
      useOrganizationPhone: event.phone === event.organization.phone
    }),
    [event]
  )
  const validationSchema = Yup.object().shape({
    problem: Yup.string().required('Debe seleccionar una opción'),
    contact: Yup.string().required('Debe escoger un medio de contacto'),
    phoneContact: Yup.string().when('contact', {
      is: (contact) => contact === CONTACT_TYPES.WHATSAPP,
      then: Yup.string()
        .required('Debe ingresar un número de teléfono')
        .min(5, 'El número de teléfono debe tener al menos 5 caracteres')
        .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
        .matches(
          /^(\+?\d{1,3})?[-.\s]?9?[-.\s]?(\(?\d{2,4}\)?)?[-.\s]?\d{2,4}[-.\s]?\d{2,4}$/,
          'El número de teléfono ingresado no es válido'
        )
    }),
    eMail: Yup.string().when('contact', {
      is: (contact) => contact === CONTACT_TYPES.EMAIL,
      then: Yup.string()
        .required('Ingresa una dirección de correo')
        .email('El e-mail es inválido')
        .min(5, 'El e-mail es demasiado corto') // Add
        .max(254, 'El e-mail es demasiado largo')
    }),
    description: Yup.string()
      .nullable()
      .test(
        'required-test',
        'Debe ingresar la descripción del problema',
        (value = STRING_EMPTY) => {
          const textContent = value.replace(/<[^>]*>/g, '').trim()
          const hasMedia = /<(img|ul|ol|li|blockquote|hr)[^>]*>/i.test(value)

          return textContent || hasMedia
        }
      ),
    descriptionText: Yup.string()
      .nullable()
      .max(4000, 'La descripción tiene un máximo de 4000 caracteres')
  })

  const onSubmit = async ({ problem, description, contact, phoneContact, eMail, id }) => {
    try {
      const eventProblem = {
        problem,
        description,
        contact,
        phone: phoneContact,
        eMail
      }

      const proxy = new EventsProxy()
      await proxy.SendEventProblem(id, eventProblem)

      setOpenReportProblemDialog(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <ReportProblemSection />

          <ProblemDescriptionSection />

          <InformationSection />

          <ContactSection />

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.sendButton}
            disabled={isSubmitting || !isValid || !values.sectionState}
            endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}>
            Enviar
          </Button>

          <ReportProblemDialog
            open={openReportProblemDialog}
            onClose={() => history.push(`${ROUTES.EVENTS.PROFILE}/${event.slugUrl}`)}
          />
        </form>
      )}
    </Formik>
  )
}

export default NeedHelpForm
