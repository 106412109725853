import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  editor: {
    '&.error': {
      '& .ql-toolbar': {
        border: `1px solid ${theme.palette.error.main}`
      },
      '& .ql-container': {
        border: `1px solid  ${theme.palette.error.main}`
      },
      '& .ql-editor': {
        '&:focus': {
          border: `1px solid  ${theme.palette.error.main}`
        }
      }
    }
  }
}))
