import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  eventCircuitSection: {
    display: 'flex',
    flexDirection: 'column',

    '&.results': {
      width: 350
    }
  },
  addEventCircuitLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '.875rem',
    fontWeight: 600,

    '&.disabled': {
      color: theme.palette.disabled.main,
      cursor: 'auto'
    }
  },
  saveButton: {
    width: 200,
    height: 48,
    alignSelf: 'flex-start',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  editor: {
    '&.error': {
      '& .ql-toolbar': {
        border: `1px solid ${theme.palette.error.main}`
      },
      '& .ql-container': {
        border: `1px solid  ${theme.palette.error.main}`
      },
      '& .ql-editor': {
        '&:focus': {
          border: `1px solid  ${theme.palette.error.main}`
        }
      }
    }
  }
}))
