import axios from 'axios'

import BaseProxy from './BaseProxy'

export class OrganizationsProxy extends BaseProxy {
  async get(id) {
    const response = await axios.get(`${this._baseUrl}/organizations/${id}`)

    return response.data
  }

  async post(organization) {
    const response = await axios.post(`${this._baseUrl}/organizations`, organization)

    return response.data
  }

  async put(id, organization) {
    const response = await axios.put(`${this._baseUrl}/organizations/${id}`, organization)

    return response.data
  }

  async putImage(id, file, type) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.put(
      `${this._baseUrl}/organizations/${id}/image?type=${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  }

  async getEvents(organizationId, mode, page, pageSize) {
    const urlParams = this._makeUrlParams({ page, pageSize, mode })
    const response = await axios.get(
      `${this._baseUrl}/organizations/${organizationId}/events${urlParams}`
    )

    return response.data
  }

  async postEvent(id, event) {
    const response = await axios.post(`${this._baseUrl}/organizations/${id}/events`, event)
    return response.data
  }

  async getMembersPage(id, page, pageSize) {
    const response = await axios.get(
      `${this._baseUrl}/organizations/${id}/members?page=${page}&pageSize=${pageSize}`
    )

    return response.data
  }

  async follow(id) {
    const response = await axios.put(`${this._baseUrl}/organizations/${id}/follow`)
    return response.data
  }

  async unFollow(id) {
    const response = await axios.put(`${this._baseUrl}/organizations/${id}/unfollow`)
    return response.data
  }

  async getSearch(searchObj, page, pageSize, orderBy, order) {
    const urlParams = this._makeUrlParams({
      ...searchObj,
      page,
      pageSize,
      orderBy,
      order
    })
    const response = await axios.get(`${this._baseUrl}/organizations${urlParams}`)
    return response.data
  }

  async getOrganizationsCategoriesCloneSuggestions() {
    const response = await axios.get(`${this._baseUrl}/organizations/categories-clone-suggestions`)
    return response.data
  }

  async getSearchMetadata(searchObj) {
    const urlParams = this._makeUrlParams({ ...searchObj })
    const response = await axios.get(`${this._baseUrl}/organizations/search-metadata/${urlParams}`)
    return response.data
  }

  async getUnPublishedEvents(id, page, pageSize) {
    const urlParams = this._makeUrlParams({ page, pageSize })
    const response = await axios.get(
      `${this._baseUrl}/organizations/${id}/un-published-events${urlParams}`
    )
    return response.data
  }

  async deleteImage(id, type) {
    const urlParams = this._makeUrlParams({ type })
    const response = await axios.delete(`${this._baseUrl}/organizations/${id}/image${urlParams}`)
    return response.data
  }

  async getCustomFields(id) {
    const response = await axios.get(`${this._baseUrl}/organizations/${id}/event-custom-fields`)
    return response.data
  }

  async postCustomField(id, customField) {
    const response = await axios.post(
      `${this._baseUrl}/organizations/${id}/event-custom-fields`,
      customField
    )
    return response.data
  }

  async putCustomField(id, customField) {
    const response = await axios.put(
      `${this._baseUrl}/organizations/${id}/event-custom-fields/${customField.id}`,
      customField
    )
    return response.data
  }

  async deleteCustomField(id, customFieldId) {
    const response = await axios.delete(
      `${this._baseUrl}/organizations/${id}/event-custom-fields/${customFieldId}`
    )
    return response.data
  }

  async postCustomFieldFile(id, file) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.post(
      `${this._baseUrl}/event-inscriptions/event-custom-fields/${id}/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  }

  async postSendQuery(query) {
    const response = await axios.post(`${this._baseUrl}/organizations/send-query`, query)

    return response.data
  }

  async acceptAdminUser(organizationId) {
    const response = await axios.put(
      `${this._baseUrl}/organizations/${organizationId}/accept-admin-user`
    )

    return response.data
  }

  async rejectAdminUser(organizationId) {
    const response = await axios.put(
      `${this._baseUrl}/organizations/${organizationId}/reject-admin-user`
    )

    return response.data
  }

  async sendOnboardingInformation(organizationId, onboardingInformation) {
    const response = await axios.post(
      `${this._baseUrl}/organizations/${organizationId}/send-onboarding-information`,
      onboardingInformation
    )

    return response.data
  }

  async getServiceLetterReport(id) {
    const response = await axios.get(
      `${this._baseUrl}/organizations/${id}/download-service-letter-report`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }
    )
    return response.data
  }

  async getActiveServiceLetterReport(id) {
    const response = await axios.get(
      `${this._baseUrl}/organizations/${id}/download-active-service-letter-report`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/pdf'
        }
      }
    )
    return response.data
  }

  async postOrganizationContract(id, values) {
    const response = await axios.post(
      `${this._baseUrl}/organizations/${id}/organization-contracts`,
      values
    )
    return response.data
  }

  async putOrganizationContract(id, values) {
    const response = await axios.put(
      `${this._baseUrl}/organizations/${id}/organization-contracts`,
      values
    )

    return response.data
  }

  async getEventOrganizationSuggestions(searchText, page, pageSize) {
    const urlParams = this._makeUrlParams({ searchText, page, pageSize })

    const response = await axios.get(
      `${this._baseUrl}/organizations/event-organization-suggestions${urlParams}`
    )

    return response.data
  }
}

export default OrganizationsProxy
