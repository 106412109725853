import React from 'react'
import { withBreakpoints } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './LoadingPlaceholder.style'

const LoadingPlaceholder = ({ breakpoints }) => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  const title = 'Cargando...'

  if (breakpoints === 'xs') {
    return (
      <ContentLoader
        className={classes.container}
        backgroundColor={bgColor}
        title={title}
        foregroundColor={fgColor}>
        <rect className={classes.card} x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
      </ContentLoader>
    )
  } else if (breakpoints === 'sm') {
    return (
      <ContentLoader
        className={classes.container}
        backgroundColor={bgColor}
        title={title}
        foregroundColor={fgColor}>
        <rect className={classes.card} x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
        <rect className={classes.card} x='300' y='0' rx='0' ry='0' width='100%' height='100%' />
      </ContentLoader>
    )
  } else {
    return (
      <ContentLoader
        className={classes.container}
        backgroundColor={bgColor}
        title={title}
        foregroundColor={fgColor}>
        <rect className={classes.card} x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
        <rect className={classes.card} x='300' y='0' rx='0' ry='0' width='100%' height='100%' />
        <rect className={classes.card} x='600' y='0' rx='0' ry='0' width='100%' height='100%' />
        <rect className={classes.card} x='900' y='0' rx='0' ry='0' width='100%' height='100%' />
      </ContentLoader>
    )
  }
}

export default withBreakpoints(LoadingPlaceholder)
