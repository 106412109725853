import React, { useState } from 'react'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import { EllipseStatus, SectionLayoutDialog } from 'shared'
import { EVENT_INSCRIPTION_STATES, SALE_ORDER_STATES } from 'utils/constants'
import { getPriceFormat } from 'utils/functions'

import { useStyles } from './MercadoPagoInfoDialog.style'

const INSCRIPTION_STATES = {
  [EVENT_INSCRIPTION_STATES.PENDING]: {
    title: 'PREINSCRIPTO',
    label: <EllipseStatus status='yellow' title='Preinscripto' />
  },
  [EVENT_INSCRIPTION_STATES.ACCEPTED]: {
    title: 'INSCRIPTO',
    label: <EllipseStatus status='green' title='Inscripto' />
  },
  [EVENT_INSCRIPTION_STATES.CANCELLED]: {
    title: 'ANULADO',
    label: <EllipseStatus status='red' title='Anulado' />
  }
}

const MercadoPagoInfoDialog = ({ inscription }) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [openMercadoPagoInfoDialog, setOpenMercadoPagoInfoDialog] = useState(false)

  const { saleOrder, firstName, lastName, idNumber, state, distance, category, ticketTypeId } =
    inscription

  const ticketItem = saleOrder?.items.find((x) => x.ticketTypeId === ticketTypeId)

  const discountItem = saleOrder?.items.find((x) => !!x.eventDiscountCode)

  const inscriptionState = INSCRIPTION_STATES[state]

  const ticketPrice = ticketItem ? getPriceFormat(ticketItem.unitPrice) : 0

  return (
    saleOrder?.state === SALE_ORDER_STATES.PAID && (
      <>
        <Button
          variant='contained'
          color='primary'
          className={classes.actionButton}
          onClick={() => setOpenMercadoPagoInfoDialog(true)}>
          MP
        </Button>

        <SectionLayoutDialog
          open={openMercadoPagoInfoDialog}
          onClose={() => setOpenMercadoPagoInfoDialog(false)}
          title='MERCADO PAGO'>
          <div className={classes.container}>
            <Typography variant='h6' color='primary' className={classes.title}>
              {isDesktop ? (
                `${firstName} ${lastName} - DNI ${idNumber}`
              ) : (
                <>
                  {firstName} {lastName}
                  <br />
                  DNI {idNumber}
                </>
              )}
            </Typography>
            <div className={classes.mainContainer}>
              <div className={classes.content}>
                {isDesktop ? (
                  <Typography
                    title={`Distancia: ${distance.name} / Categoría: ${category.name}`}
                    className={classes.distanceAndCategory}>
                    Distancia: <strong>{distance.name} / </strong> Categoría:
                    <strong> {category.name}</strong>
                  </Typography>
                ) : (
                  <>
                    <Typography
                      title={`Distancia: ${distance.name}`}
                      className={classes.distanceAndCategory}>
                      Distancia: <strong>{distance.name} </strong>
                    </Typography>
                    <Typography
                      title={`Categoría: ${category.name}`}
                      className={classes.distanceAndCategory}>
                      Categoría: <strong> {category.name}</strong>
                    </Typography>
                  </>
                )}
                <Typography title={`Ticket: ${ticketItem?.ticketTypeName} (${ticketPrice})`}>
                  Ticket:&nbsp;
                  <strong>
                    {ticketItem?.ticketTypeName} ({ticketPrice})
                  </strong>
                </Typography>
                {discountItem && (
                  <Typography
                    title={`Código de descuento: ${
                      discountItem.eventDiscountCode.name
                    } (${getPriceFormat(discountItem.unitPrice)})`}>
                    {isDesktop ? 'Código de descuento' : 'Código Dto'}:&nbsp;
                    <strong>
                      {discountItem.eventDiscountCode.name} (
                      {getPriceFormat(discountItem.unitPrice)})
                    </strong>
                  </Typography>
                )}
                <Typography title={`Importe pagado: ${getPriceFormat(saleOrder?.totalAmount)}`}>
                  Importe pagado:&nbsp;
                  <strong>{getPriceFormat(saleOrder?.totalAmount)}</strong>
                </Typography>
              </div>
              <Typography variant='h6' className={classes.state} title={inscriptionState.title}>
                ESTADO {inscriptionState.label} {inscriptionState.title}
              </Typography>
            </div>
          </div>
        </SectionLayoutDialog>
      </>
    )
  )
}
export default MercadoPagoInfoDialog
