import React, { useState } from 'react'
import { NumericFormat, numericFormatter } from 'react-number-format'
import { useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { AccessTime as AccessTimeIcon, Info as InfoIcon } from '@material-ui/icons'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik, useFormikContext } from 'formik'
import moment from 'moment'
import { CustomizedTooltip, CustomTextField, NumericField, StyledSwitch } from 'shared'
import {
  DATE_FORMAT,
  MAX_DATE,
  MIN_DATE,
  PRICE_FORMAT,
  STRING_EMPTY,
  TIME_FORMAT
} from 'utils/constants'
import { calculatePercentage, getFeeAmount } from 'utils/functions'
import * as Yup from 'yup'

import { SettingMercadoPagoDialog } from '../SettingMercadoPagoDialog'

import { useStyles } from './TicketForm.style'

const OPTIONS = {
  Category: { label: 'categorías', key: 'Category' },
  Distance: { label: 'distancias', key: 'Distance' }
}

const FORM_STATES = {
  FORM_CREATE: 'create',
  FORM_UPDATE: 'update'
}

const INSCRIPTION_FREE_PRICE = 0

const FEE_DECIMAL = 2

const TicketForm = ({ values, onSubmit, items, eventIsFree, state, activeScrolling }) => {
  const classes = useStyles()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { values: formState } = useFormikContext()
  const { distances, categories, eventBankAccounts, paymentLocations, paymentParams, event } =
    useSelector((state) => state.events)

  const [openSettingMercadoPagoDialog, setOpenSettingMercadoPagoDialog] = useState(false)

  const activeEventBankAccount = eventBankAccounts.some((x) => x.isActive)
  const activePaymentLocation = paymentLocations.some((x) => x.isActive)

  const onCloseSettingMercadoPagoDialog = () => setOpenSettingMercadoPagoDialog(false)

  const handleChangeDate = (setValues, field, format, timeField, defaultTime) => (date) =>
    setValues((values) => ({
      ...values,
      [field]: moment(date).isValid() ? moment(date).format(format) : date,
      ...(timeField && {
        [timeField]:
          !values[timeField] && !!date && moment(date).isValid() ? defaultTime : values[timeField]
      })
    }))

  const handleNameUniqueness = (value) => {
    return !formState.ticketTypes.some((e) => {
      if (state === FORM_STATES.FORM_UPDATE) {
        return false
      }
      return e.isActive && e.name.toUpperCase() === value?.toUpperCase()
    })
  }

  const handleSelectAllOption = (setFieldValue, options, hasInscription) => () => {
    if (hasInscription) return

    const optionsSelected = options.reduce((acc, { id }) => ({ ...acc, [id]: true }), {})
    setFieldValue('distancesOrCategoriesItems', optionsSelected)
  }

  const handleClearOptionSelected = (setFieldValue, hasInscription) => () => {
    if (hasInscription) return

    setFieldValue('distancesOrCategoriesItems', {})
  }

  const handleChangeOption = (setValues, id) => (e) =>
    setValues(({ distancesOrCategoriesItems, ...resProps }) => ({
      ...resProps,
      distancesOrCategoriesItems: { ...distancesOrCategoriesItems, [id]: e.target.checked }
    }))

  const getPriceDescription = (
    bearCost,
    price,
    mercadoPagoEnabled,
    mercadoPagoPrice,
    sportmetricPorcentage,
    mercadoPagoFee,
    othersPaymentsEnabled
  ) => {
    const numberPrice = numericFormatter(Number(price).toString(), PRICE_FORMAT)
    if (!mercadoPagoEnabled) return `El deportista paga: ${numberPrice}`
    else {
      const numberMercadoPagoPrice = numericFormatter(
        Number(mercadoPagoPrice).toString(),
        PRICE_FORMAT
      )

      if (!bearCost) {
        return `El deportista paga: ${numberMercadoPagoPrice} con mercado Pago${
          othersPaymentsEnabled ? ` y ${numberPrice} con los otros medios` : ''
        }. 
        \n El organizador recibe ${numberPrice} por ticket.`
      } else {
        const organizationPrice = (
          Number(price) -
          getFeeAmount(price, sportmetricPorcentage, FEE_DECIMAL) -
          getFeeAmount(price, mercadoPagoFee, FEE_DECIMAL)
        ).toFixed(2)

        const bearCostOrganizationPrice = numericFormatter(
          Number(organizationPrice).toString(),
          PRICE_FORMAT
        )
        return `El deportista paga: ${numberPrice}. \nEl organizador recibe: ${bearCostOrganizationPrice} por ticket de mercado pago${
          othersPaymentsEnabled ? ` y ${numberPrice} con los otros medios` : ''
        }.`
      }
    }
  }

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .nullable()
        .required('Debe ingresar el nombre')
        .trim()
        .max(100, 'El nombre tiene un máximo de 100 caracteres')
        .test('name-validation', 'Ya existe un ticket con este nombre', (value) =>
          handleNameUniqueness(value)
        ),
      quota: Yup.number()
        .typeError('Ingrese un número válido')
        .test(
          'test-negative',
          'La cantidad de cupos tiene que ser un número entero positivo. Dejarlo en blanco o cero (Sin límite)',
          (value = 0) => value >= 0
        )
        .integer('Ingrese un número entero')
        .max(99999999, 'Se puede ingresar hasta 8 dígitos')
        .test(
          'test-quota',
          'test quota error message',
          (value = 0, { createError, parent }) =>
            value === 0 ||
            value >= parent.inscriptionsCount ||
            createError({
              message: `El cupo debe ser mayor o igual al número de inscriptos (${parent.inscriptionsCount})`
            })
        ),
      price: Yup.number()
        .typeError('Ingrese un número válido')
        .test(
          'test-negative',
          'El precio del ticket no debe ser un número negativo',
          (value = 0) => value >= 0
        )
        .max(999999.99, 'Se puede ingresar hasta 6 dígitos')
        .when('eventIsFree', {
          is: (eventIsFree) => !eventIsFree,
          then: (schema) => schema.required('Debe ingresar el precio')
        }),
      saleFrom: Yup.mixed()
        .nullable()
        .required('Debe ingresar una fecha y hora')
        .test('valid-date', 'Formato de fecha/hora invalida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha/hora inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), DATE_FORMAT)
        )
        .test(
          'saleFrom',
          'La fecha de inicio de venta debe ser anterior al cierre del evento',
          (value) => moment(value).isSameOrBefore(moment(event.to).format(DATE_FORMAT), DATE_FORMAT)
        )
        .when('saleToDate', {
          is: (saleToDate) => !!saleToDate && moment(saleToDate).isValid(),
          then: (schema) =>
            schema.test(
              'sale-from',
              'La fecha de apertura de venta debe ser anterior al cierre de venta',
              (value, { parent }) =>
                moment(value).isSameOrBefore(moment(parent.saleToDate).format(DATE_FORMAT))
            )
        }),

      saleToDate: Yup.mixed()
        .nullable()
        .required('Debe ingresar una fecha y hora')
        .test('valid-date', 'Formato de fecha/hora invalida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha/hora inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), DATE_FORMAT)
        )
        .test(
          'saleToDate',
          `La fecha de cierre de venta debe ser igual o anterior al ${moment(event.to).format(
            'DD/MM/YYYY'
          )}`,
          (value) => moment(value).isSameOrBefore(moment(event.to).format(DATE_FORMAT), DATE_FORMAT)
        )
        .when('saleFrom', {
          is: (saleFrom) => !!saleFrom && moment(saleFrom).isValid(),
          then: (schema) =>
            schema.test(
              'saleTo',
              'La fecha de cierre de venta debe ser posterior a la apertura de venta',
              (value, { parent }) =>
                moment(
                  `${moment(value).format(DATE_FORMAT)} ${moment(parent.saleToTime).format(
                    TIME_FORMAT
                  )}`
                ).isAfter(moment(parent.saleFrom))
            )
        }),
      paymentUrl: Yup.string().when('paymentUrlEnabled', {
        is: (paymentUrlEnabled) => paymentUrlEnabled,
        then: (schema) =>
          schema
            .required('Debe ingresar el link de pago')
            .min(5, 'El link debe tener al menos 5 caracteres')
            .max(256, 'Se puede ingresar hasta 256 caracteres')
            .matches(
              /[-a-zA-Z0-9@:%._'+~#=]{1,256}\.[a-zA-Z0-9()]{1,15}\b([-a-zA-Z0-9()@:%_'+.~#?&'/'/=]*)/,
              'El link ingresado no es válido'
            )
      }),
      saleToTime: Yup.mixed()
        .nullable()
        .required('Debe ingresar una fecha y hora')
        .test('valid-date', 'Formato de fecha/hora invalida', (value) => moment(value).isValid()),
      mercadoPagoEnabled: Yup.boolean().test(
        'method-selected',
        'Debe seleccionar al menos un método de pago',
        (value, { parent }) =>
          parent.eventIsFree ||
          parent.price === INSCRIPTION_FREE_PRICE ||
          value ||
          parent.bankAccountsEnabled ||
          parent.paymentLocationsEnabled ||
          parent.paymentUrlEnabled
      ),
      mercadoPagoPrice: Yup.number().when('mercadoPagoEnabled', {
        is: (mercadoPagoEnabled) => mercadoPagoEnabled,
        then: (schema) =>
          schema
            .required('Debe configurar el precio de mercado pago')
            .positive('El precio no puede ser $0')
      }),
      distancesOrCategoriesItems: Yup.object().test(
        'required',
        'Debe seleccionar al menos una opción',
        (value) => !!Object.values(value).filter(Boolean).length
      )
    },
    ['saleFrom', 'saleToDate', 'saleToTime']
  )

  const PriceInput = (props) => {
    const { inputRef, onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value !== STRING_EMPTY ? Number(values.value) : ''
            }
          })
        }}
        {...PRICE_FORMAT}
      />
    )
  }

  const handleChangeMercadoPagoEnabled = (setValues, setOpenTooltip) => (e) => {
    const mercadoPagoEnabled = e.target.checked

    setValues((values) => ({
      ...values,
      mercadoPagoEnabled,
      ...(mercadoPagoEnabled &&
        values.price &&
        values.mercadoPagoFee &&
        values.sportmetricFeePercentage &&
        Number(values.price) !== INSCRIPTION_FREE_PRICE &&
        getMercadoPagoValues(values, values.bearCost, values.price))
    }))

    if (!values.bearCost) setOpenTooltip(e.target.checked)
  }

  const handleOpenSettingMercadoPagoDialog = (dialogEnabled, setOpenTooltip) => () => {
    if (!dialogEnabled) setOpenSettingMercadoPagoDialog(true)

    setOpenTooltip(false)
  }

  const calculateFinalCost = (price, sportmetricFeePercentage, mercadoPagoFeePercentage) => {
    const result = (
      -price /
      (sportmetricFeePercentage / 100 + mercadoPagoFeePercentage / 100 - 1)
    ).toFixed(2)
    return Number(result)
  }

  const getMercadoPagoValues = (values, bearCost, price) => {
    const mercadoPagoPrice = bearCost
      ? price
      : calculateFinalCost(
          price,
          calculatePercentage(values.sportmetricFeePercentage),
          calculatePercentage(values.mercadoPagoFee)
        )

    return {
      mercadoPagoFeeAmount: getFeeAmount(mercadoPagoPrice, values.mercadoPagoFee, FEE_DECIMAL),
      sportmetricFeeAmount: getFeeAmount(
        mercadoPagoPrice,
        values.sportmetricFeePercentage,
        FEE_DECIMAL
      ),
      mercadoPagoPrice
    }
  }

  const handleChangePrice = (setValues) => (e) => {
    const price = e.target.value
    const ticketFree = Number(price) === INSCRIPTION_FREE_PRICE

    setValues((values) => ({
      ...values,
      price,
      mercadoPagoEnabled: !ticketFree ? values.mercadoPagoEnabled : false,
      paymentLocationsEnabled: !ticketFree ? values.paymentLocationsEnabled : false,
      bankAccountsEnabled: !ticketFree ? values.bankAccountsEnabled : false,
      ...(values.mercadoPagoEnabled &&
        values.price &&
        values.mercadoPagoFee &&
        values.sportmetricFeePercentage &&
        !ticketFree &&
        getMercadoPagoValues(values, values.bearCost, price))
    }))
  }

  const handleBearCost = (setValues) => (e) => {
    const bearCost = e.target.checked
    setValues((values) => ({
      ...values,
      bearCost,
      ...(values.mercadoPagoEnabled &&
        values.price &&
        values.mercadoPagoFee &&
        values.sportmetricFeePercentage &&
        !(Number(values.price) === INSCRIPTION_FREE_PRICE) &&
        getMercadoPagoValues(values, bearCost, values.price))
    }))
  }

  const ticketValues = {
    ...values,
    inscriptionsCount: values.inscriptionsCount || 0,
    paymentUrlEnabled: !!values.paymentUrl && paymentParams.paymentUrlSelected,
    isActive: true
  }

  return (
    <Formik
      initialValues={ticketValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}>
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
        isSubmitting
      }) => {
        const hasSomeOptionSelected = !!Object.values(values.distancesOrCategoriesItems).filter(
          Boolean
        ).length
        const options =
          values.associatedBy === OPTIONS.Distance.key
            ? distances
                .filter((x) => items.some((d) => d.eventDistance.id === x.id))
                .map(({ name, ...restProps }, index) => ({
                  ...restProps,
                  name: `Distancia ${index + 1} - ${name}`
                }))
            : categories.filter((x) => items.some((c) => c.eventCategory.id === x.id))

        const freeInscriptionPrice =
          values.price === STRING_EMPTY || values.price === INSCRIPTION_FREE_PRICE

        const hasInscription = values.inscriptionsCount > 0

        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.fieldContainer}>
              <div className={classes.formGroup}>
                <Typography
                  className={clsx(classes.label, hasInscription && 'disabled')}
                  variant='h6'
                  color='primary'>
                  Nombre*
                </Typography>
                <CustomTextField
                  value={values.name}
                  placeholder='Nombre de la inscripción'
                  variant='outlined'
                  name='name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  disabled={hasInscription}
                  error={touched.name && Boolean(errors.name)}
                  helperText={errors.name}
                  className={classes.textField}
                />
              </div>
              <div className={classes.formGroup}>
                <Typography className={classes.label} variant='h6' color='primary'>
                  Cantidad disponible (opcional)
                </Typography>
                <NumericField
                  value={values.quota}
                  placeholder='Número de inscriptos'
                  variant='outlined'
                  name='quota'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.quota && Boolean(errors.quota)}
                  helperText={errors.quota}
                  className={classes.textField}
                />
              </div>
              {!eventIsFree && (
                <div className={classes.formGroup}>
                  <Typography className={classes.label} variant='h6' color='primary'>
                    Precio*
                  </Typography>

                  <TextField
                    value={values.price}
                    placeholder='Ej: $2000'
                    variant='outlined'
                    name='price'
                    onChange={handleChangePrice(setValues)}
                    onBlur={handleBlur}
                    InputProps={{
                      inputComponent: PriceInput
                    }}
                    error={touched.price && Boolean(errors.price)}
                    helperText={errors.price}
                    className={classes.textField}
                  />
                </div>
              )}
            </div>
            {!eventIsFree && (
              <div className={classes.paymentMethodContainer}>
                <Typography color='primary' variant='h6' className={classes.underlineTitle}>
                  Asocie la modalidad de pago que desea habilitar para esta inscripción
                </Typography>
                <CustomizedTooltip
                  title='La comisión de MercadoPago elegida debe coincidir con la configurada en tu cuenta de MercadoPago.'
                  isTopMost
                  position={isDesktop ? 'right-end' : 'top-start'}
                  className={classes.infoTooltip}
                  autoHide={activeScrolling}
                  arrowClassName={classes.arrowTooltip}>
                  {({ handleTooltip, setOpenTooltip }) => (
                    <div className={classes.mercadoPagoControl}>
                      <FormControlLabel
                        className={clsx(
                          classes.formControl,
                          (!formState.mercadoPagoCode || freeInscriptionPrice) && 'disabled'
                        )}
                        checked={values.mercadoPagoEnabled}
                        disabled={!formState.mercadoPagoCode || freeInscriptionPrice}
                        onChange={handleChangeMercadoPagoEnabled(setValues, setOpenTooltip)}
                        classes={{ label: classes.formControlLabel }}
                        control={<Checkbox name='mercadoPagoEnabled' color='primary' />}
                        label='Mercado Pago.'
                      />
                      <Typography
                        color='primary'
                        variant='caption'
                        className={clsx(
                          classes.mercadoPagoLink,
                          (!formState.mercadoPagoCode || freeInscriptionPrice) && 'disabled'
                        )}
                        onClick={handleOpenSettingMercadoPagoDialog(
                          freeInscriptionPrice || !formState.mercadoPagoCode,
                          setOpenTooltip
                        )}>
                        {values.bearCost ? 'Simular costo' : 'Configurar valor del ticket'}
                      </Typography>
                      <SettingMercadoPagoDialog
                        open={openSettingMercadoPagoDialog}
                        onClose={onCloseSettingMercadoPagoDialog}
                        state={values.bearCost ? 'simulate' : 'setting'}
                        values={values}
                      />

                      {!values.bearCost && !freeInscriptionPrice && formState.mercadoPagoCode && (
                        <InfoIcon className={classes.infoIcon} onClick={handleTooltip} />
                      )}
                    </div>
                  )}
                </CustomizedTooltip>
                <Typography className={classes.bearCostText}>
                  Asumir los costos. (Los costos del servicio se deducirán de tus ingresos por
                  inscripciones)
                  <StyledSwitch
                    checked={values.bearCost}
                    name='bearCost'
                    disabled={!formState.mercadoPagoCode || freeInscriptionPrice}
                    onChange={handleBearCost(setValues)}
                  />
                </Typography>
                <FormControlLabel
                  className={clsx(
                    classes.formControl,
                    (!activeEventBankAccount || freeInscriptionPrice) && 'disabled'
                  )}
                  classes={{ label: classes.formControlLabel }}
                  checked={values.bankAccountsEnabled}
                  disabled={!activeEventBankAccount || freeInscriptionPrice}
                  onChange={(e) => setFieldValue('bankAccountsEnabled', e.target.checked)}
                  control={<Checkbox name='bankAccountsEnabled' color='primary' />}
                  label='Transferencia Bancaria.'
                />
                <FormControlLabel
                  className={clsx(
                    classes.formControl,
                    (!activePaymentLocation || freeInscriptionPrice) && 'disabled'
                  )}
                  checked={values.paymentLocationsEnabled}
                  disabled={!activePaymentLocation || freeInscriptionPrice}
                  onChange={(e) => setFieldValue('paymentLocationsEnabled', e.target.checked)}
                  classes={{ label: classes.formControlLabel }}
                  control={<Checkbox name='paymentLocationsEnabled' color='primary' />}
                  label='Pago en Efectivo.'
                />
                <div className={classes.containerPaymentUrl}>
                  <FormControlLabel
                    className={clsx(
                      classes.formControl,
                      (!paymentParams.paymentUrlSelected || freeInscriptionPrice) && 'disabled'
                    )}
                    checked={values.paymentUrlEnabled}
                    disabled={!paymentParams.paymentUrlSelected || freeInscriptionPrice}
                    onChange={(e) => {
                      setValues((values) => ({
                        ...values,
                        paymentUrl: STRING_EMPTY,
                        paymentUrlEnabled: e.target.checked
                      }))
                    }}
                    classes={{ label: classes.formControlLabel }}
                    control={<Checkbox name='paymentUrl' color='primary' />}
                    label='Link de pago.'
                  />
                  {values.paymentUrlEnabled && paymentParams.paymentUrlSelected && (
                    <CustomTextField
                      value={values.paymentUrl}
                      placeholder='Ingrese el link de pago*'
                      variant='outlined'
                      name='paymentUrl'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete='off'
                      error={touched.paymentUrl && Boolean(errors.paymentUrl)}
                      helperText={errors.paymentUrl}
                      className={classes.textFieldPaymentUrl}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <CustomizedTooltip
                              title={
                                'Verifica si el precio del ticket es el mismo que el del enlace.'
                              }
                              position={isDesktop ? 'right-end' : 'bottom-end'}
                              className={classes.tooltipPaymentUrl}
                              autoHide={activeScrolling}
                              arrowClassName={classes.arrow}
                              isTopMost>
                              {({ handleTooltip }) => (
                                <IconButton color='primary' size='small'>
                                  <InfoIcon
                                    className={classes.buttonInfoIcon}
                                    onClick={handleTooltip}
                                  />
                                </IconButton>
                              )}
                            </CustomizedTooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                </div>

                {!values.eventIsFree && !!errors.mercadoPagoEnabled && (
                  <Typography variant='caption' color='error' className={classes.error}>
                    {errors.mercadoPagoEnabled}
                  </Typography>
                )}
                {values.price !== STRING_EMPTY &&
                  !errors.price &&
                  (!values.mercadoPagoEnabled ||
                    (Boolean(values.mercadoPagoFee) && Boolean(values.mercadoPagoPrice))) &&
                  !errors.mercadoPagoEnabled && (
                    <Typography className={classes.priceDescription} color='primary' variant='h6'>
                      {getPriceDescription(
                        values.bearCost,
                        values.price,
                        values.mercadoPagoEnabled,
                        values.mercadoPagoPrice,
                        values.sportmetricFeePercentage,
                        values.mercadoPagoFee,
                        [values.paymentLocationsEnabled, values.bankAccountsEnabled].some(Boolean)
                      )}
                    </Typography>
                  )}
              </div>
            )}
            <div className={classes.saleDateSection}>
              <div className={classes.timeContainer}>
                <div className={classes.saleDateContainer}>
                  <Typography color='primary' variant='h6' className={classes.saleDateLabel}>
                    Inicio de la venta
                  </Typography>
                  <div className={classes.datePickerContainer}>
                    <KeyboardDatePicker
                      className={clsx(classes.inputDateField, classes.dateField)}
                      autoOk
                      name='saleFrom'
                      format='DD/MM/YYYY'
                      variant='inline'
                      margin='none'
                      id='date-picker-inline'
                      inputVariant='outlined'
                      value={values.saleFrom}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      minDateMessage=''
                      maxDateMessage=''
                      invalidDateMessage=''
                      placeholder='dd/mm/aaaa'
                      error={touched.saleFrom && Boolean(errors.saleFrom)}
                      helperText={errors.saleFrom}
                      onChange={handleChangeDate(setValues, 'saleFrom', 'YYYY-MM-DD')}
                    />
                  </div>
                </div>
                <div className={classes.saleDateContainer}>
                  <Typography color='primary' variant='h6' className={classes.saleDateLabel}>
                    Fin de la venta
                  </Typography>
                  <div className={classes.saleToContainer}>
                    <KeyboardDatePicker
                      className={clsx(classes.inputDateField, classes.dateField)}
                      autoOk
                      name='saleToDate'
                      format='DD/MM/YYYY'
                      variant='inline'
                      margin='none'
                      id='date-picker-inline'
                      inputVariant='outlined'
                      value={values.saleToDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      minDateMessage=''
                      maxDateMessage=''
                      invalidDateMessage=''
                      onBlur={handleBlur}
                      placeholder='dd/mm/aaaa'
                      error={touched.saleToDate && Boolean(errors.saleToDate)}
                      onChange={handleChangeDate(
                        setValues,
                        'saleToDate',
                        'YYYY-MM-DD',
                        'saleToTime',
                        moment('23:59', 'HH:mm').format()
                      )}
                    />
                    <KeyboardTimePicker
                      className={clsx(`${classes.inputDateField} timePicker`, classes.dateField)}
                      autoOk
                      name='saleToTime'
                      variant='inline'
                      onBlur={handleBlur}
                      inputVariant='outlined'
                      value={values.saleToTime}
                      keyboardIcon={<AccessTimeIcon />}
                      placeholder='hh:mm am'
                      invalidDateMessage=''
                      minDateMessage=''
                      maxDateMessage=''
                      error={touched.saleToTime && Boolean(errors.saleToTime)}
                      onChange={handleChangeDate(setValues, 'saleToTime')}
                    />
                  </div>
                  {(errors.saleToDate || errors.saleToTime) && (
                    <Typography variant='caption' color='error'>
                      {errors.saleToDate || errors.saleToTime}
                    </Typography>
                  )}
                </div>
              </div>

              <Typography className={classes.saleHelperText}>
                La hora de inicio de venta de ticket es por defecto las 00:00 hs del día
                seleccionado
              </Typography>
            </div>

            <div className={clsx(classes.asocciateContainer, hasInscription && 'disabled')}>
              <div className={classes.associateOptionsContainer}>
                <Typography color='primary' variant='h6' className={classes.underlineTitle}>
                  Asociar con las siguientes {OPTIONS[values.associatedBy].label}
                </Typography>
              </div>
              {!!options.length &&
                (!hasSomeOptionSelected ? (
                  <Typography
                    color='primary'
                    variant='h6'
                    className={classes.link}
                    onClick={handleSelectAllOption(setFieldValue, options, hasInscription)}>
                    Seleccionar todas
                  </Typography>
                ) : (
                  <Typography
                    color='primary'
                    variant='h6'
                    className={classes.link}
                    onClick={handleClearOptionSelected(setFieldValue, hasInscription)}>
                    Limpiar selección
                  </Typography>
                ))}

              {options.map((x) => {
                const checked = values.distancesOrCategoriesItems[x.id] ?? false

                const hasInscription = values.items
                  ?.filter(
                    (i) =>
                      distances.some((d) => d.id === i.inscriptionFormItem.eventDistanceId) &&
                      categories.some((c) => c.id === i.inscriptionFormItem.eventCategoryId)
                  )
                  .some(
                    (i) =>
                      (values.associatedBy === OPTIONS.Distance.key
                        ? i.inscriptionFormItem?.eventDistanceId === x.id
                        : i.inscriptionFormItem?.eventCategoryId === x.id) &&
                      !!i.inscriptionFormItem?.inscriptionsCount
                  )

                return (
                  <FormControlLabel
                    checked={checked}
                    className={clsx(classes.formControl, hasInscription && 'disabled')}
                    key={x.id}
                    disabled={hasInscription}
                    onChange={handleChangeOption(setValues, x.id)}
                    classes={{ label: classes.optionLabel }}
                    control={<Checkbox name={x.name} color='primary' />}
                    label={
                      <div className={classes.separator}>
                        <Typography className={classes.optionLabel}>{x.name}</Typography>
                      </div>
                    }
                  />
                )
              })}
              {errors.distancesOrCategoriesItems && (
                <Typography variant='caption' color='error' className={classes.error}>
                  {errors.distancesOrCategoriesItems}
                </Typography>
              )}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                color='primary'
                variant='contained'
                className={classes.createButton}
                type='submit'
                endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}
                disabled={!isValid || isSubmitting}>
                {state === FORM_STATES.FORM_CREATE ? 'Crear' : 'Guardar'}
              </Button>
              {(errors.mercadoPagoPrice ||
                (!values.saleFrom && errors.saleFrom) ||
                (!values.saleTo && errors.saleTo)) && (
                <CustomizedTooltip
                  title={
                    state === FORM_STATES.FORM_CREATE
                      ? `Para crear ticket/s debes tener definida la fecha de inscripción${
                          !formState.isFree ? ' y configurada la modalidad/es de pago' : ''
                        }.`
                      : `Para guardar las modificaciones debe estar definida la fecha de inscripción${
                          !formState.isFree ? ' y tener configurada la modalidad de pago' : ''
                        }.`
                  }
                  position={isDesktop ? 'right-end' : 'bottom-end'}
                  className={classes.tooltip}
                  autoHide={activeScrolling}
                  arrowClassName={classes.arrow}>
                  {({ handleTooltip }) => (
                    <InfoIcon className={classes.buttonInfoIcon} onClick={handleTooltip} />
                  )}
                </CustomizedTooltip>
              )}
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default TicketForm
