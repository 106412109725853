import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import moment from 'moment'
import { ROUTES } from 'routes'
import { CustomizedTooltip, SectionLayoutDialog } from 'shared'
import { updateEvent } from 'state/modules/events'
import { FEE_GENERAL, FEE_TYPES } from 'utils/constants'

import { useStyles } from './ModalityEventDialog.style'

const EVENT_MODALITY_STATES = {
  FREE_MODALITY: {
    value: 'Free',
    label: 'GRATIS'
  },
  PAY_MODALITY: {
    value: 'Pay',
    label: 'PAGO'
  }
}

const EventModalityDialog = ({ open, onClose, onAction }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [eventModalityValue, setEventModalityValue] = useState(
    EVENT_MODALITY_STATES.FREE_MODALITY.value
  )

  const { user } = useSelector((state) => state.auth)
  const { event, updateEventPending, ticketTypeFees } = useSelector((state) => state.events)

  const handleChange = (e) => {
    setEventModalityValue(e.target.value)
  }

  const handleUpdateEventState = async () => {
    const { organization } = event

    const updateSuccess = await dispatch(
      updateEvent({
        ...event,
        isFree: eventModalityValue === EVENT_MODALITY_STATES.FREE_MODALITY.value
      })
    )

    if (!updateSuccess) return

    const serviceLetterSignedRequired =
      eventModalityValue === EVENT_MODALITY_STATES.PAY_MODALITY.value &&
      (!organization.hasActiveContract || updateServiceLetterRequired)

    if (!serviceLetterSignedRequired) history.push(`${ROUTES.EVENTS.INSCRIPTION}/${event.slugUrl}`)
    else {
      onAction(true)
      onClose()
    }
  }

  const isAdminOwnerEvent = event.organization.createdById === user?.id
  const payModalityEventEnabled = isAdminOwnerEvent || event.organization.hasActiveContract

  const validateServiceLetter = (organization, fees) => {
    const sportmetricFeeSorted = fees
      .filter((x) => x.feeType === FEE_TYPES.Sportmetric)
      .sort((a, b) => moment(a.createdAt).isAfter(b.createdAt))

    const sportmetricCurrentFee = sportmetricFeeSorted?.find(
      (x) => x.name === FEE_GENERAL && !x.expiredAt
    )

    return (
      organization.hasActiveContract &&
      moment(organization.activeContractSignedAt).isBefore(sportmetricCurrentFee?.createdAt)
    )
  }

  const updateServiceLetterRequired =
    Array.isArray(ticketTypeFees) &&
    !!event &&
    validateServiceLetter(event.organization, ticketTypeFees)

  const eventPayModalityDisabled =
    !payModalityEventEnabled || (updateServiceLetterRequired && !isAdminOwnerEvent)

  return (
    <SectionLayoutDialog
      title='MODALIDAD DE EVENTO'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='gender'
          name='modalityEvent'
          value={eventModalityValue}
          onChange={handleChange}>
          <FormControlLabel
            value={EVENT_MODALITY_STATES.FREE_MODALITY.value}
            className={classes.formControlLabel}
            classes={{ label: classes.radioLabel }}
            control={<Radio color='primary' className={classes.radio} />}
            label={EVENT_MODALITY_STATES.FREE_MODALITY.label}
          />
          <FormLabel
            component='p'
            className={clsx(
              classes.label,
              eventModalityValue === EVENT_MODALITY_STATES.FREE_MODALITY.value && 'selected'
            )}>
            En esta modalidad, los tickets deben ser todos gratuitos, si tienes algún ticket con
            costo seleccioná la modalidad PAGO.
          </FormLabel>

          <FormControlLabel
            value={EVENT_MODALITY_STATES.PAY_MODALITY.value}
            className={classes.formControlLabel}
            disabled={eventPayModalityDisabled}
            classes={{ label: classes.radioLabel }}
            control={
              <Radio
                color='primary'
                className={clsx(classes.radio, eventPayModalityDisabled && 'disabled')}
                disabled={eventPayModalityDisabled}
              />
            }
            label={
              <Typography
                className={clsx(classes.radioLabel, eventPayModalityDisabled && 'disabled')}>
                {EVENT_MODALITY_STATES.PAY_MODALITY.label}

                {updateServiceLetterRequired && !isAdminOwnerEvent && (
                  <CustomizedTooltip
                    position='top'
                    className={classes.tooltip}
                    arrowClassName={classes.arrowTooltip}
                    isTopMost
                    disabledClickAway
                    buttonClassName={classes.tooltipButton}
                    title={
                      <>
                        Para poder hacer un evento pago, el referente de la organización debe
                        aceptar las{' '}
                        <Link
                          to={`${ROUTES.LANDING.ORGANIZATION}?section=frequentQuestionsSection&&questionSelected=3`}
                          className={classes.link}>
                          nuevas comisiones.
                        </Link>
                      </>
                    }>
                    {({ handleTooltip }) => (
                      <IconButton onClick={handleTooltip} size='small'>
                        <InfoIcon color='primary' />
                      </IconButton>
                    )}
                  </CustomizedTooltip>
                )}

                {!payModalityEventEnabled && (
                  <CustomizedTooltip
                    position='top'
                    className={classes.tooltip}
                    arrowClassName={classes.arrowTooltip}
                    isTopMost
                    disabledClickAway
                    title='Para poder crear un evento pago, el referente de la organización debe firmar la carta de servicio'>
                    {({ handleTooltip }) => (
                      <IconButton onClick={handleTooltip} size='small'>
                        <InfoIcon color='primary' />
                      </IconButton>
                    )}
                  </CustomizedTooltip>
                )}
              </Typography>
            }
          />

          <FormLabel
            component='p'
            className={clsx(
              classes.label,
              eventModalityValue === EVENT_MODALITY_STATES.PAY_MODALITY.value && 'selected'
            )}>
            En esta modalidad, los tickets tienen un costo, aunque puedes ofrecer algunos tickets
            gratuitos.
          </FormLabel>
        </RadioGroup>
      </FormControl>

      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        disabled={updateEventPending}
        endIcon={updateEventPending && <CircularProgress size={16} color='primary' />}
        onClick={handleUpdateEventState}>
        Aceptar
      </Button>
    </SectionLayoutDialog>
  )
}

export default EventModalityDialog
