import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  sendButton: {
    height: 48,
    width: 250,
    alignSelf: 'center',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    '& span': {
      fontSize: '1.125rem',
      fontWeight: 500
    }
  }
}))
