import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { EditableRichText } from 'shared'

import { useStyles } from './ScheduleItem.style'

const ScheduleItem = ({ scheduleItem = {}, eventId, index, disabled }) => {
  const classes = useStyles()

  const { errors, setFieldValue } = useFormikContext()
  const [activeEdit, setActiveEdit] = useState(!!scheduleItem.description)

  return (
    <div className={classes.itemContainer}>
      <div className={classes.headerContainer}>
        <Typography color='primary' variant='h6' className={classes.scheduleTitle}>
          {scheduleItem.title}
        </Typography>

        {!scheduleItem.description && !activeEdit && (
          <Typography
            color='primary'
            variant='h6'
            className={clsx(classes.addDescriptionLink, disabled && 'disabled')}
            onClick={() => {
              if (disabled) return

              setActiveEdit(true)
            }}>
            Agregar detalle
          </Typography>
        )}
      </div>

      {(activeEdit || !!scheduleItem.description) && (
        <EditableRichText
          entity={scheduleItem}
          placeholder={
            'Escriba el cronograma de su evento lo más detallado posible, recuerde detallar todos los horarios de  cada actividad y aclarar dirección o lugar,  ejemplo: \n08:00 hs Acreditación – Hotel Aconcagua \n08:30 hs Charla Técnica – Hotel Aconcagua \n09:15  hs Largada Categorías Mayores  \n10:30  hs Largada Categorías Infantiles \n12:00  hs Entrega de Premios \n \n Si tiene el cronograma en formato imagen puede subirla en esta sección utilizando el botón  añadir imagen'
          }
          repositoryId={eventId}
          field='description'
          altMode
          disabled={disabled}
          className={classes.editor}
          error={
            Array.isArray(errors.schedule)
              ? errors.schedule[index]?.description || errors.schedule[index]?.descriptionText
              : null
          }
          setStatusSection={(state) => setFieldValue('sectionState', state)}
          activeModeEdit={activeEdit}
          onChange={(e) => {
            setFieldValue(`schedule[${index}].title`, scheduleItem.title)
            setFieldValue(`schedule[${index}].description`, e?.getHTML() || '')
            setFieldValue(`schedule[${index}].descriptionText`, e?.getText().trim() || '')
            setFieldValue(`schedule[${index}].scheduleDate`, scheduleItem.scheduleDate)
          }}
        />
      )}
    </div>
  )
}

export default ScheduleItem
